import { HashRouter, BrowserRouter, Switch, Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import AuthenticatedRoute from './AuthenticatedRoute'
import UnauthenticatedOnlyRoute from './UnauthenticatedOnlyRoute'
import routes from './routes'
import { isElectron } from '../utils/helpers'

const Router = isElectron() ? HashRouter : BrowserRouter

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        {routes.map(route => {
          if (route.isPublic) {
            return (
              <Route
                key={route.path}
                component={route.c}
                exact={route.isExact === undefined ? true : route.isExact}
                {...route}
              />
            )
          }

          const RouteComponent = route.auth
            ? AuthenticatedRoute
            : UnauthenticatedOnlyRoute

          return (
            <RouteComponent
              key={route.path}
              path={route.path}
              exact={route.isExact === undefined ? true : route.isExact}
              component={route.c}
            />
          )
        })}
        <Redirect from='*' to='/' />
      </Switch>
    </Router>
  )
}
export default AppRouter
