import { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_NAME}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_NAME,
  storageBucket: `${process.env.REACT_APP_FIREBASE_NAME}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = firebase.initializeApp(firebaseConfig)

export const auth = app.auth()

export const useAuthUser = () => {
  const [user, setUser] = useState(undefined)

  auth.onAuthStateChanged(user => setUser(user))

  return user
}

export default auth
