export const getPatients = ({ patient }) => patient.list

export const getFetchPatientsStatus = ({ patient }) =>
  patient.actions.fetchAll.status
export const getAddPatientStatus = ({ patient }) => patient.actions.add.status
export const getEditPatientStatus = ({ patient }) => patient.actions.edit.status
export const getDeletePatientStatus = ({ patient }) =>
  patient.actions.delete.status

export const getPatientById = ({ patient }, id) =>
  patient.list.find(p => p.id === id)
