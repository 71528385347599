import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  Container,
  InfoContainer,
  Name,
  Description,
  Label,
  MenuContainer,
} from './style'
import ProductsPreviewContainer from '../../product/products-preview-container'
import { Button, Dropdown, NavLink } from '../../ui'
import { useAuthPrescriber, useDeleteProtocole } from '../../../utils/hooks'

const getUniqueProducts = takes => {
  return takes.reduce((acc, curr) => {
    const productHandles = curr.items.reduce(
      (acc2, curr2) =>
        acc2.includes(curr2.productHandle)
          ? acc2
          : [...acc2, curr2.productHandle],
      []
    )
    const newAcc = [...acc]
    productHandles.forEach(
      handle => !newAcc.includes(handle) && newAcc.push(handle)
    )

    return newAcc
  }, [])
}

const getDescription = protocol =>
  !!protocol?.description
    ? protocol.description.length > 100
      ? protocol.description.slice(0, 100).trim() + '...'
      : protocol.description
    : '–'

const ProtocolCard = ({ protocol }) => {
  const { authUser } = useAuthPrescriber()
  const { deleteProtocole } = useDeleteProtocole()

  const renderDuration = duration => {
    switch (duration) {
      case '1-month':
        return '1 mois'
      case '2-month':
        return '2 mois'
      case '3-month':
        return '3 mois'
      default:
        return '1 mois'
    }
  }

  return (
    <Container>
      <InfoContainer>
        <Name>{protocol.name}</Name>
        <Description>{getDescription(protocol)}</Description>
      </InfoContainer>
      <InfoContainer>
        <Label>Durée de la cure</Label>
        <Name>{renderDuration(protocol.recommendedDuration)}</Name>
      </InfoContainer>
      <InfoContainer $isCenter>
        <ProductsPreviewContainer
          productHandles={getUniqueProducts(protocol.takes)}
        />
      </InfoContainer>
      <InfoContainer $isCenter $isEnd>
        <Dropdown
          style={{ display: 'flex', alignItems: 'center' }}
          content={
            <MenuContainer>
              <NavLink as={Link} to={`/protocoles/${protocol.id}`}>
                Voir
              </NavLink>
              <NavLink
                as={Link}
                to={{
                  pathname: '/prescriptions/new',
                  search: '?step=1&protocoleId=' + protocol.id,
                }}
              >
                Utiliser
              </NavLink>
              {protocol.prescriberId === authUser.id && (
                <NavLink
                  onClick={() =>
                    deleteProtocole({ variables: { id: protocol.id } })
                  }
                >
                  Supprimer
                </NavLink>
              )}
            </MenuContainer>
          }
        >
          <Button iconLeft='menu' appearance='minimal' />
        </Dropdown>
      </InfoContainer>
    </Container>
  )
}
ProtocolCard.propTypes = {
  protocol: PropTypes.object.isRequired,
}

export default ProtocolCard
