import React from 'react'

import { LogoContainer } from './style'

const Logo = () => (
  <LogoContainer>
    <img
      src={process.env.REACT_APP_AWS_BUCKET + 'assets/images/logo-black.png'}
      alt='Logo - Compliment'
    />
  </LogoContainer>
)

export default Logo
