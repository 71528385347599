import styled from 'styled-components/macro'

export const ElementGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ControlContainer = styled.div`
  position: absolute;
  top: -5px;
  right: 100%;
  padding: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  > div {
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
`

export const Infos = styled.div`
  display: flex;
  margin-top: 4px;
`

export const InfosContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 1em 0;
`

export const CommissionInfo = styled.p`
  font-size: 12px;
  position: relative;
`

export const DiscountInfo = styled.p`
  font-size: 12px;
  position: relative;
  width: 50%;
`
