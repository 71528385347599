import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

import Layout from '../../../components/layout/naked'
import {
  Container,
  Content,
  FormContainer,
  IllustrationContainer,
  Image,
  Form,
  Title,
} from './style'
import { Field, Button, Label } from '../../../components/ui'
import DiscountCode from '../../../components/prescriber/discount-code'
import { setAuthPrescriber } from '../../../store/auth-user'
import {
  useAuthPrescriber,
  useEditPrescriberOnboarding,
  useCreateDiscountCodes,
} from '../../../utils/hooks'

const Schema = Yup.object().shape({
  first: Yup.string()
    .min(3, 'Minimum 3 charactères requis')
    .required('Champs obligatoire'),
  last: Yup.string()
    .min(3, 'Minimum 3 charactères requis')
    .required('Champs obligatoire'),
})

const sharedDiscountCodeParams = {
  discount_type: 'percentage',
  duration: 'single_use',
  first_time_customer_restriction: 'customer_must_not_exist_in_recharge',
}

const discountCodes = [
  { value: 0 },
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 15,
  },
  {
    value: 20,
  },
  {
    value: 25,
  },
]

const OnboardingPage = () => {
  const dispatch = useDispatch()
  const { authUser } = useAuthPrescriber()
  const {
    editPrescriber,
    loading: editLoading,
    reset: resetEdit,
  } = useEditPrescriberOnboarding()
  const {
    createDiscountCodes,
    addDiscountCode,
    loading: createDiscountCodesLoading,
  } = useCreateDiscountCodes()
  const [selectValue, setSelectedValue] = useState(0)

  useEffect(() => {}, [])

  useEffect(() => {
    if (!authUser.discountCode) {
    }
  }, [authUser])

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: { first: '', last: '' },
      validationSchema: Schema,
      onSubmit: values => {
        // add the discount codes to Hasura
        addDiscountCode({
          variables: {
            code5: values.first[0] + values.last + '5',
            code10: values.first[0] + values.last + '10',
            code15: values.first[0] + values.last + '15',
            code20: values.first[0] + values.last + '20',
            code25: values.first[0] + values.last + '25',
            percentage5: 5,
            percentage10: 10,
            percentage15: 15,
            percentage20: 20,
            percentage25: 25,
          },
        })
          .then(res => {
            editPrescriber({
              variables: {
                fn: (values.first ? values.first : authUser.firstName) || '',
                ln: (values.last ? values.last : authUser.lastName) || '',
                discount:
                  selectValue !== 0
                    ? values.first[0] + values.last + selectValue.toString()
                    : null,
                phone: authUser.phone,
                id: authUser.id,
              },
            })
              .then(res => {
                const data = res.data.update_prescriber.returning[0]
                discountCodes.forEach(d => {
                  if (d.value !== 0) {
                    const discountName = (
                      data.firstname[0] +
                      data.name +
                      d.value.toString()
                    ).toUpperCase()
                    try {
                      // create the discount codes on Shopify and Recharge
                      createDiscountCodes({
                        discount_code: discountName,
                        value_shopify: -d.value,
                        value_recharge: d.value,
                        ...sharedDiscountCodeParams,
                      })
                    } catch (e) {
                      console.log(e)
                      toast.error(
                        "Une erreur s'est produite dans la création d'un de vos codes promos"
                      )
                    }
                  }
                })

                dispatch(
                  setAuthPrescriber({
                    name: data.name,
                    firstname: data.firstname,
                    discount: data.discount,
                  })
                )
                resetEdit()
              })
              .catch(() => {
                toast.error("Une erreur s'est produite, réessayez plus tard")
                resetEdit()
              })
          })
          .catch(() => {
            toast.error("Une erreur s'est produite, réessayez plus tard")
          })
      },
    })

  if (authUser.firstName && authUser.lastName) {
    return <Redirect to='/' />
  }

  return (
    <Layout>
      <Container>
        <Content>
          <FormContainer>
            {authUser?.firstName}
            <Form onSubmit={handleSubmit}>
              <Title>Bienvenue 🙌</Title>
              <Field
                label='Votre prénom'
                name='first'
                type='text'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first}
                error={errors.first}
                touched={touched.first}
                isDisabled={editLoading}
              />
              <Field
                label='Votre nom de famille'
                name='last'
                type='text'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last}
                error={errors.last}
                touched={touched.last}
                isDisabled={editLoading}
              />

              <Label style={{ margin: '10px 0' }}>
                En tant qu’utilisateur, vous avez la possibilité d’offrir des
                réductions à vos patients et de générer des revenus via des
                commissions.
              </Label>
              <DiscountCode
                selectValue={selectValue}
                setSelectedValue={setSelectedValue}
                firstName={values.first}
                name={values.last}
                code={
                  values.first && values.last && selectValue !== 0
                    ? '(' +
                      values.first[0] +
                      values.last +
                      selectValue.toString() +
                      ')'
                    : ''
                }
              />
              <Button
                type='submit'
                isLoading={editLoading || createDiscountCodesLoading}
              >
                Continuer
              </Button>
            </Form>
          </FormContainer>
          <IllustrationContainer>
            <Image
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/onboarding.jpg'
              }
              alt='onboarding-image'
            />
          </IllustrationContainer>
        </Content>
      </Container>
    </Layout>
  )
}

export default OnboardingPage
