import { gql } from '@apollo/client'

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient(
    $email: String!
    $fn: String!
    $ln: String!
    $phone: String!
    $id: Int!
  ) {
    update_patient(
      where: { id: { _eq: $id } }
      _set: { firstname: $fn, email: $email, name: $ln, phone_number: $phone }
    ) {
      returning {
        email
        firstname
        id
        name
        phone_number
        prescriber_id
      }
    }
  }
`

export const PRESCRIBER_PATIENTS = gql`
  query getPrescriberPatients($prescriberId: Int!) {
    patient(where: { prescriber_id: { _eq: $prescriberId } }) {
      email
      firstname
      id
      name
      phone_number
      prescriptions {
        id
      }
    }
  }
`

export const ADD_PATIENT = gql`
  mutation AddPatient(
    $email: String!
    $fn: String!
    $ln: String!
    $phone: String!
    $prescriberId: Int!
  ) {
    insert_patient(
      objects: {
        email: $email
        firstname: $fn
        name: $ln
        phone_number: $phone
        prescriber_id: $prescriberId
      }
    ) {
      returning {
        email
        firstname
        id
        name
        phone_number
        prescriber_id
      }
    }
  }
`

export const DELETE_PATIENT = gql`
  mutation delete_patient($id: Int!) {
    delete_patient_by_pk(id: $id) {
      id
      firstname
      name
    }
  }
`
