import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Label,
  Description,
  ControlContainer,
  EditForm,
  Value,
  ElementGroup,
} from './style'
import { Icon, Input, Button } from '../../../ui'
import DiscountCode from '../../../prescriber/discount-code'

const ProfileItem = ({
  defaultValue,
  description,
  isEditing,
  isLoading,
  label,
  onSubmit,
  openEdit,
  valueSuffix,
  selectValue,
  setSelectedValue,
  type,
  firstName,
  lastName,
}) => {
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    isEditing && !type === 'select' && inputRef.current.focus()
  }, [isEditing, type])

  return (
    <ElementGroup>
      <Label>{label}</Label>
      {isEditing ? (
        <EditForm>
          {type === 'select' ? (
            <DiscountCode
              selectValue={selectValue}
              setSelectedValue={setSelectedValue}
              isEditable={true}
            />
          ) : (
            <Input
              value={value}
              onChange={e => setValue(e.target.value)}
              ref={inputRef}
              isDisabled={isLoading}
              inputContainerStyling={{ margin: '10px 0' }}
            />
          )}
          <Button
            onClick={() => onSubmit(value)}
            isLoading={isLoading}
            style={{ margin: '10px', maxHeight: '50px' }}
          >
            Enregistrer
          </Button>
        </EditForm>
      ) : (
        <Value>
          {defaultValue || '-'}
          {valueSuffix}
          {openEdit && (
            <ControlContainer>
              <Icon
                name='pencil'
                onClick={() => {
                  openEdit()
                  setValue(defaultValue)
                }}
              />
            </ControlContainer>
          )}
        </Value>
      )}
      <Description>{description}</Description>
    </ElementGroup>
  )
}

ProfileItem.defaultProps = {
  valueSuffix: '',
}

ProfileItem.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  openEdit: PropTypes.func,
  valueSuffix: PropTypes.string,
}

export default ProfileItem
