import { useState, useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import { Input, Button } from '../ui'
import {
  sortAscendingByProductName,
  normalizeString,
} from '../../utils/helpers'
import { useClickOutside } from '../../utils/hooks'
import {
  Container,
  InputContainer,
  CloseButtonContainer,
  SuggestionContainer,
  Title,
  ProductName,
  ProductImgContainer,
  MiniProductPreview,
  ProductBtn,
} from './style'
import ProductPreviewContainer from '../product/products-preview-container'

const RESULT_TYPE = [
  'nameResults',
  'tagResults',
  'ingredientResults',
  'contraindicationResults',
]

const getTitleByResultType = type =>
  ({
    nameResults: 'Suggestions',
    tagResults: 'Par catégories',
    ingredientResults: 'Par ingrédients',
    contraindicationResults: 'Par contre-indications',
  }[type])

const ProductSearchBar = ({
  setProductsToDisplay,
  products,
  searchBarStyling,
  onDetailsClicked,
  hasDetailsBtn = false,
  onAddClicked,
}) => {
  const CloseButtonContainerRef = useRef()
  const suggestionContainerRef = useRef()
  const inputRef = useRef()
  const [search, setSearch] = useState('')
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
  useClickOutside({
    ref: suggestionContainerRef,
    onClick: () => isSuggestionsOpen && setIsSuggestionsOpen(false),
    exceptionRefs: [CloseButtonContainerRef],
  })

  let suggestions = useMemo(() => {
    let base = {
      length: 0,
      nameResults: [],
      tagResults: [],
      ingredientResults: [],
      contraindicationResults: [],
    }

    if (!search) return base

    products.forEach(product => {
      if (!product.isShown) return

      if (normalizeString(product.name).includes(normalizeString(search))) {
        base.nameResults.push(product)
      } else if (
        product.tags.find(tag =>
          normalizeString(tag).includes(normalizeString(search))
        )
      ) {
        base.tagResults.push(product)
      } else if (
        product.ingredients.find(ingredient =>
          normalizeString(ingredient).includes(normalizeString(search))
        )
      ) {
        base.tagResults.push(product)
      } else if (
        normalizeString(product.contraindication || '').includes(
          normalizeString(search)
        )
      ) {
        base.contraindicationResults.push(product)
      }
      return
    })
    base.length =
      base.nameResults.length +
      base.tagResults.length +
      base.ingredientResults.length +
      base.contraindicationResults.length

    setIsSuggestionsOpen(true)

    return base
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleSearch = ({ target: { value } }) => setSearch(value)
  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    setProductsToDisplay(
      products
        .filter(
          ({ isShown, name }) =>
            isShown && normalizeString(name).includes(normalizeString(search))
        )
        .sort(sortAscendingByProductName)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <Container>
      <InputContainer>
        <Input
          ref={inputRef}
          onChange={debouncedChangeHandler}
          placeholder='Chercher un produit'
          iconLeft='search'
          style={searchBarStyling}
          onFocus={() =>
            !!search && !isSuggestionsOpen && setIsSuggestionsOpen(true)
          }
          onKeyDown={e => {
            if (e.code === 'Enter') {
              e.preventDefault()
            }
          }}
        />
        {search && (
          <CloseButtonContainer
            ref={CloseButtonContainerRef}
            onClick={() => {
              setSearch('')
              setIsSuggestionsOpen(false)
              inputRef.current.value = ''
              setProductsToDisplay(
                products
                  .filter(({ isShown }) => isShown)
                  .sort(sortAscendingByProductName)
              )
            }}
          >
            <Button appearance='ultra-minimal' iconLeft='cancel' />
          </CloseButtonContainer>
        )}
        {!!suggestions.length && isSuggestionsOpen && (
          <>
            <SuggestionContainer ref={suggestionContainerRef}>
              {RESULT_TYPE.map(resultType =>
                suggestions[resultType].length ? (
                  <>
                    <Title>{getTitleByResultType(resultType)}</Title>
                    {suggestions[resultType].map(product => (
                      <MiniProductPreview
                        key={product.handle}
                        onClick={() => {
                          setProductsToDisplay([product])
                          setSearch(product.name)
                          inputRef.current.value = product.name
                          setIsSuggestionsOpen(false)
                        }}
                      >
                        <ProductImgContainer>
                          <ProductPreviewContainer
                            size='s'
                            productHandles={[product.handle]}
                          />
                        </ProductImgContainer>
                        <ProductName>{product.name}</ProductName>
                        {hasDetailsBtn && (
                          <ProductBtn
                            onClickCapture={e => {
                              e.preventDefault()
                              if (onDetailsClicked) {
                                e.stopPropagation()
                                onDetailsClicked(product.handle)
                              }
                            }}
                          >
                            Details
                          </ProductBtn>
                        )}
                        {onAddClicked && (
                          <ProductBtn
                            onClickCapture={e => {
                              e.preventDefault()
                              if (onAddClicked) {
                                e.stopPropagation()
                                onAddClicked(product)
                              }
                            }}
                          >
                            Ajouter
                          </ProductBtn>
                        )}
                      </MiniProductPreview>
                    ))}
                  </>
                ) : null
              )}
            </SuggestionContainer>
          </>
        )}
      </InputContainer>
    </Container>
  )
}

ProductSearchBar.propTypes = {
  ProductSearchBar: PropTypes.func,
  products: PropTypes.array,
  searchBarStyling: PropTypes.object,
}

export default ProductSearchBar
