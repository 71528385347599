import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { ActionType } from '../constants'
import { getUserToken } from '../../store/auth-user'
import {
  editPrescriberFailed,
  editPrescriberLoading,
  editPrescriberReset,
  editPrescriberSuccess,
  addDiscountCodesSuccess,
  addDiscountCodesFailed,
  addDiscountCodesLoading,
  createDiscountCodes,
  getEditPrescriberStatus,
  getAddDiscountCodesStatus,
  addCalendlyCodeFailed,
  addCalendlyCodeLoading,
  addCalendlyCodeSuccess,
  getAddCalendlyCodeStatus,
} from '../../store/prescriber'
import {
  UPDATE_PRESCRIBER,
  INSERT_DISCOUNT_CODES,
  INSERT_CALENDLY_CODE,
  UPDATE_PRESCRIBER_ONBOARDING,
} from '../../services/queries'

export const useEditPrescriberOnboarding = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const editPrescriberStatus = useSelector(getEditPrescriberStatus)
  const [
    editPrescriber,
    {
      data: prescriberData,
      loading: prescriberLoading,
      error: prescriberError,
    },
  ] = useMutation(UPDATE_PRESCRIBER_ONBOARDING, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    prescriberLoading && dispatch(editPrescriberLoading())
  }, [prescriberLoading, dispatch])
  useEffect(() => {
    prescriberData && dispatch(editPrescriberSuccess(prescriberData))
  }, [prescriberData, dispatch])
  useEffect(() => {
    prescriberError && dispatch(editPrescriberFailed(prescriberError))
  }, [prescriberError, dispatch])

  return {
    data: prescriberData,
    /**
     * @param {object} values
     * @param {object} values.variables
     * @param {string} values.variables.email Prescriber email
     * @param {string} values.variables.fn Prescriber first name
     * @param {string} values.variables.ln Prescriber last name
     * @param {string} values.variables.phone Prescriber phone
     * @param {number} values.variables.id Prescriber id
     *
     * @return {promise}
     */
    editPrescriber,
    reset: () => dispatch(editPrescriberReset()),
    failed: editPrescriberStatus === ActionType.FAILED,
    loading: editPrescriberStatus === ActionType.LOADING,
    success: editPrescriberStatus === ActionType.SUCCESS,
  }
}

export const useEditPrescriber = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const editPrescriberStatus = useSelector(getEditPrescriberStatus)
  const [
    editPrescriber,
    {
      data: prescriberData,
      loading: prescriberLoading,
      error: prescriberError,
    },
  ] = useMutation(UPDATE_PRESCRIBER, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    prescriberLoading && dispatch(editPrescriberLoading())
  }, [prescriberLoading, dispatch])
  useEffect(() => {
    prescriberData && dispatch(editPrescriberSuccess(prescriberData))
  }, [prescriberData, dispatch])
  useEffect(() => {
    prescriberError && dispatch(editPrescriberFailed(prescriberError))
  }, [prescriberError, dispatch])

  return {
    data: prescriberData,
    /**
     * @param {object} values
     * @param {object} values.variables
     * @param {string} values.variables.email Prescriber email
     * @param {string} values.variables.fn Prescriber first name
     * @param {string} values.variables.ln Prescriber last name
     * @param {string} values.variables.phone Prescriber phone
     * @param {number} values.variables.id Prescriber id
     *
     * @return {promise}
     */
    editPrescriber,
    reset: () => dispatch(editPrescriberReset()),
    failed: editPrescriberStatus === ActionType.FAILED,
    loading: editPrescriberStatus === ActionType.LOADING,
    success: editPrescriberStatus === ActionType.SUCCESS,
  }
}

export const useCreateDiscountCodes = () => {
  const dispatch = useDispatch()
  const addDiscountCodesStatus = useSelector(getAddDiscountCodesStatus)

  const authToken = useSelector(getUserToken)

  const [
    addDiscountCode,
    {
      data: discountCodesData,
      loading: addDiscountCodeLoading,
      error: addDiscountCodeError,
    },
  ] = useMutation(INSERT_DISCOUNT_CODES, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    addDiscountCodeLoading && dispatch(addDiscountCodesLoading())
  }, [addDiscountCodeLoading, dispatch])
  useEffect(() => {
    discountCodesData && dispatch(addDiscountCodesSuccess(discountCodesData))
  }, [discountCodesData, dispatch])
  useEffect(() => {
    addDiscountCodeError &&
      dispatch(addDiscountCodesFailed(addDiscountCodeError))
  }, [addDiscountCodeError, dispatch])
  return {
    addDiscountCode,
    createDiscountCodes: data => dispatch(createDiscountCodes(data)),
    failed: addDiscountCodesStatus === ActionType.FAILED,
    loading: addDiscountCodesStatus === ActionType.LOADING,
    success: addDiscountCodesStatus === ActionType.SUCCESS,
  }
}

export const useCreateCalendlyCode = () => {
  const dispatch = useDispatch()
  const addCalendlyCodeStatus = useSelector(getAddCalendlyCodeStatus)

  const authToken = useSelector(getUserToken)

  const [
    addCalendlyCode,
    {
      data: calendlyData,
      loading: addCalendlyCodeisLoading,
      error: addCalendlyCodeError,
    },
  ] = useMutation(INSERT_CALENDLY_CODE, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    addCalendlyCodeisLoading && dispatch(addCalendlyCodeLoading())
  }, [addCalendlyCodeisLoading, dispatch])
  useEffect(() => {
    calendlyData && dispatch(addCalendlyCodeSuccess(calendlyData))
  }, [calendlyData, dispatch])
  useEffect(() => {
    addCalendlyCodeError &&
      dispatch(addCalendlyCodeFailed(addCalendlyCodeError))
  }, [addCalendlyCodeError, dispatch])
  return {
    addCalendlyCode,
    failed: addCalendlyCodeStatus === ActionType.FAILED,
    loading: addCalendlyCodeStatus === ActionType.LOADING,
    success: addCalendlyCodeStatus === ActionType.SUCCESS,
  }
}
