import { TakeType } from '../constants'

class Take {
  data = null

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get index() {
    return this.data.index
  }

  get label() {
    return this.data.label
  }

  get type() {
    return this.data.type || TakeType.IN_CURE
  }

  get isStandAlone() {
    return this.type === TakeType.STAND_ALONE
  }
}

export default Take
