import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Title, Hr } from './style'
import { useAuthPrescriber, useEditPrescriber } from '../../../utils/hooks'
import ProfileItem from './profile-item'
import { setAuthPrescriber } from '../../../store/auth-user'

const ProfileView = () => {
  const dispatch = useDispatch()
  const { authUser } = useAuthPrescriber()
  const [editField, setEditField] = useState('')
  const {
    editPrescriber,
    loading: editLoading,
    reset: resetEdit,
  } = useEditPrescriber()

  const onSubmit = value => {
    editPrescriber({
      variables: {
        fn: (editField === 'firstName' ? value : authUser.firstName) || '',
        ln: (editField === 'lastName' ? value : authUser.lastName) || '',
        phone: (editField === 'phone' ? value : authUser.phone) || '',
        enterpriseName: authUser.enterpriseName || '',
        tvaNumber: authUser.tvaNumber || '',
        invoiceAddress: authUser.invoiceAddress || '',
        bankAccount: authUser.bank_account || '',
        discount: authUser.discountCode,
        id: authUser.id,
      },
    })
      .then(res => {
        const data = res.data.update_prescriber.returning[0]
        dispatch(
          setAuthPrescriber({
            phone_number: data.phone_number,
            name: data.name,
            firstname: data.firstname,
            discount: data.discount,
            bank_account: data.bank_account,
            tva_number: data.tva_number,
            enterprise_name: data.enterprise_name,
            invoice_address: data.invoice_address,
          })
        )
        resetEdit()
        toast.success('Votre profil a été mis à jour avec succès')
        setEditField('')
      })
      .catch(err => {
        toast.error("Une erreur s'est produite, réessayez plus tard")
        resetEdit()
      })
  }

  return (
    <>
      <Title>Votre profil</Title>
      <ProfileItem defaultValue={authUser.email} label='Email' />
      <Hr />
      <ProfileItem
        defaultValue={authUser.firstName}
        label='Prénom'
        openEdit={() => setEditField('firstName')}
        isEditing={editField === 'firstName'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
      <Hr />
      <ProfileItem
        defaultValue={authUser.lastName}
        label='Nom de famille'
        openEdit={() => setEditField('lastName')}
        isEditing={editField === 'lastName'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
      <Hr />
      <ProfileItem
        defaultValue={authUser.phone}
        label='Numéro de téléphone'
        openEdit={() => setEditField('phone')}
        isEditing={editField === 'phone'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
    </>
  )
}

export default ProfileView
