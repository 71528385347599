import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Container,
  CureContent,
  Footer,
  IconContainer,
  IconsContainer,
  InputContainer,
  Note,
  NoteContainer,
  P,
  Price,
  ProductsList,
  SecondaryTitle,
  TakeContainer,
  Title,
  Total,
  HelperText,
} from './style'
import { getPatientById } from '../../../store/patient'
import ProductPreview from './product-preview'
import { Button, Icon, Input, Tooltip } from '../../../components/ui'
import { TakeType } from '../../../utils/constants'
import { useDispatch, useAuthPrescriber } from '../../../utils/hooks'
import { getPrescriptionGain } from '../../../utils/helpers'

const NewPrescriptionDraftPreview = ({
  draft: { patientId, takes, message, duration },
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  removeTake,
  getDraftPrice,
  addTake,
  setTakeLabel,
  products,
  openDetailModal,
}) => {
  const dispatch = useDispatch()
  const patient = useSelector(state => getPatientById(state, patientId))
  const totalPrice = useSelector(getDraftPrice)
  const { authUser } = useAuthPrescriber()
  const [takeIndexLabelEditing, setTakeIndexLabelEditing] = useState(null)
  const [takeLabelValue, setTakeLabelValue] = useState('')
  const inCureTakes = []

  let outCureTake = null
  takes.forEach(take => {
    if (take.type === TakeType.IN_CURE) {
      inCureTakes.push(take)
    } else {
      outCureTake = take
    }
  })

  const labelDefaultValue = (index, take) => {
    switch (index) {
      // case 1:
      //   return 'matin'
      // case 2:
      //     return 'midi'
      // case 3:
      //       return 'soir'
      //  case 4:
      //     return 'Au coucher'
      default:
        return take.label || `Prise #${index}`
    }
  }

  return (
    <Container>
      {patient && (
        <Title>{`La cure personnalisée de ${patient.firstname}`}</Title>
      )}
      <CureContent>
        {!!inCureTakes.length && (
          <SecondaryTitle>Produits en sachets/pots</SecondaryTitle>
        )}
        {inCureTakes.map((take, index) => (
          <TakeContainer key={index} hasBackground={inCureTakes.length > 1}>
            {!!inCureTakes.length &&
              inCureTakes.length > 1 &&
              takeIndexLabelEditing !== take.index && (
                <SecondaryTitle>
                  {labelDefaultValue(index + 1, take)}
                  <IconContainer>
                    <Tooltip label='Editer le nom de la prise'>
                      <Icon
                        name='pencil'
                        onClick={() => {
                          setTakeLabelValue(
                            // take.label ||
                            // `Prise #${index + 1}`
                            labelDefaultValue(index + 1, take)
                          )
                          setTakeIndexLabelEditing(take.index)
                        }}
                      />
                    </Tooltip>
                    <Tooltip label='Supprimer la prise entièrement'>
                      <Icon
                        name='trash'
                        onClick={() => dispatch(removeTake(index))}
                      />
                    </Tooltip>
                  </IconContainer>
                </SecondaryTitle>
              )}
            {!!inCureTakes.length &&
              inCureTakes.length > 1 &&
              takeIndexLabelEditing === take.index && (
                <InputContainer>
                  <Input
                    value={takeLabelValue}
                    onChange={e => setTakeLabelValue(e.target.value)}
                    size='s'
                  />
                  <IconsContainer>
                    <Icon
                      name='check'
                      onClick={() => {
                        dispatch(
                          setTakeLabel({
                            label: takeLabelValue,
                            takeIndex: take.index,
                          })
                        )
                        setTakeIndexLabelEditing(null)
                        setTakeLabelValue('')
                      }}
                    />
                    <Icon
                      name='cancel'
                      onClick={() => {
                        setTakeIndexLabelEditing(null)
                        setTakeLabelValue('')
                      }}
                    />
                  </IconsContainer>
                </InputContainer>
              )}
            <ProductsList>
              {!take.items.length && (
                <HelperText>
                  Pour ajouter des produits à cette prise, séléctionnez le
                  produit souhaité dans la liste de gauche
                </HelperText>
              )}
              {take.items.map((item, itemIndex) => (
                <ProductPreview
                  isLast={itemIndex === take.items.length - 1}
                  takeIndex={take.index}
                  key={`${item.handle}-${take.index}`}
                  productHandle={item.handle || item.productHandle}
                  quantity={item.quantity}
                  notes={item.notes}
                  recommendedDuration={`${duration}-month`}
                  setProductNotesDraft={setProductNotesDraft}
                  decreaseQttyProductFromDraftTake={
                    decreaseQttyProductFromDraftTake
                  }
                  increaseQttyProductFromDraftTake={
                    increaseQttyProductFromDraftTake
                  }
                  removeProductFromDraftTake={removeProductFromDraftTake}
                  products={products}
                  onDetailOpen={() => openDetailModal(item.handle)}
                />
              ))}
            </ProductsList>
          </TakeContainer>
        ))}
        {!!inCureTakes.length && (
          <Button
            onClick={() => {
              dispatch(addTake())
            }}
            appearance='primary'
          >
            Séparer la cure en {inCureTakes.length + 1} prises
          </Button>
        )}
        {!!outCureTake && (
          <>
            <SecondaryTitle>Produits à part</SecondaryTitle>
            {outCureTake.items.map((item, itemIndex) => (
              <ProductPreview
                isLast={itemIndex === outCureTake.items.length - 1}
                takeIndex={outCureTake.index}
                key={item.handle}
                productHandle={item.handle}
                quantity={item.quantity}
                notes={item.notes}
                recommendedDuration={`${duration}-month`}
                setProductNotesDraft={setProductNotesDraft}
                decreaseQttyProductFromDraftTake={
                  decreaseQttyProductFromDraftTake
                }
                increaseQttyProductFromDraftTake={
                  increaseQttyProductFromDraftTake
                }
                removeProductFromDraftTake={removeProductFromDraftTake}
                products={products}
                onDetailOpen={() => openDetailModal(item.handle)}
              />
            ))}
          </>
        )}
      </CureContent>
      {message && (
        <NoteContainer>
          <Note>{message}</Note>
        </NoteContainer>
      )}
      <Footer>
        <Total>Total</Total>
        <Price>€{totalPrice}</Price>
        <P>
          ou €{(Number(totalPrice) / duration).toFixed(2)}/mois pendant{' '}
          {duration} mois
        </P>
        {totalPrice > 0 && (
          <Total>{`Vous gagnez €${getPrescriptionGain(
            authUser.discountPercentage,
            totalPrice
          )}`}</Total>
        )}
      </Footer>
    </Container>
  )
}

NewPrescriptionDraftPreview.propTypes = {
  draft: PropTypes.object,
  setProductNotesDraft: PropTypes.func,
  decreaseQttyProductFromDraftTake: PropTypes.func,
  increaseQttyProductFromDraftTake: PropTypes.func,
  removeProductFromDraftTake: PropTypes.func,
  removeTake: PropTypes.func,
  getDraftPrice: PropTypes.func,
  addTake: PropTypes.func,
  setTakeLabel: PropTypes.func,
  openDetailModal: PropTypes.func,
}

export default NewPrescriptionDraftPreview
