import styled from 'styled-components/macro'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
`

export const InputContainer = styled.div`
  max-width: 300px;
`
