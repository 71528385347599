import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import { reducer as authReducer } from './auth-user'
import { reducer as patientReducer } from './patient'
import { reducer as prescriberReducer } from './prescriber'
import { reducer as prescriptionReducer } from './prescription'
import { reducer as protocoleReducer } from './protocole'
import { reducer as productReducer } from './product'
import { reducer as cartReducer } from './cart'
import { reducer as orderReducer } from './order'

const rootReducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  prescriber: prescriberReducer,
  prescription: prescriptionReducer,
  product: productReducer,
  cart: cartReducer,
  protocole: protocoleReducer,
  order: orderReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
