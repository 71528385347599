import { gql } from '@apollo/client'

export const PROTOCOLES = gql`
  query getProtocoles {
    protocole {
      custom_message
      id
      name
      recommended_duration
      prescriber_id
      created_at
      description
      share
      prescriber {
        name
      }
      protocole_takes {
        id
        index
        protocole_items {
          id
          product_handle
          quantity
          notes
        }
        label
        type
      }
    }
  }
`

export const ADD_PROTOCOLE = gql`
  mutation AddProtocole(
    $customMessage: String
    $name: String
    $prescriberId: Int!
    $protocoleTakes: protocole_take_arr_rel_insert_input!
    $recommendedDuration: String!
    $description: String!
    $share: Boolean!
  ) {
    insert_protocole(
      objects: {
        custom_message: $customMessage
        name: $name
        prescriber_id: $prescriberId
        protocole_takes: $protocoleTakes
        recommended_duration: $recommendedDuration
        description: $description
        share: $share
      }
    ) {
      returning {
        name
        created_at
        custom_message
        id
        prescriber_id
        recommended_duration
        description
        share
        prescriber {
          name
        }
        protocole_takes {
          id
          index
          label
          type
          protocole_items {
            notes
            product_handle
            quantity
            product {
              name
            }
          }
        }
      }
    }
  }
`

export const DELETE_PROTOCOLE = gql`
  mutation DeleteProtocole($id: Int!) {
    delete_protocole_item(
      where: { protocole_take: { protocole_id: { _eq: $id } } }
    ) {
      affected_rows
    }
    delete_protocole_take(where: { protocole_id: { _eq: $id } }) {
      affected_rows
    }
    delete_protocole_by_pk(id: $id) {
      id
    }
  }
`
