import styled from 'styled-components/macro'

export const Section = styled.section`
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 12vh;
  margin: 1em 0;
  display: table;
  width: 100%;
  padding: 4px;
`

export const Header = styled.p`
  margin-left: 1em;
  font-size: 16px;
`
export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  flex-direction: column;
  margin-left: 1.3em;
  padding: 3px 0;
`

export const ListItem = styled.p`
  font-size: 12px;
`

export const Column = styled.div`
  display: table-cell;
  width: 40%;
  vertical-align: top;
  height: 100%;
`

export const Label = styled.p`
  font-size: 12px;
  padding-top: 5px;
`
