import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 20px;
  max-width: 998px;
`

export const Aside = styled.aside`
  width: 200px;
  margin-right: 30px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`
