import {
  PillImageContainer,
  PillImage,
  SecondaryTitle,
  Img,
  SmallText,
  DialogTitle,
  DialogSubTitle,
  DialogBody,
  BodyCol,
  DialogHeader,
  StrongPoints,
  Point,
  PointTitle,
  PointText,
  VerticalSeparator,
} from './style'
import { getProductLabelPicture } from '../../../utils/helpers'
import { Dialog, List } from '../../ui'

const ProductDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  name,
  headline,
  pillPicture,
  strongPoints,
  ingredients,
  whyRecommendIt,
  forWho,
  benefits,
  contraindication,
  deficiencies,
  handle,
}) => {
  const productLabelPicture = getProductLabelPicture(handle)
  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      type='large'
    >
      <DialogHeader>
        <div>
          <DialogTitle>{name}</DialogTitle>
          {headline && <DialogSubTitle>{headline}</DialogSubTitle>}
        </div>
        <PillImageContainer
          $bg={
            process.env.REACT_APP_AWS_BUCKET +
            'assets/images/product-background.jpg'
          }
        >
          <PillImage src={pillPicture} />
        </PillImageContainer>
      </DialogHeader>

      <DialogBody>
        <BodyCol>
          {!!strongPoints.length && (
            <>
              <SecondaryTitle>Les points forts</SecondaryTitle>
              <StrongPoints>
                {strongPoints.map((item, index) => (
                  <Point key={index}>
                    <PointTitle>{item.title}</PointTitle>
                    {item.text && <PointText>{item.text}</PointText>}
                  </Point>
                ))}
              </StrongPoints>
            </>
          )}

          {ingredients.length && (
            <>
              <SecondaryTitle>Formulation</SecondaryTitle>
              <List items={ingredients} />
            </>
          )}
        </BodyCol>
        <VerticalSeparator />
        <BodyCol>
          {whyRecommendIt && (
            <>
              <SecondaryTitle>Pourquoi le recommander ?</SecondaryTitle>
              <SmallText>{whyRecommendIt}</SmallText>
            </>
          )}
          {forWho && (
            <>
              <SecondaryTitle>Pour qui ?</SecondaryTitle>
              <SmallText>{forWho}</SmallText>
            </>
          )}
          {benefits && (
            <>
              <SecondaryTitle>Les bienfaits</SecondaryTitle>
              <SmallText>{benefits}</SmallText>
            </>
          )}
          {contraindication && (
            <>
              <SecondaryTitle>Contre indication(s)</SecondaryTitle>
              <SmallText>{contraindication}</SmallText>
            </>
          )}
          {deficiencies && (
            <>
              <SecondaryTitle>Signes de carences</SecondaryTitle>
              <SmallText>{deficiencies}</SmallText>
            </>
          )}
        </BodyCol>
      </DialogBody>
      {productLabelPicture && (
        <Img alt={`${handle}-labels`} src={productLabelPicture} />
      )}
    </Dialog>
  )
}

export default ProductDialog
