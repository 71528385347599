import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Content,
  Title,
  Subtitle,
  Top,
  Bottom,
  Right,
} from './style'

const PageTitle = ({ title, subtitle, rightElements }) => (
  <Container>
    <Content>
      <Top>
        <Title>{title}</Title>
        {rightElements && <Right>{rightElements}</Right>}
      </Top>

      {subtitle && (
        <Bottom>
          <Subtitle>{subtitle}</Subtitle>
        </Bottom>
      )}
    </Content>
  </Container>
)

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
