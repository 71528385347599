import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  cart: null,
  actions: {
    initCart: { ...INITIAL_ACTION_STATE },
    fetchCart: { ...INITIAL_ACTION_STATE },
    updateCart: { ...INITIAL_ACTION_STATE },
    addToCart: { ...INITIAL_ACTION_STATE },
    cartLinesRemove: { ...INITIAL_ACTION_STATE },
    cartAttributesUpdate: { ...INITIAL_ACTION_STATE },
  },
}

export default state
