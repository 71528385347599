import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.button`
  display: flex;
  width: 100%;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 25px 10px;
  justify-content: center;
  border: 1px solid ${Color.TRANSPARENT};
  background: white;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  justify-content: ${({ $isCenter, $isRow, $isEnd }) =>
    $isRow
      ? $isEnd
        ? 'flex-end'
        : 'flex-start'
      : $isCenter
      ? 'center'
      : 'flex-start'};
  align-items: ${({ $isEnd, $isRow, $isCenter }) =>
    $isRow
      ? $isCenter
        ? 'center'
        : 'flex-start'
      : $isEnd
      ? 'flex-end'
      : 'flex-start'};
  flex: 1;
  padding: 0 10px;
  height: 100%;
`

export const Name = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`

export const Description = styled.p`
  font-size: 12px;
  opacity: 0.4;
  text-align: left;
`

export const Label = styled.label`
  font-size: 12px;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`
