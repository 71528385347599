import { Container, Content, Aside } from './style'
import Layout from '../../../components/layout/default'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import SettingAsideNav from '../../../components/settings/aside-nav'
import PaymentView from '../../../components/settings/payment-section'

const PaymentPage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout>
      <Container>
        <Aside>
          <SettingAsideNav />
        </Aside>
        <Content>
          <PaymentView />
        </Content>
      </Container>
    </Layout>
  )
}

export default PaymentPage
