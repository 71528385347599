import styled from 'styled-components/macro'

export const Container = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Item = styled.li`
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Cockpit = styled.div`
  display: flex;
  margin-bottom: 20px;
`
