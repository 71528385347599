import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import mixpanel from 'mixpanel-browser'

import { Form, FooterText, FooterTextContent } from './style'
import { Button, Field } from '../../ui'
import {
  login,
  getLoginStatus,
  getFetchAuthPrescriberStatus,
  sendResetPasswordEmail,
  getSendResetPasswordStatus,
  getSendResetPasswordError,
} from '../../../store/auth-user'
import { ActionType } from '../../../utils/constants'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 charactères requis')
    .required('Champs obligatoire'),
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
})

const LoginForm = () => {
  const dispatch = useDispatch()
  const loginStatus = useSelector(getLoginStatus)
  const sendResetPasswordError = useSelector(getSendResetPasswordError)
  const sendPasswordResetStatus = useSelector(getSendResetPasswordStatus)
  const fetchAuthPrescriberStatus = useSelector(getFetchAuthPrescriberStatus)
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: LoginSchema,
      onSubmit: values => {
        mixpanel.track('Login', {
          email: values.email,
        })
        dispatch(login(values))
      },
    })

  useEffect(() => {
    loginStatus !== ActionType.LOADING &&
      loginStatus === ActionType.FAILED &&
      toast.error("L'email ou le mot de passe est incorrect")
  }, [loginStatus])

  useEffect(() => {
    const errorMsg =
      sendResetPasswordError === 'auth/user-not-found'
        ? "L'adresse mail renseignée n'est pas valide, merci de vérifier l'adresse mail"
        : 'Il y a eu une erreur dans la réinitialisation de votre mot de passe, merci de réessayer'

    sendPasswordResetStatus === ActionType.FAILED && toast.error(errorMsg)
    sendPasswordResetStatus === ActionType.SUCCESS &&
      toast.success(
        'Un email de réinitialisation de mot de passe a été envoyé à votre adresse email'
      )
  }, [sendPasswordResetStatus, sendResetPasswordError])

  const resetPassword = email => {
    if (email) {
      dispatch(sendResetPasswordEmail(email))
      return
    }

    toast.error(
      'Un email doit être renseigné afin de demander une réinitialisation de mot de passe'
    )
  }

  const isLoading =
    loginStatus === ActionType.LOADING ||
    fetchAuthPrescriberStatus === ActionType.LOADING

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='login-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={isLoading}
      />
      {errors.email && errors.touched}
      <Field
        label='Mot de passe'
        id='password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={isLoading}
      />

      <Button type='submit' isLoading={isLoading}>
        Se connecter
      </Button>
      <FooterText>
        Mot de passe oublié ?{' '}
        <FooterTextContent onClick={() => resetPassword(values.email)}>
          Réinitialisez votre mot de passe
        </FooterTextContent>
      </FooterText>
    </Form>
  )
}

export default LoginForm
