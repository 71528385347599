import { Container, Number, Text, Content, NavLinkWrapper } from './style'
import { Link } from 'react-router-dom'
import { Spinner, NavLink } from '../../../components/ui'

const PrescriptionsStatsPreviewCard = ({ data, loading }) => {
  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Text>Recommandations</Text>
          <Content>
            <Number>{data.length}</Number>
            <NavLinkWrapper>
              <NavLink
                as={Link}
                style={{ padding: '0', fontSize: '18px' }}
                to={'/prescriptions/new'}
              >
                Créer
              </NavLink>
            </NavLinkWrapper>
          </Content>
        </div>
      )}
    </Container>
  )
}

export default PrescriptionsStatsPreviewCard
