import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import { getAuthUser } from '../store/auth-user'

const UnauthenticatedRouteOnly = ({
  component: Component,
  authUser,
  ...rest
}) => {
  const renderRoute = props => <Component {...props} />
  const renderToDashboard = props => (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  )

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? renderToDashboard(props) : renderRoute(props)
      }
    />
  )
}

const mapStateToProps = state => ({
  authUser: getAuthUser(state),
})

export default connect(mapStateToProps)(UnauthenticatedRouteOnly)
