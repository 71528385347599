import styled from 'styled-components/macro'

const Container = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 4px;
  letter-spacing: 1px;
`

export const Success = styled(Container)`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
`

export const Danger = styled(Container)`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`

export const Info = styled(Container)`
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
`

export const Warning = styled(Container)`
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
`

export const Done = styled(Container)`
  color: #ff7021;
  background-color: rgb(255, 141, 78, 0.4);
  border-color: #ffecb5;
`
