import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'

import Layout from '../../../components/layout/action'
import { Button, ButtonGroup, Dialog, Step } from '../../../components/ui'
import { Container, Content, ActionFooter, DialogContent, Title } from './style'
import {
  useDispatch,
  useAddProtocole,
  useProducts,
  useProtocoles,
  useAuthPrescriber,
} from '../../../utils/hooks'
import NewPrescriptionDraftPreview from '../../../components/prescription/new-prescription-draft-preview'
import CreateProtocoleStep1 from '../../../components/protocoles/create-protocole-form/step-1'
import CreateProtocoleStep2 from '../../../components/prescription/create-prescription-form/step-2'
import CreateProtocoleStep3 from '../../../components/prescription/create-prescription-form/step-3'
import {
  getProtocoleNewDraft,
  setProtocoleDraftProductTake,
  setNewProtocoleDraft,
  resetNewProtocoleDraft,
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  getProtocoleDraftPrice,
  addTake,
  setTakeLabel,
} from '../../../store/protocole'

const NewProtocolePage = () => {
  const dispatch = useDispatch()
  const { data: protocoles, loading: protocolesLoading } = useProtocoles()
  const { data: products } = useProducts()
  const location = useLocation()
  const history = useHistory()
  const [step, setStep] = useState(1)

  const [isBeforeLeaveModalOpen, setIsBeforeLeaveModalOpen] = useState(false)

  const { authUser } = useAuthPrescriber()
  const {
    addProtocole,
    data: addProtocoleData,
    loading: addProtocoleLoading,
  } = useAddProtocole()
  const draft = useSelector(getProtocoleNewDraft)
  const [value, setValue] = useState(draft.name || '')

  useEffect(() => {
    const { step: urlStep } = queryString.parse(location.search)
    if (Number(step) !== Number(urlStep)) {
      history.push({ search: `?step=${step}` })
    }
    if (![1, 2, 3].includes(Number(step))) {
      setStep(1)
    }
  }, [history, location.search, step])

  useEffect(() => {
    if (!addProtocoleData) return
    dispatch(resetNewProtocoleDraft())

    history.push('/protocoles')
  }, [addProtocoleData, history, dispatch])

  const updateStep = newStep => {
    const nbNewStep = Number(newStep)
    if (nbNewStep < 1 || nbNewStep > 3) return
    setStep(nbNewStep)
  }

  const onProtocoleSubmitted = () => {
    const takes = draft.takes
      .filter(t => !!t.items.length)
      .map(take => ({
        index: take.index,
        label: take.label,
        type: take.type,
        protocole_items: {
          data: take.items.map(item => ({
            product_handle: item.handle,
            quantity: item.quantity,
            notes: item.notes,
          })),
        },
      }))
    addProtocole({
      variables: {
        name: draft.name,
        prescriberId: authUser.id,
        recommendedDuration: `${draft.duration}-${draft.durationUnit}`,
        customMessage: draft.message,
        description: draft.description,
        share: draft.share,
        protocoleTakes: {
          data: takes,
        },
      },
    })
    mixpanel.track('New protocole', {
      prescriberId: authUser.id,
      prescriberFullName: authUser.fullName,
      name: draft.name,
      recommendedDuration: `${draft.duration}-${draft.durationUnit}`,
      customMessage: draft.message,
      description: draft.description,
      share: draft.share,
      protocoleTakes: takes,
    })
  }

  let isNextStepBlocked =
    (step === 1 && draft.name) || (step === 2 && draft.takes.length)

  return (
    <Layout
      aside={
        <NewPrescriptionDraftPreview
          draft={draft}
          decreaseQttyProductFromDraftTake={decreaseQttyProductFromDraftTake}
          increaseQttyProductFromDraftTake={increaseQttyProductFromDraftTake}
          removeProductFromDraftTake={removeProductFromDraftTake}
          setProductNotesDraft={setProductNotesDraft}
          getDraftPrice={getProtocoleDraftPrice}
          addTake={addTake}
          setTakeLabel={setTakeLabel}
          products={products}
        />
      }
      top={
        <Button
          appearance='minimal'
          iconLeft='arrow-left'
          onClick={() => {
            if (draft.name) {
              setIsBeforeLeaveModalOpen(true)
            } else {
              history.push('/protocoles')
            }
          }}
        >
          Annuler
        </Button>
      }
    >
      <Container>
        <Content>
          <Step
            currentStep={step}
            texts={['Information', 'Cure', 'Finalisation']}
          />
          {step === 1 && (
            <CreateProtocoleStep1
              value={value}
              setValue={setValue}
              draft={draft}
            />
          )}
          {step === 2 && (
            <CreateProtocoleStep2
              draft={draft}
              setDraftProductTake={setProtocoleDraftProductTake}
              canSelectProtocole={false}
              protocoles={protocoles}
              protocolesLoading={protocolesLoading}
              productSearchBarContent={'ajouter produit par produit'}
            />
          )}
          {step === 3 && (
            <CreateProtocoleStep3
              draft={draft}
              setNewDraft={setNewProtocoleDraft}
              shareProtocole={true}
            />
          )}
        </Content>
        <ActionFooter>
          <div>
            {step !== 1 && (
              <Button
                onClick={() => updateStep(step - 1)}
                isDisabled={step <= 1}
              >
                Précédent
              </Button>
            )}
          </div>
          <div>
            {step !== 3 && (
              <Button
                onClick={() => {
                  if (isNextStepBlocked) {
                    updateStep(step + 1)
                  }
                }}
                isDisabled={!isNextStepBlocked}
                appearance='primary'
              >
                Continuer
              </Button>
            )}
            {step === 3 && (
              <Button
                onClick={onProtocoleSubmitted}
                isLoading={addProtocoleLoading}
                appearance='primary'
              >
                Créer
              </Button>
            )}
          </div>
        </ActionFooter>
      </Container>
      <Dialog
        type='default'
        isOpen={isBeforeLeaveModalOpen}
        onClose={() => setIsBeforeLeaveModalOpen(false)}
      >
        <DialogContent>
          <Title isCenter>
            Attention, si vous annulez toutes vos données encodées seront
            perdues.
            <br />
            <br />
            Êtes-vous certain de vouloir quitter la création de votre
            recommandation ?
          </Title>
          <ButtonGroup spaced>
            <Button
              appearance='minimal'
              onClick={() => setIsBeforeLeaveModalOpen(false)}
            >
              Non, je continue ma recommandation
            </Button>
            <Button
              onClick={() => {
                dispatch(resetNewProtocoleDraft())
                history.push('/protocoles')
              }}
            >
              Oui, je suis sûr
            </Button>
          </ButtonGroup>
        </DialogContent>
      </Dialog>
    </Layout>
  )
}

export default NewProtocolePage
