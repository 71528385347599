import styled from 'styled-components/macro'

import { Color } from '../../../../utils/constants'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const SelectInputContainer = styled.div`
  display: flex;
  flex: 1;
`

export const SelectProductContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
`

export const TakeChoice = styled.button`
  cursor: pointer;
  border: 1px solid ${Color.MYSTIC};
  padding: 20px;
  background-color: ${Color.TRANSPARENT};
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  &:hover {
    background-color: ${Color.MYSTIC}55;
  }
`

export const ProductSearchBarHeader = styled.p`
  font-size: 14px;
  margin-top: 0.7em;
`

export const StickyContainer = styled.div`
  display: flex;
  position: sticky;
  top: 85px;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  z-index: 1;
  border-radius: 14px;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 20px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
