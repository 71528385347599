import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
`

export const StatsPreviewContainer = styled.section`
  display: flex;
  width: 100%;
  margin: 0 -20px;
`

export const Left = styled.div`
  display: flex;
  flex: 1;
  border: 20px solid rgba(255, 255, 255, 0);
`

export const Right = Left
