import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  position: sticky;
  top: 20px;
  width: 100%;
  background-color: #d6f1e7;
  padding: 50px 40px;
  box-shadow: 0px 10px 40px -17px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  height: ${props => (props.height ? props.height : 'none')};

  ${MediaQuery.M_AND_DOWN} {
    border-radius: 0px;
    display: ${props =>
      props.displayMAndDown ? props.displayMAndDown : 'block'};
  }
`

export const Choice = styled.div`
  margin-bottom: 40px;
  h3 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 12px;
  }
  div {
    display: flex;
    justify-content: space-between;
  }
`
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.column ? 'column' : 'none')};

  & > *:not(:last-child) {
    margin-bottom: ${props => (props.column ? '15px' : 'none')};
  }
`

export const Selector = styled.button`
  position: relative;
  cursor: pointer;
  height: 35px;
  padding: 8px;
  border: ${props =>
    props.active ? '1px solid #00865C88' : '1px solid transparent'};
  opacity: ${props => (props.active ? '1' : '.7')};
  background-color: ${props => (props.active ? '#00865C33' : '')};
  border-radius: 8px;
  width:  ${props => (props.fullWidth ? '100%' : '49%')};
  width: ${props => (props.column ? '100%' : 'none')};
  display flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    border: 1px solid #00865C88;
    background-color:#00865C33;
  }

  p {
    margin-top: 2px;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
  }
`

export const LoadingIcon = styled.span`
  font-size: 10px;
  display: block;
  margin-top: 0px;
  margin-left: 10px;
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const Pricing = styled.div`
  margin: 50px 0 30px 0;
`

export const PricingLine = styled.div`
  font-weight: 300;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const PaymentMethods = styled.div``
export const PaymentImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PaymentImage = styled.img`
  width: 40px;
  height: 25px;
  border-radius: 5px;
  object-fit: contain;
  background-color: white;
  filter: grayscale(20%);
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const SubscriptionContent = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
      font-size: 14px;
    }
    span {
      color: #64b595;
      margin-right: 10px;
    }
  }
`
