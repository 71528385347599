import styled from 'styled-components/macro'

import { ViewMode } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
`

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  padding: 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 40px 0 20px 0;
  position: sticky;
  top: 84px;
  background-color: white;
  padding: 20px;
  z-index: 1;
  border-radius: 14px;
  flex-direction: column;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const InputContainer = styled.div`
  width: 70%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: inherit;
`
