import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  padding: 50px 0 20px 0;
  display: flex;
  flex-direction: column;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Bottom = styled.div`
  margin-top: 10px;
`

export const Right = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
`

export const Title = styled.h1`
  font-size: 28px;
  color: ${Color.MIRAGE};
`

export const Subtitle = styled.p`
  font-size: 16px;
  max-width: 800px;
  color: ${Color.MIRAGE};
`
