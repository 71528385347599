import { Link, useLocation } from 'react-router-dom'

import { Nav } from './style'
import { NavLink } from '../../ui'

const navItems = [
  { to: '/settings/profile', label: 'Profil' },
  { to: '/settings/payment', label: 'Paiement' },
]

const SettingsAsideNav = () => {
  const { pathname } = useLocation()

  return (
    <Nav>
      {navItems.map(item => (
        <NavLink
          key={item.to}
          as={Link}
          to={item.to}
          isActive={pathname === item.to}
        >
          {item.label}
        </NavLink>
      ))}
    </Nav>
  )
}

export default SettingsAsideNav
