import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Wrapper, Title } from './style'
import NakedLayout from '../../components/layout/naked'
import ResetPasswordForm from '../../components/auth/reset-password-form'
import { getRegisterStatus } from '../../store/auth-user'
import { ActionType } from '../../utils/constants'

const ResetPassordPage = ({ mode, oobCode, history }) => {
  const resetPasswordStatus = useSelector(getRegisterStatus)

  useEffect(() => {
    if (mode !== 'resetPassword' || !oobCode) {
      history.push('/')
    }
  })

  return (
    <NakedLayout>
      <Wrapper>
        {resetPasswordStatus !== ActionType.SUCCESS && (
          <>
            <Title>Réinitialiser son mot de passe</Title>
            <ResetPasswordForm oobCode={oobCode} history={history} />
          </>
        )}
      </Wrapper>
    </NakedLayout>
  )
}

export default ResetPassordPage
