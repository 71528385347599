import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { Provider } from 'react-redux'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import App from './App'
import GlobalStyle from './style/GlobalStyle'
import store from './store'
import ThemeProvider from './style/ThemeProvider'
import { ToastContainer } from './components/ui'
import { isProd } from './utils/helpers'
import { Font } from './utils/constants'
import basisGrotesqueProEOTUrl from './assets/fonts/BasisGrotesquePro-Medium.eot'
import basisGrotesqueProSVGUrl from './assets/fonts/BasisGrotesquePro-Medium.svg'
import basisGrotesqueProTTFUrl from './assets/fonts/BasisGrotesquePro-Medium.ttf'
import basisGrotesqueProWOFF2Url from './assets/fonts/BasisGrotesquePro-Medium.woff2'
import basisGrotesqueProWOFFUrl from './assets/fonts/BasisGrotesquePro-Medium.woff'
// import sctoGroteskAEOTUrl from './assets/fonts/SctoGroteskA-Regular.eot'
// import sctoGroteskASVGUrl from './assets/fonts/SctoGroteskA-Regular.svg'
// import sctoGroteskATTFUrl from './assets/fonts/SctoGroteskA-Regular.ttf'
// import sctoGroteskAWOFF2Url from './assets/fonts/SctoGroteskA-Regular.woff2'
// import sctoGroteskAWOFFUrl from './assets/fonts/SctoGroteskA-Regular.woff'
// import sctoGroteskBEOTUrl from './assets/fonts/SctoGroteskB-Regular.eot'
// import sctoGroteskBSVGUrl from './assets/fonts/SctoGroteskB-Regular.svg'
// import sctoGroteskBTTFUrl from './assets/fonts/SctoGroteskB-Regular.ttf'
// import sctoGroteskBWOFF2Url from './assets/fonts/SctoGroteskB-Regular.woff2'
// import sctoGroteskBWOFFUrl from './assets/fonts/SctoGroteskB-Regular.woff'

// If we want to apply this SCTO fonts
/* @font-face {
    font-family: ${Font.SCTO_GROTESK_A};
    src: url('${sctoGroteskAEOTUrl}');
    src: url('${basisGrotesqueProEOTUrl}?#iefix') format('embedded-opentype'),
      url('${sctoGroteskAWOFF2Url}') format('woff2'),
      url('${sctoGroteskAWOFFUrl}') format('woff'),
      url('${sctoGroteskATTFUrl}') format('truetype'),
      url('${sctoGroteskASVGUrl}#SctoGroteskA-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${Font.SCTO_GROTESK_B};
    src: url('${sctoGroteskBEOTUrl}');
    src: url('${basisGrotesqueProEOTUrl}?#iefix') format('embedded-opentype'),
      url('${sctoGroteskBWOFF2Url}') format('woff2'),
      url('${sctoGroteskBWOFFUrl}') format('woff'),
      url('${sctoGroteskBTTFUrl}') format('truetype'),
      url('${sctoGroteskBSVGUrl}#SctoGroteskB-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } */

import 'react-toastify/dist/ReactToastify.css'
import mixpanel from 'mixpanel-browser'

mixpanel.init('7251b264160a06781c9f2eb7378d5e7c')

isProd && Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
})

const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  const fontFace = `
    @font-face {
      font-family: ${Font.BASIS_GROTESQUE_PRO};
      src: url('${basisGrotesqueProEOTUrl}');
      src: url('${basisGrotesqueProEOTUrl}?#iefix') format('embedded-opentype'),
        url('${basisGrotesqueProWOFF2Url}') format('woff2'),
        url('${basisGrotesqueProWOFFUrl}') format('woff'),
        url('${basisGrotesqueProTTFUrl}') format('truetype'),
        url('${basisGrotesqueProSVGUrl}#BasisGrotesquePro-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
  `
  style.innerHTML = fontFace
  head.appendChild(style)
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <GlobalStyle />
        <ApolloProvider client={client}>
          <ToastContainer />
          <App />
        </ApolloProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
  loadFonts
)
