import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SecondaryTitle = styled.h3`
  font-size: 18px;
  margin: 15px 0;
  color: ${Color.MINE_SHAFT};
`

export const TakeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const TakeLabel = styled.h4`
  font-size: 20px;
  margin: 10px 0;
`
