import { ErrorCodes, Status, Color } from '../constants'

const productUrl = process.env.REACT_APP_AWS_BUCKET + 'assets/images/products/'

const labelUrl =
  process.env.REACT_APP_AWS_BUCKET + 'assets/images/product-labels/'

export const getProductPicture = handle => {
  return productUrl + handle + '.png'
}

export const getProductLabelPicture = handle => labelUrl + handle + '.png'

export const isProd = process.env.REACT_APP_ENV === 'production'

const unitDisplay = unit =>
  ({
    month: 'mois',
    year: 'an(s)',
  }[unit])

export const getRecommendationDurationDisplay = (key = '-') => {
  const [duration, unit] = key ? key.split('-') : []

  if (!duration) return null

  return `${duration} ${unitDisplay(unit)}`
}

export const getBaseAppLink = () =>
  ({
    local: 'http://localhost:3000/',
    staging: 'https://app-staging.compliment.me/',
    production: 'https://app.compliment.me/',
  }[process.env.REACT_APP_ENV || 'production'])

export const removePlural = string => {
  const strings = string.trim().split(' ')
  return strings.reduce((acc, curr) => {
    const isFirstWord = !!acc.length
    if (curr[curr.length - 1] === 's') {
      return isFirstWord
        ? `${acc} ${curr.substring(0, curr.length - 1)}`
        : `${acc}${curr.substring(0, curr.length - 1)}`
    } else {
      return isFirstWord ? `${acc} ${curr}` : `${acc}${curr}`
    }
  }, '')
}

export const getErrorMessage = errorCode => {
  switch (errorCode) {
    case ErrorCodes.PRODUCT_ALREADY_IN_TAKE:
      return 'Ce produit ne peut pas être ajouté deux fois pour une même prise, augmentez la quantité du produit à la place.'
    case ErrorCodes.PRODUCT_NOT_FOUND:
      return 'Le produit est introuvable'
    case ErrorCodes.TAKE_NOT_FOUND:
      return 'La prise est introuvable'
    default:
      return "Une erreur s'est produite, réessayez plus tard"
  }
}

export const sortAscendingByProductName = (productA, productB) => {
  if (productA.name < productB.name) {
    return -1
  }
  if (productA.name > productB.name) {
    return 1
  }
  return 0
}

/**
 * Will add an item to a take
 *
 * @param {Object} take Receiving take
 * @param {String} handle Product handle for the item to add
 * @return {undefined} Void
 */
export const pushItemInTake = (take, handle) => {
  const existingProductItemIndex = take.items.findIndex(
    item => item.handle === handle
  )

  if (existingProductItemIndex > -1)
    throw Error(ErrorCodes.PRODUCT_ALREADY_IN_TAKE)

  const productToAdd = { notes: '', handle: handle, quantity: 1 }
  take.items.push(productToAdd)
}

/**
 * Will add a new take to recommendation draft
 *
 * @param {Object} draft Recommendation (prescription) draft object
 * @param {String} takeType IN_CURE || STAND_ALONE constant
 * @param {String} handle Product handle for the item to add
 * @return {undefined} Void
 */
export const pushTakeInDraft = (draft, takeType, handle) => {
  const takeToAdd = {
    index: draft.takes.length + 1,
    label: 'Prise #' + (draft.takes.length + 1),
    type: takeType,
    items: [],
  }
  pushItemInTake(takeToAdd, handle)
  draft.takes.push(takeToAdd)
}

/**
 * Will get the array index of the take by the take index (this one from DB
 * who's responsible of the order)
 *
 * @param {Array} takes List of takes
 * @param {Number} index Index from a take (order index)
 *
 * @return {Number} Take index
 */
export const getTakeIndexByIndex = (takes, index) => {
  const concernedTakeIndex = takes.findIndex(take => take.index === index)

  if (concernedTakeIndex === -1) throw Error(ErrorCodes.TAKE_NOT_FOUND)

  return concernedTakeIndex
}

/**
 * Will return the take based on order index
 *
 * @param {Array} takes List of takes
 * @param {*} index Index from a take (order index)
 *
 * @return {Object} Corresponding take
 */
export const getTakeByIndex = (takes, index) => {
  const takeIndex = getTakeIndexByIndex(takes, index)

  return takes[takeIndex]
}

export const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac'] // add your OS values
  return os.find(v => window.navigator.userAgent.indexOf(v) >= 0)
}

export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf(' electron/') !== -1
}

export const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

export const getStatusColor = status =>
  Object.values(Status).includes(status)
    ? Color[status.toUpperCase()]
    : Color.MIRAGE

export const getPrescriptionGain = (
  discountPercentage,
  prescriptionTotalPrice
) => ((prescriptionTotalPrice / 100) * (25 - discountPercentage)).toFixed(2)

export const normalizeString = str =>
  str
    ?.trim()
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
