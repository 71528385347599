import store from '../index'
import { getProducts } from '../product'
import { Protocole } from '../../utils/classes'

export const getProtocoles = ({ protocole }) =>
  protocole.list.map(p => new Protocole(p))

export const getProtocoleById = ({ protocole }, id) => {
  const p = protocole.list.find(p => p.id === id)

  if (!p) return null

  return new Protocole(p)
}

export const getProtocoleNewDraft = ({ protocole }) => protocole.newDraft

// Statuses
export const getFetchProtocolesStatus = ({ protocole }) =>
  protocole.actions.fetchAll.status
export const getFetchProtocoleStatus = ({ protocole }) =>
  protocole.actions.fetchOne.status
export const getAddProtocoleStatus = ({ protocole }) =>
  protocole.actions.add.status
export const getDeleteProtocoleStatus = ({ protocole }) =>
  protocole.actions.delete.status
export const getSendByEmailStatus = ({ protocole }) =>
  protocole.actions.sendByEmail.status
export const getPushProtocoleStatusStatus = ({ protocole }) =>
  protocole.actions.pushStatus.status

export const getProtocolePrice = (state, id) => {
  const protocole = getProtocoleById(state, id)
  const products = getProducts(store.getState())

  if (!products.length || !protocole || !protocole.takes.length) return 0

  const totalPrice = protocole.takes.reduce((takeAccPrice, currentTake) => {
    return (
      takeAccPrice +
      currentTake.items.reduce((itemAccPrice, currentItem) => {
        const product = products.find(
          p => p.handle === currentItem.productHandle
        )
        return (
          itemAccPrice +
          Number(product.getPrice(protocole.recommendedDuration)) *
            Number(currentItem.quantity)
        )
      }, 0)
    )
  }, 0)

  return totalPrice.toFixed(2)
}

export const getNewlyCreatedProtocole = ({ protocole }) => {
  if (!protocole.lastCreatedId) return null

  const p = protocole.list.find(p => p.id === protocole.lastCreatedId)

  if (!p) return null

  return new Protocole(p)
}

export const getProtocoleDraftPrice = ({ protocole }) => {
  if (!protocole.newDraft.takes.length) return 0.0

  const products = getProducts(store.getState())

  if (!products.length) return 0

  const totalPrice = protocole.newDraft.takes.reduce(
    (takeAccPrice, currentTake) => {
      return (
        takeAccPrice +
        currentTake.items.reduce((itemAccPrice, currentItem) => {
          const product = products.find(p => p.handle === currentItem.handle)

          return (
            itemAccPrice +
            Number(product.getPrice(`${protocole.newDraft.duration}-month`)) *
              Number(currentItem.quantity)
          )
        }, 0)
      )
    },
    0
  )

  return totalPrice.toFixed(2)
}
