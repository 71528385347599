import Constants from './constants'

export const fetchPatientsLoading = () => ({
  type: Constants.FETCH_PATIENTS_LOADING,
})
export const fetchPatientsSuccess = payload => ({
  type: Constants.FETCH_PATIENTS_SUCCESS,
  payload: payload.patient,
})
export const fetchPatientsFailed = error => ({
  type: Constants.FETCH_PATIENTS_FAILED,
  error,
})

export const addPatientReset = () => ({
  type: Constants.ADD_PATIENT_RESET,
})
export const addPatientLoading = () => ({
  type: Constants.ADD_PATIENT_LOADING,
})
export const addPatientSuccess = payload => ({
  type: Constants.ADD_PATIENT_SUCCESS,
  payload: payload.insert_patient.returning[0],
})
export const addPatientFailed = error => ({
  type: Constants.ADD_PATIENT_FAILED,
  error,
})
export const deletePatientSuccess = payload => ({
  type: Constants.DELETE_PATIENT_SUCCESS,
  payload: payload.delete_patient_by_pk,
})
export const deletePatientFailed = error => ({
  type: Constants.DELETE_PATIENT_FAILED,
  error,
})
export const deletePatientLoading = () => ({
  type: Constants.DELETE_PATIENT_LOADING,
})

export const editPatientLoading = () => ({
  type: Constants.EDIT_PATIENT_LOADING,
})
export const editPatientSuccess = payload => ({
  type: Constants.EDIT_PATIENT_SUCCESS,
  payload: payload.update_patient.returning[0],
})
export const editPatientFailed = error => ({
  type: Constants.EDIT_PATIENT_FAILED,
  error,
})
export const editPatientReset = () => ({
  type: Constants.EDIT_PATIENT_RESET,
})
