import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Redirect } from 'react-router'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'

import { Button, Spinner, Tooltip, Icon } from '../../../components/ui'
import {
  ActionSection,
  BodySection,
  Container,
  Content,
  HeaderSection,
  MainP,
  MainTitle,
  Note,
  P,
  Pane,
  RecoLink,
  SecondaryTitle,
} from './style'
import Layout from '../../../components/layout/action'
import PatientTile from '../../../components/patient/patient-tile'
import PrescriptionStatusDetailed from '../../../components/prescription/prescription-status-detailed'
import DilaogCreateOrderForCustomer from '../../../components/prescription/dilaog-create-order-for-customer'
import {
  getNewlyCreatedPrescription,
  getPrescriptionPrice,
  sendByEmailReset,
  getPrescriptionRecoLink,
} from '../../../store/prescription'
import { getPatientById } from '../../../store/patient'
import { getCart } from '../../../store/cart'
import { getGiftCard } from '../../../store/auth-user'
import {
  usePatients,
  usePrescriptions,
  useSendPrescription,
  useAuthPrescriber,
  useProducts,
  usePushPrescriptionStatus,
  useOnboardingProfileCheck,
  useInitCart,
} from '../../../utils/hooks'
import {
  getRecommendationDurationDisplay,
  getPrescriptionGain,
} from '../../../utils/helpers'
import PrescriptionProductsList from '../../../components/prescription/prescription-products-list'

const lineItemsForCart = (takes, productList, recommendedDuration) => {
  if (!takes.length || !productList.length) return

  let standAloneTake = []
  let inCureTakesArray = []

  const standAloneTakefind = takes.find(take => take.isStandAlone)
  if (standAloneTakefind) {
    standAloneTake = standAloneTakefind.items
  }

  let inCureTakes = takes.filter(take => !take.isStandAlone)
  inCureTakes = inCureTakes.map(item => {
    return item.items.map(i => {
      return {
        ...i,
        id: i.id,
        notes: i.notes,
        productHandle: i.productHandle,
        quantity: i.quantity,
        index: item.index,
      }
    })
  })

  inCureTakes.map(i => i.map(i => inCureTakesArray.push(i)))

  const takesArray = [...standAloneTake, ...inCureTakesArray]

  return takesArray.map(item => {
    const productId = productList
      .find(p => p.handle === item.productHandle)
      ?.getVariantId(recommendedDuration)

    if (!productId) {
      // TODO: send Sentry error
      console.warn('Product not found', item.productHandle)
      return item
    }
    let attributes = []
    if (item?.productHandle)
      attributes.push({ key: 'handle', value: `${item.productHandle}` })
    if (item?.index) attributes.push({ key: 'prise', value: `${item.index}` })
    if (item?.notes) attributes.push({ key: 'notes', value: `${item.notes}` })

    return {
      quantity: item.quantity,
      merchandiseId: productId,
      attributes: attributes,
    }
  })
}

const NewPrescriptionConfirmPage = ({ history, match }) => {
  const [isDilaogCreateOrderForCustomerOpen, setDilaogCreateOrderForCustomer] =
    useState(false)
  useOnboardingProfileCheck()
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const { authUser } = useAuthPrescriber()
  const dispatch = useDispatch()
  const { loading: patientsLoading } = usePatients()
  const { loading: prescriptionsLoading } = usePrescriptions()
  const prescription = useSelector(getNewlyCreatedPrescription)
  const discountCode = prescription?.discountCode
  const prescriptionId = Number(match.params.prescriptionId)
  const prescriptionPrice = useSelector(state =>
    getPrescriptionPrice(state, prescription?.id)
  )
  const patient = useSelector(state =>
    getPatientById(state, prescription?.patientId)
  )
  const prescriptionRecoLink = useSelector(state =>
    getPrescriptionRecoLink(state, prescription?.id)
  )
  const {
    sendPrescription,
    loading: sendPrescriptionEmailLoading,
    success: sendPrescriptionEmailSuccess,
    failed: sendPrescriptionEmailError,
  } = useSendPrescription()
  const {
    data: products,
    loading: productsLoading,
    success: productsSuccess,
  } = useProducts()
  const {
    loading: pushPrescriptionStatusLoading,
    pushStatus,
    reset: resetPushPrescriptionStatus,
    success: pushPrescriptionStatusSuccess,
  } = usePushPrescriptionStatus()
  const { createCart } = useInitCart()

  let lineItems = []

  lineItems = lineItemsForCart(
    prescription?.lastStatus.takes || [],
    products,
    prescription?.recommendedDuration
  )

  const cart = useSelector(state => getCart(state))
  const giftCard = useSelector(state => getGiftCard(state))
  let checkoutUrl = prescription && giftCard && lineItems && cart?.checkoutUrl
  if (discountCode) {
    checkoutUrl += `?discount=${discountCode}`
  } else {
    checkoutUrl += `?attributes[prescription_id]=${prescriptionId}`
  }

  useEffect(() => {
    !!lineItems?.length &&
      productsSuccess &&
      createCart({
        variables: {
          cartInput: {
            lines: lineItems,
            attributes: {
              key: 'prescription_id',
              value: `${prescriptionId}`,
            },
          },
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLoading])

  useEffect(() => {
    if (!sendPrescriptionEmailLoading && sendPrescriptionEmailError) {
      toast.error("Une erreur est survenue lors de l'envoi de l'email.")
    } else if (!sendPrescriptionEmailLoading && sendPrescriptionEmailSuccess) {
      pushStatus(prescription?.id, prescription.lastStatus.takes, 'SENT')
      dispatch(sendByEmailReset())
    }
  }, [
    dispatch,
    patient,
    prescription,
    pushStatus,
    sendPrescriptionEmailError,
    sendPrescriptionEmailLoading,
    sendPrescriptionEmailSuccess,
  ])

  useEffect(() => {
    if (!pushPrescriptionStatusLoading && pushPrescriptionStatusSuccess) {
      toast.success(`L'email a été envoyé à ${patient?.email} avec succès !`)
      history.push('/prescriptions')
    }
  }, [
    history,
    patient?.email,
    pushPrescriptionStatusLoading,
    pushPrescriptionStatusSuccess,
  ])

  useEffect(() => {
    return () => resetPushPrescriptionStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopyToClipBoard = (copiedValue, isCopied) => {
    if (isCopied) {
      setIsLinkCopied(true)
      setTimeout(() => setIsLinkCopied(false), 3000)
    } else {
      setIsLinkCopied(false)
      toast.error('Impossible de copier le lien dans votre presse papier')
    }
  }

  if (!prescription) return <Redirect to='/prescriptions' />

  const sendEmailData = {
    patient_email: patient?.email,
    patient_firstname: patient?.firstname,
    patient_phonenumber: patient?.phone_number || undefined,
    custom_message: prescription?.customMessage,
    prescriber_email: authUser.email,
    prescriber_fullname: authUser.fullName || '',
    purchase_url: prescriptionRecoLink,
    promo_code: prescription.discountCode,
    discount: prescription.discountPercentage,
  }

  return (
    <Layout>
      <Container>
        <Content>
          {(patientsLoading || prescriptionsLoading) && <Spinner />}
          {!patientsLoading && !prescriptionsLoading && (
            <>
              <HeaderSection>
                <MainTitle>Votre recommandation est prête 🎉</MainTitle>
                <MainP>
                  Vérifiez votre recommandation et envoyez la à{' '}
                  {patient?.firstname} une fois que tout vous semble en ordre.
                </MainP>
              </HeaderSection>
              <BodySection>
                <SecondaryTitle>Votre patient</SecondaryTitle>
                <Pane>
                  <PatientTile patient={patient} />
                  <PrescriptionStatusDetailed
                    statuses={prescription.statuses}
                  />
                </Pane>
                <SecondaryTitle>Lien de recommandation</SecondaryTitle>
                <RecoLink>{prescriptionRecoLink}</RecoLink>
                <CopyToClipboard
                  text={prescriptionRecoLink}
                  onCopy={handleCopyToClipBoard}
                >
                  <Button appearance='primary'>Copier le lien</Button>
                </CopyToClipboard>
                {isLinkCopied && <P>Copié dans le presse papier !</P>}
                <PrescriptionProductsList
                  isLoading={!products}
                  takes={prescription.lastStatus.takes}
                  prescriptionRecommendedDuration={
                    prescription.recommendedDuration
                  }
                />
                <SecondaryTitle>La durée recommandée</SecondaryTitle>
                {getRecommendationDurationDisplay(
                  prescription.recommendedDuration
                )}
                <SecondaryTitle>Message personnel</SecondaryTitle>
                <Note>{prescription.customMessage || '-'}</Note>
                <SecondaryTitle>Le prix</SecondaryTitle>
                <P>
                  €
                  {(Number(prescriptionPrice) / prescription.duration).toFixed(
                    2
                  )}{' '}
                  par mois
                </P>
                <P>
                  Soit €{prescriptionPrice} pour{' '}
                  {getRecommendationDurationDisplay(
                    prescription.recommendedDuration
                  )}
                </P>
                <P>
                  {`Vous gagnez €${getPrescriptionGain(
                    authUser.discountPercentage,
                    prescriptionPrice
                  )}`}
                </P>
              </BodySection>
              <ActionSection>
                <Button
                  appearance='minimal'
                  onClick={() => history.push('/prescriptions')}
                >
                  Retour
                </Button>
                <DilaogCreateOrderForCustomer
                  isDilaogCreateOrderForCustomerOpen={
                    isDilaogCreateOrderForCustomerOpen
                  }
                  setDilaogCreateOrderForCustomer={
                    setDilaogCreateOrderForCustomer
                  }
                  giftCard={giftCard}
                  checkoutUrl={checkoutUrl}
                />
                <Button
                  style={{
                    margin: '0 15px',
                  }}
                  onClick={() => setDilaogCreateOrderForCustomer(true)}
                >
                  Commander pour mon patient{' '}
                  <Tooltip
                    size='l'
                    label='En confirmant la commande pour votre patient, vous acceptez que Compliment vous facture la montant de la commande. Il vous faudra entrer les coordonnées de livraison pour votre patient'
                  >
                    <Icon name='info' />
                  </Tooltip>
                </Button>

                <Button
                  onClick={() => {
                    prescription?.lastStatus?.name === 'CREATED' &&
                      sendPrescription(sendEmailData)
                  }}
                  isDisabled={
                    pushPrescriptionStatusSuccess ||
                    prescription?.lastStatus?.name !== 'CREATED'
                  }
                  isLoading={
                    sendPrescriptionEmailLoading ||
                    pushPrescriptionStatusLoading
                  }
                  appearance='primary'
                >
                  Envoyer la recommandation à {patient?.firstname}
                </Button>
              </ActionSection>
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default NewPrescriptionConfirmPage
