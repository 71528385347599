export const fr = {
  name: 'fr_FR',
  translations: {
    header: {
      title: 'Mes notifications',
      'mark-all-read': 'Marquer tout comme lu',
    },
    messages: {
      'empty-inbox':
        "Aucune notification!<br>Nous vous informerons dès qu'il y aura une notification.",
      'server-error':
        'Nous rencontrons une erreur pour obtenir vos notifications.<br>Merci de réessayer ultérieurement.',
      'no-internet-connection':
        'Hmm, nous ne parvenons pas à vous connecter à internet.<br>Merci de vérifier votre connexion.',
    },
    'web-push-notifications': {
      notice:
        'En activant les notifications de votre navigateur web, vous avez encore plus de chance de rester à jour!',
      'enable-now': 'Activer maintenant',
    },
    notification: {
      'mark-as-read': 'Marqué comme lu',
      'mark-as-unread': 'Marqué comme non lu',
      delete: 'Supprimer',
    },
  },
}
