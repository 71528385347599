import axios from 'axios'

import Constants from './constants'

export const editPrescriberLoading = () => ({
  type: Constants.EDIT_PRESCRIBER_LOADING,
})
export const editPrescriberSuccess = payload => ({
  type: Constants.EDIT_PRESCRIBER_SUCCESS,
  payload: payload.update_prescriber.returning[0],
})
export const editPrescriberFailed = error => ({
  type: Constants.EDIT_PRESCRIBER_FAILED,
  error,
})
export const editPrescriberReset = () => ({
  type: Constants.EDIT_PRESCRIBER_RESET,
})

export const addDiscountCodesLoading = () => ({
  type: Constants.ADD_DISCOUNT_CODES_LOADING,
})

export const addDiscountCodesSuccess = payload => ({
  type: Constants.ADD_DISCOUNT_CODES_SUCCESS,
  payload,
})

export const addDiscountCodesFailed = error => ({
  type: Constants.ADD_DISCOUNT_CODES_FAILED,
  error,
})

export const addCalendlyCodeFailed = error => ({
  type: Constants.ADD_CALENDLY_CODE_FAILED,
  error,
})

export const addCalendlyCodeLoading = () => ({
  type: Constants.ADD_CALENDLY_CODE_LOADING,
})

export const addCalendlyCodeSuccess = payload => ({
  type: Constants.ADD_CALENDLY_CODE_SUCCESS,
  payload,
})

export const createDiscountCodes = data => dispatch => {
  dispatch(addDiscountCodesLoading())
  axios
    .post('https://hook.integromat.com/ium7iedakghvkigb2744jzi7hf0iwreg', data)
    .then(({ data }) => {
      dispatch(addDiscountCodesSuccess(data))
    })
    .catch(error => {
      dispatch(addDiscountCodesFailed(error.code))
    })
}
