import React from 'react'
import PropTypes from 'prop-types'

import { Container, SecondaryTitle, TakeLabel, TakeContainer } from './style'
import ProductTile from '../../product/product-tile'
import { Spinner } from '../../ui'
import { useProducts } from '../../../utils/hooks'
import { outOfBagProductsHandle } from '../../../utils/data'

const PrescriptionProductsList = ({
  isLoading,
  prescriptionRecommendedDuration,
  takes,
  titleInCure,
  titleOutCure,
  AbilityToManageQuantity,
  cart,
  updateVariantToCart,
  removeVariantsToCart,
  updateCartLoading,
  from,
  editMode,
  prescription,
  takesArray,
}) => {
  const { data } = useProducts()

  if (!data.length || isLoading) return <Spinner />

  const standAloneTake = takes.find(take => take.isStandAlone)
  const inCureProductTakes = takes.filter(take => !take.isStandAlone)

  // if it comes from the recommendation page
  if (cart?.lines) {
    const standAloneTake = cart?.lines.edges.filter(take =>
      outOfBagProductsHandle.includes(take.node.merchandise.product.handle)
    )
    const inCureTakes = cart?.lines.edges.filter(
      take =>
        !outOfBagProductsHandle.includes(take.node.merchandise.product.handle)
    )

    let index = prescription?.allItems?.length

    for (let i = 0; i < index; i++) {
      takesArray[i] = cart?.lines.edges.filter(cartItem => {
        if (
          cartItem.node.attributes.find(i => i.key === 'prise')?.value ===
          `${i + 1}`
        ) {
          return (
            cartItem.node.attributes.find(i => i.key === 'prise')?.value ===
            `${i + 1}`
          )
        }
        return false
      })
    }

    return (
      <Container>
        {!!inCureTakes.length && (
          <>
            {titleInCure ? (
              titleInCure
            ) : (
              <SecondaryTitle>Les produits en sachets</SecondaryTitle>
            )}
            <TakeContainer>
              {takesArray.map((take, i) => {
                if (take.length) {
                  return (
                    <React.Fragment key={i}>
                      {
                        <TakeLabel>
                          {inCureProductTakes[i]?.label
                            ? inCureProductTakes[i]?.label
                            : `Prise #${i + 1}`}
                        </TakeLabel>
                      }
                      {take.map((item, i) => (
                        <ProductTile
                          handle={item.node.merchandise.product.handle}
                          key={item.node.merchandise.product.handle}
                          quantity={item.node.quantity}
                          notes={
                            item.node.attributes?.find(i => i.key === 'notes')
                              ?.value
                          }
                          recommendedDuration={prescriptionRecommendedDuration}
                          isStandAlone
                          AbilityToManageQuantity={AbilityToManageQuantity}
                          cart={cart}
                          updateVariantToCart={updateVariantToCart}
                          removeVariantsToCart={removeVariantsToCart}
                          updateCartLoading={updateCartLoading}
                          from={from}
                          editMode={editMode}
                        />
                      ))}
                    </React.Fragment>
                  )
                } else {
                  return ''
                }
              })}

              {/* {inCureTakes.map(item => (
                <ProductTile
                  handle={item.node.merchandise.product.handle}
                  key={item.node.merchandise.product.handle}
                  quantity={item.node.quantity}
                  // notes={item.notes}
                  recommendedDuration={prescriptionRecommendedDuration}
                  isStandAlone
                  AbilityToManageQuantity={AbilityToManageQuantity}
                  cart={cart}
                  updateVariantToCart={updateVariantToCart}
                  removeVariantsToCart={removeVariantsToCart}
                  updateCartLoading={updateCartLoading}
                  from={from}
                />
              ))} */}
            </TakeContainer>
          </>
        )}
        {!!standAloneTake.length && (
          <>
            {titleOutCure ? (
              titleOutCure
            ) : (
              <SecondaryTitle>Les produits hors sachets</SecondaryTitle>
            )}
            {standAloneTake.map(item => (
              <ProductTile
                handle={item.node.merchandise.product.handle}
                key={item.node.merchandise.product.handle}
                quantity={item.node.quantity}
                notes={
                  item.node.attributes?.find(i => i.key === 'notes')?.value
                }
                recommendedDuration={prescriptionRecommendedDuration}
                isStandAlone
                AbilityToManageQuantity={AbilityToManageQuantity}
                cart={cart}
                updateVariantToCart={updateVariantToCart}
                removeVariantsToCart={removeVariantsToCart}
                updateCartLoading={updateCartLoading}
                from={from}
                editMode={editMode}
              />
            ))}
          </>
        )}
      </Container>
    )
  }
  // if it comes from the prescriptions page
  else {
    return (
      <Container>
        {!!inCureProductTakes.length && (
          <>
            {titleInCure ? (
              titleInCure
            ) : (
              <SecondaryTitle>Les produits en sachets</SecondaryTitle>
            )}
            {inCureProductTakes.map((take, loopIndex) => (
              <TakeContainer key={take.index}>
                {/* Don't show the title of the take if only one take */}
                {inCureProductTakes.length > 1 && (
                  <TakeLabel>
                    {take.label || `Prise #${loopIndex + 1}`}
                  </TakeLabel>
                )}
                {take.items.map(item => (
                  <ProductTile
                    handle={item.productHandle}
                    key={item.productHandle}
                    quantity={item.quantity}
                    notes={item.notes}
                    recommendedDuration={prescriptionRecommendedDuration}
                    AbilityToManageQuantity={AbilityToManageQuantity}
                    cart={cart}
                    updateVariantToCart={updateVariantToCart}
                    removeVariantsToCart={removeVariantsToCart}
                    updateCartLoading={updateCartLoading}
                    from={from}
                  />
                ))}
              </TakeContainer>
            ))}
          </>
        )}
        {!!standAloneTake && (
          <>
            {titleOutCure ? (
              titleOutCure
            ) : (
              <SecondaryTitle>Les produits hors sachets</SecondaryTitle>
            )}
            {standAloneTake.items.map(item => (
              <ProductTile
                handle={item.productHandle}
                key={item.productHandle}
                quantity={item.quantity}
                notes={item.notes}
                recommendedDuration={prescriptionRecommendedDuration}
                isStandAlone
                AbilityToManageQuantity={AbilityToManageQuantity}
                cart={cart}
                updateVariantToCart={updateVariantToCart}
                removeVariantsToCart={removeVariantsToCart}
                updateCartLoading={updateCartLoading}
                from={from}
              />
            ))}
          </>
        )}
      </Container>
    )
  }
}

PrescriptionProductsList.propTypes = {
  isLoading: PropTypes.bool,
  prescriptionRecommendedDuration: PropTypes.string,
  // TODO: make custom prop type for products
  takes: PropTypes.array,
  titleInCure: PropTypes.node,
  titleOutCure: PropTypes.node,
}

PrescriptionProductsList.defaultProps = {
  isLoading: false,
}

export default PrescriptionProductsList
