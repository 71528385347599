import React from 'react'
import MagicBell, {
  FloatingNotificationInbox,
} from '@magicbell/magicbell-react'

import { fr } from './locales'
import { NotificationWrapper } from './style'
import { Color } from '../../../../utils/constants'

const Notification = ({ userEmail }) => {
  const theme = {
    icon: { borderColor: Color.MINE_SHAFT },
    header: {
      fontFamily: 'Basis Grotesque Pro',
      backgroundColor: Color.MINE_SHAFT,
      borderRadius: '4px',
    },
    footer: { backgroundColor: Color.MINE_SHAFT, borderRadius: '4px' },
  }

  const playSound = () => {
    const audio = new Audio(
      process.env.REACT_APP_AWS_BUCKET +
        'assets/sounds/mixkit-long-pop-2358.wav'
    )
    audio.addEventListener('canplaythrough', event => {
      // the audio is now playable; play it if permissions allow
      audio.play()
    })
  }

  return (
    <NotificationWrapper>
      <MagicBell
        apiKey={process.env.REACT_APP_MAGICBELL_API_KEY}
        userExternalId={userEmail}
        theme={theme}
        locale={fr}
        onNewNotification={playSound}
      >
        {props => <FloatingNotificationInbox height={400} {...props} />}
      </MagicBell>
    </NotificationWrapper>
  )
}

export default Notification
