import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { ActionType } from '../constants'
import {
  fetchProductsLoading,
  fetchProductsSuccess,
  fetchProductsFailed,
  getFetchProductsStatus,
  getProducts,
} from '../../store/product'
import { ALL_PRODUCTS } from '../../services/queries'
import { getUserToken } from '../../store/auth-user'

export const useProducts = () => {
  const dispatch = useDispatch()
  const fetchProductsStatus = useSelector(getFetchProductsStatus)
  const products = useSelector(getProducts)
  const authToken = useSelector(getUserToken)
  const context = authToken
    ? { headers: { Authorization: `Bearer ${authToken}` } }
    : undefined
  const [
    fetchProducts,
    { data: productsData, loading: productsLoading, error: productsError },
  ] = useLazyQuery(ALL_PRODUCTS, {
    context,
  })

  useEffect(() => {
    !products.length &&
      fetchProductsStatus !== ActionType.LOADING &&
      fetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    productsLoading &&
      fetchProductsStatus !== ActionType.LOADING &&
      dispatch(fetchProductsLoading())
  }, [productsLoading, dispatch, fetchProductsStatus])
  useEffect(() => {
    productsData &&
      fetchProductsStatus !== ActionType.SUCCESS &&
      dispatch(fetchProductsSuccess(productsData))
  }, [productsData, dispatch, fetchProductsStatus])
  useEffect(() => {
    if (productsError && fetchProductsStatus !== ActionType.FAILED) {
      dispatch(fetchProductsFailed(productsError))
      toast.error(
        "Une erreur s'est produite lors de la récupération des produits, réessayez plus tard"
      )
    }
  }, [productsError, dispatch, fetchProductsStatus])
  return {
    data: products,
    failed: fetchProductsStatus === ActionType.FAILED,
    loading: fetchProductsStatus === ActionType.LOADING,
    success: fetchProductsStatus === ActionType.SUCCESS,
  }
}
