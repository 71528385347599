import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  Container,
  InfoContainer,
  PhoneContainer,
  P,
  MenuContainer,
} from './style'
import { Avatar, Button, Label, Dropdown, NavLink } from '../../../ui'

const PatientCard = ({ patient, onDeletePatient }) => {
  return (
    <Container>
      <Avatar firstName={patient?.firstname} lastName={patient?.name} />
      <InfoContainer>
        <P>{`${patient?.firstname} ${patient?.name}`}</P>
        <P>{patient?.email}</P>
      </InfoContainer>
      <PhoneContainer>
        <Label>Téléphone</Label>
        <P>{patient?.phone_number || '-'}</P>
      </PhoneContainer>
      <Dropdown
        content={
          <MenuContainer>
            <NavLink as={Link} to={`/patients/${patient?.id}`}>
              Voir le profil
            </NavLink>
            <NavLink onClick={() => onDeletePatient(patient)}>
              Supprimer
            </NavLink>
          </MenuContainer>
        }
      >
        <Button iconLeft='menu' appearance='minimal' />
      </Dropdown>
    </Container>
  )
}
PatientCard.propTypes = {
  patient: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
}

export default PatientCard
