import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Content } from './style'
import { fetchAll } from '../../store/order'
import { getOrders } from '../../store/order'
import Layout from '../../components/layout/default'
import OrderTable from '../../components/order/table'
import PageTitle from '../../components/section/page-title'

const OrdersPage = () => {
  const dispatch = useDispatch()
  const orders = useSelector(getOrders)

  useEffect(() => {
    dispatch(fetchAll())
  }, [dispatch])

  console.log('orders', orders)

  return (
    <Layout>
      <PageTitle title='Commandes' />
      <Container>
        <Content>
          <OrderTable />
        </Content>
      </Container>
    </Layout>
  )
}

export default OrdersPage
