import styled from 'styled-components/macro'

export const Container = styled.article`
  display: flex;
  width: 100%;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 25px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const P = styled.p`
  font-size: 14px;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`
