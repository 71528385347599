import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(65, 91, 115, 0.25);
  width: ${props => props.width};
  min-height: 200px;
  min-width: 265px;
  margin: ${props => props.margin};
  padding: 15px 15px;
  max-height: 150px;
`

export const Title = styled.p`
  font-size: 14px;
`

export const SubTitle = styled.p`
  font-size: 10px;
`

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
  justify-content: flex-end;
  font-size: 12px;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`

export const MetricsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`

export const MetricCard = styled.div`
  background: #f5f8fc;
  border-radius: 4px;
  width: 40%;
  margin: 5px;
  min-height: 100px;
  padding: 10px 15px;
  position: relative;
`

export const MetricTitle = styled.p`
  font-size: 12px;
`

export const MetricTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ValueContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 10px;
  align-items: baseline;
`
export const Value = styled.p`
  font-size: 40px;
`

export const ValueLabel = styled.p`
  font-size: 20px;
`
