import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  padding: 65px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const ActionFooter = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #e3e8ee;
  background-color: #fff;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`

export const Title = styled.h3`
  font-size: 22px;
  margin-bottom: 20px;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`
