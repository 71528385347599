import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
`

export const List = styled.p`
  font-size: 12px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-radius: 4px;
  margin: 5px 0;
  padding: 5px;
  text-align: center;
`
