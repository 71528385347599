import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Body,
  BodyCol,
  Button,
  ButtonGroup,
  Close,
  Container,
  Description,
  DialogBody,
  DialogHeader,
  DialogSubTitle,
  DialogTitle,
  Div,
  Footer,
  Image,
  PriceContainer,
  Img,
  Name,
  PillImage,
  PillImageContainer,
  Point,
  PointText,
  PointTitle,
  Price,
  SecondaryTitle,
  Small,
  SmallText,
  StrongPoints,
  TakeChoice,
  Title,
  VerticalSeparator,
  MoreInfo,
} from './style'
import { TagsGroup, Tag, Dialog, List, Label, Icon } from '../../ui'
import { VscLinkExternal } from 'react-icons/vsc'
import {
  getProductPicture,
  getProductLabelPicture,
} from '../../../utils/helpers'

const ProductPreviewCard = ({
  product,
  onClick,
  recommendedDuration,
  displayTakeChoiceDialog,
  variantId,
  addProduct,
  takesArray,
  hasDetailModalOpen,
  closeDetailModal,
  mode,
}) => {
  const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)
  const [selectedHandle, setSelectedHandle] = useState(false)
  const productPicture = getProductPicture(product.handle)
  const productLabelPicture = getProductLabelPicture(product.handle)

  const lengthtakes = takesArray?.filter(i => {
    return Object.keys(i).length !== 0
  })

  const closeModal = () =>
    hasDetailModalOpen ? closeDetailModal() : setIsDetailsModalOpen(false)

  return (
    <Container $mode={mode}>
      <Image alt={product.name} src={productPicture} $mode={mode} />
      <Body>
        <Div $mode={mode}>
          <Name $mode={mode}>{product.name}</Name>
          <TagsGroup>
            {product.tags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </TagsGroup>
        </Div>
        <Description>
          {product.headline && (
            <>
              <Label>Description</Label>
              <p>{product.headline}</p>
            </>
          )}
        </Description>
      </Body>
      <Footer $mode={mode}>
        <PriceContainer $mode={mode}>
          <Price $mode={mode}>
            <strong>
              {product.getPrice(recommendedDuration)}€
              {!product.isStandAlone && <span>/mois</span>}
            </strong>
          </Price>
          <Small>{product.format}</Small>
        </PriceContainer>

        <ButtonGroup>
          <Button
            onClick={e => {
              e.preventDefault()
              setIsDetailsModalOpen(true)
            }}
          >
            Détails
          </Button>
          {onClick && (
            <Button
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                e.stopPropagation()
                if (displayTakeChoiceDialog) {
                  setSelectedHandle(true)
                } else {
                  onClick(product)
                }
              }}
            >
              Ajouter
            </Button>
          )}
        </ButtonGroup>
      </Footer>
      <Dialog
        isOpen={isDetailModalOpen || hasDetailModalOpen}
        onClose={closeModal}
        style={{ zIndex: '999' }}
      >
        <Close onClick={closeModal}>
          <Icon name='cancel' />
        </Close>
        <DialogHeader>
          <div>
            <DialogTitle>
              <span>{product.name}</span>
              {!product?.noProductPageUrl && (
                <MoreInfo
                  href={`https://compliment.me/products/${product.handle}`}
                  target='_blank'
                >
                  Plus d'info{' '}
                  <span>
                    <VscLinkExternal />
                  </span>
                </MoreInfo>
              )}
            </DialogTitle>
            {product.headline && (
              <DialogSubTitle>{product.headline}</DialogSubTitle>
            )}
          </div>
          <PillImageContainer
            $bg={
              process.env.REACT_APP_AWS_BUCKET +
              'assets/images/product-background.jpg'
            }
          >
            <PillImage src={product.pillPicture} />
          </PillImageContainer>
        </DialogHeader>
        <DialogBody>
          <BodyCol>
            {!!product.strongPoints.length && (
              <>
                <SecondaryTitle>Les points forts</SecondaryTitle>
                <StrongPoints>
                  {product.strongPoints.map((item, index) => (
                    <Point key={index}>
                      <PointTitle>{item.title}</PointTitle>
                      {item.text && <PointText>{item.text}</PointText>}
                      {item.text1 && <PointText>{item.text1}</PointText>}
                      {item.text2 && <PointText>{item.text2}</PointText>}
                      {item.text3 && <PointText>{item.text3}</PointText>}
                    </Point>
                  ))}
                </StrongPoints>
              </>
            )}
            {product.ingredients.length && (
              <>
                <SecondaryTitle>Formulation</SecondaryTitle>
                <List items={product.ingredients} />
              </>
            )}
          </BodyCol>
          <VerticalSeparator />
          <BodyCol>
            {product.whyRecommendIt && (
              <>
                <SecondaryTitle>Pourquoi le recommander ?</SecondaryTitle>
                <SmallText>{product.whyRecommendIt}</SmallText>
              </>
            )}
            {product.forWho && (
              <>
                <SecondaryTitle>Pour qui ?</SecondaryTitle>
                <SmallText>{product.forWho}</SmallText>
              </>
            )}
            {product.benefits && (
              <>
                <SecondaryTitle>Les bienfaits</SecondaryTitle>
                <SmallText>{product.benefits}</SmallText>
              </>
            )}
            {product.contraindication && (
              <>
                <SecondaryTitle>Contre indication(s)</SecondaryTitle>
                <SmallText>{product.contraindication}</SmallText>
              </>
            )}
            {product.deficiencies && (
              <>
                <SecondaryTitle>Signes de carences</SecondaryTitle>
                <SmallText>{product.deficiencies}</SmallText>
              </>
            )}
            {product.supplier && (
              <>
                <SecondaryTitle>Fournisseur</SecondaryTitle>
                <SmallText>{product.supplier}</SmallText>
              </>
            )}
          </BodyCol>
        </DialogBody>
        {productLabelPicture && (
          <Img alt={`${product.handle}-labels`} src={productLabelPicture} />
        )}
      </Dialog>
      <Dialog isOpen={selectedHandle} onClose={() => setSelectedHandle('')}>
        <Close onClick={() => setSelectedHandle('')}>
          <Icon name='cancel' />
        </Close>
        <Title>
          Dans quelle prise voulez vous ajouter{' '}
          {/* {selectedHandle
                      ? products.find(p => p.handle === selectedHandle).name
                      : ''}{' '} */}
          ?
        </Title>
        {
          lengthtakes?.map((i, index) => {
            return (
              <TakeChoice
                onClick={() => {
                  if (variantId) {
                    addProduct(variantId, `${index + 1}`, product.handle)
                  }
                }}
              >
                Prise #{index + 1}
              </TakeChoice>
            )
          })
          // .slice(0, -1)
        }
        {/* <TakeChoice
          onClick={() => {
            if (variantId) {
              addProduct(variantId, '2', product.handle)
            }
          }}
        >
          Prise #2
        </TakeChoice> */}
      </Dialog>
    </Container>
  )
}

ProductPreviewCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  hasDetailModalOpen: PropTypes.bool,
}

export default ProductPreviewCard
