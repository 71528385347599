import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Number, Text, NavLinkWrapper, Content } from './style'
import { Spinner, NavLink } from '../../ui'
import { getPatients, getFetchPatientsStatus } from '../../../store/patient'
import { ActionType } from '../../../utils/constants'
const PatientsStatsPreviewCard = () => {
  const patients = useSelector(getPatients)
  const patientsStatus = useSelector(getFetchPatientsStatus)

  return (
    <Container>
      {patientsStatus === ActionType.LOADING ? (
        <Spinner />
      ) : (
        <div>
          <Text>Patients</Text>
          <Content>
            <Number>{patients.length}</Number>
            <NavLinkWrapper>
              <NavLink
                as={Link}
                style={{ padding: '0', fontSize: '18px' }}
                to={'/patients/new'}
              >
                Créer
              </NavLink>
            </NavLinkWrapper>
          </Content>
        </div>
      )}
    </Container>
  )
}

export default PatientsStatsPreviewCard
