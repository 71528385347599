import styled from 'styled-components/macro'

export const Title = styled.h3`
  text-align: center;
  margin: 0 20px 40px 20px;
  font-size: 20px;
`

export const SubTitle = styled.h3`
  text-align: center;
  margin: 0 20px 40px 20px;
  font-size: 12px;
`

export const CopyToClipboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.37);
  margin: 0 auto;
  padding: 10px 10px;
  margin-bottom: 40px;
`

export const CopyIcon = styled.span`
  font-size: 17px;
  cursor: pointer;
`

export const Footer = styled.div`
  text-align: center;
  margin: 0px auto 0 auto;

  p {
    font-size: 12px;
    margin: 20px 20px 0px 20px;
  }
`
