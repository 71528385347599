import { useEffect, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import { Container, ListContainer, List } from './style'
import { Spinner } from '../../ui'

const listItems = [
  {
    type: 'created',
    title: 'Créée',
    label: 'créée',
    color: '#084298',
    backgroundColor: '#cfe2ff',
    borderColor: '#b6d4fe',
  },
  {
    type: 'sent',
    title: 'Envoyée',
    label: 'envoyée',
    color: '#664d03',
    backgroundColor: '#fff3cd',
    borderColor: '#ffecb5',
  },
  {
    type: 'ordered',
    title: 'Achetée',
    label: 'achetée',
    color: '#0f5132',
    backgroundColor: '#d1e7dd',
    borderColor: '#badbcc',
  },
  {
    type: 'done',
    title: 'Finie',
    label: 'finie',
    color: '#ff7021',
    backgroundColor: 'rgb(255, 141, 78, 0.4)',
    borderColor: '#ffecb5',
  },
]

const StatusPie = ({ data, prescriptions }) => {
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState({})
  const [hovered, setHovered] = useState(undefined)

  useEffect(() => {
    if (prescriptions?.length === 0) {
      setLoading(false)
      return
    }
    if (data.filter(d => d.value >= 0).length === 3) {
      const values = {
        created: 0,
        ordered: 0,
        sent: 0,
        done: 0,
      }
      prescriptions.forEach(p => {
        switch (p.lastStatus.name) {
          case 'CREATED':
            values['created'] += 1
            break
          case 'SENT':
            values['sent'] += 1
            break

          case 'ORDERED':
            values['ordered'] += 1
            break
          case 'DONE':
            values['done'] += 1
            break
          default:
            break
        }
      })
      setValues(values)
      setLoading(false)
    }
  }, [data, prescriptions])

  if (loading) return <Spinner />
  return prescriptions && prescriptions.length > 1 ? (
    <Container>
      <>
        <ListContainer>
          {listItems.map(i => (
            <List
              key={i.type}
              color={i.color}
              backgroundColor={i.backgroundColor}
              borderColor={i.borderColor}
            >
              {i.label}
            </List>
          ))}
        </ListContainer>
        <PieChart
          data={listItems.map((entry, i) => {
            if (hovered === i)
              return { ...entry, value: values[entry.type], color: 'grey' }
            return {
              ...entry,
              value: values[entry.type],
              color: entry.backgroundColor,
            }
          })}
          animate
          lineWidth={60}
          labelPosition={100 - 60 / 2}
          style={{
            fontFamily:
              '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
            fontSize: '8px',
            height: '150px',
          }}
          segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
          label={({ dataIndex, dataEntry }) => {
            return dataIndex === hovered
              ? Math.round(dataEntry.percentage) + '%'
              : null
          }}
          labelStyle={{
            opacity: 0.75,
            pointerEvents: 'none',
          }}
          totalValue={prescriptions?.length || 0}
          onMouseOver={(_, index) => {
            setHovered(index)
          }}
          onMouseOut={() => {
            setHovered(undefined)
          }}
        />
      </>
    </Container>
  ) : (
    <p style={{ textAlign: 'center', marginTop: '2em', fontSize: '12px' }}>
      Aucune donnée
    </p>
  )
}

export default StatusPie
