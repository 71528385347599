import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, DatePicker } from '../ui'
import { ListItem, Section, Column, Header, List, Label } from './style.js'
import { useEffect } from 'react'

const prescriptionFilterTickBoxes = [
  { handleName: 'created', name: 'Créée' },
  { handleName: 'sent', name: 'Envoyée' },
  { handleName: 'ordered', name: 'Achetée' },
  { handleName: 'done', name: 'Finie' },
]

const productCategoryFilterTickBoxes = [
  { handleName: 'Sport', name: 'Sport' },
  { handleName: 'Detox', name: 'Detox' },
  { handleName: 'Digestion', name: 'Digestion' },
  { handleName: 'Energie', name: 'Energie' },
  { handleName: 'Cheveux & ongles', name: 'Cheveux & ongles' },
  { handleName: 'Immunité', name: 'Immunité' },
  { handleName: 'Grossesse', name: 'Grossesse' },
  { handleName: 'Peau', name: 'Peau' },
  { handleName: 'Sommeil', name: 'Sommeil' },
  { handleName: 'Stress', name: 'Stress' },
  { handleName: 'Poids', name: 'Poids' },
  { handleName: 'Articulations', name: 'Articulations' },
  { handleName: 'Vision', name: 'Vision' },
  { handleName: 'Cerveau', name: 'Cerveau' },
  { handleName: 'Cardiovasculaire', name: 'Cardiovasculaire' },
  { handleName: 'Végane', name: 'Vegan' },
  { handleName: 'Os', name: 'Os' },
]

const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const TickBox = ({ tb, handleOnTick, filters }) => {
  const [isTicked, setIsTicked] = useState(false)
  const prevFilters = usePrevious(filters)
  useEffect(() => {
    if (filters && filters.length > 0 && prevFilters !== filters) {
      if (filters.includes(tb.handleName)) setIsTicked(true)
    }
  }, [filters, filters?.length, tb.handleName, prevFilters])

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => handleOnTick(tb.handleName, isTicked, setIsTicked)}
    >
      <List style={{ flexDirection: 'row', marginLeft: '0' }}>
        {isTicked ? <Icon name={'checkBox'} /> : <Icon name={'tickBox'} />}
        <ListItem style={{ marginLeft: '4px' }}>{tb.name}</ListItem>
      </List>
    </div>
  )
}

const FilterBar = ({
  handleOnTick,
  filterByStatus,
  filterProductByCategory,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  filters,
}) => {
  return (
    <Section>
      {filterByStatus && (
        <>
          <Column style={{ width: '15%' }}>
            <Header>Status</Header>
            <List>
              {prescriptionFilterTickBoxes.map((tb, idx) => (
                <TickBox
                  key={idx}
                  tb={tb}
                  handleOnTick={handleOnTick}
                  filters={filters}
                />
              ))}
            </List>
          </Column>
          <Column>
            <Header>Date</Header>
            <List>
              <Label>Date de début</Label>
              <DatePicker
                selected={startDate}
                onChange={date =>
                  setStartDate(new Date(new Date(date).setHours(0, 0, 0, 0)))
                }
                maxDate={new Date()}
              />
              <Label>Date de fin</Label>
              <DatePicker
                selected={endDate}
                onChange={date =>
                  setEndDate(new Date(new Date(date).setHours(23, 59, 59, 0)))
                }
                maxDate={new Date()}
              />
            </List>
          </Column>
        </>
      )}
      {filterProductByCategory && (
        <Column>
          <Header>Tag</Header>
          <List style={{ height: '150px' }}>
            {productCategoryFilterTickBoxes.map((tb, idx) => (
              <TickBox key={idx} tb={tb} handleOnTick={handleOnTick} />
            ))}
          </List>
        </Column>
      )}
    </Section>
  )
}

FilterBar.propTypes = {
  handleOnTick: PropTypes.func,
  filterByStatus: PropTypes.bool,
  filterProductByCategory: PropTypes.bool,
}

export default FilterBar
