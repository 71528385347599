import React, { useState } from 'react'
import { Dialog, Button } from '../../ui'
import { Title, CopyToClipboardContainer, CopyIcon, Footer } from './style'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { MdContentCopy } from 'react-icons/md'

const DilaogCreateOrderForCustomer = ({
  checkoutUrl,
  giftCard,
  isDilaogCreateOrderForCustomerOpen,
  setDilaogCreateOrderForCustomer,
}) => {
  const [value] = useState(giftCard[0]?.code ? giftCard[0]?.code : '/')
  const [copied, setCopied] = useState(false)

  return (
    <Dialog
      isOpen={isDilaogCreateOrderForCustomerOpen}
      onClose={() => setDilaogCreateOrderForCustomer('')}
    >
      {giftCard?.length > 0 ? (
        <>
          <Title>
            Copiez & insérez le code ci-dessous dans le champs “Carte cadeau” au
            moment du paiement. Vous pourrez ainsi valider la commande sans
            paiement
          </Title>

          <CopyToClipboardContainer>
            <p>{value}</p>

            <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
              <CopyIcon>
                <MdContentCopy />
              </CopyIcon>
            </CopyToClipboard>
          </CopyToClipboardContainer>
          {/* {checkoutUrl} */}
          <Footer>
            <Button
              as='a'
              href={`${checkoutUrl}`}
              appearance='primary'
              // onClick={() => {
              //   mixpanel.track('Checkout', {
              //     cartId: cart?.id,
              //     prescriptionId: prescription.id,
              //     discountCode: discountCode,
              //   })
              // }}
              isDisabled={!copied}
            >
              Continuer vers le paiement
            </Button>

            <p>
              Pour confirmer la commande, il vous faudra entrer les coordonnées
              de livraison pour votre patient. En confirmant la commande pour
              votre patient, vous acceptez que Compliment vous facture la
              montant de cette commande. Ce montant sera réduit de votre
              commission
            </p>
          </Footer>
        </>
      ) : (
        <>
          <Title>
            Veuillez contacter le support pour obtenir votre carte cadeau afin
            de confirmer la commande pour votre patient
          </Title>
          <Footer>
            <Button as='a' href='mailto:pro@compliment.me' appearance='primary'>
              contacter le support
            </Button>
          </Footer>
        </>
      )}
    </Dialog>
  )
}

export default DilaogCreateOrderForCustomer
