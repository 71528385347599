import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  AvatarContainer,
  BreadCrumbsContainer,
  Container,
  Content,
  ElementGroup,
  PatientInfos,
  PatientProfile,
  Value,
  ControlContainer,
  EditForm,
  PatientPageHeader,
  ButtonWrapper,
} from './style'
import Layout from '../../../components/layout/default'
import {
  Icon,
  Avatar,
  Label,
  Input,
  Button,
  Spinner,
} from '../../../components/ui'
import PrescriptionContainer from '../../../components/prescription/prescriptions-container'
import { getPatientById } from '../../../store/patient'
import {
  usePatients,
  useEditPatient,
  useOnboardingProfileCheck,
  useDeletePatient,
  useDeletePrescription,
} from '../../../utils/hooks'

const Controls = ({ setEditField, setInputValue, fieldName, value }) => (
  <ControlContainer>
    <Icon
      name='pencil'
      onClick={() => {
        setEditField(fieldName)
        setInputValue(value)
      }}
    />
  </ControlContainer>
)

const PatientPage = ({ history, match }) => {
  useOnboardingProfileCheck()
  const firstNameInputEl = useRef(null)
  const lastNameInputEl = useRef(null)
  const emailInputEl = useRef(null)
  const phoneInputEl = useRef(null)
  const { loading, success } = usePatients()
  const patient = useSelector(state =>
    getPatientById(state, Number(match.params.id))
  )
  const [editField, setEditField] = useState('')
  const [inputValue, setInputValue] = useState('')
  const {
    editPatient,
    loading: editLoading,
    success: editSuccess,
    failed: editFailed,
    reset: resetEdit,
  } = useEditPatient()
  const { deletePrescription } = useDeletePrescription()
  const {
    data: patientData,
    loading: deletePatientLoading,
    success: deletePatientSuccess,
    failed: deletePatientFailed,
    deletePatient,
  } = useDeletePatient()

  useEffect(() => {
    if (!editField) return

    const inputEl = {
      firstName: firstNameInputEl,
      lastName: lastNameInputEl,
      email: emailInputEl,
      phone: phoneInputEl,
    }[editField]

    if (!inputEl?.current) return

    inputEl.current.focus()
  }, [editField])

  useEffect(() => {
    if (editLoading) return

    if (editSuccess) {
      toast.success('La patient a été édité avec succès !')
      resetEdit()
      setEditField('')
      setInputValue('')
    } else if (editFailed) {
      toast.error(
        "Une erreur s'est produite lors de l'édition du patient, réessayez plus tard."
      )
      resetEdit()
      setEditField('')
      setInputValue('')
    }
  }, [editLoading, editSuccess, editFailed, resetEdit])

  useEffect(() => {
    if (patientData || deletePatientSuccess) {
      toast.success(`Le patient a été supprimé avec succès !`)
      history.push('/patients/')
    }
    if (deletePatientFailed) {
      toast.error(
        `Il y a eu une erreur dans la supression du patient, merci de réessayer.`
      )
    }
  }, [deletePatientFailed, patientData, deletePatientSuccess, history])

  const onSubmit = fieldName => {
    editPatient({
      variables: {
        email: (fieldName === 'email' ? inputValue : patient.email) || '',
        fn: (fieldName === 'firstName' ? inputValue : patient.firstname) || '',
        ln: (fieldName === 'lastName' ? inputValue : patient.name) || '',
        phone:
          (fieldName === 'phone' ? inputValue : patient.phone_number) || '',
        id: patient.id,
      },
    })
  }

  const onDeletePatient = patient => {
    // use promises to make sure that the prescriptions of the patient
    // will be deleted before trying to delete the patient itself
    const promises = []
    if (patient.prescriptions && patient.prescriptions.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          patient.prescriptions.forEach(pres => {
            deletePrescription({ variables: { id: pres.id } })
          })
        })
      )
    }
    promises.push(
      new Promise((resolve, reject) => {
        deletePatient({ variables: { id: patient.id } })
      })
    )
    Promise.all(promises)
  }

  return (
    <Layout>
      <Container>
        <Content>
          {(loading || !patient) && <Spinner />}
          {!loading && success && patient && (
            <>
              <PatientPageHeader>
                <BreadCrumbsContainer>
                  <Link to='/patients'>Patients</Link>
                  <Icon name='angle-right' />
                  <span>
                    {patient?.firstname} {patient?.name}
                  </span>
                </BreadCrumbsContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => onDeletePatient(patient)}
                    style={{
                      fontFamily: 'Basis Grotesque Pro',
                    }}
                    isDisabled={!patient}
                    isLoading={deletePatientLoading}
                    appearance={'red'}
                  >
                    Supprimer
                  </Button>
                </ButtonWrapper>
              </PatientPageHeader>
              <PatientProfile>
                <AvatarContainer>
                  <Avatar
                    size='xxl'
                    firstName={patient?.firstname}
                    lastName={patient?.name}
                  />
                </AvatarContainer>
                <PatientInfos>
                  <ElementGroup>
                    <Label>Prénom</Label>
                    <Value showControls={editField !== 'firstName'}>
                      {editField === 'firstName' ? (
                        <EditForm>
                          <Input
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            ref={firstNameInputEl}
                            isDisabled={editLoading}
                          />
                          <Button
                            onClick={() => onSubmit('firstName')}
                            isLoading={editLoading}
                          >
                            Enregistrer
                          </Button>
                        </EditForm>
                      ) : (
                        patient?.firstname || '-'
                      )}
                      <Controls
                        setEditField={setEditField}
                        setInputValue={setInputValue}
                        fieldName='firstName'
                        value={patient.firstname}
                      />
                    </Value>
                  </ElementGroup>
                  <ElementGroup>
                    <Label>Nom</Label>
                    <Value showControls={editField !== 'lastName'}>
                      {editField === 'lastName' ? (
                        <EditForm>
                          <Input
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            ref={lastNameInputEl}
                            isDisabled={editLoading}
                          />
                          <Button
                            onClick={() => onSubmit('lastName')}
                            isLoading={editLoading}
                          >
                            Enregistrer
                          </Button>
                        </EditForm>
                      ) : (
                        patient?.name || '-'
                      )}
                      <Controls
                        setEditField={setEditField}
                        setInputValue={setInputValue}
                        fieldName='lastName'
                        value={patient.name}
                      />
                    </Value>
                  </ElementGroup>
                  <ElementGroup>
                    <Label>Email</Label>
                    <Value showControls={editField !== 'email'}>
                      {editField === 'email' ? (
                        <EditForm>
                          <Input
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            ref={emailInputEl}
                            isDisabled={editLoading}
                          />
                          <Button
                            onClick={() => onSubmit('email')}
                            isLoading={editLoading}
                          >
                            Enregistrer
                          </Button>
                        </EditForm>
                      ) : (
                        patient?.email
                      )}
                      <Controls
                        setEditField={setEditField}
                        setInputValue={setInputValue}
                        fieldName='email'
                        value={patient.email}
                      />
                    </Value>
                  </ElementGroup>
                  <ElementGroup>
                    <Label>Téléphone</Label>
                    <Value showControls={editField !== 'phone'}>
                      {editField === 'phone' ? (
                        <EditForm>
                          <Input
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            ref={phoneInputEl}
                            isDisabled={editLoading}
                          />
                          <Button
                            onClick={() => onSubmit('phone')}
                            isLoading={editLoading}
                          >
                            Enregistrer
                          </Button>
                        </EditForm>
                      ) : (
                        patient?.phone_number || '-'
                      )}
                      <Controls
                        setEditField={setEditField}
                        setInputValue={setInputValue}
                        fieldName='phone'
                        value={patient.phone_number}
                      />
                    </Value>
                  </ElementGroup>
                </PatientInfos>
              </PatientProfile>
              <PrescriptionContainer
                showDashboard={false}
                patientId={patient?.id}
              />
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default PatientPage
