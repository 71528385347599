import { Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import validator from 'validator'

import { Form } from './style'
import { Button, Field, FieldGroup } from '../../ui'

const PatientSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
  first: Yup.string().required('Champs obligatoire'),
  last: Yup.string().required('Champs obligatoire'),
  phone: Yup.string()
    .required('Champs obligatoire')
    .test('isValidPhone', "Le numéro de téléphone n'est pas valide", value => {
      if (value)
        return validator.isMobilePhone(value, [
          'fr-FR',
          'fr-LU',
          'fr-BE',
          'nl-BE',
          'de-LU',
          'de-CH',
        ])
    }),
})

const CreatePatientForm = ({ onSubmit, isLoading }) => (
  <Formik
    initialValues={{ email: '', first: '', last: '', phone: '' }}
    validationSchema={PatientSchema}
    onSubmit={values => {
      onSubmit(values)
    }}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <Field
          placeholder='john.doe@email.com'
          label='Email'
          name='email'
          id='login-email'
          type='email'
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.email}
          error={props.errors.email}
          touched={props.touched.email}
          isRequired
        />
        <FieldGroup>
          <Field
            placeholder='John'
            label='Prénom'
            id='first-name'
            name='first'
            type='text'
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.first}
            error={props.errors.first}
            touched={props.touched.first}
            isRequired
          />
          <Field
            placeholder='Doe'
            label='Nom de famille'
            id='last-name'
            name='last'
            type='text'
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.last}
            error={props.errors.last}
            touched={props.touched.last}
            isRequired
          />
        </FieldGroup>
        <Field
          placeholder='+32*********, +33*********, +41*********, +352********'
          label='Téléphone'
          id='phone'
          name='phone'
          type='text'
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.phone}
          error={props.errors.phone}
          touched={props.touched.phone}
          isRequired
        />
        <Button
          type='submit'
          isLoading={isLoading}
          isDisabled={
            !props.values.email &&
            !props.values.first &&
            !props.values.last &&
            !props.values.phone
          }
        >
          Ajouter
        </Button>
      </Form>
    )}
  </Formik>
)

CreatePatientForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default CreatePatientForm
