import { ProductCategory, ProductHandle } from '../utils/constants'

export const fr = {
  products: {
    [ProductHandle.ACEROLA]: {
      display: false,
      format: {
        quantity: 30,
        type: 'Comprimés',
      },
      dosage: "500mg Vitamine C (%VNR) issue d'acérola bio",
      arguments: ["Vitamine C issue d'acérola", '500mg de vitamine C'],
      ingredients: [
        'Vitamine C (acide ascorbique 97%) 502,1mg',
        'Citrus bioflavonoïdes 7,6mg',
        'Extrait acérola pure 1mg',
        'Rutine 0,9mg',
        'Saccharose 2358,4mg',
        'Maltodextrine 144mg',
        'Arôme naturel de framboise 17,6mg',
        'Stéarate de calcium 54,4mg',
        'Phosphate tricalcique 65mg',
        'Dioxyde de silicium 9,8mg',
      ],
      categories: [ProductCategory.IMUNITY],
    },
    [ProductHandle.ASHWAGANDHA]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '300mg Ashwagandha Bio (2.5% en withanolides)',
      arguments: [
        'Dosée à 2.5% de withanolides',
        'Plante adaptogène',
        'Stress et fatigue',
      ],
      ingredients: ["300mg d'Ashwagandha Bio (à 2.5% de withanolides)"],
      categories: [ProductCategory.SLEEP, ProductCategory.STRESS],
      headline: 'En cas de moments de stress pour faciliter la relaxation',
      strongPoints: [
        {
          title: 'Dosée à 2.5% de withanolides',
        },
        {
          title: 'Associé au lithothamne : réduit le stress',
        },
        {
          title: 'Produit 100% naturel à base de plantes : origine biologique',
        },
        {
          title: 'Pas de dépendance',
        },
      ],
      forWho: 'Personne stressé ou anxieuse\nTroubles du sommeil',
      benefits:
        "Diminue le stress\nOptimise la relaxation\nFacilite l'endormissement",
      contraindication:
        "Déconseillé aux femmes enceintes, allaitantes et aux enfants / Déconseillé en cas d'hyperthyroïdie.",
      supplier: 'Equi Nutri',
    },
    [ProductHandle.CHARDON_MARIE]: {
      display: false,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '475mg chardon-Marie',
      arguments: [],
      ingredients: [
        'Chardon-Marie Bio (Silybum marianum - extrait sec) 360mg',
        'Lithothamne (Lithothamnium calcareum) 105,4mg. Gélule végétale HPMC 95mg',
      ],
      categories: [ProductCategory.DETOX, ProductCategory.DIGESTION],
    },
    [ProductHandle.HAIR_NAIL]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        '150mg Levure de bière + 50mg prêle + 30mg zinc + 24mg bêta-carotène + cystéine',
      arguments: [],
      ingredients: [
        'Levure de bière, L-cystéine, L-glycine, L-méthionine, prêle, vitamine C, zinc, bêta-carotène, gluconate de manganèse et vitamines du groupe B.',
      ],
      categories: [ProductCategory.BEAUTY, ProductCategory.HAIR_NAILS],
      headline:
        'Contient l’ensemble des nutriments ayant un effet prouvé sur la santé des cheveux et des ongles.',
      strongPoints: [
        {
          title:
            'Formule complète: levure de bière, zinc, vitamine C, bêta-carotène, cystéine et prêle',
          text: 'Contribue à la synthèse de kératine',
        },
        {
          title: '100% des VNR en vitamine B6 et zinc',
        },
        {
          title: 'Contient de la prêle pour favoriser la pousse des cheveux',
        },
        {
          title: 'Limite les effets du vieillissement sur la chevelure',
        },
      ],
      forWho:
        'Pour les personnes souffrant de changement hormonaux (puberté, ménopause, grossesse) ou de pertes de cheveux liées au stress.',
      benefits:
        'Anti-perte passagère des cheveux, densifie et stimule la pousse des cheveux et ongles, limite le vieillissement des cheveux, stimule pigmentation des cheveux (cuivre).',
      contraindication:
        'Durant la grossesse et l’allaitement : demandez conseil à votre médecin ou à votre spécialiste (contient de la prêle) ',
      supplier: 'Lepi Vits',
    },
    [ProductHandle.COLLAGENE]: {
      display: true,
      standAlone: true,
      format: {
        quantity: 15,
        type: 'sticks de 7 grammes',
      },
      dosage: '5g de collagène marin Peptan + 30mg de CoQ10 + 20mg resvératrol',
      arguments: [],
      ingredients: [
        '5g de Collagène Peptan, maltodextrine, inuline, arôme fraise, extrait sec de vigne (20 mg de resvératrol), vitamine C, hyaluronate de sodium, coenzyme Q10, extrait sec de renouée du Japon, betterave, biotine.',
        '15 sticks de 7 grammes.',
      ],
      categories: [
        ProductCategory.BEAUTY,
        ProductCategory.SKIN,
        ProductCategory.HAIR_NAILS,
      ],
      headline: 'Pour une peau plus jeune, repulpée et lumineuse.',
      strongPoints: [
        {
          title: '20 mg de resvératrol',
          text: 'Puissant antioxydant pour prévenir le vieillissement cellulaire',
        },
        {
          title: 'Absorption optimisée',
          text: 'Associé à la vitamine C et à la Biotine',
        },
      ],
      deficiencies: 'Peau terne\nCheveux et les ongles cassants',
      forWho:
        'Tout le monde: la synthèse de collagène diminue à partir de 20 ans',
      benefits:
        'Une peau repulpée et lumineuse\nStimule la pousse des cheveux et diminue leur chute\nDes ongles longs et solides',
      supplier: 'Digamed',
    },
    [ProductHandle.DRAINAGE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "225mg d'orthosiphon + 75mg d'artichaut + 75mg de chardon Marie + 75mg de radis noir",
      arguments: [],
      ingredients: [
        'Orthosiphon (à 0,1% de sinensétine), artichaut (à 5% de cynarine), chardon-Marie (à 80% de silymarine), radis noir.',
      ],
      categories: [ProductCategory.DETOX, ProductCategory.WEIGHT],
      headline:
        'Soutient les différentes phases de la détoxification hépatique et le fonctionnement des reins',
      strongPoints: [
        {
          title:
            'Formule complète : orthosiphon, chardon-marie, artichaut et radis noir.',
          text: '100% naturel\nAction sur le foie et la vésicule biliaire bien documentée',
        },
        {
          title: 'Riche en principe actifs',
          text: '0,225 mg sinensétine: effets diurétique\n3,75 mg cynarine: stimule la vésicule biliaire\n60 mg de silymarine: action hépatoprotectrice',
        },
      ],
      forWho:
        'Pour les personnes voulant détoxifier leur organisme après une période d’excès.',
      benefits:
        'Réduit la fatigue après les repas et les lourdeurs digestives, hépatoprotecteur, facilite la digestion.',
      contraindication:
        'Prendre conseil auprès de son médecin lors d’une grossesse ou d’un allaitement ou en cas d’hypotension / Boire 1.5L d’eau par jour pour faciliter l’action du complete detox.',
    },
    [ProductHandle.DIET]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        '3µg de chrome +  150mg de Guarana +  150mg de fucus +  100mg de nopal +  100mg de Safran',
      arguments: [],
      ingredients: [
        "Fucus (à 0.05% d'iode), guarana (à 12% de caféine), nopal, reine des prés (à 0.2% salicyline), safran (à 0.34% de safranal), picolinate de chrome (à 12.3% de chrome).",
      ],
      categories: [ProductCategory.WEIGHT],
      headline: 'Pour accompagner sainement la perte de poids.',
      strongPoints: [
        {
          title: "Une gélule pour une synérgie d'actions",
          text: 'Gestion de la satiété\nAbsorption des graisses et des glucides\nStimule le métabolisme\nDiminue la rétention d’eau',
        },
        {
          title: 'Riche en principe actifs',
          text: 'caféine (18mg), acide salicylique (0,2mg), safranal (0,34mg) et iode (0,07mg).',
        },
        {
          title: 'Pas de dépendance',
        },
      ],
      forWho:
        'Pour les personnes désirants perdre du poids, et diminuer leurs excès alimentaires et grignotages.',
      benefits:
        'Accompagne la perte de poids, diminue les pics de glycémie, diminue la rétention d’eau, réduit l’appétit.',
      contraindication:
        'Déconseillé en cas d’insuffisance cardiaques ou d’hypertension artérielle / Diabétiques: demander avis d’un médecin (produit hypoglycémiant) / Déconseillé aux femmes enceintes et allaitantes',
      supplier: 'Digamed',
    },
    [ProductHandle.ENERGY]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "250mg de L-tyrosine +  100mg de ginseng Panax +  50mg de Guarana +  50mg de Rhodiola +  vitamine B's",
      arguments: [],
      ingredients: [
        '250 mg L-Tyrosine, 100 mg panax ginseng (à 20% de ginsénosides), 50 mg guarana (à 12% de caféine), 50 mg rhodiola rosea (à 3% de salidrosides) et vitamine B6.',
      ],
      categories: [ProductCategory.ENERGY],
      headline: 'Un booster d’énergie et un soutien aux fonctions cognitives.',
      strongPoints: [
        {
          title:
            'Synergie de nootropiques pour un boost d’énergie court et long terme',
          text: 'Augmente les performances cognitives: concentration, mémoire et capacités d’apprentissage\nLutte contre le vieillissement cérébral\nFavorise une meilleure vigilance et augmente les niveaux d’énergie',
        },
        {
          title: 'L-tyrosine: précurseur de la dopamine',
          text: 'Augmente les performances physiques et mentales, l’attention, la concentration et la motivation',
        },
        {
          title: 'Plantes adaptogènes riches en principe actif',
          text: 'Ginseng: 20 mg ginsénosides par gélule\nGuarana: 6 mg caféine\nRhodiola rosea: 1,5 mg salidrosides',
        },
      ],
      forWho:
        'Pour les personnes souffrant de fatigue physique, baisses d’énergie ou de concentration, ou durant les périodes de forte sollicitation mentale.',
      benefits:
        'Renforce l’énergie physique, optimise les fonctions cognitive, soutien la concentration, augmente la motivation.',
      contraindication:
        'Déconseillé aux femmes enceintes, durant l’allaitement ou aux enfants / Hypertension artérielle, traitement anticoagulant, médicaments cardiaques / Troubles du sommeil si pris trop tard dans la journée.',
    },
    [ProductHandle.PROBIO]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "9 souches de ferments lactiques et 19.5 milliards d'UFC - 4,5mg de vitamine C",
      arguments: [],
      ingredients: [
        "9 souches de ferments lactiques et 19.5 milliards d'UFC + 4,5mg de vitamine C",
      ],
      categories: [
        ProductCategory.IMUNITY,
        ProductCategory.DIGESTION,
        ProductCategory.ENERGY,
      ],
      headline:
        'Soutien la digestion de manière naturelle et aide à développer les défenses naturelles.',
      strongPoints: [
        {
          title:
            'Dosage très élevé : 19,5 milliards de bactéries et 9 souches bactériennes',
        },
        {
          title: 'Micro-organismes lyophilisés',
          text: 'Stables à température ambiante',
        },
        {
          title: 'Résiste aux sels biliaires et à l’acidité gastrique',
        },
      ],
      forWho:
        'Pour les personnes souffrant de problèmes de transit & ballonements, ou suite à une antibiothérapie pour rééquilibrer le microbiote.',
      benefits:
        'Assure un confort intestinal, favorise une digestion optimale, stimule le système immunitaire.',
      supplier: 'Nutrissentiel',
    },
    [ProductHandle.RELAX]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: "200mg d'ashwagandha +  100mg de L-théanine +  Vitamine B's",
      arguments: [],
      ingredients: [
        "200 mg d'ashwagandha, 100 mg de L-théanine et vitamines du groupe B.",
      ],
      categories: [ProductCategory.STRESS, ProductCategory.SLEEP],
      headline: 'En cas de moments de stress pour faciliter la relaxation.',
      strongPoints: [
        {
          title: 'Formule riche en principe actif',
          text: 'Ashwagandha titrée à 1.5% de withanolides\n3 mg de withanolides et 2 mg d’alcaloïdes par gélule',
        },
        {
          title: 'Association avec la L-théanine',
          text: '100 mg/gélule',
        },
        {
          title: 'Formule complète, associée aux vitamines du groupe B',
          text: 'Vitamine B3, B6, B1, B2, B12 et B9\nForme active',
        },
      ],
      forWho:
        'Pour les personnes stressées, anxieuses ou souffrant de troubles du sommeil.',
      benefits:
        'Diminue le stress, optimise la relaxation, entretien l’activité cérébrale, facilite l’endormissement.',
      contraindication:
        "Déconseillé aux femmes enceintes, durant l'allaitement ou aux enfants / Déconseillé en cas d'hyperthyroïdie.",
      supplier: 'Digamed',
    },
    [ProductHandle.SUN]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "223mg de lithothamne + 150mg d'ovomet® + 50mg L-lysine + 40mg prêle + 40mg L-arginine + 23mg β-carotène",
      arguments: [],
      ingredients: [
        "Lithothamne, Ovomet® (membrane de coquille d'oeuf), lécithine de soja, L-lysine, prêle (Bio), L-arginine, béta-carotène, vitamine C, vitamine E, zinc, vitamine B5, B3, B6 et B8",
      ],
      categories: [ProductCategory.BEAUTY, ProductCategory.SKIN],
      headline:
        "Contribue au maintien d'une peau normal et protège les cellules du stress oxydatif.",
      strongPoints: [
        {
          title: 'Elasticité, souplesse, purification, protection',
        },
        {
          title: 'Acides aminés essentiels pour une peau saine',
          text: 'Lysine et arginine',
        },
        {
          title: "Ovomet®: Membrane d'oeuf contenant naturellement",
          text: 'Collagène natif / acide hyaluronique / glucosamine / chondroïtine / élastine / acides aminés soufrés\nPlus de 500 protéines\nTesté dans de nombreuses études cliniques en nutricosmétique',
        },
      ],
      whyRecommendIt:
        'Préparer sa peau au soleil\nLutter contre le vieillissement cellulaire',
      forWho:
        'Pour les personnes souhaitant préparer leur peau au soleil, la protéger, et ainsi freiner le vieillissement cutané.',
      contraindication:
        'Déconseillé aux femmes enceintes, allaitantes et aux enfants.',
      supplier: 'Equi Nutri',
    },
    [ProductHandle.PRENATAL]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "Vitamine B's (folates 200% VNR) + 120mg vitamine C (150% VNR) + 7,5mg vitamine D3 (150% VNR) + 28mg fer (200% VNR) + 5 milliards Lactobacillus rhamnosus",
      arguments: [],
      ingredients: [
        'Vitamines du groupe B (folates 200% VNR), vitamine C, vitamine D, vitamine E, ß-carotène, fer (200% VNR), magnésium, manganèse, zinc, cuivre, iode, sélénium, chrome, molybdène, Lactobacillus rhamnosus (5 milliards) et poudre de rhizome de gingembre.',
      ],
      categories: [ProductCategory.PREGNANCY],
      headline:
        'Une synergie de vitamines et minéraux adaptés et essentiels à la maternité',
      strongPoints: [
        {
          title:
            'Une association de vitamines, minéraux et Lactobacillus rhamnosus',
        },
        {
          title: 'Dosage optimale pour suppléer aux besoins accrus',
        },
        {
          title:
            'Favorise le développement optimal du futur nouveau-né: présence de folates',
          text: "200% des VNR\nFormation du placenta, croissance de l'utérus",
        },
        { title: 'Digestion facilité grâce au rhizome de gingembre' },
        {
          title:
            "Accompagnement complet: du désir de grossesse jusqu'à l'allaitement",
        },
      ],
      whyRecommendIt:
        'Pour suppléer aux besoins accrus de la femme, avant, pendant et après la grossesse.',
      forWho:
        'Toute femme ayant un désir de grossesse, enceinte ou allaitante.',
      noProductPageUrl: true,
    },
    [ProductHandle.CURCUMA]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '300mg Curcuma (95% curcuminoides) + 2mg de bioperine',
      arguments: [],
      ingredients: [
        '300 mg Curcuma (95% curcuminoides) et piment noir (95% piperine).',
      ],
      categories: [
        ProductCategory.SPORT,
        ProductCategory.DIGESTION,
        ProductCategory.ARTICULATION,
      ],
      headline:
        'Une action anti-inflammatoire et antioxydante pour des articulations et des muscles souples.',
      strongPoints: [
        {
          title: 'Concentration élevée de principe actif',
          text: '95% de curcumine active\n285 mg par gélule',
        },
        {
          title: 'Contient de la pipérine',
          text: 'Augmente la biodisponibilité, démultiplie les effets du curcuma',
        },
      ],
      forWho:
        'Pour les sportifs ou les personnes souffrant d’arthrose ou de maladie inflammatoires.',
      benefits:
        'Soulage les douleurs articulaires et maintient la flexibilité des articulations.',
      supplier: 'Digamed',
    },
    [ProductHandle.IRON]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        '83mg Pidolate de fer (100% VNR) + 200mg Spiruline Bio + 117mg Moringa',
      arguments: [],
      ingredients: ['83,8 mg fer (100% VNR) et 200 mg spiruline (Bio).'],
      categories: [ProductCategory.ENERGY],
      headline:
        'Soutient la formation des globules rouges et participe à la production d’énergie.',
      strongPoints: [
        {
          title: 'Forme organique : Pidolate de fer',
          text: 'Haut dosage: 83,8 mg de pidolate de fer (100% de VNR)',
        },
        {
          title: 'Associé à de la spiruline 100% naturel',
        },
        {
          title: '100% compatible avec un régimes végétarien.',
        },
      ],
      deficiencies:
        'Fatigue, maux de tête, vertiges ou diminution de la force.',
      forWho:
        'Pour toute personne anémique.\nPour toute personne anémique\nPour les personnes consommants peu/pas de produits d’origine animale.',
      benefits: 'Prévient les risques liés à une anémie et réduit la fatigue.',
      contraindication: 'Supplémentation suivant un avis médical.',
      supplier: 'Equi Nutri',
    },
    [ProductHandle.BOURRACHE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Capsules huileuses',
      },
      dosage:
        "500mg huile de bourrache (19% acide d'acide linolénique) + 6,3mg vitamine E",
      arguments: [],
      ingredients: [
        "500 mg d'huile de bourrache (min. 19% d'acide linolénique) et 6,3 mg de Vitamine E.",
      ],
      categories: [ProductCategory.BEAUTY],
      headline: 'Contribue à la beauté et à l’élasticité de la peau.',
      strongPoints: [
        {
          title: 'Huile 100% pure et naturelle',
          text: '500 mg d’huile de bourrache par capsule / 19% d’acide gamma-linolénique (AGL)',
        },
        {
          title: 'Enrichie en vitamine E',
          text: 'Antioxydant\nRalentit le vieillissement cutané',
        },
        {
          title: 'Accompagne la période de la ménopause',
        },
      ],
      forWho:
        'Pour les personnes souffrant de sécheresse cutanée, de dermatoses (eczéma, herpès, psoriasis) ou encore en période de ménopause.',
      benefits:
        'Hydrate la peau en profondeur, atténue les rides et les vergétures, tempères les bouffées de chaleur.',
      contraindication:
        "Les femmes enceintes et allaitantes devront demander l'avis de leur médecin avant d'entreprendre une cure d'huile de bourrache.",
      supplier: 'Lambo',
    },
    [ProductHandle.MAGNESIUM]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage:
        "489mg bisglycinate de magnésium + 204mg citrate de magnésium + vitamine B's",
      arguments: [],
      ingredients: [
        'Bisglycinate de magnésium et citrate de magnésium (130mg de magnésium élément), vitamines du groupe B et L-leucine.',
      ],
      categories: [
        ProductCategory.STRESS,
        ProductCategory.SLEEP,
        ProductCategory.ENERGY,
        ProductCategory.SPORT,
      ],
      headline:
        'Pour les périodes de stress, de fatigue et le maintien des muscles et articulations.',
      strongPoints: [
        {
          title: 'Bisglycinate et citrate de magnésium',
          text: 'Sels de magnésium organiques\nHaute biodisponibilité\n130 mg de magnésium élément par gélule',
        },
        {
          title: 'Haute assimilation: synergie avec des cofacteurs essentiels',
          text: 'Vitamines B6, B9, B12\nVitamine B sous leurs formes actives',
        },
      ],
      deficiencies: 'Somnolence, faiblesse, spasmes musculaires.',
      forWho:
        'Pour les sportifs, les personnes stressées ou celles souffrants de troubles du sommeil.',
      benefits:
        'A une action sur la souplesse et la tonicité des muscles, un effet anti-stress et facilite l’endormissement.',
      supplier: 'Digamed',
    },
    [ProductHandle.OMEGA_3]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Capsules huileuses',
      },
      dosage:
        '1000mg huile de poisson (EPA 350mg/DHA 250mg) + 7,5mg vitamine E (62,5% VNR)',
      arguments: [],
      ingredients: [
        "1000 mg d'huile d'anchois (EPA 350 mg et DHA 250 mg), vitamine E et mélange d'antioxydants (Vitaheess E sf Plus®).",
      ],
      categories: [
        ProductCategory.BRAIN,
        ProductCategory.CARDIOVASCULAR,
        ProductCategory.VIEW,
      ],
      headline: 'Soutient le coeur et la fonction cognitive.',
      strongPoints: [
        {
          title: "Dosage optimal: 1000mg d'OMEGA_3",
          text: 'EPA (35%) et DHA (25%)',
        },
        {
          title: "Forme naturelle de triglycérides : Issus d'anchois",
        },
        {
          title: 'Hautement stable, garantie non oxydée',
          text: 'Complexe vitaheess E sf Plus® : Enrichissement en antioxydants (tocophérols)',
        },
      ],
      forWho:
        'Pour toutes personnes consommant du poisson gras moins de 3x par semaine.',
      benefits:
        'Pour un fonctionnement normal du cerveau ainsi qu’une puissante action anti-inflammatoire.',
      contraindication:
        "Les personnes prenant un traitement anti coagulant doivent avoir l'avis d'un professionnel de santé. / Cesser l'utilisation 15 jours avant une opération et pendant le dernier trimestre de la grossesse. / Ne convient pas au végétariens",
      supplier: 'Lambo',
    },
    [ProductHandle.OMEGA_3_VEGGIE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Capsules huileuses',
      },
      dosage: "834mg huile d'algues (125mg EPA/250mg DHA)",
      arguments: [],
      ingredients: [
        "834 mg d'huile d'algues (Schizochytrium sp.) dont 250mg de DHA et 125mg d'EPA, Vitamine E",
      ],
      categories: [
        ProductCategory.BRAIN,
        ProductCategory.CARDIOVASCULAR,
        ProductCategory.VIEW,
        ProductCategory.VEGAN,
      ],
      headline:
        'Un complément soutenant le coeur et la fonction cognitive, riche en EPA et DHA.',
      strongPoints: [
        {
          title: "Huile issue d'alges (Schizochytrium)",
          text: 'Pas de contaminations aux métaux lourds\nForte teneur en oméga 3 : 350 mg par gélule',
        },
        {
          title: 'Formule 100% végétale',
        },
      ],
      forWho:
        'Pour toutes personnes consommant du poisson gras moins de 3x par semaine.',
      benefits:
        'Pour un fonctionnement normal du cerveau ainsi qu’une puissante action anti-inflammatoire.',
      contraindication:
        'Les personnes prenant un traitement anti coagulant doivent avoir l’avis d’un professionnel de santé / Cesser l’utilisation 15 jours avant une opération et pendant le dernier trimestre de la grossesse.',
      supplier: 'Alfa',
    },
    [ProductHandle.OSTEOSIN]: {
      display: false,
      format: {
        quantity: 30,
        type: 'Comprimés',
      },
      dosage:
        'Pour 2 comprimés : 600mg gylcerophosphate de calcium + 300mg curcuma longa +  200mg glycérophosphate de magnésium + 200mg prêle + 100mg carbonate de calcium + 100mg phosphate tricalcique +100mg,stéarate de magnésium + vitamine D3 + vitamine K1',
      arguments: [],
      ingredients: [
        'Gylcerophosphate de calcium, curcuma, glycérophosphate de magnésium, prêle, cellulose microcritique, phosphate dicalcique, oxyde de magnésium, carbonate de calcium, phosphate tricalcique, stéarate de magnésium, vitamine D3, vitamine K1.',
      ],
      categories: [ProductCategory.BONES, ProductCategory.ARTICULATION],
      headline: 'Pour le maintien d’une ossature normale.',
      strongPoints: [
        {
          title: 'Double action',
          text: 'Rééquilibrage du remodelage osseux\nApport complet des constituants indispensables à la fabrication de l’os',
        },
        {
          title:
            'Forme de sel biodisponible de 3e génération: calcium et magnésium',
          text: 'Favorise une meilleure absorption\nPas de troubles gastro-intestinaux',
        },
      ],
      forWho: 'Perte de densité osseuse (ostéopénie, ostéoporose)\nMénopause',
      benefits:
        'Renforce la trame osseuse\nParticipe à la fabrication de nouveaux tissus osseux',
      contraindication:
        'Ne convient pas aux femmes enceintes et allaitantes\nNe convient pas aux personnes souffrant troubles hépatiques ou biliaires / Les personnes sous anticoagulants doivent demander conseil à leur médecin avant toute complémentation / Ne convient pas au vegan : enveloppe en gélatine bovine.',
      supplier: 'Normaphar',
    },
    [ProductHandle.PAMPLEMOUSSE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: "100mg d'extrait glycérine de pépins de pamplemousse ",
      arguments: [],
      ingredients: ["100 mg d'extrait glycérine de pépins de pamplemousse."],
      categories: [ProductCategory.IMUNITY],
      headline:
        'Contribue à réduire la fatigue et soutient les défenses naturelles.',
      strongPoints: [
        {
          title: 'Un puissant antibiotique 100% naturel',
        },
        {
          title: 'Stimule le système immunitaire',
          text: 'Action antibactérienne et antioxydante',
        },
      ],
      forWho: 'Fatigue chronique\nInfections à répétition\nTroubles digestifs',
      benefits: 'Renforce les défenses immunitaires\nRéduit la fatigue',
      contraindication:
        "Contre-indiqué lors de traitements médicamenteux (peut en entraver l'absorption) / Demander conseil a son médecin en cas de grossesse et d’allaitement.",
      supplier: 'Vera Sana',
      noProductPageUrl: true,
    },
    [ProductHandle.MELATONINE]: {
      display: true,
      standAlone: true,
      format: {
        quantity: 1,
        type: 'Spray',
      },
      dosage: '0,299mg de mélatonine pour une pulvérisation (0,15ml) ',
      arguments: [],
      ingredients: ['0.299mg de mélatonine par pulvérisation (0,15ml)'],
      categories: [ProductCategory.SLEEP],
      headline: 'Pour améliorer la qualité du sommeil et se réveiller reposé.',
      strongPoints: [
        {
          title:
            'Triple action: endormissement, diminution des réveils nocturnes et sommeil réparateur',
        },
        {
          title: 'Spray sublingual',
          text: 'Absorption plus rapide, plus complète et plus pratique',
        },
        {
          title: 'Dosage optimal : 0,299 mg de mélatonnine par pulvérisation',
        },
        {
          title: 'Meilleure alternative naturelle aux somnifères',
          text: 'Sans accoutumance ni dépendance',
        },
      ],
      forWho:
        'Pour les personnes souffrant de réveils noctures fréquents, qui appréhendent le moment du coucher et les travailleurs de nuit.',
      benefits:
        'Facilite l’endormissement, diminue la sensation de fatigue au réveil, lutte contre les effets du décalage horaire.',
      contraindication:
        'Déconseillé aux femmes enceintes et allaitantes / Usage prolongé déconseillé / A forte dose peut entraîner un risque de somnolence.',
      supplier: 'Digamed',
    },
    [ProductHandle.TYROSINE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '400mg de L-tyrosine',
      arguments: [],
      ingredients: ['400 mg de L-tyrosine.'],
      categories: [ProductCategory.ENERGY],
      headline: 'Lutte contre la fatigue chronique et le manque d’entrain.',
      strongPoints: [
        {
          title: 'Précurseur de la dopamine',
          text: 'Augmente les performances physiques, mentales et la motivation',
        },
        {
          title: 'Indispensable à la production des hormones thyroïdiennes',
        },
        {
          title: "Assimilation optimale par l'organisme: L-tyrosine",
        },
      ],
      forWho:
        'Pour les personne en manque de motivation, qui ont des difficultés à se concentrer ou qui on est troubles de la mémoire.\nEgalement adapté pour les sportifs',
      benefits:
        "Réduit la fatigue et aide à retrouver de la vivacité d'esprit.",
      contraindication:
        'Ne pas dépasser la dose quotidienne recommandée / Déconseillé aux femmes enceintes, durant l’allaitement ou aux enfants / Hypertension artérielle, médicaments cardiaques.',
      supplier: 'Equi Nutri',
      noProductPageUrl: true,
    },
    [ProductHandle.V_B12]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '1,01mg méthylcobalamine (40 000% VNR)',
      arguments: [],
      ingredients: ['1.01mg de méthylcobalamine.'],
      categories: [ProductCategory.ENERGY, ProductCategory.VEGAN],
      headline:
        'Soutient la fonction psychologique, le système nerveux et le système immunitaire.',
      strongPoints: [
        {
          title: 'Forme bioactive et naturelle : méthylcobalamine',
          text: 'Excellente tolérance gastro-intestinale\nDirectement absorbée',
        },
        {
          title: 'Dosage élevé : 1000 μg par géllule',
        },
      ],
      deficiencies:
        'Perte d’énergie, fatigue chronique, susceptibilité aux infections, problèmes nerveux.',
      forWho:
        'Pour les végétariens, végétaliens, végan ou personnes souffrant de malabsorption (maladie de Crohn, opération de l’estomac ou intestin).',
      benefits:
        'Réduit la fatigue et optimise le fonctionnement du système nerveux.',
      contraindication:
        "Les femmes enceintes ou allaitantes devront demander l'avis de leur médecin avant d'entreprendre une cure.",
      supplier: 'Digamed',
    },
    [ProductHandle.V_C]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '250mg vitamine C (312,5% VNR)',
      arguments: [],
      ingredients: ['250 mg de vitamine C liposomée (312,5% VNR).'],
      categories: [ProductCategory.IMUNITY, ProductCategory.ENERGY],
      headline:
        'Contribue au fonctionnement normal du système immunitaire et à réduire la fatigue.',
      strongPoints: [
        {
          title: 'Vitamine C liposomale',
          text: '4 fois mieux absorbée que les formes classiques\nDiffusion progressive de la vitamine C',
        },
        {
          title: 'Haute teneur en vitamine C : 250mg',
          text: '312,5% des besoins couverts par une gélule',
        },
      ],
      deficiencies: 'Fatigue, faiblesse et irritabilité.',
      forWho:
        'Pour les personnes souffrant d’une grosse fatigue matinale, en cas d’activité physique intense ou d’une consommation excessive d’alcool ou tabagisme.',
      benefits: 'Réduit la fatigue et renforce le système immunitaire',
      contraindication:
        'Utiliser avec précaution chez les patients souffrant de troubles du métabolisme du fer.',
      supplier: 'Vita Nutrics',
    },
    [ProductHandle.V_D]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Capsules huileuses',
      },
      dosage: "75μg vitamine D3 (1500% VNR) + 150mg huile d'olive vierge",
      arguments: [],
      ingredients: ["75µg de vitamine D3 (3 000 UI) et huile d'olive vierge."],
      categories: [ProductCategory.IMUNITY, ProductCategory.BONES],
      headline:
        'Soutient le système immunitaire et maintient la solidité des os.',
      strongPoints: [
        {
          title: 'Vitamine D3 (cholécalciférol)',
          text: 'Une perle 3000 UI (1500% des VNR)',
        },
        {
          title: 'Une origine naturelle: issue de la lanoline',
        },
        {
          title: "Associé à de l'huile d'olive (source de vitamine K):",
          text: 'Assimilation optimisée',
        },
        {
          title: 'Autorisé pour la femme enceinte.',
        },
      ],
      deficiencies:
        'Douleurs ou baisses de tonus musculaires, asthénie, fragilité osseuses.',
      forWho:
        'Pour tous : 80% de la population est en situation de déficit, mais aussi pour les personnes âgées afin de prévenir le risque élevé de fractures.',
      benefits:
        'Optimise la minéralisation osseuse et éviter l’ostéoporose, protège le système immunitaire.',
      contraindication:
        'Déconseillé aux patients avec insuffisance rénale, hypercalcémie et hypercalciurie.',
      supplier: 'Lambo',
    },
    [ProductHandle.ZINC]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Comprimés',
      },
      dosage: '20mg zinc (200% VNR)',
      arguments: [],
      ingredients: [
        'Complexe de zinc (citrate de zinc, bisglycinate de zinc, zinc-L-monométhionine - OptiZinc®) 20mg.',
      ],
      categories: [
        ProductCategory.BEAUTY,
        ProductCategory.IMUNITY,
        ProductCategory.ENERGY,
        ProductCategory.HAIR_NAILS,
        ProductCategory.VEGAN,
      ],
      headline:
        'Puissant antioxydant, soutient le système immunitaire et contribue au maintien de cheveux, d’ongles et d’une peau normale.',
      strongPoints: [
        {
          title:
            'Complexe de Zinc : Citrate de zinc, Bisglycinate de zinc et Zinc-L-monométhionine',
          text: '20 mg de Zinc élément par gélule Zinc-L-monométhionine : OptiZinc® (Liée à de la méthionine pour accroître sa biodisponibilité)',
        },
        {
          title: 'Forme organique : biodisponibilité optimale',
        },
      ],
      deficiencies:
        'Troubles de l’immunité, fatigue, chute de cheveux, fragilité des ongles et problèmes cutanées.',
      forWho:
        'Les personnes ayant un régime alimentaire particulier (végétarien, végétalien, vegan) ou souffrants de troubles cutanés, des phanères ou de chutes de cheveux.',
      benefits:
        'Améliore les défenses naturelles, traite l’acné et renforce le cuir chevelu.',
      contraindication:
        'Déconseillé aux personnes sous traitement anticonvulsants ou hormonothérapie. Déconseillé de suivre une complémentation simultanée en fer à celle du zinc.',
      supplier: 'Alfa',
    },
    [ProductHandle.VEGAN_WHEY]: {
      display: true,
      standAlone: true,
      format: {
        quantity: 500,
        type: 'Grammes',
      },
      dosage: '25g (2 dosettes) par jour',
      arguments: [],
      ingredients: [
        'Protéine de petit pois (71%), protéine de riz (13,38%), extrait sec de courge (6%), poudre de chia (6%), parfum de chocolat (3,5%). Edulcorant: sucralose et glycosides de stéviol.',
        'Sachet de 500gr. 20 doses de 25gr/jour.',
      ],
      categories: [
        ProductCategory.VEGAN,
        ProductCategory.SPORT,
        ProductCategory.WEIGHT,
      ],
      headline:
        'Un mix de protéines végétales contenant les 9 acides aminés essentiels.',
      strongPoints: [
        {
          title: 'Un mix de protéines végétales',
          text: 'Protéine de riz et de petit pois, poudre de chia et de courge',
        },
        {
          title: 'Une alternative végétale complète',
          text: '59% de protéine\nAminogramme complet\n10% de BCAA',
        },
        {
          title: 'Goût intense de chocolat',
        },
      ],
      deficiencies:
        'Troubles de l’immunité, fatigue, chute de cheveux, fragilité des ongles et problèmes cutanées.',
      forWho:
        'Les sportifs.\nPour les vegans, les végétariens, ou toute personne souhaitant diminuer sa consommation de protéines animales.',
      benefits:
        'Réduit le catabolisme musculaire et augmente la masse musculaire.',
      contraindication:
        'Déconseillé aux femmes enceintes, allaitantes, aux enfants ou aux personnes atteintes de pathologies rénales.',
      supplier: 'Digamed',
    },
    [ProductHandle.GLUTAMINE]: {
      display: true,
      standAlone: true,
      format: {
        quantity: 75,
        type: 'Grammes',
      },
      dosage: '1 sachet (5gr) par jour',
      arguments: [],
      ingredients: ['L-Glutamine 100%.', '15 sachets de 5 grammes'],
      categories: [
        ProductCategory.VEGAN,
        ProductCategory.SPORT,
        ProductCategory.DIGESTION,
      ],
      headline:
        'Un acide aminé essentiel pour renforcer l’imperméabilité de l’intestin.',
      strongPoints: [
        {
          title: "Renforce l'imperméabilité de l'intestin",
          text: "Aide à soutenir les défenses immunitaires\nFacilite le passage des nutriments\nFacilite l'élimination des toxines",
        },
        {
          title: 'Idéal pour le sportif',
          text: 'Aicide aminé le plus présent dans le muscule\nAide à la récupération musculaire',
        },
      ],
      deficiencies:
        'Troubles de l’immunité, fatigue, chute de cheveux, fragilité des ongles et problèmes cutanées.',
      forWho:
        'Les sportifs.\nPour tous: réparer et protéger la barrière intestinale.',
      benefits:
        'Soutient le système immunitaire.\nRéduire le catabolisme musculaire.',
      contraindication:
        "Il est conseillé de demander l'avis d'un médecin durant la grossesse et l'allaitement.\nNe pas dépasser la dose recommandée.",
    },
    [ProductHandle.COMPLETE_THYROIDE]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '2 gélules par jour',
      arguments: [],
      ingredients: [
        "L-tyrosine 400mg, Maca 200mg, Extrait de Laminaria digitata (à 0,1 % d'iode) 150mg, zinc 14mg, sélénométhionine 40μg.",
      ],
      categories: [
        ProductCategory.VEGAN,
        ProductCategory.WEIGHT,
        ProductCategory.ENERGY,
      ],
      headline: 'Stimule naturellement les sécrétions thyroïdiennes.',
      strongPoints: [
        {
          title:
            'Contient des stimulants essentiels de la thyroïde (L-Tyrosine, iode, Zinc et sélénium)',
        },
        {
          title: 'Formule contenant du Maca',
          text: 'Rôle considérable dans le bon fonctionnement des glandes endocrines et diminue la fatigue',
        },
        {
          title:
            "Vitamines et minéraux sous des formes biodisponibles et assimilables par l'organisme",
        },
      ],
      forWho: "Pour les personnes souffrant d'hypothyroïdie.",
      benefits:
        'Stimule les sécrétions thyroïdiennes (réduit la fatigue et stimule le métabolisme).',
      contraindication:
        "Il est conseillé de demander l'avis d'un médecin durant la grossesse et l'allaitement.\nNe pas prendre en même temps que la L Dopamine.\nPeut altérer les effets des médicaments thyroïdiens, risque d'hyperthyroïdie.",
      supplier: 'Planticinal',
    },
    [ProductHandle.PHYCO]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '1 gélule par jour ',
      arguments: [],
      ingredients: [
        'Bisglycinate de magnésium, Vitamine B1, vitamine B2, vitamine B3, vitamine B5, vitamine B6 (active-pyridoxal-5-phosphate), vitamin B8, vitamine B9 (active-Quatrefolic), vitamine B12 (methylcobalamine), zinc et phycocyanine.',
      ],
      categories: [
        ProductCategory.STRESS,
        ProductCategory.IMUNITY,
        ProductCategory.ENERGY,
      ],
      headline: 'Les essentiels des vitamines et minéraux hydrosolubles',
      strongPoints: [
        {
          title: 'Magnésium',
          text: 'Haut dosage: 120 mg élément (32% VNR) ',
          text1: 'Excellente biodisponibilité: Bisglycinate de magnésium',
        },
        {
          title: 'Vitamines B (B1, B2, B3, B5, B6, B8, B9 et B12) ',
          text: '50% des apports journaliers ',
          text1: '100% AJR pour la B12 ',
          text2: 'Les formes les plus bioactives ',
        },
        {
          title: 'Zinc',
          text: 'Haut dosage: 10mg ',
          text1:
            'Excellente biodisponibilité: OptiZinc® (Monométhionine de zinc)',
          text2: '100% AJR ',
        },
        {
          title: 'Spiruline',
          text: '166 mg de spiruline premium',
          text1: 'Hautement dosé en phycocyanine: 50mg',
        },
      ],
      forWho: 'Tout le monde',
      benefits:
        'Les éléments micronutritionnels hydrosolubles bénéfiques pour tous. ',
      contraindication:
        'Femmes enceintes ou allaitantes, demandez conseil à votre médecin.',
      noProductPageUrl: true,
    },
    [ProductHandle.ENZYMES_DIGESTIVES]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '1 à 2 gélules par jour',
      arguments: [],
      ingredients: [
        'Mélange d’enzyme (alpha-amylase, lactase, lipase, protéase, cellulase), Bromélaïne, Papaïne et extrait de gingembre.',
      ],
      categories: [ProductCategory.DIGESTION],
      headline:
        'Soutient la digestion des glucides, des lipides et des protéines du bol alimentaire.',
      strongPoints: [
        {
          title: 'Mélange de 5 enzymes digestives végétales: «VEGAZYM»',
        },
        {
          title: 'Sources d’origine microbienne',
          text: 'Plus résistantes à l’acide gastrique\nUn spectre d’action plus large dans le tube digestif',
        },
        {
          title: 'Bromélaïne + Papaïne ',
          text: 'Digestion des protéines',
        },
        {
          title: 'Extrait de gingembre ',
          text: 'Le gingembre aide à soutenir la digestion',
        },
      ],
      forWho:
        "Troubles digestifs (ballonnements, gaz ou diarrhées après les repas)\nIntolérances et allergies alimentaires (lactose, gluten, protéines de lait, ...)\nPersonnes âgées et/ou en cas de stress chronique (manque d'enzymes)\nSyndrome de l'intestin irritable et/ou perméable",
      benefits: 'Diminue les inconforts digestifs\nAméliore la digestion',
      contraindication:
        'Femmes enceintes ou allaitantes, demandez conseil à votre médecin.',
      supplier: 'Lepi Vits',
    },
    [ProductHandle.LYCO]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '1 gélules par jour',
      arguments: [],
      ingredients: [
        'Vitamine D3 50µg, Vitamine K2 40µg, Astaxanthine, Bêta-carotène, Lycopène et Oméga 3. ',
      ],
      categories: [],
      headline:
        'Les éléments liposolubles essentiels pour tous',
      strongPoints: [
        {
          title: 'Oméga 3',
          text: 'Haut dosage: 500mg'
        },
        {
          title: 'Vitamine D3',
          text: 'Dosage idéal pour une prise quotidienne: 2000 UI',
          text1: 'Forme active: cholécalciferol'
        },
        {
          title: 'Vitamine K2',
          text: 'Haut dosage: 40µg',
        },
        {
          title:'Bêta-carotène ',
          text: 'Issu de sources naturelles ',
          text1: 'Haut dosage: 4mg '
        },
        {
          title:'Astaxanthine ',
          text: 'Issu de sources naturelles ',
          text1: 'Haut dosage: 2mg'
        },
        {
          title:'Lycopène ',
          text: 'Issu de sources naturelles',
          text1: 'Haut dosage: 6mg '
        },
      ],
      forWho: 'Pour tout le monde. Comble 80% des carences les plus fréquentes dans la population',
      benefits: 'Renforce l’énergie et le tonus. Renforce les défenses naturelles. Prévient les carences alimentaires',
      contraindication:
      'Femmes enceintes ou allaitantes, demandez conseil à votre médecin. Les personnes prenant des médicaments ne doivent utiliser le produit que sous contrôle médical.',
      supplier: '',
    },
    [ProductHandle.METABO]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '2 gélules par jour',
      arguments: ['Régule la glycémie et diminue les envies de sucré', 'Améliore la sphère cardiovasculaire', 'Stimule le métabolisme', 'Régule les taux de cholestérol'],
      ingredients: [
        "Extrait sec standardisé d'Épine-vinette 200mg, Extrait de mangue 150mg, Extrait d’olive 50mg et Picolinate de chrome 0,04mg.",
      ],
      categories: [ProductCategory.WEIGHT],
      headline:
        'Un soutien métabolique complet',
      strongPoints: [
        {
          title: 'Berbérine',
          text: 'Haut dosage: 200mg',
          text1: 'Diminue le stockage des graisses',
          text2: 'Régule la glycémie'
        },
        {
          title: 'Extrait de Mangue (Zynamite®)',
          text: 'Haut dosage: 150mg',
          text1: 'Stimule le métabolisme',
          text2: 'Standardisé à 60 % de mangiférine'
        },
        {
          title: 'Chrome',
          text: 'Haut dosage: 40µg de chrome élément (100% VNR)',
          text1: 'Régule la glycémie',
          text2: 'Bonne assimilation : Picolinate de chrome'
        },
        {
          title:'Extrait d’olivier (Mediteanox®)',
          text: 'Régule le métabolisme des glucides et des lipides',
          text1: 'Standardisé à 20% d’hydroxytyrosol',
          text2: 'Soutient la santé cardiovasculaire '
        },
      ],
      forWho: 'Difficulté à perdre du poids. Prévention des pathologies métaboliques',
      benefits: '',
      contraindication:
      'Femmes enceintes ou allaitantes, demandez conseil à votre médecin. Diabétiques: demander avis d’un médecin (produit hypoglycémiant)',
      supplier: '',
    },
    [ProductHandle.IMMUNO]: {
      display: true,
      format: {
        quantity: 30,
        type: 'Gélules',
      },
      dosage: '2 gélules par jour',
      arguments: [],
      ingredients: [
        'Bêta-glucane 200mg, Sureau 200mg, Citrate de zinc 5mgE.',
      ],
      categories: [ProductCategory.IMUNITY],
      headline:
        'Une protection immunitaire complète ',
      strongPoints: [
        {
          title: 'Bêta-glucane (Wellmune®)',
          text: 'Haut dosage: 200mg ',
          text1: 'Bêta-(1,3/1,6)-glucane '
        },
        {
          title: 'Sureau (ElderCraft®)',
          text: 'Haut dosage: 200mg ',
          text1: 'Extrait standardisé en anthocyanes'
        },
        {
          title: 'Zinc',
          text: 'Haut dosage: 10mg',
          text1: 'Biodisponibilité optimale : Citrate de zinc '
        }
      ],
      forWho: 'Toute personne souhaitant renforcer son système immunitaire ',
      benefits: 'Renforce les défenses immunitaires ',
      contraindication:
      'Femmes enceintes ou allaitantes, demandez conseil à votre médecin. Les personnes prenant des médicaments ne doivent utiliser le produit que sous contrôle médical.',
      supplier: '',
    },
  },
}
