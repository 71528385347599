import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { Button, Field } from '../../../ui'
import { Form, ButtonGroup, Span } from './style'
import { Container, Title } from '../style'
import {
  getFetchPatientsStatus,
  fetchPatientsLoading,
  fetchPatientsSuccess,
  fetchPatientsFailed,
  getPatients,
} from '../../../../store/patient'
import { PRESCRIBER_PATIENTS } from '../../../../services/queries'
import { ActionType } from '../../../../utils/constants'
import { getUserToken } from '../../../../store/auth-user'
import {
  getPrescriptionNewDraft,
  setNewPrescriptionDraft,
  resetNewPrescriptionDraft,
} from '../../../../store/prescription'
import { useAuthPrescriber } from '../../../../utils/hooks'

const PrescriptionFormStep1 = ({ onCreatePatient }) => {
  const dispatch = useDispatch()
  const { authUser } = useAuthPrescriber()
  const authToken = useSelector(getUserToken)
  const patients = useSelector(getPatients)
  const prescriptionDraft = useSelector(getPrescriptionNewDraft)
  const fetchPatientsStatus = useSelector(getFetchPatientsStatus)
  const [
    fetchPatients,
    { loading: patientsLoading, error: patientsError, data: patientsData },
  ] = useLazyQuery(PRESCRIBER_PATIENTS, {
    variables: { prescriberId: authUser.id },
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    fetchPatientsStatus !== ActionType.SUCCESS && fetchPatients()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    patientsLoading && dispatch(fetchPatientsLoading())
    /**
     * Do not sync the store data all the time this function is trigger since
     * it is trigger too many time by apollo client so it update the store
     * and overwrite the data retrieved by mutations queries
     */
    !patients.length &&
      patientsData &&
      dispatch(fetchPatientsSuccess(patientsData))
    patientsError && dispatch(fetchPatientsFailed(patientsData))
  }, [patientsLoading, patientsData, dispatch, patientsError, patients.length])

  const options = patients.map(p => ({
    value: p.id,
    label: `${p.firstname} ${p.name} (${p.email})`,
  }))

  const selectValue = {}
  const { firstname, name, email, id } =
    patients.find(p => p.id === prescriptionDraft.patientId) || {}
  if (id) {
    selectValue.label = `${firstname} ${name} (${email})`
    selectValue.value = id
  }

  return (
    <Container>
      <Title>Selectioner un patient</Title>
      <Form>
        <Field
          label='Ajouter le patient concerné'
          type='select'
          options={options}
          value={selectValue}
          isLoading={patientsLoading}
          onChange={data =>
            dispatch(
              data
                ? setNewPrescriptionDraft({ patientId: data.value })
                : resetNewPrescriptionDraft()
            )
          }
          isSearchable
          isClearable
        />
        {!id && (
          <ButtonGroup>
            <Span>ou</Span>
            <Button
              type='submit'
              appearance='primary'
              onClick={e => {
                e.preventDefault()
                onCreatePatient(e)
              }}
            >
              Créer un nouveau patient
            </Button>
          </ButtonGroup>
        )}
      </Form>
    </Container>
  )
}

export default PrescriptionFormStep1
