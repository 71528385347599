import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  getUserUid,
  setUid,
  setToken,
  setIsEmailVerified,
  fetchAuthUserFailed,
  getFetchAuthPrescriberStatus,
} from './store/auth-user'
import { Spinner } from './components/ui'
import { useAuthPrescriber } from './utils/hooks'
import { useAuthUser } from './services/firebase'
import NakedLayout from './components/layout/naked'
import Router from './router'
import { ActionType } from './utils/constants'
import { deviceType } from './utils/helpers'

const App = () => {
  const dispatch = useDispatch()
  const [isAutoLoginLoading, setIsAutoLoginLoading] = useState(true)
  const { loading: isFetchAuthPrescriberLoading } = useAuthPrescriber()
  /**
   * Auth user will be automatically set by firebase listener
   */
  const auth = useAuthUser()
  const userUid = useSelector(getUserUid)
  const fetchAuthPrescriberStatus = useSelector(getFetchAuthPrescriberStatus)

  useEffect(() => {
    const device = deviceType()
    if (device === 'mobile' && window.location.pathname.split('/')[1] !== 'r') {
      toast.warning(
        'Cette application ne fonctionne pas encore sur mobile, veuillez vous connecter sur votre ordinateur ou sur votre tablette, merci.'
      )
    }
  }, [])

  useEffect(() => {
    async function doAction(auth) {
      const token = await auth.getIdToken()
      dispatch(setToken(token))
      dispatch(setUid(auth.uid))
      dispatch(setIsEmailVerified(auth.emailVerified))
    }
    // If authUse is set to null, it mean that the auto auth failed
    if (auth === null) {
      setIsAutoLoginLoading(false)
      // If it's an object, it was successful
    } else if (auth && typeof auth === 'object') {
      if (userUid) return

      if (!auth.emailVerified) {
        dispatch(fetchAuthUserFailed('email-not-verified'))
      } else if (auth.emailVerified) {
        doAction(auth)
      }
    }
  }, [auth, dispatch, userUid])

  useEffect(() => {
    if (
      fetchAuthPrescriberStatus === ActionType.LOADING &&
      isAutoLoginLoading
    ) {
      setIsAutoLoginLoading(false)
    } else if (
      fetchAuthPrescriberStatus === ActionType.FAILED &&
      isAutoLoginLoading
    ) {
      setIsAutoLoginLoading(false)
    }
  }, [fetchAuthPrescriberStatus, isAutoLoginLoading])

  if (isAutoLoginLoading || isFetchAuthPrescriberLoading)
    return (
      <NakedLayout>
        <Spinner />
      </NakedLayout>
    )

  return <Router />
}

export default App
