import { useState } from 'react'
import PropTypes from 'prop-types'

import { Container, Item, Cockpit } from './style'
import ProtocolCard from '../card'
import { useProtocoles, useAuthPrescriber } from '../../../utils/hooks'
import { Button } from '../../ui'

const Tab = {
  TEMPLATE_CUSTOM: 'template-custom',
  TEMPLATE_COMPLIMENT: 'template-compliment',
  TEMPLATE_COMMUNITY: 'template-community',
}

const ProtocolsContainer = ({ onChoose }) => {
  const [activeTab, setActiveTab] = useState(Tab.TEMPLATE_COMPLIMENT)
  const { authUser } = useAuthPrescriber()
  const { data } = useProtocoles()

  const customProtocols = []
  const complimentProtocols = []
  data?.forEach(protocol => {
    if (protocol.prescriberName === 'Compliment') {
      complimentProtocols.push(protocol)
      return
    }
    if (protocol.prescriberId === authUser.id) {
      customProtocols.push(protocol)
      return
    }
  })
  const displayedProtocols =
    activeTab === Tab.TEMPLATE_COMPLIMENT
      ? complimentProtocols
      : customProtocols

  return (
    <Container>
      <Cockpit>
        <Button
          appearance='minimal'
          onClick={() => setActiveTab(Tab.TEMPLATE_COMPLIMENT)}
          isActive={activeTab === Tab.TEMPLATE_COMPLIMENT}
        >
          Compliment
        </Button>
        <Button
          appearance='minimal'
          onClick={() => setActiveTab(Tab.TEMPLATE_CUSTOM)}
          isActive={activeTab === Tab.TEMPLATE_CUSTOM}
        >
          Mes protocoles
        </Button>
      </Cockpit>
      {displayedProtocols.map(protocol => (
        <Item key={protocol.id}>
          <ProtocolCard protocol={protocol} />
        </Item>
      ))}
    </Container>
  )
}
ProtocolsContainer.propTypes = {
  protocols: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProtocolsContainer
