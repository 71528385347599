import { Product } from '../../utils/classes'

export const getProducts = ({ product }) =>
  product.list.map(p => new Product(p))

export const getFetchProductsStatus = ({ product }) =>
  product.actions.fetchAll.status

export const getProductByHandle = ({ product }, handle) =>
  new Product(product.list.find(p => p.shopify_product_handle === handle))
