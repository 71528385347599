import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Header = styled.header`
  display: flex;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid ${Color.MONTE_CARLO};
  position: sticky;
  top: 0;
  padding: 0 20px;
  background-color: white;
  z-index: 10;
`

export const Nav = styled.nav`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const Ul = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;

  > a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`
