import React, { useState, useEffect } from 'react'
import {
  Container,
  Choice,
  Selector,
  ButtonsWrapper,
  Pricing,
  PricingLine,
  PaymentMethods,
  PaymentImage,
  PaymentImageWrapper,
  ButtonWrapper,
  SubscriptionContent,
  LoadingIcon,
} from './style'
import { Color } from '../../../utils/constants'
import { Button } from '../../ui'
import { BsCheckSquareFill } from 'react-icons/bs'
import { AiOutlineLoading } from 'react-icons/ai'
import cartRenew from '../../../assets/images/cart-renew.png'

const PaymentSettings = ({
  cart,
  cartAttributesUpdate,
  updateOneTimePurchaseToSubscripton,
  updateSubscriptonToOneTimePurchase,
  isSubscription,
  prescription,
  checkoutUrl,
  updateVariantDurationToCart,
  selectDefaultDuration,
  products,
  cartAttributesUpdateLoading,
  updateCartLoading,
  displayMAndDown,
  height,
  onClose,
  goBackButton,
  purchaseOption
}) => {
  console.log('purchaseOption', purchaseOption)
  const discountCode = prescription?.discountCode
  const discountPercentage = prescription?.discountPercentage
  const [format, setFormat] = useState('1')
  const [typeOfPurchase, setTypeOfPurchase] = useState('1')
  const [duration, setDuration] = useState('1')

  useEffect(() => {
    updateCartLoading === false &&
      cartAttributesUpdateLoading === false &&
      cartAttributesUpdate === false &&
      setFormat('')
    updateCartLoading === false &&
      cartAttributesUpdateLoading === false &&
      cartAttributesUpdate === false &&
      setTypeOfPurchase('')
    updateCartLoading === false &&
      cartAttributesUpdateLoading === false &&
      cartAttributesUpdate === false &&
      setDuration('')
  }, [updateCartLoading, cartAttributesUpdateLoading, cartAttributesUpdate])

  return (
    <Container displayMAndDown={displayMAndDown} height={height}>
      <Choice>
        <h3>Recevoir ma cure</h3>
        <ButtonsWrapper>
          <Selector
            onClick={() => {
              setFormat('1')
              cartAttributesUpdate({
                variables: {
                  cartId: cart?.id,
                  attributes: [
                    ...cart?.attributes?.map(i => ({
                      key: i?.key,
                      value: i?.value,
                    })),
                    {
                      key: 'glassPackaging',
                      value: 'false',
                    },
                  ],
                },
              })
            }}
            active={
              cart?.attributes?.find(i => i.key === 'glassPackaging')?.value !==
              'true'
            }
          >
            En Sachets{' '}
            {format === '1' && cartAttributesUpdateLoading && (
              <LoadingIcon>
                <AiOutlineLoading />
              </LoadingIcon>
            )}
          </Selector>
          <Selector
            onClick={() => {
              setFormat('2')
              cartAttributesUpdate({
                variables: {
                  cartId: cart?.id,
                  attributes: [
                    ...cart?.attributes?.map(i => ({
                      key: i?.key,
                      value: i?.value,
                    })),
                    {
                      key: 'glassPackaging',
                      value: 'true',
                    },
                  ],
                },
              })
            }}
            active={
              cart?.attributes?.find(i => i.key === 'glassPackaging')?.value ===
              'true'
            }
          >
            En pots{' '}
            {format === '2' && cartAttributesUpdateLoading && (
              <LoadingIcon>
                <AiOutlineLoading />
              </LoadingIcon>
            )}
          </Selector>
        </ButtonsWrapper>
      </Choice>

      <Choice>
        <h3>Type d'achat</h3>


        <ButtonsWrapper>
        {purchaseOption === 'oneTimePurchase' ? (
           <Selector
           fullWidth
           onClick={() => {
             setTypeOfPurchase('1')
             updateSubscriptonToOneTimePurchase()
           }}
           active={!isSubscription}
         >
           Achat unique{' '}
           {typeOfPurchase === '1' && updateCartLoading && (
             <LoadingIcon>
               <AiOutlineLoading />
             </LoadingIcon>
           )}
         </Selector>
        ) : (
            ''
          )}
           {purchaseOption === 'subscription' ? (
           <Selector
           fullWidth
           onClick={() => {
             setTypeOfPurchase('2')
             updateOneTimePurchaseToSubscripton()
           }}
           active={isSubscription}
         >
           Abonnement{' '}
           {typeOfPurchase === '2' && updateCartLoading && (
             <LoadingIcon>
               <AiOutlineLoading />
             </LoadingIcon>
           )}
         </Selector>
        ) : (
            ''
          )}

{(purchaseOption === null) | purchaseOption === 'subscriptionOrOneTimePurchase' ? (
         <>
          <Selector
            onClick={() => {
              setTypeOfPurchase('1')
              updateSubscriptonToOneTimePurchase()
            }}
            active={!isSubscription}
          >
            Achat unique{' '}
            {typeOfPurchase === '1' && updateCartLoading && (
              <LoadingIcon>
                <AiOutlineLoading />
              </LoadingIcon>
            )}
          </Selector>
          <Selector
            onClick={() => {
              setTypeOfPurchase('2')
              updateOneTimePurchaseToSubscripton()
            }}
            active={isSubscription}
          >
            Abonnement{' '}
            {typeOfPurchase === '2' && updateCartLoading && (
              <LoadingIcon>
                <AiOutlineLoading />
              </LoadingIcon>
            )}
          </Selector>
         </>
        ) : (
            ''
          )}
         
        </ButtonsWrapper>



      </Choice>

      <Choice>
        <h3>Durée de la cure</h3>
        <ButtonsWrapper column>
          <Selector
            onClick={() => {
              setDuration('1')
              updateVariantDurationToCart(products, '1-month', isSubscription)
            }}
            column
            active={selectDefaultDuration(cart) === '1-month'}
          >
            1 mois{prescription.duration === 1 && ('*')}
            {duration === '1' && updateCartLoading && (
              <LoadingIcon>
                <AiOutlineLoading />
              </LoadingIcon>
            )}
            <span>
              {' '}
              {prescription.duration === 1 && (
                <p>*Durée recommandée par le prescripteur</p>
              )}
            </span>
          </Selector>
          {!isSubscription && (
            <Selector
              onClick={() => {
                setDuration('2')
                updateVariantDurationToCart(products, '2-month', isSubscription)
              }}
              column
              active={selectDefaultDuration(cart) === '2-month'}
            >
              2 mois{prescription.duration === 2 && ('*')}
              {duration === '2' && updateCartLoading && (
                <LoadingIcon>
                  <AiOutlineLoading />
                </LoadingIcon>
              )}
              <span>
                {prescription.duration === 2 && (
                  <p>*Durée recommandée par le prescripteur</p>
                )}
              </span>
            </Selector>
          )}
          {!isSubscription && (
            <Selector
              onClick={() => {
                setDuration('3')
                updateVariantDurationToCart(products, '3-month', isSubscription)
              }}
              column
              active={selectDefaultDuration(cart) === '3-month'}
            >
              3 mois{prescription.duration === 3 && ('*')}
              {duration === '3' && updateCartLoading && (
                <LoadingIcon>
                  <AiOutlineLoading />
                </LoadingIcon>
              )}
              <span>
                {' '}
                {prescription.duration === 3 && (
                  <p>*Durée recommandée par le prescripteur</p>
                )}
              </span>
            </Selector>
          )}
        </ButtonsWrapper>
      </Choice>

      {isSubscription && (
        <SubscriptionContent>
          <div>
            <span>
              <img src={cartRenew} alt='cart-renew' />
            </span>
            <p>Votre cure se renouvelle automatiquement tous les 30 jours</p>
          </div>

          <div
            style={{
              marginBottom: '15px',
            }}
          ></div>

          <div>
            <span>
              <BsCheckSquareFill />
            </span>
            <p>Payer en plusieurs fois.</p>
          </div>
          <div>
            <span>
              <BsCheckSquareFill />
            </span>
            <p>Sans engagement.Stoppez quand vous voulez.</p>
          </div>
          <div>
            <span>
              <BsCheckSquareFill />
            </span>
            <p>Adaptez le contenu de votre cure tous les mois.</p>
          </div>
        </SubscriptionContent>
      )}

      <Pricing>
        <PricingLine>
          <span>Prix mensuel</span>
          <span>€{cart?.estimatedCost.subtotalAmount.amount}</span>
        </PricingLine>
        <PricingLine>
          <span>Sous-total (3mois)</span>
          <span>€{cart?.estimatedCost.subtotalAmount.amount}</span>
        </PricingLine>
        <PricingLine>
          <span>
            Réduction <strong>{discountCode}</strong>
          </span>
          <span>-{discountPercentage || 0}%</span>
        </PricingLine>
        <hr></hr>
        <PricingLine>
          <span>Total</span>
          <span>€{cart?.estimatedCost.totalAmount.amount}</span>
        </PricingLine>
      </Pricing>

      <PaymentMethods>
        <span>Méthodes de paiement</span>
        <hr></hr>
        <PaymentImageWrapper>
          {!isSubscription && (
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/paypal.png'
              }
              alt='Paypal icon'
            />
          )}
          {!isSubscription && (
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/bancontact.png'
              }
              alt='Bancontact icon'
            />
          )}
          <PaymentImage
            src={
              process.env.REACT_APP_AWS_BUCKET + 'assets/images/icons/visa.png'
            }
            alt='Visa icon'
          />
          <PaymentImage
            src={
              process.env.REACT_APP_AWS_BUCKET +
              'assets/images/icons/maestro.png'
            }
            alt='Maestro icon'
          />
          <PaymentImage
            src={
              process.env.REACT_APP_AWS_BUCKET + 'assets/images/icons/amex.png'
            }
            alt='American Express icon'
          />
          <PaymentImage
            src={
              process.env.REACT_APP_AWS_BUCKET +
              'assets/images/icons/mastercard.png'
            }
            alt='Mastercard icon'
          />
        </PaymentImageWrapper>
      </PaymentMethods>

      <ButtonWrapper>
        {cart?.checkoutUrl && (
          <Button as='a' href={`${checkoutUrl}`} appearance='primary'>
            Continuer vers le paiement
          </Button>
        )}
        <div style={{ marginBottom: '10px' }}></div>
        {goBackButton && (
          <Button
            onClick={onClose}
            style={{ border: 'none', color: Color.DEEP_SEA }}
          >
            Retourner sur la cure
          </Button>
        )}
      </ButtonWrapper>
    </Container>
  )
}

export default PaymentSettings
