import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Icon, Dialog, Button } from '../../components/ui'
import {
  BodySection,
  Container,
  Content,
  HeaderSection,
  IconWrapper,
  Img,
  Left,
  Li,
  MainTitle,
  Note,
  P,
  Right,
  SecondaryTitle,
  TransportInfo,
  Ul,
  OrderSummary,
  InfoCard,
  InfoCardContainer,
  LineButton,
  TitleWrapperFlex,
  FooterBar,
  DialogPaymentClose,
  // PriceWrapper
} from './style'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoCloseOutline } from 'react-icons/io5'
import Layout from '../../components/layout/naked'
// import { getPrescriptionPrice } from '../../store/prescription'
import { getCart } from '../../store/cart'
import {
  useProducts,
  usePrescription,
  useInitCart,
  useAddToCart,
  useUpdateCart,
  useCartLinesRemove,
  useFetchCart,
  useCartAttributesUpdate,
} from '../../utils/hooks'
import PrescriptionProductsList from '../../components/prescription/prescription-products-list'
import ProductsUpsellsDialog from '../../components/product/products-upsells-dialog'
import { outOfBagProductsHandle } from '../../utils/data'
import PaymentSettings from '../../components/payment/payment-settings'

const lineItemsForCart = (takes, productList, recommendedDuration) => {
  if (!takes.length || !productList.length) return

  let standAloneTake = []
  let inCureTakesArray = []

  const standAloneTakefind = takes.find(take => take.isStandAlone)
  if (standAloneTakefind) {
    standAloneTake = standAloneTakefind.items
  }

  let inCureTakes = takes.filter(take => !take.isStandAlone)
  inCureTakes = inCureTakes.map(item => {
    return item.items.map(i => {
      return {
        ...i,
        id: i.id,
        notes: i.notes,
        productHandle: i.productHandle,
        quantity: i.quantity,
        index: item.index,
        label: item.label,
      }
    })
  })

  inCureTakes.map(i => i.map(i => inCureTakesArray.push(i)))

  const takesArray = [...standAloneTake, ...inCureTakesArray]

  return takesArray.map(item => {
    const productId = productList
      .find(p => p.handle === item.productHandle)
      ?.getVariantId(recommendedDuration)

    if (!productId) {
      // TODO: send Sentry error
      console.warn('Product not found', item.productHandle)
      return item
    }

    let attributes = []
    if (item?.productHandle)
      attributes.push({ key: 'handle', value: `${item.productHandle}` })
    if (item?.index) attributes.push({ key: 'prise', value: `${item.index}` })
    if (item?.notes) attributes.push({ key: 'notes', value: `${item.notes}` })
    if (item?.label) attributes.push({ key: 'label', value: `${item.label}` })

    return {
      quantity: item.quantity,
      merchandiseId: productId,
      attributes: attributes,
    }
  })
}

const RecommendationPage = ({ match }) => {
  const takes = []
  const prescriptionId = Number(match.params.prescriptionId)
  const {
    data: prescription,
    loading: prescriptionLoading,
    success: prescriptionSuccess,
  } = usePrescription(prescriptionId)


  const {
    data: products,
    loading: productsLoading,
    success: productsSuccess,
  } = useProducts()

  const { createCart, loading: createCartLoading } = useInitCart()
  const {
    fetchCart,
    data: fetchCartData,
    loading: fetchCartLoading,
  } = useFetchCart()
  const { cartAttributesUpdate, loading: cartAttributesUpdateLoading } =
    useCartAttributesUpdate()
  const { addToCart, loading: addToCartLoading } = useAddToCart()
  const { updateCart, loading: updateCartLoading } = useUpdateCart()
  const { cartLinesRemove } = useCartLinesRemove()
  // const prescriptionPrice = useSelector(state =>
  //   getPrescriptionPrice(state, prescriptionId)
  // )
  const cart = useSelector(state => getCart(state))
  const prescriberName = prescription?.prescriber?.firstname
  const discountCode = prescription?.discountCode
  // const discountPercentage = prescription?.discountPercentage
  // const discountSaving = (prescriptionPrice / 100) * discountPercentage
  // const discountPrice = prescriptionPrice - discountSaving
  // const hasDiscount = Number(discountPrice) !== Number(prescriptionPrice)

  const [editMode, setEditMode] = useState(false)
  const [isOpenDialogMoreProducts, setIsOpenDialogMoreProducts] =
    useState(false)
  const [paymentDialog, setPaymentDialog] = useState(false)

  let lineItems = []

  lineItems = lineItemsForCart(
    prescription?.lastStatus.takes || [],
    products,
    prescription?.recommendedDuration
  )

  let checkoutUrl = prescription && lineItems && cart?.checkoutUrl
  if (discountCode) {
    checkoutUrl += `?discount=${discountCode}&attributes[prescription_id]=${prescriptionId}`
  } else {
    checkoutUrl += `?attributes[prescription_id]=${prescriptionId}`
  }

  const attributes = [
    {
      key: 'prescription_id',
      value: `${prescriptionId}`,
    },
    {
      key: 'prescriber_firstname',
      value: `${prescription?.data?.prescriber?.firstname}`,
    },
  ]

  if(prescription?.data?.custom_message?.length > 0) {
    attributes.push({
      key: 'custom_message',
      value: `${prescription.data.custom_message}`,
    })
  }

  const isSubscription = cart => {
    const duration = cart?.lines?.edges.filter(
      i => !outOfBagProductsHandle.includes(i.node.merchandise.product.handle)
    )[0]?.node?.merchandise?.title
    if (duration?.includes('subscription')) {
      return true
    } else {
      return false
    }
  }

  const selectDefaultDuration = cart => {
    const duration = cart?.lines?.edges.filter(
      i => !outOfBagProductsHandle.includes(i.node.merchandise.product.handle)
    )[0]?.node?.merchandise?.title
    if (duration === '1month_onetime') {
      return '1-month'
    } else if (duration === '2month_onetime') {
      return '2-month'
    } else if (duration === '3month_onetime') {
      return '3-month'
    } else if (duration === '1month_subscription') {
      return '1-month'
    } else if (duration === '2month_subscription') {
      return '2-month'
    } else if (duration === '3month_subscription') {
      return '3-month'
    } else {
      return '1-month'
    }
  }

  const addVariantToCart = (merchandiseId, takeNumber, handle) => {
    addToCart({
      variables: {
        lines: [
          {
            merchandiseId: merchandiseId,
            attributes: [
              {
                key: 'handle',
                value: handle,
              },
              {
                key: 'prise',
                value: takeNumber ? takeNumber : '/',
              },
            ],
          },
        ],
        cartId: cart.id,
      },
    }).then(() => {
      setIsOpenDialogMoreProducts()
    })
  }

  const updateVariantToCart = (cartId, cartLineId, qty) => {
    updateCart({
      variables: {
        cartId: cartId,
        lines: {
          id: cartLineId,
          quantity: qty,
        },
      },
    })
  }

  const removeVariantsToCart = (cartId, lineIds) => {
    cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: lineIds,
      },
    })
  }

  const updateVariantDurationToCart = (
    productList,
    duration,
    isSubscription
  ) => {
    const lines = cart.lines.edges.map(i => {
      let title = ''
      let durationTitle = ''

      if (isSubscription) {
        if (duration === '1-month') {
          title = '1-month-subscription'
          durationTitle = 'Delivery every 30 Days'
        } else if (duration === '2-month') {
          title = '2-month-subscription'
          durationTitle = 'Delivery every 60 Days'
        } else if (duration === '3-month') {
          title = '3-month-subscription'
          durationTitle = 'Delivery every 90 Days'
        }

        const productId = productList
          .find(p => p.handle === i.node.merchandise.product.handle)
          ?.getVariantId(title)

        const sellingPlanId =
          i.node.merchandise.sellingPlanAllocations.edges.find(
            i => i.node.sellingPlan.name === durationTitle
          ).node.sellingPlan.id

        return {
          id: i.node.id,
          merchandiseId: productId,
          sellingPlanId,
        }
      } else {
        if (duration === '1-month') {
          title = '1-month'
        } else if (duration === '2-month') {
          title = '2-month'
        } else if (duration === '3-month') {
          title = '3-month'
        }

        const productId = productList
          .find(p => p.handle === i.node.merchandise.product.handle)
          ?.getVariantId(title)

        return {
          id: i.node.id,
          merchandiseId: productId,
        }
      }
    })
    const cartId = cart.id
    updateCart({
      variables: {
        cartId,
        lines,
      },
    })
  }

  const updateOneTimePurchaseToSubscripton = (productList, duration, type) => {
    const lines = cart.lines.edges.map(i => {
      let title = ''
      let durationTitle = ''

      if (type === 'SUBSCRIPTION') {
        if (duration === '1-month') {
          title = '1-month-subscription'
          durationTitle = 'Delivery every 30 Days'
        } else if (duration === '2-month') {
          title = '2-month-subscription'
          durationTitle = 'Delivery every 60 Days'
        } else if (duration === '3-month') {
          title = '3-month-subscription'
          durationTitle = 'Delivery every 90 Days'
        }

        const productId = productList
          .find(p => p.handle === i.node.merchandise.product.handle)
          ?.getVariantId(title)

        const sellingPlanId =
          i.node.merchandise.sellingPlanAllocations.edges.find(
            i => i.node.sellingPlan.name === durationTitle
          ).node.sellingPlan.id

        return {
          id: i.node.id,
          merchandiseId: productId,
          sellingPlanId,
        }
      } else {
        if (duration === '1-month') {
          title = '1-month'
        } else if (duration === '2-month') {
          title = '2-month'
        } else if (duration === '3-month') {
          title = '3-month'
        }

        const productId = productList
          .find(p => p.handle === i.node.merchandise.product.handle)
          ?.getVariantId(title)

        return {
          id: i.node.id,
          merchandiseId: productId,
          sellingPlanId: null,
        }
      }
    })

    const cartId = cart.id
    updateCart({
      variables: {
        cartId,
        lines,
      },
    })
  }


  useEffect(() => {
    !!cart &&
      prescriptionSuccess &&
      productsSuccess &&
      updateOneTimePurchaseToSubscripton(
        products,
        selectDefaultDuration(cart),
        prescription?.data?.purchase_option?.toUpperCase()
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionLoading, productsLoading, !!cart])

  useEffect(() => {
    // Shopify automatically deletes the cart when the customer completes their checkout.
    !!lineItems?.length &&
      prescriptionSuccess &&
      productsSuccess &&
      cart === null &&
      !fetchCartData?.cart &&
      createCart({
        variables: {
          cartInput: {
            lines: lineItems,
            attributes: attributes,
            discountCodes: [discountCode],
          },
        },
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCartData])

  useEffect(() => {
    !!lineItems?.length &&
      prescriptionSuccess &&
      productsSuccess &&
      (`${localStorage.getItem('cartId')}${prescriptionId}` ===
      localStorage.getItem('sessionId')
        ? fetchCart({
            variables: { cartId: localStorage.getItem('cartId') },
          })
        : createCart({
            variables: {
              cartInput: {
                lines: lineItems,
                attributes: attributes,
                discountCodes: [discountCode],
              },
            },
          }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionLoading, productsLoading])

  return (
    <Layout>
      <Container>
        <Content>
          {prescriptionLoading && <Spinner />}
          {!prescriptionLoading && prescription && (
            <>
              <HeaderSection>
                <MainTitle>
                  Bonjour {prescription?.patient?.firstname},
                  <br />
                  Votre recommandation personnalisée 💊
                </MainTitle>

                {editMode ? (
                  <LineButton
                    onClick={() => {
                      setEditMode(v => !v)
                      localStorage.removeItem('cartId')
                      localStorage.removeItem('sessionId')
                      window.location.reload(false)
                    }}
                    style={{ marginRight: '15px' }}
                  >
                    Réinitialiser la cure
                  </LineButton>
                ) : (
                  <LineButton
                    onClick={() => setEditMode(v => !v)}
                    style={{ marginRight: '15px' }}
                  >
                    Modifier la cure
                  </LineButton>
                )}
              </HeaderSection>

              <BodySection>
                <Left>
                  <OrderSummary>
                    <TitleWrapperFlex>
                      <SecondaryTitle>
                        {prescriberName} vous à laissé(e) un message
                      </SecondaryTitle>
                      {editMode && (
                        <LineButton
                          onClick={() => setIsOpenDialogMoreProducts(true)}
                        >
                          <span>
                            <AiOutlinePlus />
                          </span>
                          Ajouter des produits
                        </LineButton>
                      )}
                    </TitleWrapperFlex>

                    <Note>{prescription.customMessage || '-'}</Note>
                    <PrescriptionProductsList
                      takesArray={takes}
                      prescription={prescription}
                      cart={cart}
                      editMode={editMode}
                      updateVariantToCart={updateVariantToCart}
                      removeVariantsToCart={removeVariantsToCart}
                      updateCartLoading={updateCartLoading}
                      isLoading={
                        !!(
                          productsLoading |
                          createCartLoading |
                          fetchCartLoading
                        )
                      }
                      takes={prescription.lastStatus.takes}
                      prescriptionRecommendedDuration={
                        prescription.recommendedDuration
                      }
                      titleInCure={
                        <SecondaryTitle>
                          Vos produits en sachets{' '}
                        </SecondaryTitle>
                      }
                      titleOutCure={
                        <SecondaryTitle>
                          Vos produits hors sachets
                        </SecondaryTitle>
                      }
                      AbilityToManageQuantity={true}
                      from='recommendationPage'
                    />
                  </OrderSummary>

                  <InfoCard>
                    <SecondaryTitle>A propos de Compliment</SecondaryTitle>
                    <InfoCardContainer>
                      <Img
                        src={
                          // process.env.REACT_APP_AWS_BUCKET +
                          // 'assets/images/compliment-cure-packaging.png'
                          'https://compliment-pro.s3-eu-west-1.amazonaws.com/assets/images/compliment-cure-packaging.png'
                        }
                        alt='Compliment packaging'
                      />
                      <Ul>
                        <Li>
                          <Icon name='check' />
                          Fait en Belgique
                        </Li>
                        <Li>
                          <Icon name='check' />
                          Assemblé éthiquement en atelier protégé
                        </Li>
                        <Li>
                          <Icon name='check' />
                          Naturel et sans OGM
                        </Li>
                        <Li>
                          <Icon name='check' />
                          Ingrédients certifié
                        </Li>
                        <Li>
                          <Icon name='check' />
                          Emballage recyclable
                        </Li>
                        <TransportInfo>
                          <IconWrapper>
                            <Icon name='shipping' />
                          </IconWrapper>
                          <P>
                            Livraison express <b>directement chez vous</b> et{' '}
                            <b>sans frais supplémentaire</b>
                          </P>
                        </TransportInfo>
                      </Ul>
                    </InfoCardContainer>
                  </InfoCard>
                </Left>
                <Right>
                  <Dialog
                    isOpen={paymentDialog}
                    type='full'
                    style={{
                      position: 'relative',
                    }}
                    //  onClose={() => setSelectedHandle('')}
                  >
                    <DialogPaymentClose onClick={() => setPaymentDialog(false)}>
                      <span>
                        <IoCloseOutline />
                      </span>
                    </DialogPaymentClose>
                    <PaymentSettings
                      onClose={() => setPaymentDialog(false)}
                      height='100%'
                      updateCartLoading={updateCartLoading}
                      cartAttributesUpdateLoading={cartAttributesUpdateLoading}
                      cartAttributesUpdate={cartAttributesUpdate}
                      prescription={prescription}
                      selectDefaultDuration={selectDefaultDuration}
                      cart={cart}
                      products={products}
                      goBackButton={true}
                      updateOneTimePurchaseToSubscripton={() =>
                        updateOneTimePurchaseToSubscripton(
                          products,
                          '1-month',
                          'SUBSCRIPTION'
                        )
                      }
                      updateSubscriptonToOneTimePurchase={() =>
                        updateOneTimePurchaseToSubscripton(
                          products,
                          selectDefaultDuration(cart),
                          'ONE-TIME-PURCHASE'
                        )
                      }
                      isSubscription={isSubscription(cart)}
                      purchaseOption={prescription?.data?.purchase_option}
                      updateVariantDurationToCart={updateVariantDurationToCart}
                      checkoutUrl={checkoutUrl}
                    />
                  </Dialog>
                  <PaymentSettings
                    displayMAndDown='none'
                    updateCartLoading={updateCartLoading}
                    cartAttributesUpdateLoading={cartAttributesUpdateLoading}
                    cartAttributesUpdate={cartAttributesUpdate}
                    prescription={prescription}
                    selectDefaultDuration={selectDefaultDuration}
                    cart={cart}
                    products={products}
                    updateOneTimePurchaseToSubscripton={() =>
                      updateOneTimePurchaseToSubscripton(
                        products,
                        '1-month',
                        'SUBSCRIPTION'
                      )
                    }
                    updateSubscriptonToOneTimePurchase={() =>
                      updateOneTimePurchaseToSubscripton(
                        products,
                        selectDefaultDuration(cart),
                        'ONE-TIME-PURCHASE'
                      )
                    }
                    isSubscription={isSubscription(cart)}
                    purchaseOption={prescription?.data?.purchase_option}
                    updateVariantDurationToCart={updateVariantDurationToCart}
                    checkoutUrl={checkoutUrl}
                  />
                </Right>
              </BodySection>

              {cart?.checkoutUrl && (
                <FooterBar>
                  <Button
                    onClick={() => setPaymentDialog(true)}
                    style={{ border: 'none', textDecoration: 'underline' }}
                    // iconLeft='plus'
                  >
                    Afficher les details
                  </Button>
                  <Button
                    as='a'
                    href={`${checkoutUrl}`}
                    appearance='primary'
                    // onClick={() => {
                    //   mixpanel.track('Checkout', {
                    //     cartId: cart?.id,
                    //     prescriptionId: prescription.id,
                    //     discountCode: discountCode,
                    //   })
                    // }}
                  >
                    Payer {cart?.estimatedCost.totalAmount.amount} €
                  </Button>
                </FooterBar>
              )}

              <ProductsUpsellsDialog
                takesArray={takes}
                prescription={prescription}
                isDialogOpen={isOpenDialogMoreProducts}
                setIsDialogOpen={setIsOpenDialogMoreProducts}
                addProduct={addVariantToCart}
                recommendedDuration={prescription?.recommendedDuration}
                addToCartLoading={addToCartLoading}
              />
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default RecommendationPage
