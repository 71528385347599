import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PatientCell, InfoContainer, P } from './style'
import { Avatar } from '../../../ui'
import { getPatientById } from '../../../../store/patient'

const PatientCellEl = ({ patientId }) => {
  const patient = useSelector(state => getPatientById(state, patientId))

  return (
    <PatientCell>
      <Avatar firstName={patient?.firstname} lastName={patient?.name} />
      <InfoContainer>
        <P>{`${patient?.firstname} ${patient?.name}`}</P>
        <P>{patient?.email}</P>
      </InfoContainer>
    </PatientCell>
  )
}

PatientCellEl.propTypes = {
  patientId: PropTypes.number.isRequired,
}

export default PatientCellEl
