import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Statuses, StatusContainer, Title, P, MarkAsSent } from './style'
import PrescriptionStatus from '../prescription-status'
import { Tooltip, Icon } from '../../ui'
import { Color } from '../../../utils/constants'
import { usePushPrescriptionStatus } from '../../../utils/hooks'
import {
  getPrescriptionById,
  setIsStatusSendedManually,
  setIsStatusSendedManuallyReset,
} from '../../../store/prescription'

const PrescriptionStatuses = ({ statuses, match }) => {
  const dispatch = useDispatch()

  const prescription = useSelector(state =>
    getPrescriptionById(state, Number(match?.params?.id))
  )

  const {
    loading: pushPrescriptionStatusLoading,
    pushStatus,
    success: pushPrescriptionStatusSuccess,
  } = usePushPrescriptionStatus()

  return (
    <Statuses>
      <Title>Status</Title>
      {statuses.map((status, index) => (
        <StatusContainer key={index}>
          <P>{`${status.time} -`}</P>
          <PrescriptionStatus status={status} />
        </StatusContainer>
      ))}
      <MarkAsSent
        onClick={() => {
          prescription?.lastStatus?.name === 'CREATED' &&
            new Promise(resolve => resolve())
              .then(() => dispatch(setIsStatusSendedManually()))
              .then(() =>
                pushStatus(
                  prescription?.id,
                  prescription.lastStatus.takes,
                  'SENT'
                )
              )
              .catch(() => dispatch(setIsStatusSendedManuallyReset()))
        }}
        isDisabled={
          pushPrescriptionStatusSuccess ||
          prescription?.lastStatus?.name !== 'CREATED'
        }
        isLoading={pushPrescriptionStatusLoading}
      >
        Marquer comme "envoyé"{' '}
        <Tooltip
          size='l'
          label='Si vous partagez le lien de la recommandation avec votre patient par un canal de votre choix (Facebook messenger, Whatsapp, autre) vous pouvez manuellement indiquer à Compliment Pro que cette recommandation est "Envoyée"'
        >
          <Icon name='info' color={Color.DEEP_SEA} />
        </Tooltip>{' '}
        {pushPrescriptionStatusLoading && (
          <Icon name='loading' color={Color.DEEP_SEA} />
        )}
      </MarkAsSent>
    </Statuses>
  )
}

PrescriptionStatuses.defaultProps = {
  statuses: [],
}

PrescriptionStatuses.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      status: PropTypes.string,
    })
  ),
}

export default PrescriptionStatuses
