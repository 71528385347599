import styled from 'styled-components/macro'

export const LogoContainer = styled.div`
  height: 100%;
  width: 180px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
