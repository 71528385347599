import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { ActionType } from '../constants'
import {
  fetchAuthUserFailed,
  fetchAuthUserLoading,
  fetchAuthUserSuccess,
  fetchAuthUserReset,
  getAuthUser,
  getFetchAuthPrescriberStatus,
  getUserIsEmailVerified,
  getUserToken,
  getUserUid,
  getLoginStatus,
  getFetchAuthPrescriberError,
} from '../../store/auth-user'
import { AUTH_PRESCRIBER } from '../../services/queries'
import { toast } from 'react-toastify'
import { Link } from '../../components/ui'
import { useAuthUser } from '../../services/firebase'

export const useAuthPrescriber = () => {
  const dispatch = useDispatch()
  const auth = useAuthUser()
  const newUserUid = useSelector(getUserUid)
  const authUser = useSelector(getAuthUser)
  const authToken = useSelector(getUserToken)
  const isUserEmailVerified = useSelector(getUserIsEmailVerified)
  const fetchAuthPrescriberStatus = useSelector(getFetchAuthPrescriberStatus)
  const fetchAuthPrescriberError = useSelector(getFetchAuthPrescriberError)
  const loginStatus = useSelector(getLoginStatus)
  const [
    fetchAuthPrescriber,
    {
      loading: prescriberLoading,
      error: prescriberError,
      data: prescriberData,
    },
  ] = useLazyQuery(AUTH_PRESCRIBER, {
    variables: { uid: newUserUid },
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  const sendVerificationEmail = useCallback(() => {
    if (auth.sendEmailVerification) {
      let errorText = "Une erreur s'est produite, réessayez plus tard"
      auth
        .sendSignInLinkToEmail()
        .then(error => {
          if (error) {
            toast.error(errorText)
          } else {
            toast.success(
              `Un mail de confirmation a été envoyé à ${auth.email}`
            )
          }
        })
        .catch(error => {
          if (error.code === 'auth/too-many-requests') {
            errorText =
              'Veuillez attendre avant de renvoyer un mail de confirmation'
          }
          toast.error(errorText)
        })
    } else {
    }
  }, [auth])

  useEffect(() => {
    if (
      newUserUid &&
      isUserEmailVerified &&
      authToken &&
      !prescriberData &&
      fetchAuthPrescriberStatus !== ActionType.SUCCESS
    )
      fetchAuthPrescriber()
  }, [
    authToken,
    fetchAuthPrescriber,
    fetchAuthPrescriberStatus,
    isUserEmailVerified,
    newUserUid,
    prescriberData,
  ])

  useEffect(() => {
    if (
      loginStatus === ActionType.SUCCESS &&
      fetchAuthPrescriberError === 'email-not-verified'
    ) {
      toast.error(
        <p>
          Votre email n'est pas vérifié.
          <br />
          <Link onClick={sendVerificationEmail}>
            Renvoyez l'email de verification maintenant
          </Link>
        </p>,
        {
          autoClose: false,
        }
      )
      /*
       * Reset the error directly after showing it to prevent next login to
       * have a stale unrelated error still present in the store
       */
      dispatch(fetchAuthUserReset())
    }
  }, [dispatch, fetchAuthPrescriberError, loginStatus, sendVerificationEmail])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    prescriberLoading && dispatch(fetchAuthUserLoading())
  }, [dispatch, prescriberLoading])
  useEffect(() => {
    prescriberError && dispatch(fetchAuthUserFailed(prescriberError))
  }, [dispatch, prescriberError])
  useEffect(() => {
    if (
      prescriberData?.prescriber?.length &&
      fetchAuthPrescriberStatus !== ActionType.SUCCESS
    ) {
      dispatch(fetchAuthUserSuccess(prescriberData))
      /**
       * Force the failed to trigger only if the data received doesn't
       * contain any prescriber object
       */
    } else if (prescriberData?.prescriber?.length === 0) {
      console.warn(prescriberData)
      dispatch(fetchAuthUserFailed('auto-login-failed'))
    }
  }, [
    dispatch,
    prescriberData,
    prescriberLoading,
    newUserUid,
    fetchAuthPrescriberStatus,
  ])

  return {
    authUser,
    loading:
      prescriberLoading || fetchAuthPrescriberStatus === ActionType.LOADING,
    success: fetchAuthPrescriberStatus === ActionType.SUCCESS,
    error: fetchAuthPrescriberStatus === ActionType.FAILED,
  }
}
