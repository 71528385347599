import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  Container,
  Card,
  Title,
  Filter,
  MetricCard,
  MenuContainer,
  MetricsContainer,
  MetricTitle,
  MetricTitleContainer,
  ValueContainer,
  Value,
  ValueLabel,
} from './style'
import { Icon, Dropdown, NavLink, Tooltip, Spinner } from '../ui'
import { useProducts } from '../../utils/hooks'
import StatusPie from './status-pie'
const filterOptions = [
  { label: 'les 30 derniers jours', value: '30-days' },
  { label: 'les 7 derniers jours', value: '7-days' },
  { label: 'les dernières 24 heures', value: '24-hours' },
  { label: 'les 3 derniers mois', value: '3-months' },
  { label: 'la dernière année', value: '1-year' },
]

const data = [
  {
    type: 'ordered',
    title: 'Patients en cure',
    tooltip:
      'un patient en cure est un patient qui a acheté votre recommendation de produits et qui la consomme encore actuellement.',
    valueLabel: '%',
  },
  {
    type: 'sent',
    title: 'Patients en attente',
    tooltip:
      "un patient en attente est un patient qui a reçu votre recommendation de produits mais qui n'a pas encore passé commande.",
    valueLabel: '%',
  },
  {
    type: 'created',
    title: 'Patients sans cure',
    tooltip:
      'un patient sans cure est un patient qui a déjà acheté une de vos recommendations de produits mais qui est arrivé au bout de sa cure.',
    valueLabel: '%',
  },
]

// {
//   type: 'earnings',
//   title: 'Gains',
//   tooltip: 'Total des commissions gagnées sur toutes vos prescriptions',
//   valueLabel: '€',
// },

const Dashboard = ({ prescriptions }) => {
  const [filterValue, setFilterValue] = useState(filterOptions[0])
  const [selectedData, setSelectedData] = useState(data)
  const filters = filterOptions.filter(f => f !== filterValue)
  const {
    data: products,
    loading: productLoading,
    success: productSuccess,
  } = useProducts()

  const getPrescriptionPrice = p => {
    if (!p.lastStatus.takes.length) return 0

    const totalPrice = p.lastStatus.takes.reduce(
      (takeAccPrice, currentTake) => {
        return (
          takeAccPrice +
          currentTake.items.reduce((itemAccPrice, currentItem) => {
            const product = products.find(
              p => p.handle === currentItem.productHandle
            )
            return (
              itemAccPrice +
              Number(product.getPrice(p.recommendedDuration)) *
                Number(currentItem.quantity)
            )
          }, 0)
        )
      },
      0
    )

    return totalPrice.toFixed(2)
  }

  const populateData = useCallback(() => {
    const filterDate = moment()
    const newData = [...selectedData]

    switch (filterValue.value) {
      case '7-days':
        filterDate.subtract(7, 'days')
        break
      case '30-days':
        filterDate.subtract(30, 'days')
        break
      case '24-hours':
        filterDate.subtract(24, 'hours')
        break
      case '3-months':
        filterDate.subtract(3, 'months')
        break
      case '1-year':
        filterDate.subtract(1, 'years')
        break
      default:
        break
    }
    newData.forEach(d => {
      let value = 0
      prescriptions.forEach(p => {
        const date = moment(p.lastStatus.createdAt)
        switch (d.type) {
          case 'ordered':
            if (
              p.lastStatus.name === 'ORDERED' &&
              date.isBetween(filterDate, moment())
            ) {
              value += 1
            }
            break
          case 'sent':
            if (
              p.lastStatus.name === 'SENT' &&
              date.isBetween(filterDate, moment())
            ) {
              value += 1
            }
            break
          case 'created':
            if (
              p.lastStatus.name === 'DONE' &&
              date.isBetween(filterDate, moment())
            ) {
              value += 1
            }
            break
          case 'earnings':
            if (
              (p.lastStatus.name === 'ORDERED' ||
                p.lastStatus.name === 'DONE') &&
              date.isBetween(filterDate, moment())
            ) {
              const price = getPrescriptionPrice(p)
              if (p.discountPercentage)
                value += (price * p.discountPercentage) / 100
            }
            break
          default:
            break
        }
      })
      if (prescriptions?.length > 1) {
        if (d.type !== 'earnings')
          d.value =
            value >= 0 ? ((value / prescriptions.length) * 100).toFixed(0) : 0
        else
          d.value =
            value.length > 3 ? value.toString()[0] + 'k' : value.toFixed(0)
      } else d.value = 0
    })
    setSelectedData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptions, filterValue.value])

  useEffect(() => {
    if (productSuccess) populateData()
  }, [filterValue, populateData, productSuccess, products])

  useEffect(() => {
    const filterDate = moment()

    switch (filterValue.value) {
      case '7-days':
        filterDate.subtract(7, 'days')
        break
      case '30-days':
        filterDate.subtract(30, 'days')
        break
      case '24-hours':
        filterDate.subtract(24, 'hours')
        break
      case '3-months':
        filterDate.subtract(3, 'months')
        break
      case '1-year':
        filterDate.subtract(1, 'years')
        break
      default:
        break
    }
  }, [filterValue])

  return (
    <Container>
      <Card width='20%' margin='10px 0px 10px 0px'>
        <Title style={{ marginBottom: '10px' }}>
          Status de vos recommandations
        </Title>
        <StatusPie data={data} prescriptions={prescriptions} />
      </Card>
      <Card width='80%' margin=' 10px 0px 10px 10px'>
        <Container>
          <Title>Statut de vos patients</Title>
          <Dropdown
            content={
              <MenuContainer>
                {filters.map(f => (
                  <NavLink key={f.value} onClick={() => setFilterValue(f)}>
                    {f.label}
                  </NavLink>
                ))}
              </MenuContainer>
            }
          >
            <Filter>
              <p>{filterValue.label}</p>
              <Icon height={'9px'} width={'12px'} name='arrow-down' />
            </Filter>
          </Dropdown>
        </Container>
        {productLoading && <Spinner />}
        {productSuccess && (
          <MetricsContainer>
            {selectedData.map(d => (
              <MetricCard key={d.type}>
                <MetricTitleContainer>
                  <MetricTitle>{d.title}</MetricTitle>
                  <Tooltip size='l' label={d.tooltip}>
                    <Icon name='info' width='10px' height='10px' />
                  </Tooltip>
                </MetricTitleContainer>
                <ValueContainer>
                  <Value>{d.value}</Value>
                  <ValueLabel>{d.valueLabel}</ValueLabel>
                </ValueContainer>
              </MetricCard>
            ))}
          </MetricsContainer>
        )}
      </Card>
    </Container>
  )
}

Dashboard.propTypes = {
  prescriptions: PropTypes.array,
}

export default Dashboard
