import Constants from './constants'

export const fetchProductsLoading = () => ({
  type: Constants.FETCH_PRODUCTS_LOADING,
})
export const fetchProductsSuccess = payload => ({
  type: Constants.FETCH_PRODUCTS_SUCCESS,
  payload: payload.shopify_product,
})
export const fetchProductsFailed = error => ({
  type: Constants.FETCH_PRODUCTS_FAILED,
  error,
})
