import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType, ErrorCodes, TakeType } from '../../utils/constants'
import {
  pushItemInTake,
  pushTakeInDraft,
  getTakeByIndex,
  getTakeIndexByIndex,
} from '../../utils/helpers'

const fetchPrescriptionsFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.FAILED, error }

  return newState
}
const fetchPrescriptionsLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.LOADING }

  return newState
}
const fetchPrescriptionsSuccess = (state, { payload, error }) => {
  const newState = cloneDeep(state)
  newState.list = [...payload]
  newState.actions.fetchAll = { status: ActionType.SUCCESS, error }

  return newState
}

const fetchPrescriptionFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.fetchOne = { status: ActionType.FAILED, error }

  return newState
}
const fetchPrescriptionLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchOne = { status: ActionType.LOADING }

  return newState
}
const fetchPrescriptionSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const prescriptionIndex = newState.list.findIndex(
    ({ id }) => id === payload.id
  )

  newState.list[
    prescriptionIndex === -1 ? newState.list.length : prescriptionIndex
  ] = payload

  newState.actions.fetchOne = { status: ActionType.SUCCESS }

  return newState
}

const setNewPrescriptionDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.newDraft = { ...newState.newDraft, ...payload }

  return newState
}

const resetNewPrescriptionDraft = state => {
  const newState = cloneDeep(state)
  newState.newDraft = {
    message: null,
    patientId: null,
    takes: [],
    duration: 1,
    durationUnit: 'month',
  }

  return newState
}

const setProtocoleToDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.newDraft = {
    ...newState.newDraft,
    duration: payload.duration,
    message: payload.customMessage,
    takes: [],
  }

  const takesToAdd = []
  payload.takes.forEach(t => {
    const itemsToAdd = []
    t.items.forEach(i => {
      itemsToAdd.push({
        notes: i.notes,
        handle: i.productHandle,
        quantity: i.quantity,
      })
    })
    takesToAdd.push({
      index: t.index,
      label: t.label,
      type: t.type,
      items: itemsToAdd,
    })
  })
  newState.newDraft.takes = [...takesToAdd]
  return newState
}

const setPrescriptionToDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.newDraft = {
    ...newState.newDraft,
    patientId: payload.patientId,
    duration: payload.duration,
    message: payload.customMessage,
    takes: [],
  }

  const takesToAdd = []
  payload.lastStatus.takes.forEach(t => {
    const itemsToAdd = []
    t.items.forEach(i => {
      itemsToAdd.push({
        notes: i.notes,
        handle: i.productHandle,
        quantity: i.quantity,
      })
    })
    takesToAdd.push({
      index: t.index,
      label: t.label,
      type: t.type,
      items: itemsToAdd,
    })
  })
  newState.newDraft.takes = [...takesToAdd]

  return newState
}

const resetProtocoleToDraft = state => {
  const newState = cloneDeep(state)
  newState.newDraft = {
    patientId: newState.newDraft.patientId,
    message: '',
    takes: [],
    duration: 1,
    durationUnit: 'month',
  }

  return newState
}

const setPrescriptionDraftProductTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { takeIndex, product } = payload
  const { STAND_ALONE, IN_CURE } = TakeType

  if (product.isStandAlone) {
    const take = newState.newDraft.takes.find(t => t.type === STAND_ALONE)
    if (take) {
      pushItemInTake(take, product.handle)
    } else {
      pushTakeInDraft(newState.newDraft, STAND_ALONE, product.handle)
    }
  } else {
    const takes = newState.newDraft.takes.filter(t => t.type === IN_CURE)
    if (!takes.length) {
      // Need to create a new take
      pushTakeInDraft(newState.newDraft, IN_CURE, product.handle)
    } else if (takeIndex !== undefined) {
      // Add to a specific take
      const concernedTake = takes.find(take => take.index === takeIndex)
      pushItemInTake(concernedTake, product.handle)
    } else {
      // Add to the first take since no take index is received
      pushItemInTake(takes[0], product.handle)
    }
  }

  return newState
}

const removeProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { product, takeIndex } = payload
  const {
    newDraft: { takes },
  } = newState
  const take = getTakeByIndex(takes, takeIndex)

  const productIndex = take.items.findIndex(
    item => item.handle === product.handle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items.splice(productIndex, 1)

  /**
   * When a product is removed, we want to make sure we're not staying with an
   * empty take, so if it's the case, we remove it as well.
   */
  if (!take.items.length) {
    const takeIndexToRemove = getTakeIndexByIndex(takes, takeIndex)
    takes.splice(takeIndexToRemove, 1)
  }

  return newState
}
const decreaseQttyProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  const currentQuantity = take.items[productIndex].quantity

  if (currentQuantity > 1) {
    take.items[productIndex].quantity -= 1
  }

  return newState
}
const increaseQttyProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items[productIndex].quantity += 1

  return newState
}
const setProductNotesDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex, notes } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items[productIndex].notes = notes

  return newState
}
const setNewPrescriptionId = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.lastCreatedId = payload

  return newState
}

const addPrescriptionFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.FAILED, error: error }

  return newState
}
const addPrescriptionLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.LOADING, error: null }

  return newState
}
const addPrescriptionSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = [...newState.list, payload]
  newState.actions.add = { status: ActionType.SUCCESS, error: null }

  return newState
}

const resetDeletePrescription = state => {
  const newState = cloneDeep(state)
  newState.actions.delete = {
    status: null,
    error: null,
  }

  return newState
}

const deletePrescriptionFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.delete = { status: ActionType.FAILED, error: error }

  return newState
}

const deletePrescriptionLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.delete = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}

const deletePrescriptionTakeLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.update.take.delete = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}
const deletePrescriptionTakeFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.update.take.delete = {
    status: ActionType.FAILED,
    error: error,
  }

  return newState
}

const deletePrescriptionTakeSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const prescriptionIdx = newState.list.findIndex(
    p => p.id === payload.prescription_status.prescription_id
  )
  const lastStatusIdx =
    newState.list[prescriptionIdx].prescription_statuses.length - 1
  newState.list[prescriptionIdx].prescription_statuses[
    lastStatusIdx
  ].prescription_takes = []
  newState.actions.update.take.delete = {
    status: ActionType.SUCCESS,
    error: null,
  }
  return newState
}

const deletePrescriptionSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = newState.list.filter(p => p.id !== payload.id)
  newState.actions.delete = { status: ActionType.SUCCESS, error: null }

  return newState
}

const updatePrescriptionSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  Object.assign(
    newState.list.find(p => p.id === payload.id),
    {
      custom_message: payload.custom_message,
      recommended_duration: payload.recommended_duration,
      discount: payload.discount,
    }
  )
  newState.actions.update.prescription = {
    status: ActionType.SUCCESS,
    error: null,
  }

  return newState
}

const updatePrescriptionFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.update.prescription = {
    status: ActionType.FAILED,
    error: error,
  }

  return newState
}

const updatePrescriptionLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.update.prescription = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}

const addPrescriptionTakeSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  payload.forEach(take => {
    const prescriptionIdx = newState.list.findIndex(
      p => p.id === take.prescription_status.prescription_id
    )
    newState.list[
      prescriptionIdx
    ].prescription_statuses[0].prescription_takes.push({
      index: take.index,
      label: take.label,
      type: take.type,
      prescription_items: [...take.prescription_items],
    })
  })

  newState.actions.update.take.add = {
    status: ActionType.SUCCESS,
    error: null,
  }

  return newState
}

const addPrescriptionTakeFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.update.take.add = {
    status: ActionType.FAILED,
    error: error,
  }

  return newState
}

const addPrescriptionTakeLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.update.take.add = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}

const addPrescriptionTake = state => {
  const newState = cloneDeep(state)
  newState.newDraft.takes.push({
    index: newState.newDraft.takes.length + 1,
    label: '',
    // 'Prise #' + (newState.newDraft.takes.length + 1)
    type: TakeType.IN_CURE,
    items: [],
  })

  return newState
}
const removePrescriptionTake = (state, { payload: takeIndex }) => {
  const newState = cloneDeep(state)
  newState.newDraft.takes.splice(takeIndex, 1)

  return newState
}
const setTakeLabel = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { label, takeIndex } = payload
  const {
    newDraft: { takes },
  } = newState
  const take = getTakeByIndex(takes, takeIndex)
  take.label = label

  return newState
}

const sendByEmailFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.sendByEmail = { status: ActionType.FAILED, error }

  return newState
}
const sendByEmailLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.sendByEmail = { status: ActionType.LOADING }

  return newState
}
const sendByEmailSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.sendByEmail = { status: ActionType.SUCCESS }

  return newState
}
const sendByEmailReset = state => {
  const newState = cloneDeep(state)
  newState.actions.sendByEmail = { status: ActionType.RESET }

  return newState
}

const pushPrescriptionStatusFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const pushPrescriptionStatusLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = { status: ActionType.LOADING }

  return newState
}
const pushPrescriptionStatusSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const prescriptionIndex = newState.list.findIndex(
    ({ id }) => id === payload.prescription_id
  )
  newState.list[prescriptionIndex].prescription_statuses.push({ ...payload })
  if (prescriptionIndex === -1) {
    // TODO: push sentry error
    console.warn(
      `Precription not in the list -> ID: ${payload.prescription_id}`
    )
    return state
  }

  newState.actions.pushStatus = { status: ActionType.SUCCESS }

  return newState
}
const pushPrescriptionStatusReset = state => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = { status: ActionType.RESET }

  return newState
}

const setIsStatusSendedManually = state => {
  const newState = cloneDeep(state)
  newState.isStatusSendedManually = true

  return newState
}

const setIsStatusSendedManuallyReset = state => {
  const newState = cloneDeep(state)
  newState.isStatusSendedManually = false

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_PRESCRIPTIONS_FAILED:
      return fetchPrescriptionsFailed(state, action)
    case Constants.FETCH_PRESCRIPTIONS_LOADING:
      return fetchPrescriptionsLoading(state)
    case Constants.FETCH_PRESCRIPTIONS_SUCCESS:
      return fetchPrescriptionsSuccess(state, action)

    case Constants.FETCH_PRESCRIPTION_FAILED:
      return fetchPrescriptionFailed(state, action)
    case Constants.FETCH_PRESCRIPTION_LOADING:
      return fetchPrescriptionLoading(state)
    case Constants.FETCH_PRESCRIPTION_SUCCESS:
      return fetchPrescriptionSuccess(state, action)

    case Constants.SET_NEW_PRESCRIPTION_DRAFT:
      return setNewPrescriptionDraft(state, action)
    case Constants.RESET_NEW_PRESCRIPTION_DRAFT:
      return resetNewPrescriptionDraft(state)
    case Constants.SET_PRESCRIPTION_DRAFT_PRODUCT_TAKE:
      return setPrescriptionDraftProductTake(state, action)
    case Constants.REMOVE_PRODUCT_FROM_PRESCRIPTION_DRAFT_TAKE:
      return removeProductFromDraftTake(state, action)
    case Constants.INCREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT:
      return increaseQttyProductFromDraftTake(state, action)
    case Constants.DECREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT:
      return decreaseQttyProductFromDraftTake(state, action)
    case Constants.SET_PRODUCT_NOTES_PRESCRIPTION_DRAFT_TAKE:
      return setProductNotesDraft(state, action)
    case Constants.SET_NEW_PRESCRIPTION_ID:
      return setNewPrescriptionId(state, action)
    case Constants.ADD_PRESCRIPTION_TAKE:
      return addPrescriptionTake(state)
    case Constants.REMOVE_PRESCRIPTION_TAKE:
      return removePrescriptionTake(state, action)
    case Constants.SET_PRESCRIPTION_TAKE_LABEL:
      return setTakeLabel(state, action)
    case Constants.SET_PROTOCOLE_TO_DRAFT:
      return setProtocoleToDraft(state, action)
    case Constants.RESET_PROTOCOLE_DRAFT:
      return resetProtocoleToDraft(state)
    case Constants.RESET_DELETE_PRESCRIPTION:
      return resetDeletePrescription(state)
    case Constants.SET_PRESCRIPTION_TO_DRAFT:
      return setPrescriptionToDraft(state, action)

    case Constants.ADD_PRESCRIPTION_FAILED:
      return addPrescriptionFailed(state, action)
    case Constants.ADD_PRESCRIPTION_LOADING:
      return addPrescriptionLoading(state)
    case Constants.ADD_PRESCRIPTION_SUCCESS:
      return addPrescriptionSuccess(state, action)
    case Constants.DELETE_PRESCRIPTION_LOADING:
      return deletePrescriptionLoading(state)
    case Constants.DELETE_PRESCRIPTION_FAILED:
      return deletePrescriptionFailed(state, action)
    case Constants.DELETE_PRESCRIPTION_SUCCESS:
      return deletePrescriptionSuccess(state, action)
    case Constants.UPDATE_PRESCRIPTION_SUCCESS:
      return updatePrescriptionSuccess(state, action)
    case Constants.UPDATE_PRESCRIPTION_FAILED:
      return updatePrescriptionFailed(state, action)
    case Constants.UPDATE_PRESCRIPTION_LOADING:
      return updatePrescriptionLoading(state, action)
    case Constants.ADD_PRESCRIPTION_TAKE_SUCCESS:
      return addPrescriptionTakeSuccess(state, action)
    case Constants.ADD_PRESCRIPTION_TAKE_FAILED:
      return addPrescriptionTakeFailed(state, action)
    case Constants.ADD_PRESCRIPTION_TAKE_LOADING:
      return addPrescriptionTakeLoading(state, action)
    case Constants.DELETE_PRESCRIPTION_TAKE_LOADING:
      return deletePrescriptionTakeLoading(state)
    case Constants.DELETE_PRESCRIPTION_TAKE_SUCCESS:
      return deletePrescriptionTakeSuccess(state, action)
    case Constants.DELETE_PRESCRIPTION_TAKE_FAILED:
      return deletePrescriptionTakeFailed(state, action)
    case Constants.SEND_BY_EMAIL_FAILED:
      return sendByEmailFailed(state, action)
    case Constants.SEND_BY_EMAIL_LOADING:
      return sendByEmailLoading(state)
    case Constants.SEND_BY_EMAIL_SUCCESS:
      return sendByEmailSuccess(state, action)
    case Constants.SEND_BY_EMAIL_RESET:
      return sendByEmailReset(state)

    case Constants.PUSH_PRESCRIPTION_STATUS_FAILED:
      return pushPrescriptionStatusFailed(state, action)
    case Constants.PUSH_PRESCRIPTION_STATUS_LOADING:
      return pushPrescriptionStatusLoading(state)
    case Constants.PUSH_PRESCRIPTION_STATUS_SUCCESS:
      return pushPrescriptionStatusSuccess(state, action)
    case Constants.PUSH_PRESCRIPTION_STATUS_RESET:
      return pushPrescriptionStatusReset(state, action)

    case Constants.SET_IS_STATUS_SENDED_MANUALLY:
      return setIsStatusSendedManually(state)
    case Constants.SET_IS_STATUS_SENDED_MANUALLY_RESET:
      return setIsStatusSendedManuallyReset(state)

    default:
      return state
  }
}
