import { gql } from '@apollo/client'

export const AUTH_PRESCRIBER = gql`
  query getAuthPrescriber($uid: String!) {
    prescriber(where: { token: { _eq: $uid } }) {
      email
      firstname
      id
      name
      phone_number
      bank_account
      gift_card {
        code
      }
      discount {
        code
        percentage
      }
      bank_account
      enterprise_name
      tva_number
      invoice_address
    }
  }
`

export const INSERT_CALENDLY_CODE = gql`
  mutation UpdatePrescriber($calendlyCode: String!, $id: Int!) {
    update_prescriber(
      where: { id: { _eq: $id } }
      _set: { calendly_code: $calendlyCode }
    ) {
      returning {
        calendly_code
      }
    }
  }
`

export const INSERT_DISCOUNT_CODES = gql`
  mutation InsertDiscountCode(
    $code5: String!
    $percentage5: Int!
    $code10: String!
    $percentage10: Int!
    $code15: String!
    $percentage15: Int!
    $code20: String!
    $percentage20: Int!
    $code25: String!
    $percentage25: Int!
  ) {
    insert_discount(
      objects: [
        { code: $code5, percentage: $percentage5 }
        { code: $code10, percentage: $percentage10 }
        { code: $code15, percentage: $percentage15 }
        { code: $code20, percentage: $percentage20 }
        { code: $code25, percentage: $percentage25 }
      ]
    ) {
      returning {
        code
        percentage
      }
    }
  }
`

export const UPDATE_PRESCRIBER_ONBOARDING = gql`
  mutation UpdatePrescriber(
    $fn: String!
    $ln: String!
    $phone: String!
    $id: Int!
    $discount: String!
  ) {
    update_prescriber(
      where: { id: { _eq: $id } }
      _set: {
        firstname: $fn
        name: $ln
        phone_number: $phone
        active_discount_code: $discount
      }
    ) {
      returning {
        firstname
        name
        phone_number
        discount {
          percentage
          code
        }
      }
    }
  }
`

export const UPDATE_PRESCRIBER = gql`
  mutation UpdatePrescriber(
    $fn: String!
    $ln: String!
    $phone: String!
    $id: Int!
    $discount: String!
    $tvaNumber: String!
    $enterpriseName: String!
    $bankAccount: String!
    $invoiceAddress: String!
  ) {
    update_prescriber(
      where: { id: { _eq: $id } }
      _set: {
        firstname: $fn
        name: $ln
        phone_number: $phone
        active_discount_code: $discount
        tva_number: $tvaNumber
        enterprise_name: $enterpriseName
        bank_account: $bankAccount
        invoice_address: $invoiceAddress
      }
    ) {
      returning {
        firstname
        name
        phone_number
        discount {
          percentage
          code
        }
        bank_account
        enterprise_name
        tva_number
        invoice_address
      }
    }
  }
`
