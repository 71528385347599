import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'
import { ViewMode } from '../../../utils/constants'

export const Container = styled.article`
  display: flex;
  border: 10px solid rgba(255, 255, 255, 0);
  flex-direction: row;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background-color: #fff;
  margin-bottom: 20px;
  padding-right: ${({ $mode }) => ($mode === ViewMode.LIST ? '5px' : 0)};
  padding-bottom: ${({ $mode }) => ($mode === ViewMode.GRID ? '5px' : 0)};
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.LIST ? 'row' : 'column'};
  width: ${({ $mode }) => {
    if ($mode === ViewMode.LIST) {
      return '100%'
    } else if ($mode === ViewMode.GRID) {
      return '32%'
    } else if ($mode === ViewMode.GRID2) {
      return '48%'
    }
  }};
  min-height: 140px;
`

export const Name = styled.h3`
  font-size: ${({ $mode }) => ($mode === ViewMode.LIST ? '22px' : '20px')};
  margin-right: 10px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  flex: 1;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  > div {
    flex: 1;
    box-sizing: border-box;

    &:first-child {
      padding-right: 20px;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 2px;
    opacity: 0.4;
    letter-spacing: 2px;
    display: inline-block;
    white-space: pre-wrap;
  }

  p {
    font-size: 14px;
    white-space: pre-wrap;
    max-width: 500px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  margin-top: ${({ $mode }) => ($mode === ViewMode.GRID ? '20px' : 'none')};
  align-items: flex-end;
`

export const PriceContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'flex-start' : 'flex-end'};
`

export const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 900;
  color: ${({ isDisabled }) => (isDisabled ? '#ddd' : Color.DEEP_SEA)};
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0);

  &:hover {
    transform: scale(1.02);
  }
`

export const ButtonGroup = styled.div`
  display: flex;

  ${Button}:not(:last-child) {
    margin-right: 10px;
  }
`
export const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.LIST ? 'column' : 'column'};
`

export const Image = styled.img`
  width: ${({ $mode }) =>
    ($mode === ViewMode.GRID) | ($mode === ViewMode.GRID2) ? '100%' : '180px'};
  height: ${({ $mode }) =>
    ($mode === ViewMode.GRID) | ($mode === ViewMode.GRID2) ? '130px' : 'auto'};
  object-fit: cover;
  border-radius: 14px;
  background-color: #faf2e7;
  margin-right: 10px;
`

export const SecondaryTitle = styled.h2`
  font-size: 22px;
  margin-bottom: 20px;
`

export const SecondarySubTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`

export const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 50px;
`

export const Price = styled.div`
  display: flex;
  font-size: 14px;

  > strong {
    font-size: ${({ $mode }) => ($mode === ViewMode.GRID ? '24px' : '28px')};

    span {
      font-size: ${({ $mode }) => ($mode === ViewMode.GRID ? '14px' : '16px')};
    }
  }
`

export const Small = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const DialogHeader = styled.div`
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Color.MYSTIC};

  > div:nth-child(1) {
    flex: 1;
    padding-right: 50px;
  }
`

export const PillImageContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ $bg }) => `url("${$bg}")`};
`

export const PillImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`

export const DialogTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DialogSubTitle = styled.h2`
  font-size: 20px;
`

export const DialogBody = styled.div`
  display: flex;
  padding: 50px 0;
`

export const BodyCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const VerticalSeparator = styled.div`
  width: 1px;
  background-color: ${Color.MYSTIC};
  margin: 0 50px;
`

export const StrongPoints = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 20px;
`

export const Point = styled.ul`
  position: relative;
  padding: 0 0 20px 0;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 8px;
    left: -22px;
    background-color: ${Color.MINE_SHAFT};
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 12px;
    left: -19px;
    background-color: ${Color.MINE_SHAFT};
  }

  &:last-child:after {
    display: none;
  }
`

export const PointTitle = styled.p`
  font-size: 18px;
  font-weight: 900;
  white-space: pre-wrap;
`
export const PointText = styled.p`
  font-size: 14px;
  margin-top: 10px;
  white-space: pre-wrap;
`

export const SmallText = styled(Small)`
  margin-bottom: 20px;
  white-space: pre-wrap;
`

export const Title = styled.h1`
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 20px;
`

export const TakeChoice = styled.button`
  cursor: pointer;
  border: 1px solid ${Color.MYSTIC};
  padding: 20px;
  background-color: ${Color.TRANSPARENT};
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  &:hover {
    background-color: ${Color.MYSTIC}55;
  }
`

export const Close = styled.span`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
`

export const MoreInfo = styled.a`
  font-size: 13px;
  cursor: pointer;
  font-weight: 300;
  border-bottom: 1px solid ${Color.DEEP_SEA};
  color: ${Color.DEEP_SEA};
  text-decoration: none;
  white-space: nowrap;
  span {
    margin-left: 5px;
    font-size: 10px;
  }
`
