import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'

import { ActionType } from '../constants'
import {
  addPrescriptionFailed,
  addPrescriptionLoading,
  addPrescriptionSuccess,
  updatePrescriptionFailed,
  updatePrescriptionLoading,
  updatePrescriptionSuccess,
  addPrescriptionTakeFailed,
  addPrescriptionTakeLoading,
  addPrescriptionTakeSuccess,
  deletePrescriptionFailed,
  deletePrescriptionLoading,
  deletePrescriptionSuccess,
  deletePrescriptionTakeLoading,
  deletePrescriptionTakeSuccess,
  deletePrescriptionTakeFailed,
  fetchPrescriptionFailed,
  fetchPrescriptionLoading,
  fetchPrescriptionsFailed,
  fetchPrescriptionsLoading,
  fetchPrescriptionsSuccess,
  fetchPrescriptionSuccess,
  getAddPrescriptionStatus,
  getDeletePrescriptionStatus,
  getFetchPrescriptionsStatus,
  getFetchPrescriptionStatus,
  getPrescriptionById,
  getPrescriptions,
  getPushPrescriptionStatusStatus,
  getSendByEmailStatus,
  getUpdatePrescriptionStatus,
  getDeletePrescriptionTakeStatus,
  getAddPrescriptionTakeStatus,
  pushPrescriptionStatusFailed,
  pushPrescriptionStatusLoading,
  pushPrescriptionStatusReset,
  pushPrescriptionStatusSuccess,
  sendByEmail,
} from '../../store/prescription'
import {
  ADD_PRESCRIPTION,
  PRESCRIBER_PRESCRIPTIONS,
  PUSH_PRESCRIPTION_STATUS,
  PRESCRIPTION,
  DELETE_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  ADD_PRESCRIPTION_TAKE,
  DELETE_PRESCRIPTION_TAKE,
} from '../../services/queries'
import { getAuthUser, getUserToken } from '../../store/auth-user'

export const useAddPrescription = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const addPrescriptionStatus = useSelector(getAddPrescriptionStatus)
  const [
    addPrescription,
    {
      data: prescriptionData,
      loading: prescriptionLoading,
      error: prescriptionError,
    },
  ] = useMutation(ADD_PRESCRIPTION, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync addPrescription with store
   */
  useEffect(() => {
    prescriptionLoading && dispatch(addPrescriptionLoading())
  }, [prescriptionLoading, dispatch])
  useEffect(() => {
    prescriptionData && dispatch(addPrescriptionSuccess(prescriptionData))
  }, [prescriptionData, dispatch])
  useEffect(() => {
    prescriptionError && dispatch(addPrescriptionFailed(prescriptionError))
  }, [prescriptionError, dispatch])

  return {
    addPrescription,
    data: prescriptionData,
    failed: addPrescriptionStatus === ActionType.FAILED,
    loading: addPrescriptionStatus === ActionType.LOADING,
    success: addPrescriptionStatus === ActionType.SUCCESS,
  }
}

export const useDeletePrescription = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const deletePrescriptionStatus = useSelector(getDeletePrescriptionStatus)
  const [
    deletePrescription,
    {
      data: prescriptionData,
      loading: prescriptionLoading,
      error: prescriptionError,
    },
  ] = useMutation(DELETE_PRESCRIPTION, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })
  /**
   * Sync deletePrescription with store
   */
  useEffect(() => {
    prescriptionLoading && dispatch(deletePrescriptionLoading())
  }, [prescriptionLoading, dispatch])
  useEffect(() => {
    prescriptionData && dispatch(deletePrescriptionSuccess(prescriptionData))
  }, [prescriptionData, dispatch])
  useEffect(() => {
    prescriptionError && dispatch(deletePrescriptionFailed(prescriptionError))
  }, [prescriptionError, dispatch])
  return {
    deletePrescription,
    data: prescriptionData,
    failed: deletePrescriptionStatus === ActionType.FAILED,
    loading: deletePrescriptionStatus === ActionType.LOADING,
    success: deletePrescriptionStatus === ActionType.SUCCESS,
  }
}

export const useDeletePrescriptionTake = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const deletePrescriptionTakeStatus = useSelector(
    getDeletePrescriptionTakeStatus
  )
  const [
    deletePrescriptionTake,
    {
      data: prescriptionTakeData,
      loading: prescriptionTakeLoading,
      error: prescriptionTakeError,
    },
  ] = useMutation(DELETE_PRESCRIPTION_TAKE, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync deletePrescriptionTake with store
   */
  useEffect(() => {
    prescriptionTakeLoading && dispatch(deletePrescriptionTakeLoading())
  }, [prescriptionTakeLoading, dispatch])
  useEffect(() => {
    prescriptionTakeData &&
      dispatch(deletePrescriptionTakeSuccess(prescriptionTakeData))
  }, [prescriptionTakeData, dispatch])
  useEffect(() => {
    prescriptionTakeError &&
      dispatch(deletePrescriptionTakeFailed(prescriptionTakeError))
  }, [prescriptionTakeError, dispatch])
  return {
    deletePrescriptionTake,
    data: prescriptionTakeData,
    failed: deletePrescriptionTakeStatus === ActionType.FAILED,
    loading: deletePrescriptionTakeStatus === ActionType.LOADING,
    success: deletePrescriptionTakeStatus === ActionType.SUCCESS,
  }
}

export const useUpdatePrescription = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const updatePrescriptionStatus = useSelector(getUpdatePrescriptionStatus)
  const [
    updatePrescription,
    {
      data: prescriptionData,
      loading: prescriptionLoading,
      error: prescriptionError,
    },
  ] = useMutation(UPDATE_PRESCRIPTION, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync UPDATE_PRESCRIPTION with store
   */
  useEffect(() => {
    prescriptionLoading && dispatch(updatePrescriptionLoading())
  }, [prescriptionLoading, dispatch])
  useEffect(() => {
    prescriptionData && dispatch(updatePrescriptionSuccess(prescriptionData))
  }, [prescriptionData, dispatch])
  useEffect(() => {
    prescriptionError && dispatch(updatePrescriptionFailed(prescriptionError))
  }, [prescriptionError, dispatch])

  return {
    updatePrescription,
    data: prescriptionData,
    failed: updatePrescriptionStatus === ActionType.FAILED,
    loading: updatePrescriptionStatus === ActionType.LOADING,
    success: updatePrescriptionStatus === ActionType.SUCCESS,
  }
}

export const useAddPrescriptionTake = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const addPrescriptionTakeStatus = useSelector(getAddPrescriptionTakeStatus)
  const [
    addPrescriptionTakes,
    {
      data: prescriptionTakeData,
      loading: prescriptionTakeLoading,
      error: prescriptionTakeError,
    },
  ] = useMutation(ADD_PRESCRIPTION_TAKE, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync ADD_PRESCRIPTION_TAKE with store
   */
  useEffect(() => {
    prescriptionTakeLoading && dispatch(addPrescriptionTakeLoading())
  }, [prescriptionTakeLoading, dispatch])
  useEffect(() => {
    prescriptionTakeData &&
      dispatch(addPrescriptionTakeSuccess(prescriptionTakeData))
  }, [prescriptionTakeData, dispatch])
  useEffect(() => {
    prescriptionTakeError &&
      dispatch(addPrescriptionTakeFailed(prescriptionTakeError))
  }, [prescriptionTakeError, dispatch])

  return {
    addPrescriptionTakes,
    data: prescriptionTakeData,
    failed: addPrescriptionTakeStatus === ActionType.FAILED,
    loading: addPrescriptionTakeStatus === ActionType.LOADING,
    success: addPrescriptionTakeStatus === ActionType.SUCCESS,
  }
}

export const usePrescriptions = () => {
  const dispatch = useDispatch()
  const fetchPrescriptionsStatus = useSelector(getFetchPrescriptionsStatus)
  const prescriptions = useSelector(getPrescriptions)
  const authUser = useSelector(getAuthUser)
  const authToken = useSelector(getUserToken)
  const [
    fetchPrescriptions,
    {
      data: prescriptionsData,
      loading: prescriptionsLoading,
      error: prescriptionsError,
    },
  ] = useLazyQuery(PRESCRIBER_PRESCRIPTIONS, {
    variables: { prescriberId: authUser.id },
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    fetchPrescriptionsStatus !== ActionType.SUCCESS &&
      fetchPrescriptionsStatus !== ActionType.FAILED &&
      fetchPrescriptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    prescriptionsLoading &&
      fetchPrescriptionsStatus !== ActionType.LOADING &&
      dispatch(fetchPrescriptionsLoading())
  }, [prescriptionsLoading, dispatch, fetchPrescriptionsStatus])
  useEffect(() => {
    prescriptionsData &&
      fetchPrescriptionsStatus !== ActionType.SUCCESS &&
      dispatch(fetchPrescriptionsSuccess(prescriptionsData))
  }, [prescriptionsData, dispatch, fetchPrescriptionsStatus])
  useEffect(() => {
    prescriptionsError &&
      fetchPrescriptionsStatus !== ActionType.FAILED &&
      dispatch(fetchPrescriptionsFailed(prescriptionsError))
  }, [prescriptionsError, dispatch, fetchPrescriptionsStatus])
  return {
    data: prescriptions,
    failed: fetchPrescriptionsStatus === ActionType.FAILED,
    loading: fetchPrescriptionsStatus === ActionType.LOADING,
    success: fetchPrescriptionsStatus === ActionType.SUCCESS,
  }
}

export const useSendPrescription = () => {
  const dispatch = useDispatch()
  const sendByEmailStatus = useSelector(getSendByEmailStatus)

  return {
    sendPrescription: data => dispatch(sendByEmail(data)),
    failed: sendByEmailStatus === ActionType.FAILED,
    loading: sendByEmailStatus === ActionType.LOADING,
    success: sendByEmailStatus === ActionType.SUCCESS,
  }
}

export const usePushPrescriptionStatus = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const pushPrescriptionStatusStatus = useSelector(
    getPushPrescriptionStatusStatus
  )
  const [pushStatus, { data, loading, error }] = useMutation(
    PUSH_PRESCRIPTION_STATUS,
    {
      context: { headers: { Authorization: `Bearer ${authToken}` } },
    }
  )

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    loading && dispatch(pushPrescriptionStatusLoading())
  }, [loading, dispatch])
  useEffect(() => {
    data && dispatch(pushPrescriptionStatusSuccess(data))
  }, [data, dispatch])
  useEffect(() => {
    error && dispatch(pushPrescriptionStatusFailed(error))
  }, [error, dispatch])

  return {
    data,
    failed: pushPrescriptionStatusStatus === ActionType.FAILED,
    loading: pushPrescriptionStatusStatus === ActionType.LOADING,
    pushStatus: (prescriptionId, prescriptionTakes, status) =>
      pushStatus({
        variables: {
          prescriptionId,
          prescriptionTakes: {
            data: prescriptionTakes.map(take => ({
              index: take.index,
              label: take.label,
              type: take.type,
              prescription_items: {
                data: take.items.map(item => ({
                  product_handle: item.productHandle,
                  quantity: item.quantity,
                  notes: item.notes,
                })),
              },
            })),
          },
          status,
        },
      }),
    reset: () => dispatch(pushPrescriptionStatusReset()),
    success: pushPrescriptionStatusStatus === ActionType.SUCCESS,
  }
}

/**
 * @param {number} id Prescription id
 */
export const usePrescription = id => {
  const dispatch = useDispatch()
  const fetchPrescriptionStatus = useSelector(getFetchPrescriptionStatus)
  const prescription = useSelector(state => getPrescriptionById(state, id))
  const [
    fetchPrescription,
    {
      data: prescriptionData,
      loading: prescriptionLoading,
      error: prescriptionError,
    },
  ] = useLazyQuery(PRESCRIPTION, {
    variables: { id },
  })

  useEffect(() => {
    !prescription &&
      fetchPrescriptionStatus !== ActionType.LOADING &&
      fetchPrescription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    prescriptionLoading && dispatch(fetchPrescriptionLoading())
  }, [prescriptionLoading, dispatch])
  useEffect(() => {
    prescriptionData && dispatch(fetchPrescriptionSuccess(prescriptionData))
  }, [prescriptionData, dispatch])
  useEffect(() => {
    prescriptionError && dispatch(fetchPrescriptionFailed(prescriptionError))
  }, [prescriptionError, dispatch])

  return {
    data: prescription,
    failed: fetchPrescriptionStatus === ActionType.FAILED,
    loading: fetchPrescriptionStatus === ActionType.LOADING,
    success: fetchPrescriptionStatus === ActionType.SUCCESS,
  }
}
