import { Link } from 'react-router-dom'

import NakedLayout from '../../components/layout/naked'
import LoginForm from '../../components/auth/login-form'
import { Wrapper, Title, FooterText } from './style'

const LoginPage = ({ location }) => (
  <NakedLayout>
    <Wrapper>
      <Title>Login</Title>
      <LoginForm />
      <FooterText>
        Pas encore de compte hh?{' '}
        <Link
          to={{
            pathname: '/register',
            search: location.search,
          }}
        >
          Créez un nouveau compte
        </Link>
      </FooterText>
    </Wrapper>
  </NakedLayout>
)

export default LoginPage
