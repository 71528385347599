import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { FaRegCopy, FaCheck } from 'react-icons/fa'

import { Button, Spinner, Icon, Tooltip } from '../../../components/ui'
import {
  ActionSection,
  BodySection,
  Container,
  Content,
  HeaderSection,
  MainTitle,
  Note,
  P,
  Pane,
  RecoLink,
  ButtonWrapper,
  RecommandationPageHeader,
  SecondaryTitle,
  RecoContainer,
} from './style'
import { BreadCrumbsContainer } from '../../patients/patient/style'
import Layout from '../../../components/layout/default'
import PatientTile from '../../../components/patient/patient-tile'
import PrescriptionStatusDetailed from '../../../components/prescription/prescription-status-detailed'
import {
  getPrescriptionPrice,
  getPrescriptionById,
  getPrescriptionRecoLink,
  sendByEmailReset,
  getIsStatusSendedManually,
  setIsStatusSendedManuallyReset,
  setPrescriptionToDraft,
} from '../../../store/prescription'
import { getPatientById } from '../../../store/patient'
import { getCart } from '../../../store/cart'
import { getGiftCard } from '../../../store/auth-user'
import {
  usePatients,
  usePrescriptions,
  useSendPrescription,
  useAuthPrescriber,
  usePushPrescriptionStatus,
  useDeletePrescription,
  useProducts,
  useInitCart,
} from '../../../utils/hooks'
import {
  getRecommendationDurationDisplay,
  getPrescriptionGain,
} from '../../../utils/helpers'

import PrescriptionProductsList from '../../../components/prescription/prescription-products-list'
import DilaogCreateOrderForCustomer from '../../../components/prescription/dilaog-create-order-for-customer'

const lineItemsForCart = (takes, productList, recommendedDuration) => {
  if (!takes.length || !productList.length) return

  let standAloneTake = []
  let inCureTakesArray = []

  const standAloneTakefind = takes.find(take => take.isStandAlone)
  if (standAloneTakefind) {
    standAloneTake = standAloneTakefind.items
  }

  let inCureTakes = takes.filter(take => !take.isStandAlone)
  inCureTakes = inCureTakes.map(item => {
    return item.items.map(i => {
      return {
        ...i,
        id: i.id,
        notes: i.notes,
        productHandle: i.productHandle,
        quantity: i.quantity,
        index: item.index,
      }
    })
  })

  inCureTakes.map(i => i.map(i => inCureTakesArray.push(i)))

  const takesArray = [...standAloneTake, ...inCureTakesArray]

  return takesArray.map(item => {
    const productId = productList
      .find(p => p.handle === item.productHandle)
      ?.getVariantId(recommendedDuration)

    if (!productId) {
      // TODO: send Sentry error
      console.warn('Product not found', item.productHandle)
      return item
    }
    let attributes = []
    if (item?.productHandle)
      attributes.push({ key: 'handle', value: `${item.productHandle}` })
    if (item?.index) attributes.push({ key: 'prise', value: `${item.index}` })
    if (item?.notes) attributes.push({ key: 'notes', value: `${item.notes}` })

    return {
      quantity: item.quantity,
      merchandiseId: productId,
      attributes: attributes,
    }
  })
}

const PrescriptionPage = ({ history, match }) => {
  const dispatch = useDispatch()
  const [isDilaogCreateOrderForCustomerOpen, setDilaogCreateOrderForCustomer] =
    useState(false)
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const { loading: patientsLoading, success: patientsSuccess } = usePatients()
  const {
    data: products,
    loading: productsLoading,
    success: productsSuccess,
  } = useProducts()
  const prescriptionId = Number(match.params.prescriptionId)
  const { loading: prescriptionsLoading, success: prescriptionsSuccess } =
    usePrescriptions()
  const prescription = useSelector(state =>
    getPrescriptionById(state, Number(match.params.id))
  )
  const discountCode = prescription?.discountCode
  const prescriptionPrice = useSelector(state =>
    getPrescriptionPrice(state, prescription?.id)
  )
  const prescriptionRecoLink = useSelector(state =>
    getPrescriptionRecoLink(state, prescription?.id)
  )
  const IsStatusSendedManually = useSelector(state =>
    getIsStatusSendedManually(state)
  )
  const patient = useSelector(state =>
    getPatientById(state, prescription?.patientId)
  )
  const { authUser } = useAuthPrescriber()
  const {
    sendPrescription,
    loading: sendPrescriptionEmailLoading,
    success: sendPrescriptionEmailSuccess,
    failed: sendPrescriptionEmailError,
  } = useSendPrescription()
  const {
    failed: pushPrescriptionStatusError,
    loading: pushPrescriptionStatusLoading,
    pushStatus,
    reset,
    success: pushPrescriptionStatusSuccess,
  } = usePushPrescriptionStatus()
  const {
    success: deletePrescriptionSuccess,
    loading: deletePrescriptionLoading,
    failed: deletePrescriptionFailed,
    deletePrescription,
  } = useDeletePrescription()
  const { createCart } = useInitCart()

  let lineItems = []

  lineItems = lineItemsForCart(
    prescription?.lastStatus.takes || [],
    products,
    prescription?.recommendedDuration
  )

  const cart = useSelector(state => getCart(state))
  const giftCard = useSelector(state => getGiftCard(state))
  let checkoutUrl = prescription && giftCard && lineItems && cart?.checkoutUrl
  if (discountCode) {
    checkoutUrl += `?discount=${discountCode}`
  } else {
    checkoutUrl += `?attributes[prescription_id]=${prescriptionId}`
  }

  useEffect(() => {
    !!lineItems?.length &&
      productsSuccess &&
      createCart({
        variables: {
          cartInput: {
            buyerIdentity: {
              email: 'tanguydeherdt@gmail.com',
            },
            lines: lineItems,
            attributes: {
              key: 'prescription_id',
              value: `${prescriptionId}`,
            },
          },
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLoading])

  useEffect(() => {
    if (!sendPrescriptionEmailLoading && sendPrescriptionEmailError) {
      toast.error("Une erreur est survenue lors de l'envoi de l'email.")
    } else if (!sendPrescriptionEmailLoading && sendPrescriptionEmailSuccess) {
      pushStatus(prescription?.id, prescription.lastStatus.takes, 'SENT')
      dispatch(sendByEmailReset())
      reset()
    }
  }, [
    dispatch,
    patient,
    prescription,
    pushStatus,
    sendPrescriptionEmailError,
    sendPrescriptionEmailLoading,
    sendPrescriptionEmailSuccess,
    reset,
  ])

  useEffect(() => {
    !pushPrescriptionStatusLoading &&
      pushPrescriptionStatusSuccess &&
      toast.success(
        IsStatusSendedManually
          ? 'Le status a bien été marqué comme "envoyé"'
          : `L'email a été envoyé à ${patient?.email} avec succès !`
      )
  }, [
    IsStatusSendedManually,
    patient?.email,
    pushPrescriptionStatusLoading,
    pushPrescriptionStatusSuccess,
  ])

  useEffect(() => {
    if (pushPrescriptionStatusError) {
      // TODO: send Sentry error
      toast.error(
        'Une erreur est survenue lors de la mise à jours sur status de la prescription.'
      )
    }
  }, [pushPrescriptionStatusError])

  useEffect(() => {
    if (deletePrescriptionSuccess) {
      history.push('/prescriptions/')
    }
    if (deletePrescriptionFailed) {
      toast.error(
        `Il y a eu une erreur dans la supression de la prescription, merci de réessayer.`
      )
    }
  }, [deletePrescriptionFailed, history, deletePrescriptionSuccess])

  useEffect(() => {
    return () => {
      reset()
      dispatch(setIsStatusSendedManuallyReset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopyToClipBoard = (copiedValue, isCopied) => {
    if (isCopied) {
      setIsLinkCopied(true)
      setTimeout(() => setIsLinkCopied(false), 3000)
    } else {
      setIsLinkCopied(false)
      toast.error('Impossible de copier le lien dans votre presse papier')
    }
  }

  const sendEmailData = {
    patient_email: patient?.email,
    patient_firstname: patient?.firstname,
    patient_phonenumber: patient?.phone_number || undefined,
    custom_message: prescription?.customMessage,
    prescriber_email: authUser?.email,
    prescriber_fullname: `${authUser?.firstName} ${authUser?.lastName}`,
    purchase_url: prescriptionRecoLink,
    promo_code: prescription?.discountCode,
    discount: prescription?.discountPercentage,
  }

  const onDeletePrescription = prescription => {
    deletePrescription({ variables: { id: prescription.id } })
  }

  return (
    <Layout>
      <Container>
        <Content>
          <RecommandationPageHeader>
            <BreadCrumbsContainer>
              <Link to='/prescriptions'>Recommandations</Link>
              <Icon name='angle-right' />
              <span>
                Recommandation pour {patient?.firstname} {patient?.name}
              </span>
            </BreadCrumbsContainer>
            <ButtonWrapper>
              {prescription && prescription.lastStatus.name === 'CREATED' && (
                <Button
                  onClick={() => dispatch(setPrescriptionToDraft(prescription))}
                  appearance='primary'
                  style={{ marginRight: '.5em', textTransform: 'none' }}
                  isDisabled={!prescription}
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: '?step=2&prescriptionId=' + prescription.id,
                  }}
                >
                  Modifier
                </Button>
              )}
              <Button
                onClick={() => onDeletePrescription(prescription)}
                appearance={'red'}
                isDisabled={!prescription}
                isLoading={deletePrescriptionLoading}
              >
                Supprimer
              </Button>
            </ButtonWrapper>
          </RecommandationPageHeader>
          {(patientsLoading || prescriptionsLoading || !prescription) && (
            <Spinner />
          )}
          {prescriptionsSuccess && patientsSuccess && prescription && (
            <>
              <HeaderSection>
                <MainTitle>
                  Détails de la recommandation de {patient?.firstname}
                </MainTitle>
              </HeaderSection>
              <BodySection>
                <Pane>
                  <PatientTile patient={patient} />
                  <PrescriptionStatusDetailed
                    match={match}
                    statuses={prescription?.statuses}
                  />
                </Pane>
                <SecondaryTitle>Lien de recommandation</SecondaryTitle>
                <RecoContainer>
                  <RecoLink>{prescriptionRecoLink}</RecoLink>
                  <CopyToClipboard
                    text={prescriptionRecoLink}
                    onCopy={handleCopyToClipBoard}
                  >
                    <Tooltip
                      size='l'
                      label={
                        isLinkCopied
                          ? `Copié !`
                          : 'Copier dans le presse papier'
                      }
                    >
                      <Button appearance='minimal'>
                        {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                  <Button
                    onClick={() => {
                      window.open(prescriptionRecoLink, '_blank')
                    }}
                    appearance='minimal'
                  >
                    Ouvrir
                  </Button>
                </RecoContainer>
                <PrescriptionProductsList
                  takes={
                    prescription.lastStatus.takes?.length > 0
                      ? prescription.lastStatus.takes
                      : prescription.statuses[0].takes
                  }
                  prescriptionRecommendedDuration={
                    prescription.recommendedDuration
                  }
                />
                <SecondaryTitle>La durée recommandée</SecondaryTitle>
                {getRecommendationDurationDisplay(
                  prescription.recommendedDuration
                )}
                <SecondaryTitle>Message personnel</SecondaryTitle>
                <Note>{prescription.customMessage || '-'}</Note>
                <SecondaryTitle>Le prix</SecondaryTitle>
                <P>
                  €
                  {(Number(prescriptionPrice) / prescription.duration).toFixed(
                    2
                  )}{' '}
                  par mois
                </P>
                <P>
                  Soit €{prescriptionPrice} pour{' '}
                  {getRecommendationDurationDisplay(
                    prescription.recommendedDuration
                  )}
                </P>
                <P>
                  {`Vous gagnez €${getPrescriptionGain(
                    authUser.discountPercentage,
                    prescriptionPrice
                  )}`}
                </P>
              </BodySection>
              <ActionSection>
                <Button
                  appearance='minimal'
                  onClick={() => history.push('/prescriptions')}
                >
                  Retour
                </Button>
                <DilaogCreateOrderForCustomer
                  isDilaogCreateOrderForCustomerOpen={
                    isDilaogCreateOrderForCustomerOpen
                  }
                  setDilaogCreateOrderForCustomer={
                    setDilaogCreateOrderForCustomer
                  }
                  giftCard={giftCard}
                  checkoutUrl={checkoutUrl}
                />
                <Button
                  style={{
                    margin: '0 15px',
                  }}
                  onClick={() => setDilaogCreateOrderForCustomer(true)}
                >
                  Commander pour mon patient{' '}
                  <Tooltip
                    size='l'
                    label='En confirmant la commande pour votre patient, vous acceptez que Compliment vous facture la montant de la commande. Il vous faudra entrer les coordonnées de livraison pour votre patient'
                  >
                    <Icon name='info' />
                  </Tooltip>
                </Button>

                <Button
                  onClick={() => {
                    prescription?.lastStatus?.name === 'CREATED' &&
                      sendPrescription(sendEmailData)
                  }}
                  isDisabled={
                    pushPrescriptionStatusSuccess ||
                    prescription?.lastStatus?.name !== 'CREATED'
                  }
                  isLoading={
                    sendPrescriptionEmailLoading ||
                    pushPrescriptionStatusLoading
                  }
                  appearance='primary'
                >
                  Envoyer
                </Button>
              </ActionSection>
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default PrescriptionPage
