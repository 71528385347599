import styled from 'styled-components/macro'

import { MediaQuery } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${MediaQuery.M_AND_DOWN} {
    padding: 30px 20px;
  }
`
