import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const SelectInputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const SelectProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -5px;
`
