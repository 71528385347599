import styled from 'styled-components/macro'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 20px;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableHead = styled.thead`
  border-bottom: 1px solid black;
`

export const TableBody = styled.tbody``

export const Cell = styled.td`
  flex-grow: 1;
  width: ${({ width }) => width || 'auto'};
`

export const Row = styled.tr`
  width: 100%;

  ${Cell}:nth-child(4) {
    max-width: 100px;
  }
`

export const HeadRow = styled(Row)`
  ${Cell} {
    padding: 10px 0;
  }
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`
