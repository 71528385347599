import Take from './Take'
import Item from './Item'

const getDisplayMonth = MonthNumber =>
  [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ][MonthNumber]

class ProtocoleTake extends Take {
  get items() {
    return this.data.protocole_items.map(item => new Item(item))
  }
}

class Protocole {
  data = null

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get description() {
    return this.data.description
  }

  get share() {
    return this.data.share
  }

  get name() {
    return this.data.name
  }

  get prescriberId() {
    return this.data.prescriber_id
  }

  get createdAt() {
    return this.data.created_at
  }

  get duration() {
    return Number(this.data.recommended_duration.split('-')[0])
  }

  get displayTime() {
    const d = new Date(this.data.created_at)
    const day = d.getDate().toString().padStart(2, '0')
    const month = getDisplayMonth(d.getMonth())
    const year = d.getFullYear()

    return `${day} ${month} ${year}`
  }

  get customMessage() {
    return this.data.custom_message
  }

  get recommendedDuration() {
    return this.data.recommended_duration
  }

  get takes() {
    return this.data.protocole_takes.map(take => new ProtocoleTake(take))
  }

  get prescriberName() {
    return this.data.prescriber.name
  }
}

export default Protocole
