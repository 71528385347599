import styled from 'styled-components/macro'

import { Color } from '../../utils/constants'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  padding: 25px;
  border-radius: 3px;
`

export const Title = styled.h1`
  font-size: 22px;
  text-align: center;
`

export const FooterText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  max-width: 280px;

  > a {
    color: ${Color.DEEP_SEA};
    text-decoration: none;
  }
`

export const SuccessRegistrationContainer = styled.div`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  font-size: 22px;
  line-height: 28px;
  max-width: 450px;
  text-align: center;

  > a {
    color: inherit;
  }
`
