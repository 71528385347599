import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  margin: auto;
  align-items: flex-start;

  > button {
    margin-top: 20px;
  }
`
