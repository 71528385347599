import React from 'react'
import PropTypes from 'prop-types'

import { Success, Info, Warning, Done } from './style'

const getComponent = type =>
  ({
    created: Info,
    sent: Warning,
    ordered: Success,
    done: Done,
    modified: Warning,
  }[type])

const getDisplayText = type =>
  ({
    created: 'Créée',
    sent: 'Envoyée',
    modified: 'Modifiée',
    ordered: 'Achetée',
    done: 'Finie',
  }[type])

const PrescriptionStatus = ({ status }) => {
  let content = 'done'
  let Component = getComponent(content)

  if (!status) return <Component>{getDisplayText(content)}</Component>

  const statusName = status.name.trim().toLowerCase()
  if (['created', 'sent', 'modified', 'ordered', 'done'].includes(statusName)) {
    content = statusName
  }
  Component = getComponent(content)

  return <Component>{getDisplayText(content)}</Component>
}

PrescriptionStatus.defaultProps = {
  status: [],
}

PrescriptionStatus.propTypes = {
  status: PropTypes.shape({
    created: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default PrescriptionStatus
