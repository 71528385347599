import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`

export const Content = styled(Container)`
  display: flex;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-width: 300px;
  padding: 10px;
`

export const IllustrationContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 15px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin-left: 10%;

  > button {
    margin-top: 20px;
  }
`

export const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 20px;
`
