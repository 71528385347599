import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  Wrapper,
  FooterText,
  Title,
  SuccessRegistrationContainer,
} from './style'
import NakedLayout from '../../components/layout/naked'
import RegisterForm from '../../components/auth/register-form'
import { getRegisterStatus } from '../../store/auth-user'
import { ActionType } from '../../utils/constants'

const RegisterPage = ({ location }) => {
  const registerStatus = useSelector(getRegisterStatus)

  return (
    <NakedLayout>
      <Wrapper>
        {registerStatus !== ActionType.SUCCESS ? (
          <>
            <Title>Créer un nouveau compte</Title>
            <RegisterForm />
            <FooterText>
              Déjà un compte ?{' '}
              <Link
                to={{
                  pathname: '/login',
                  search: location.search,
                }}
              >
                Connectez-vous
              </Link>
            </FooterText>
          </>
        ) : (
          <SuccessRegistrationContainer>
            🙌
            <br />
            Votre compte a été créé avec succès <br />
            <br />
            Vérifiez vos emails afin de confirmer votre email et connectez vous
            ensuite.
            <br />
            <br />
            <Link
              to={{
                pathname: '/login',
                search: location.search,
              }}
            >
              Se connecter
            </Link>
          </SuccessRegistrationContainer>
        )}
      </Wrapper>
    </NakedLayout>
  )
}

export default RegisterPage
