import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.article`
  display: flex;
  width: 100%;
  padding: 10px 0;
  align-items: flex-start;
  position: relative;
`

export const Img = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10px;
  background-color: #faf2e7;
  margin-right: 20px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Info = styled.div`
  display: flex;
  min-height: 40px;
`
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`
export const Right = styled(Left)`
  flex: 0 1 auto;
`

export const Name = styled.h6`
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 5px;
`

export const Price = styled.p`
  font-size: 26px;
  text-align: right;

  > small {
    font-size: 14px;
  }
`

export const P = styled.p`
  font-size: 12px;
`

export const NotesContainer = styled.div`
  width: 100%;
  margin-top: 10px;

  > ${P} {
    padding: 10px;
    background-color: ${Color.MYSTIC};
    border-radius: 10px;
  }
`

export const Quantity = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`

export const Minus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.MYSTIC};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    border-bottom: 2px solid black;
    width: 6px;
    border-radius: 5px;
  }
`

export const Plus = styled(Minus)`
  &:before {
    content: '';
    border-left: 2px solid black;
    height: 6px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }
`

export const Number = styled.span`
  display: inline-flex;
  justify-content: center;
  min-width: 30px;
`
export const MoreInfo = styled.span`
  font-weight: 300;
  font-size: 10px;
  cursor: pointer;
  margin-left: 5px;
`

export const Remvove = styled.span`
  cursor: pointer;
  margin-left: 15px;
  margin-top: 20px;
`
