import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'

import { ActionType } from '../constants'
import {
  addPatientFailed,
  addPatientLoading,
  addPatientSuccess,
  deletePatientSuccess,
  deletePatientFailed,
  deletePatientLoading,
  editPatientFailed,
  editPatientLoading,
  editPatientReset,
  editPatientSuccess,
  fetchPatientsFailed,
  fetchPatientsLoading,
  fetchPatientsSuccess,
  getAddPatientStatus,
  getDeletePatientStatus,
  getEditPatientStatus,
  getFetchPatientsStatus,
  getPatients,
} from '../../store/patient'
import { getAuthUser, getUserToken } from '../../store/auth-user'
import {
  ADD_PATIENT,
  DELETE_PATIENT,
  PRESCRIBER_PATIENTS,
  UPDATE_PATIENT,
} from '../../services/queries'

export const useAddPatient = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const addPatientStatus = useSelector(getAddPatientStatus)
  const [
    addPatient,
    { data: patientData, loading: patientLoading, error: patientError },
  ] = useMutation(ADD_PATIENT, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    patientLoading && dispatch(addPatientLoading())
  }, [patientLoading, dispatch])
  useEffect(() => {
    patientData && dispatch(addPatientSuccess(patientData))
  }, [patientData, dispatch])
  useEffect(() => {
    patientError && dispatch(addPatientFailed(patientError))
  }, [patientError, dispatch])

  return {
    addPatient,
    data: patientData,
    failed: addPatientStatus === ActionType.FAILED,
    loading: addPatientStatus === ActionType.LOADING,
    success: addPatientStatus === ActionType.SUCCESS,
  }
}

export const usePatients = () => {
  const dispatch = useDispatch()
  const authUser = useSelector(getAuthUser)
  const authToken = useSelector(getUserToken)
  const fetchPatientsStatus = useSelector(getFetchPatientsStatus)
  const patients = useSelector(getPatients)
  const [
    fetchPatients,
    { data: patientsData, loading: patientsLoading, error: patientsError },
  ] = useLazyQuery(PRESCRIBER_PATIENTS, {
    variables: { prescriberId: authUser.id },
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    !patients.length &&
      fetchPatientsStatus !== ActionType.LOADING &&
      fetchPatientsStatus !== ActionType.FAILED &&
      fetchPatients()
  }, [fetchPatients, fetchPatientsStatus, patients.length])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    patientsLoading &&
      fetchPatientsStatus !== ActionType.LOADING &&
      dispatch(fetchPatientsLoading())
  }, [patientsLoading, dispatch, fetchPatientsStatus])
  useEffect(() => {
    patientsData &&
      fetchPatientsStatus !== ActionType.SUCCESS &&
      dispatch(fetchPatientsSuccess(patientsData))
  }, [patientsData, dispatch, fetchPatientsStatus])
  useEffect(() => {
    patientsError &&
      fetchPatientsStatus !== ActionType.FAILED &&
      dispatch(fetchPatientsFailed(patientsError))
  }, [patientsError, dispatch, fetchPatientsStatus])

  return {
    data: patients,
    failed: fetchPatientsStatus === ActionType.FAILED,
    loading: fetchPatientsStatus === ActionType.LOADING,
    success: fetchPatientsStatus === ActionType.SUCCESS,
  }
}

export const useEditPatient = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const editPatientStatus = useSelector(getEditPatientStatus)
  const [
    editPatient,
    { data: patientData, loading: patientLoading, error: patientError },
  ] = useMutation(UPDATE_PATIENT, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync useEditPatient with store
   */
  useEffect(() => {
    patientLoading && dispatch(editPatientLoading())
  }, [patientLoading, dispatch])
  useEffect(() => {
    patientData && dispatch(editPatientSuccess(patientData))
  }, [patientData, dispatch])
  useEffect(() => {
    patientError && dispatch(editPatientFailed(patientError))
  }, [patientError, dispatch])

  return {
    data: patientData,
    editPatient,
    reset: () => dispatch(editPatientReset()),
    failed: editPatientStatus === ActionType.FAILED,
    loading: editPatientStatus === ActionType.LOADING,
    success: editPatientStatus === ActionType.SUCCESS,
  }
}

export const useDeletePatient = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const deletePatientStatus = useSelector(getDeletePatientStatus)
  const [
    deletePatient,
    { data: patientData, loading: patientLoading, error: patientError },
  ] = useMutation(DELETE_PATIENT, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync useDeletePatient with store
   */
  useEffect(() => {
    patientLoading && dispatch(deletePatientLoading())
  }, [patientLoading, dispatch])
  useEffect(() => {
    patientData && dispatch(deletePatientSuccess(patientData))
  }, [patientData, dispatch])
  useEffect(() => {
    patientError && dispatch(deletePatientFailed(patientError))
  }, [patientError, dispatch])
  return {
    deletePatient,
    data: patientData,
    loading: deletePatientStatus === ActionType.LOADING,
    failed: deletePatientStatus === ActionType.FAILED,
    success: deletePatientSuccess === ActionType.SUCCESS,
  }
}
