import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import queryString from 'query-string'

import {
  setPrescriptionToDraft,
  resetDeletePrescription,
} from '../../../store/prescription'
import FilterBar from '../../filter-bar'
import Dashboard from '../../dashboard'
import {
  Section,
  Header,
  Table,
  HeadRow,
  Cell,
  Row,
  TableHead,
  TableBody,
  MenuContainer,
} from './style'
import { Spinner, Dropdown, NavLink, Button } from '../../ui'
import PatientCell from './patient-cell'
import PrescriptionStatus from '../prescription-status'
import {
  usePrescriptions,
  usePatients,
  useDeletePrescription,
  useDispatch,
} from '../../../utils/hooks'

const PrescriptionsContainer = ({
  location,
  headerCta,
  patientId,
  showFilterBar,
  setShowFilterBar,
  showDashboard,
}) => {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(23, 59, 59, 0))
  )
  const [filters, setFilters] = useState([])
  const [sortedPrescriptions, setSortedPrescriptions] = useState([])
  const {
    data: prescriptions,
    loading: prescriptionsLoading,
    success: prescriptionsSuccess,
  } = usePrescriptions()
  const { loading: patientsLoading } = usePatients()

  useEffect(() => {
    if (location) {
      const { minDate, maxDate, status } = queryString.parse(location.search)
      if (status && ['sent', 'created', 'ordered', 'done'].includes(status)) {
        setFilters(prevFilters => [...prevFilters, status])
        setShowFilterBar(true)
      }
      if (maxDate && new Date(maxDate) <= new Date().getTime()) {
        setEndDate(new Date(new Date(maxDate).setHours(23, 59, 59, 0)))
      }
      if (minDate && new Date(minDate) <= new Date().getTime()) {
        setStartDate(new Date(new Date(minDate).setHours(0, 0, 0, 0)))
      }
    }
  }, [location, setShowFilterBar, setFilters])

  useEffect(() => {
    if (filters.length === 0) {
      let filteredPrescriptions = prescriptions
      if (patientId) {
        filteredPrescriptions = filteredPrescriptions.filter(
          p => p.patientId === patientId
        )
      }
      setSortedPrescriptions(
        filteredPrescriptions.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      )
    }
  }, [patientId, filters.length, prescriptions])

  useEffect(() => {
    if (!patientId) {
      let filteredPrescriptions = prescriptions
      if (filters.length > 0) {
        filteredPrescriptions = filteredPrescriptions.filter(p =>
          filters.includes(p.lastStatus.name.toLowerCase())
        )
        setSortedPrescriptions(
          filteredPrescriptions.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        )
      }

      if (
        startDate &&
        endDate.getTime() >= startDate.getTime() &&
        showFilterBar
      ) {
        filteredPrescriptions = filteredPrescriptions.filter(
          p =>
            new Date(p.createdAt).getTime() >= startDate.getTime() &&
            new Date(p.createdAt).getTime() <= endDate.getTime()
        )
      }
      if (!startDate && endDate && showFilterBar) {
        filteredPrescriptions = filteredPrescriptions.filter(
          p => new Date(p.createdAt).getTime() <= endDate.getTime()
        )
      }

      setSortedPrescriptions(
        filteredPrescriptions.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      )
    }
  }, [patientId, prescriptions, filters, startDate, endDate, showFilterBar])

  const handleOnTick = (content, isTicked, setIsTicked) => {
    setIsTicked(!isTicked)
    if (!isTicked) setFilters(prevFilters => [...prevFilters, content])
    else setFilters(prevFilters => prevFilters.filter(f => f !== content))
  }

  const {
    success: deletePrescriptionSuccess,
    failed: deletePrescriptionFailed,
    deletePrescription,
  } = useDeletePrescription()

  const onDeletePrescription = prescription => {
    deletePrescription({ variables: { id: prescription.id } })
  }

  useEffect(() => {
    if (deletePrescriptionSuccess) {
      toast.success(`La prescription a été supprimé avec succès !`)
      dispatch(resetDeletePrescription())
    }
    if (deletePrescriptionFailed) {
      toast.error(
        `Il y a eu une erreur dans la supression de la prescription, merci de réessayer.`
      )
      dispatch(resetDeletePrescription())
    }
  }, [deletePrescriptionFailed, deletePrescriptionSuccess, dispatch])

  return (
    <Section>
      <Header>{headerCta}</Header>
      {showFilterBar && (
        <FilterBar
          handleOnTick={handleOnTick}
          filterByStatus={true}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filters={filters}
        />
      )}
      {prescriptionsLoading && <Spinner />}
      {prescriptionsSuccess && prescriptions && (
        <>
          {showDashboard && <Dashboard prescriptions={prescriptions} />}
          <Table>
            <TableHead>
              <HeadRow>
                <Cell>Date</Cell>
                <Cell>Patient</Cell>
                <Cell>Status</Cell>
                <Cell>Actions</Cell>
              </HeadRow>
            </TableHead>
            <TableBody>
              {sortedPrescriptions.map(prescription => (
                <Row key={prescription.id}>
                  <Cell>{prescription.displayTime}</Cell>
                  {patientsLoading ? (
                    <Cell>
                      <Spinner />
                    </Cell>
                  ) : (
                    <PatientCell patientId={prescription.patientId} />
                  )}
                  <Cell width='100px'>
                    <PrescriptionStatus status={prescription.lastStatus} />
                  </Cell>
                  <Cell width='70px'>
                    <Dropdown
                      content={
                        <MenuContainer>
                          <NavLink
                            as={Link}
                            to={`/prescriptions/${prescription.id}`}
                          >
                            Voir
                          </NavLink>
                          {prescription.lastStatus.name === 'DONE' && (
                            <NavLink
                              as={Link}
                              to={{
                                pathname: '/prescriptions/new/',
                                search: '?step=1',
                              }}
                              onClick={() =>
                                dispatch(setPrescriptionToDraft(prescription))
                              }
                            >
                              Dupliquer
                            </NavLink>
                          )}
                          {prescription.lastStatus.name === 'CREATED' && (
                            <NavLink
                              as={Link}
                              to={{
                                pathname: '/prescriptions/new',
                                search:
                                  '?step=2&prescriptionId=' + prescription.id,
                              }}
                              onClick={() =>
                                dispatch(setPrescriptionToDraft(prescription))
                              }
                            >
                              Modifier
                            </NavLink>
                          )}
                          <NavLink
                            onClick={() => onDeletePrescription(prescription)}
                          >
                            Supprimer
                          </NavLink>
                        </MenuContainer>
                      }
                    >
                      <Button iconLeft='menu' appearance='minimal' />
                    </Dropdown>
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Section>
  )
}

PrescriptionsContainer.defaultProps = {
  patientId: undefined,
  headerCta: undefined,
}

PrescriptionsContainer.propTypes = {
  patientId: PropTypes.number,
  headerCta: PropTypes.node,
  showFilterBar: PropTypes.bool,
}

export default PrescriptionsContainer
