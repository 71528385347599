import styled from 'styled-components/macro'

import { Color } from '../../utils/constants'

export const Container = styled.div`
  max-width: 250px;
`

export const InputContainer = styled.div`
  position: relative;
`

export const SuggestionContainer = styled.div`
  display: flex;
  position: absolute;
  background: white;
  top: calc(100% + 10px);
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 200%;
  width: auto;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: visible;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  background: white;
  top: 6px;
  right: 10px;
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h3`
  font-size: 12px;
  border-bottom: 1px solid #0001;
  padding-bottom: 5px;
  margin: 10px 0;
`

export const MiniProductPreview = styled.article`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #0001;
  }
`
export const ProductImgContainer = styled.div`
  margin-right: 10px;
`

export const ProductName = styled.p`
  font-size: 14px;
  flex: 1;
`

export const ProductBtn = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${Color.DEEP_SEA};
  margin-left: 10px;
`
