import { Link } from 'react-router-dom'

import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import { Container, Content } from './style'
import { Button } from '../../components/ui'
// import ProtocolesContainer from '../../components/protocoles/protocoles-container'
import ProtocolsContainer from '../../components/protocoles/container'

const ProtocolesPages = () => {
  return (
    <Layout>
      <PageTitle
        title='Mes protocoles'
        subtitle='Les protocoles sont des recommandations de produits pré-enregistrées que vous pouvez réutiliser à tout moment. Vous pouvez également modifier le protocole avant d’envoyer la recommandation à votre patient.'
        rightElements={
          <Button
            as={Link}
            to={{
              pathname: '/protocoles/new',
              search: '?step=1',
            }}
            iconLeft='plus'
            appearance='primary'
          >
            Nouveau protocole
          </Button>
        }
      />
      <Container>
        <Content>
          {/* <ProtocolesContainer /> */}
          <ProtocolsContainer />
        </Content>
      </Container>
    </Layout>
  )
}

export default ProtocolesPages
