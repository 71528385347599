import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  actions: {
    add: { ...INITIAL_ACTION_STATE },
    delete: { ...INITIAL_ACTION_STATE },
    edit: { ...INITIAL_ACTION_STATE },
    fetchAll: { ...INITIAL_ACTION_STATE },
  },
}

export default state
