import {
  Table,
  TableHead,
  HeadRow,
  Cell,
  Row,
  TableBody,
} from '../../prescription/prescriptions-container/style'
import { OrderContainer } from './style'

const OrdersTable = () => {
  return (
    <OrderContainer>
      OrdersTable
      <Table>
        <TableHead>
          <HeadRow>
            <Cell>Patient</Cell>
            <Cell>Prescription</Cell>
            <Cell>Montant</Cell>
            <Cell>Comission</Cell>
            <Cell>Code promo</Cell>
          </HeadRow>
        </TableHead>
        <TableBody>
          <Row>
            <Cell>Loic</Cell>
            <Cell>3456789</Cell>
            <Cell>34.00€</Cell>
            <Cell>10%</Cell>
            <Cell>kevtss25</Cell>
          </Row>
        </TableBody>
      </Table>
    </OrderContainer>
  )
}

export default OrdersTable
