import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

export const HeaderSection = styled.section`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  padding-bottom: 50px;
`

export const MainTitle = styled.h1`
  font-size: 32px;
`

export const SecondaryTitle = styled.h3`
  font-size: 22px;
  margin: 15px 0;
`

export const BodySection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 50px;
  align-items: flex-start;
`

export const ActionSection = styled.section`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  background-color: #fff;
`

export const P = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
  margin-bottom: 10px;
`

export const RecoLink = styled.p`
  font-size: 14px;
  color: ${Color.DEEP_SEA};
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 3px;
  word-wrap: break-word;
  max-width: 100%;
`

export const Pane = styled.div`
  display: flex;
  width: 100%;
`

export const Note = styled(P)`
  background-color: #ddd;
  padding: 10px;
  border-radius: 10px;
`

export const RecommandationPageHeader = styled.div`
  display: flex;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin: 50px 0;
`

export const FieldWrapper = styled.div`
  width: 40%;
`

export const RecoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
