import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const initCartFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.initCart = { status: ActionType.FAILED, error }

  return newState
}
const initCartLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.initCart = { status: ActionType.LOADING }

  return newState
}
const initCartSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cartCreate.cart
  const cartId = action.payload.cartCreate.cart?.id
  const prescriptionId = action.payload.cartCreate.cart?.attributes.find(
    i => i.key === 'prescription_id'
  ).value
  localStorage.setItem('sessionId', `${cartId}${prescriptionId}`)
  localStorage.setItem('cartId', `${cartId}`)

  newState.actions.initCart = { status: ActionType.SUCCESS }

  return newState
}

const fetchCartFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.initCart = { status: ActionType.FAILED, error }

  return newState
}
const fetchCartLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.initCart = { status: ActionType.LOADING }

  return newState
}
const fetchCartSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cart
  newState.actions.initCart = { status: ActionType.SUCCESS }

  return newState
}

const addCartFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.addToCart = { status: ActionType.FAILED, error }

  return newState
}
const addCartLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.addToCart = { status: ActionType.LOADING }

  return newState
}
const addCartSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cartLinesAdd.cart
  newState.actions.addToCart = { status: ActionType.SUCCESS }

  return newState
}

const updateCartFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.updateCart = { status: ActionType.FAILED, error }

  return newState
}
const updateCartLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.updateCart = { status: ActionType.LOADING }

  return newState
}
const updateCartSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cartLinesUpdate.cart
  newState.actions.updateCart = { status: ActionType.SUCCESS }

  return newState
}

const cartLinesRemoveFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.cartLinesRemove = { status: ActionType.FAILED, error }

  return newState
}
const cartLinesRemoveLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.cartLinesRemove = { status: ActionType.LOADING }

  return newState
}
const cartLinesRemoveSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cartLinesRemove.cart
  newState.actions.cartLinesRemove = { status: ActionType.SUCCESS }

  return newState
}

const cartAttributesUpdateFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.cartAttributesUpdate = { status: ActionType.FAILED, error }

  return newState
}
const cartAttributesUpdateLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.cartAttributesUpdate = { status: ActionType.LOADING }

  return newState
}
const cartAttributesUpdateSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.cart = action.payload.cartAttributesUpdate.cart
  newState.actions.cartAttributesUpdate = { status: ActionType.SUCCESS }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.INIT_CART_FAILED:
      return initCartFailed(state, action)
    case Constants.INIT_CART_LOADING:
      return initCartLoading(state)
    case Constants.INIT_CART_SUCCESS:
      return initCartSuccess(state, action)

    case Constants.FETCH_CART_FAILED:
      return fetchCartFailed(state, action)
    case Constants.FETCH_CART_LOADING:
      return fetchCartLoading(state)
    case Constants.FETCH_CART_SUCCESS:
      return fetchCartSuccess(state, action)

    case Constants.ADD_CART_FAILED:
      return addCartFailed(state, action)
    case Constants.ADD_CART_LOADING:
      return addCartLoading(state)
    case Constants.ADD_CART_SUCCESS:
      return addCartSuccess(state, action)

    case Constants.UPDATE_CART_FAILED:
      return updateCartFailed(state, action)
    case Constants.UPDATE_CART_LOADING:
      return updateCartLoading(state)
    case Constants.UPDATE_CART_SUCCESS:
      return updateCartSuccess(state, action)

    case Constants.CART_LINES_REMOVE_FAILED:
      return cartLinesRemoveFailed(state, action)
    case Constants.CART_LINES_REMOVE_LOADING:
      return cartLinesRemoveLoading(state)
    case Constants.CART_LINES_REMOVE_SUCCESS:
      return cartLinesRemoveSuccess(state, action)

    case Constants.CART_ATTRIBUTES_UPDATE_FAILED:
      return cartAttributesUpdateFailed(state, action)
    case Constants.CART_ATTRIBUTES_UPDATE_LOADING:
      return cartAttributesUpdateLoading(state)
    case Constants.CART_ATTRIBUTES_UPDATE_SUCCESS:
      return cartAttributesUpdateSuccess(state, action)

    default:
      return state
  }
}
