import Take from './Take'
import Item from './Item'
const getDisplayMonth = MonthNumber =>
  [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ][MonthNumber]

class PrescriptionTake extends Take {
  get items() {
    return this.data.prescription_items.map(item => new Item(item))
  }
}

class Status {
  data = null

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get createdAt() {
    return this.data.created
  }

  get time() {
    const d = new Date(this.data.created)
    const day = d.getDate().toString().padStart(2, '0')
    const month = getDisplayMonth(d.getMonth())
    const year = d.getFullYear()
    const hours = d.getHours().toString().padStart(2, '0')
    const min = d.getMinutes().toString().padStart(2, '0')

    return `Le ${day} ${month} ${year} à ${hours}h${min}`
  }

  get name() {
    return this.data.status
  }

  get takes() {
    return this.data.prescription_takes.map(take => new PrescriptionTake(take))
  }

  get allItems() {
    return this.data.prescription_takes.reduce((acc, curr) => {
      return [...acc, ...curr.prescription_items.map(item => new Item(item))]
    }, [])
  }
}

class Prescription {
  data = null

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get createdAt() {
    return this.data.created_at
  }

  get displayTime() {
    const d = new Date(this.lastStatus.createdAt)
    const day = d.getDate().toString().padStart(2, '0')
    const month = getDisplayMonth(d.getMonth())
    const year = d.getFullYear()

    return `${day} ${month} ${year}`
  }

  get customMessage() {
    return this.data.custom_message
  }

  get discountCode() {
    return this.data.discount?.code || null
  }

  get discountPercentage() {
    return this.data.discount?.percentage || null
  }

  get patientId() {
    return this.data.patient_id
  }

  get recommendedDuration() {
    return this.data.recommended_duration
  }

  get duration() {
    return Number(this.data.recommended_duration.split('-')[0])
  }

  get allItems() {
    const lastStatusIdx = this.data.prescription_statuses.length - 1
    return this.data.prescription_statuses[
      lastStatusIdx
    ].prescription_takes.reduce((acc, curr) => {
      return [...acc, ...curr.prescription_items.map(item => new Item(item))]
    }, [])
  }

  get statuses() {
    return this.data.prescription_statuses.map(status => new Status(status))
  }

  get lastStatus() {
    if (!this.statuses.length) return null

    const sortedStatuses = [...this.statuses].sort(
      (val1, val2) =>
        new Date(val2.createdAt).getTime() - new Date(val1.createdAt).getTime()
    )

    return sortedStatuses[0]
  }

  get patient() {
    return this.data.patient
  }

  get prescriber() {
    return this.data.prescriber
  }
}

export default Prescription
