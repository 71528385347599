import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: 20px 0 0 0;

  > button {
    margin-top: 20px;
  }
`

export const FooterText = styled.div`
  font-size: 12px;
  margin-top: 20px;
  max-width: 280px;
  margin-bottom: 5px;
`

export const FooterTextContent = styled.p`
  color: ${Color.DEEP_SEA};
  display: inline;
  cursor: pointer;
`

export const Error = styled.p`
  color: red;
  font-size: 12px;
`
