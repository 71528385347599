import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, SelectInputContainer, SelectProductContainer } from './style'
import { Dialog, Input, Spinner } from '../../ui'
import { useProducts } from '../../../utils/hooks'
import ProductPreviewCard from '../product-preview-card'
import { sortAscendingByProductName } from '../../../utils/helpers'
import { getCart } from '../../../store/cart'
import { outOfBagProductsHandle } from '../../../utils/data'

const ProductsUpsellsDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  addProduct,
  recommendedDuration,
  addToCartLoading,
  prescription,
  takesArray,
}) => {
  const { data: products, loading, success } = useProducts()
  const [search, setSearch] = useState('')
  const cart = useSelector(state => getCart(state))
  const cartProductsHandle = cart?.lines.edges.map(
    product => product.node.merchandise.product.handle
  )
  const [selectedHandle, setSelectedHandle] = useState(false)

  const getVariantName = (recommendedDuration, handle) => {
    if (handle === 'collagene' && recommendedDuration === '1-month')
      return '15sticks_onetime'
    if (handle === 'collagene' && recommendedDuration === '2-month')
      return '30sticks_onetime'
    if (handle === 'collagene' && recommendedDuration === '3-month')
      return '45sticks_onetime'

    switch (recommendedDuration) {
      case '1-month':
        return '1month_onetime'
      case '2-month':
        return '2month_onetime'
      case '3-month':
        return '3month_onetime'
      default:
        return '1month_onetime'
    }
  }

  const take1 = cart?.lines.edges.filter(cartItem => {
    return cartItem.node.attributes.find(i => i.key === 'prise')?.value === '1'
  })
  const take2 = cart?.lines.edges.filter(cartItem => {
    return cartItem.node.attributes.find(i => i.key === 'prise')?.value === '2'
  })

  let productsToDisplay = products
    .filter(p => p.isShown)
    .filter(p => !cartProductsHandle?.includes(p.handle))
    .sort(sortAscendingByProductName)
  if (search) {
    const searchValue = search.trim().toLocaleLowerCase()
    productsToDisplay = products
      .filter(
        product =>
          product.name.trim().toLocaleLowerCase().includes(searchValue) &&
          product.isShown
      )
      .filter(p => !cartProductsHandle?.includes(p.handle))
      .sort(sortAscendingByProductName)
  }

  return (
    <>
      {addToCartLoading && <Spinner fullScreen />}
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type='large'
      >
        <Form>
          <SelectInputContainer>
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder='Chercher un produit'
              iconLeft='search'
            />
          </SelectInputContainer>
          <SelectProductContainer>
            {loading && <Spinner />}
            {success &&
              productsToDisplay &&
              productsToDisplay.map((product, index) => {
                const variantId =
                  product?.data?.shopify_product?.variants.edges.find(
                    i =>
                      i.node.title ===
                      getVariantName(recommendedDuration, product.handle)
                  ).node.id
                const handle = product.handle
                return (
                  <ProductPreviewCard
                    takesArray={takesArray}
                    key={product.handle}
                    setSelectedHandle={setSelectedHandle}
                    selectedHandle={selectedHandle}
                    product={product}
                    allowSelectTake={true}
                    displayTakeChoiceDialog={
                      !!take1?.length &&
                      !!take2?.length &&
                      !outOfBagProductsHandle.includes(handle)
                    }
                    variantId={variantId}
                    addProduct={addProduct}
                    onClick={() => {
                      addProduct(
                        variantId,
                        outOfBagProductsHandle.includes(handle) ? '/' : '1',
                        handle
                      )
                    }}
                  />
                )
              })}
          </SelectProductContainer>
        </Form>
      </Dialog>
    </>
  )
}

export default ProductsUpsellsDialog
