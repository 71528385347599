class AuthUser {
  data = {}

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get email() {
    return this.data.email
  }

  get phone() {
    return this.data.phone_number || ''
  }

  get firstName() {
    return this.data.firstname || ''
  }

  get lastName() {
    return this.data.name || ''
  }

  get fullName() {
    let value = ''
    if (this.firstName) {
      value += this.firstName
    }
    if (this.lastName) {
      let space = ''
      if (this.firstName) {
        space = ' '
      }
      value += space + this.lastName
    }

    return value
  }

  get discountCode() {
    return this.data.discount?.code || null
  }

  get discountPercentage() {
    return this.data.discount?.percentage || 0
  }

  get bankAccount() {
    return this.data?.bank_account || ''
  }

  get invoiceAddress() {
    return this.data?.invoice_address || ''
  }

  get enterpriseName() {
    return this.data?.enterprise_name || ''
  }

  get tvaNumber() {
    return this.data?.tva_number || ''
  }

  get calendlyCode() {
    return this.data?.calendly_code || ''
  }
}

export default AuthUser
