import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Form } from './style'
import { Container, Title } from '../style'
import { Field, FieldGroup, Tooltip, Icon } from '../../../ui'
import { getPatientById } from '../../../../store/patient'
import { useAuthPrescriber } from '../../../../utils/hooks'
import Collapse from '../../../ui/collapse'

const durationOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
]

const purchaseOptions = [
  {
    value: 'subscriptionOrOneTimePurchase',
    label: 'Abonnement ou achat unique',
  },
  { value: 'subscription', label: 'Abonnement' },
  { value: 'oneTimePurchase', label: 'Achat unique' },
]

const shareOptions = [
  { value: false, label: 'Non' },
  { value: true, label: 'Oui' },
]

const getFallbackMessage = (patientName, prescriber, duration) => {
  let discountContent = ''
  if (prescriber.discountCode) {
    discountContent += `Vous bénéficiez d'une réduction de ${prescriber.discountPercentage}% sur votre commande avec le code ${prescriber.discountCode}.`
  }

  return `Bonjour ${patientName || ''}, voici ma recommandation.

${discountContent}

${prescriber.firstName || '*votre nom*'}`
}

const PrescriptionFormStep3 = ({
  setNewDraft,
  draft: { message, duration, share, purchaseOption },
  shareProtocole,
}) => {
  const dispatch = useDispatch()

  const patient = useSelector(state => getPatientById(state))
  const durationValueIndex = durationOptions.findIndex(
    u => u.value === duration
  )
  const shareValueIndex = shareOptions.findIndex(u => u.value === share)
  const pushaseOptionValueIndex = purchaseOptions.findIndex(
    u => u.value === purchaseOption
  )
  // const unitValueIndex = unitOptions.findIndex(u => u.value === durationUnit)
  const { authUser } = useAuthPrescriber()

  useEffect(() => {
    if (typeof message === 'string' && !message.length) {
      dispatch(
        setNewDraft({
          message: getFallbackMessage(
            patient && patient.firstname ? patient.firstname : '',
            authUser,
            duration
          ),
          duration: durationOptions[2].value,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>Finaliser la cure</Title>
      <Form>
        <Field
          label={`Ajouter un message personnalisé pour ${
            patient ? patient.firstname : 'votre patient'
          }`}
          name='message'
          id='message'
          type='textarea'
          onChange={e => dispatch(setNewDraft({ message: e.target.value }))}
          value={message}
        />
        <FieldGroup
          label='Durée recommandée'
          labelAppend={
            <Tooltip
              size='l'
              label='Pour chaque mois recommandé, votre patient recevra une boite de 30 sachets quotidien contenant votre prescription'
            >
              <Icon name='info' />
            </Tooltip>
          }
        >
          <Field
            type='select'
            name='duration'
            value={durationOptions[durationValueIndex]}
            options={durationOptions}
            onChange={data => dispatch(setNewDraft({ duration: data.value }))}
            defaultValue={durationOptions[2]}
          />
          <Field type='text' name='month' value='mois' disabled />
        </FieldGroup>

        <div
          style={{
            marginTop: '20px',
          }}
        ></div>

        <Collapse collapsed={false} headerText='Paramètres avancés'>
          <div
            style={{
              marginTop: '10px',
            }}
          ></div>
          <FieldGroup
            label="Options d'achat"
            labelAppend={
              <Tooltip
                size='l'
                label='Offrez la possibilité à vos patients de choisir entre un achat unique ou un abonnement où restreignez à un des deux choix.'
              >
                <Icon name='info' />
              </Tooltip>
            }
          >
            <Field
              type='select'
              name='purchaseOption'
              value={purchaseOptions[pushaseOptionValueIndex]}
              options={purchaseOptions}
              onChange={data =>
                dispatch(setNewDraft({ purchaseOption: data.value }))
              }
              defaultValue={purchaseOptions[0]}
            />
          </FieldGroup>
        </Collapse>

        {shareProtocole && (
          <FieldGroup
            label='Partager le protocole'
            labelAppend={
              <Tooltip
                size='l'
                label='Le protocole sera accessible à tous les prescripteurs de la plateforme'
              >
                <Icon name='info' />
              </Tooltip>
            }
          >
            <Field
              type='select'
              name='duration'
              value={shareOptions[shareValueIndex]}
              options={shareOptions}
              onChange={data => dispatch(setNewDraft({ share: data.value }))}
              defaultValue={shareOptions[0]}
            />
          </FieldGroup>
        )}
      </Form>
    </Container>
  )
}

PrescriptionFormStep3.propTypes = {
  setNewDraft: PropTypes.func,
  draft: PropTypes.object,
  shareProtocole: PropTypes.bool,
}

export default PrescriptionFormStep3
