import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {
  Container,
  Infos,
  DiscountInfo,
  CommissionInfo,
  InfosContainer,
} from './style'
import { Label } from '../../ui'

const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls='rc-slider-tooltip'
      overlay={`${value} %`}
      visible={dragging}
      placement='top'
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const DiscountCode = ({ selectValue, setSelectedValue }) => {
  return (
    <Container>
      <Label style={{ fontSize: '12px' }}>
        Choisissez la répartition entre votre commission et la réduction que
        vous offrez à vos patients (vous pouvez toujours la modifier plus tard).
      </Label>
      <Slider
        min={0}
        max={25}
        handle={handle}
        step={5}
        value={selectValue}
        onChange={v => setSelectedValue(v)}
        marks={{ 0: 0, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25 }}
        style={{ margin: '0.5em 0' }}
      />
      <Infos>
        <InfosContainer>
          <DiscountInfo>Réduction</DiscountInfo>
          <DiscountInfo>{selectValue}%</DiscountInfo>
        </InfosContainer>
        <InfosContainer style={{ alignItems: 'flex-end' }}>
          <CommissionInfo>Commission</CommissionInfo>
          <CommissionInfo>{25 - selectValue}%</CommissionInfo>
        </InfosContainer>
      </Infos>
    </Container>
  )
}

export default DiscountCode
