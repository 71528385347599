import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchPatientsFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.FAILED, error }

  return newState
}
const fetchPatientsLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.LOADING }

  return newState
}
const fetchPatientsSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.list = [...action.payload]
  newState.actions.fetchAll = { status: ActionType.SUCCESS }

  return newState
}

const addPatientFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.FAILED, error }

  return newState
}

const addPatientReset = state => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: null }

  return newState
}

const addPatientLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.LOADING }

  return newState
}
const addPatientSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.list = [...newState.list, action.payload]
  newState.actions.add = { status: ActionType.SUCCESS }

  return newState
}

const deletePrescriptionLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.delete = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}

const deletePatientFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.delete = { status: ActionType.FAILED, error }

  return newState
}

const deletePatientSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = newState.list.filter(p => p.id !== payload.id)
  newState.actions.delete = { status: ActionType.SUCCESS, error: null }

  return newState
}

const editPatientFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.FAILED, error }

  return newState
}
const editPatientLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.LOADING }

  return newState
}
const editPatientSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const patientIndex = newState.list.findIndex(({ id }) => id === payload.id)
  newState.list[patientIndex] = { ...payload }
  newState.actions.edit = { status: ActionType.SUCCESS }

  return newState
}
const editPatientReset = state => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.RESET }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_PATIENTS_FAILED:
      return fetchPatientsFailed(state, action)
    case Constants.FETCH_PATIENTS_LOADING:
      return fetchPatientsLoading(state)
    case Constants.FETCH_PATIENTS_SUCCESS:
      return fetchPatientsSuccess(state, action)

    case Constants.ADD_PATIENT_FAILED:
      return addPatientFailed(state, action)
    case Constants.ADD_PATIENT_LOADING:
      return addPatientLoading(state)
    case Constants.ADD_PATIENT_SUCCESS:
      return addPatientSuccess(state, action)
    case Constants.ADD_PATIENT_RESET:
      return addPatientReset(state)

    case Constants.DELETE_PATIENT_SUCCESS:
      return deletePatientSuccess(state, action)
    case Constants.DELETE_PATIENT_FAILED:
      return deletePatientFailed(state, action)
    case Constants.DELETE_PATIENT_LOADING:
      return deletePrescriptionLoading(state)

    case Constants.EDIT_PATIENT_FAILED:
      return editPatientFailed(state, action)
    case Constants.EDIT_PATIENT_LOADING:
      return editPatientLoading(state)
    case Constants.EDIT_PATIENT_SUCCESS:
      return editPatientSuccess(state, action)
    case Constants.EDIT_PATIENT_RESET:
      return editPatientReset(state)

    default:
      return state
  }
}
