export const TakeType = {
  /**
   * Represent products that are packed inside personalized daily packs
   */
  IN_CURE: 'in-cure',
  /**
   * Represent a take who contain only products that are not inside packs, but
   * as separate items
   */
  STAND_ALONE: 'stand-alone',
}

export const Status = {
  DANGER: 'danger',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const ViewMode = {
  LIST: 'list',
  GRID: 'grid',
  GRID2: 'grid2',
}
