import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Header, Nav, Ul, Li } from './style'
import { Logo } from '../../ui'
import AuthState from '../../auth/auth-state'
import { useAuthPrescriber } from '../../../utils/hooks'
import Notification from './notification/Notification'

const HeaderEl = () => {
  const { authUser } = useAuthPrescriber()

  useEffect(() => {}, [authUser])

  return (
    <Header>
      <Link to='/'>
        <Logo />
      </Link>
      <Nav>
        <Ul>
          <Li>
            <Link to='/patients'>Patients</Link>
          </Li>
          <Li>
            <Link to='/prescriptions'>Recommandations</Link>
          </Li>
          <Li>
            <Link to='/catalog'>Catalogue</Link>
          </Li>
          <Li>
            <Link to='/protocoles'>Protocoles</Link>
          </Li>
          {/* <Li>
            <Link to='/integrations'>Intégrations</Link>
          </Li> */}
        </Ul>
      </Nav>
      {authUser?.email && (
        <>
          <Notification userEmail={authUser?.email} />
          <AuthState />
        </>
      )}
    </Header>
  )
}

export default HeaderEl
