import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { checkVAT, belgium, france, luxembourg } from 'jsvat'

import { Title, Hr } from './style'
import { useAuthPrescriber, useEditPrescriber } from '../../../utils/hooks'
import ProfileItem from './profile-item'
import { setAuthPrescriber } from '../../../store/auth-user'

const PaymentSection = () => {
  const dispatch = useDispatch()
  const { authUser } = useAuthPrescriber()
  const [editField, setEditField] = useState('')
  const [selectValue, setSelectedValue] = useState(authUser.discountPercentage)
  const {
    editPrescriber,
    loading: editLoading,
    reset: resetEdit,
  } = useEditPrescriber()

  const onSubmit = value => {
    let vat = {}
    if (editField === 'tvaNumber') {
      const vatInfos = checkVAT(value, [belgium, france, luxembourg])
      if (!vatInfos.isValid) {
        toast.error(
          "Le numéro de TVA entré n'est pas correct, merci de ré-essayer."
        )
        return
      }
      vat = vatInfos
    }
    editPrescriber({
      variables: {
        fn: authUser.firstName || '',
        ln: authUser.lastName || '',
        phone: authUser.phone || '',
        enterpriseName:
          (editField === 'enterpriseName' ? value : authUser.enterpriseName) ||
          '',
        tvaNumber:
          (editField === 'tvaNumber' && vat.isValid
            ? vat.value
            : authUser.tvaNumber) || '',
        invoiceAddress:
          (editField === 'invoiceAddress' ? value : authUser.invoiceAddress) ||
          '',
        bankAccount:
          (editField === 'bankAccount' ? value : authUser.bank_account) || '',
        discount:
          editField === 'discount' && selectValue !== 0
            ? authUser.firstName[0] + authUser.lastName + selectValue.toString()
            : authUser.discountCode,
        id: authUser.id,
      },
    })
      .then(res => {
        const data = res.data.update_prescriber.returning[0]
        dispatch(
          setAuthPrescriber({
            phone_number: data.phone_number,
            name: data.name,
            firstname: data.firstname,
            discount: data.discount,
            bank_account: data.bank_account,
            tva_number: data.tva_number,
            enterprise_name: data.enterprise_name,
            invoice_address: data.invoice_address,
          })
        )
        resetEdit()
        toast.success('Votre profil a été mis à jour avec succès')
        setEditField('')
      })
      .catch(err => {
        toast.error("Une erreur s'est produite, réessayez plus tard")
        resetEdit()
      })
  }

  return (
    <>
      <Title>Vos paramètres de paiement.</Title>
      <ProfileItem
        defaultValue={authUser.discountCode}
        label='Votre code personnel de réduction'
        description="Ce code peut-être utilisé par vos patients afin de bénéficier d'un tarif
        avantageux lors de leurs commandes."
      />
      <Hr />
      <ProfileItem
        defaultValue={selectValue}
        valueSuffix='%'
        label='Pourcentage de réduction'
        openEdit={() => setEditField('discount')}
        isEditing={editField === 'discount'}
        description={`Votre code ${
          authUser.firstName && authUser.lastName && selectValue !== 0
            ? authUser.firstName[0] + authUser.lastName + selectValue.toString()
            : ''
        } offre ${selectValue}% de
        réduction à vos patients qui l'utilise.`}
        type='select'
        selectValue={selectValue}
        setSelectedValue={setSelectedValue}
        onSubmit={onSubmit}
        firstName={authUser.firstName}
        lastName={authUser.name}
      />
      <Hr />
      <ProfileItem
        defaultValue={authUser.enterpriseName}
        label="Nom d'entreprise"
        openEdit={() => setEditField('enterpriseName')}
        isEditing={editField === 'enterpriseName'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
      <Hr />
      <ProfileItem
        defaultValue={authUser.tvaNumber || ''}
        label='Numéro de TVA'
        openEdit={() => setEditField('tvaNumber')}
        isEditing={editField === 'tvaNumber'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
      <Hr />
      <ProfileItem
        defaultValue={authUser.invoiceAddress}
        label='Addresse de facturation'
        openEdit={() => setEditField('invoiceAddress')}
        isEditing={editField === 'invoiceAddress'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
      <ProfileItem
        defaultValue={authUser.bankAccount}
        label='Compte Bancaire'
        openEdit={() => setEditField('bankAccount')}
        isEditing={editField === 'bankAccount'}
        onSubmit={onSubmit}
        isLoading={editLoading}
      />
    </>
  )
}

export default PaymentSection
