import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Button = styled.button`
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 900;
  color: ${({ isDisabled }) => (isDisabled ? '#ddd' : Color.DEEP_SEA)};
  padding: 10px 20px;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0);

  &:hover {
    transform: scale(1.02);
  }
`

export const SecondaryTitle = styled.h2`
  font-size: 22px;
  margin-bottom: 20px;
`

export const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 50px;
`

export const Small = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const DialogHeader = styled.div`
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Color.MYSTIC};

  > div:nth-child(1) {
    flex: 1;
    padding-right: 50px;
  }
`

export const PillImageContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ $bg }) => `url("${$bg}")`};
`

export const PillImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`

export const DialogTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 10px;
`
export const DialogSubTitle = styled.h2`
  font-size: 20px;
`

export const DialogBody = styled.div`
  display: flex;
  padding: 50px 0;
`

export const BodyCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const VerticalSeparator = styled.div`
  width: 1px;
  background-color: ${Color.MYSTIC};
  margin: 0 50px;
`

export const StrongPoints = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 20px;
`

export const Point = styled.ul`
  position: relative;
  padding: 0 0 20px 0;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 8px;
    left: -22px;
    background-color: ${Color.MINE_SHAFT};
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 12px;
    left: -19px;
    background-color: ${Color.MINE_SHAFT};
  }

  &:last-child:after {
    display: none;
  }
`

export const PointTitle = styled.p`
  font-size: 18px;
  font-weight: 900;
  white-space: pre-wrap;
`
export const PointText = styled.p`
  font-size: 14px;
  margin-top: 10px;
  white-space: pre-wrap;
`

export const SmallText = styled(Small)`
  margin-bottom: 20px;
  white-space: pre-wrap;
`
