import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  verifyEmail,
  getEmailConfirmationStatus,
  getEmailConfirmationError,
} from '../../store/auth-user'
import { SuccessRegistrationContainer } from './style'
import { ActionType } from '../../utils/constants'
import { Spinner } from '../../components/ui'

const EmailConfirmation = ({ mode, oobCode, location }) => {
  const dispatch = useDispatch()
  const confirmEmailStatus = useSelector(getEmailConfirmationStatus)
  const confirmEmailError = useSelector(getEmailConfirmationError)

  useEffect(() => {
    if (mode === 'verifyEmail') {
      dispatch(verifyEmail(oobCode))
    }
  }, [dispatch, oobCode, mode])

  useEffect(() => {
    if (confirmEmailStatus === ActionType.SUCCESS) {
      console.log('SUCCESS')
    }
    if (confirmEmailStatus === ActionType.FAILED) {
      toast.error(
        'Il y a eu une erreur dans la vérification de votre adresse email, merci de réessayer.'
      )
    }
  }, [confirmEmailStatus, confirmEmailError])

  return (
    <>
      {confirmEmailStatus === ActionType.SUCCESS && (
        <SuccessRegistrationContainer>
          🙌
          <br />
          Votre compte a été confirmé avec succès <br />
          <br />
          Vous pouvez vous connecter dès à présent en cliquant sur le bouton
          ci-dessous.
          <br />
          <br />
          <Link
            to={{
              pathname: '/login',
              search: location.search,
            }}
          >
            Se connecter
          </Link>
        </SuccessRegistrationContainer>
      )}
      {confirmEmailStatus !== ActionType.SUCCESS && <Spinner />}
    </>
  )
}

export default EmailConfirmation
