import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'

import { ActionType } from '../constants'
import {
  initCartLoading,
  initCartSuccess,
  initCartFailed,
  fetchCartLoading,
  fetchCartSuccess,
  fetchCartFailed,
  getInitCartStatus,
  getAddToCartStatus,
  getupdateCartStatus,
  addCartLoading,
  addCartSuccess,
  addCartFailed,
  updateCartLoading,
  updateCartSuccess,
  updateCartFailed,
  cartLinesRemoveLoading,
  cartLinesRemoveSuccess,
  cartLinesRemoveFailed,
  getcartLinesRemoveStatus,
  getcartAtributesUpadteStatus,
  cartAttributesUpdateLoading,
  cartAttributesUpdateSuccess,
  cartAttributesUpdateFailed,
} from '../../store/cart'

import {
  CREATE_CART,
  ADD_CART,
  UPDATE_CART,
  CART_LINES_REMOVE,
  FETCH_CART,
  CART_ATTRIBUTES_UPDATE,
} from '../../services/queries'

export const useInitCart = () => {
  const dispatch = useDispatch()
  const initCartStatus = useSelector(getInitCartStatus)
  const [
    createCart,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useMutation(CREATE_CART)

  useEffect(() => {
    cartLoading && dispatch(initCartLoading())
  }, [cartLoading, dispatch])
  useEffect(() => {
    cartData && dispatch(initCartSuccess(cartData))
  }, [cartData, dispatch])
  useEffect(() => {
    cartError && dispatch(initCartFailed(cartError))
  }, [cartError, dispatch])

  return {
    data: cartData,
    createCart,
    failed: initCartStatus === ActionType.FAILED,
    loading: initCartStatus === ActionType.LOADING,
    success: initCartStatus === ActionType.SUCCESS,
  }
}

export const useFetchCart = () => {
  const dispatch = useDispatch()
  const fetchCartStatus = useSelector(getInitCartStatus)
  const [
    fetchCart,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useLazyQuery(FETCH_CART)

  useEffect(() => {
    cartLoading && dispatch(fetchCartLoading())
  }, [cartLoading, dispatch])
  useEffect(() => {
    cartData && dispatch(fetchCartSuccess(cartData))
  }, [cartData, dispatch])
  useEffect(() => {
    cartError && dispatch(fetchCartFailed(cartError))
  }, [cartError, dispatch])

  return {
    data: cartData,
    fetchCart,
    failed: fetchCartStatus === ActionType.FAILED,
    loading: fetchCartStatus === ActionType.LOADING,
    success: fetchCartStatus === ActionType.SUCCESS,
  }
}

export const useAddToCart = () => {
  const dispatch = useDispatch()
  const addCartStatus = useSelector(getAddToCartStatus)
  const [
    addToCart,
    { data: addToCartData, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(ADD_CART)

  useEffect(() => {
    addToCartLoading && dispatch(addCartLoading())
  }, [addToCartLoading, dispatch])
  useEffect(() => {
    addToCartData && dispatch(addCartSuccess(addToCartData))
  }, [addToCartData, dispatch])
  useEffect(() => {
    addToCartError && dispatch(addCartFailed(addToCartError))
  }, [addToCartError, dispatch])

  return {
    data: addToCartData,
    addToCart,
    failed: addCartStatus === ActionType.FAILED,
    loading: addCartStatus === ActionType.LOADING,
    success: addCartStatus === ActionType.SUCCESS,
  }
}

export const useUpdateCart = () => {
  const dispatch = useDispatch()
  const updateCartStatus = useSelector(getupdateCartStatus)
  const [
    updateCart,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useMutation(UPDATE_CART)

  useEffect(() => {
    cartLoading && dispatch(updateCartLoading())
  }, [cartLoading, dispatch])
  useEffect(() => {
    cartData && dispatch(updateCartSuccess(cartData))
  }, [cartData, dispatch])
  useEffect(() => {
    cartError && dispatch(updateCartFailed(cartError))
  }, [cartError, dispatch])

  return {
    data: cartData,
    updateCart,
    failed: updateCartStatus === ActionType.FAILED,
    loading: updateCartStatus === ActionType.LOADING,
    success: updateCartStatus === ActionType.SUCCESS,
  }
}

export const useCartLinesRemove = () => {
  const dispatch = useDispatch()
  const cartLinesRemoveStatus = useSelector(getcartLinesRemoveStatus)
  const [
    cartLinesRemove,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useMutation(CART_LINES_REMOVE)

  useEffect(() => {
    cartLoading && dispatch(cartLinesRemoveLoading())
  }, [cartLoading, dispatch])
  useEffect(() => {
    cartData && dispatch(cartLinesRemoveSuccess(cartData))
  }, [cartData, dispatch])
  useEffect(() => {
    cartError && dispatch(cartLinesRemoveFailed(cartError))
  }, [cartError, dispatch])

  return {
    data: cartData,
    cartLinesRemove,
    failed: cartLinesRemoveStatus === ActionType.FAILED,
    loading: cartLinesRemoveStatus === ActionType.LOADING,
    success: cartLinesRemoveStatus === ActionType.SUCCESS,
  }
}

export const useCartAttributesUpdate = () => {
  const dispatch = useDispatch()
  const cartAttributesUpadteStatus = useSelector(getcartAtributesUpadteStatus)
  const [
    cartAttributesUpdate,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useMutation(CART_ATTRIBUTES_UPDATE)

  useEffect(() => {
    cartLoading && dispatch(cartAttributesUpdateLoading())
  }, [cartLoading, dispatch])
  useEffect(() => {
    cartData && dispatch(cartAttributesUpdateSuccess(cartData))
  }, [cartData, dispatch])
  useEffect(() => {
    cartError && dispatch(cartAttributesUpdateFailed(cartError))
  }, [cartError, dispatch])

  return {
    data: cartData,
    cartAttributesUpdate,
    failed: cartAttributesUpadteStatus === ActionType.FAILED,
    loading: cartAttributesUpadteStatus === ActionType.LOADING,
    success: cartAttributesUpadteStatus === ActionType.SUCCESS,
  }
}
