import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;
  background-color: white;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 25px;

  > div {
    display: flex;
    width: 100%;
  }
`

export const Text = styled.p`
  display: inline-flex;
  align-items: flex-end;
  font-size: 22px;
  flex: 1;
`

export const Number = styled.p`
  display: inline-flex;
  justify-content: flex-end;
  flex: 1;
  font-size: 68px;
`

export const NavLinkWrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  font-size: 18px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
