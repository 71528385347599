import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  SelectInputContainer,
  SelectProductContainer,
  TakeChoice,
  ProductSearchBarHeader,
  StickyContainer,
  Row,
  TitleWrapper,
} from './style'
import { Container, Title } from '../style'
import { Spinner, Dialog, Input, Tooltip, Button, Field } from '../../../ui'
import ProductPreviewCard from '../../../../components/product/product-preview-card'
import ProductSearchBar from '../../../../components/product-search'
// import FilterBar from '../../../../components/filter-bar'
import { useProducts, useDispatch } from '../../../../utils/hooks'
import {
  sortAscendingByProductName,
  normalizeString,
} from '../../../../utils/helpers'
import {
  TakeType,
  ViewMode,
  ProductCategory,
} from '../../../../utils/constants'

const PrescriptionFormStep2 = ({
  productSearchBarContent,
  canSelectProtocole,
  setProtocoleToDraft,
  resetProtocoleToDraft,
  setDraftProductTake,
  selectedProtocole,
  draft: { recommendedDuration, takes },
  protocoles,
  protocolesLoading,
  closeDetailModal,
  detailModalOpenHandle,
  openDetailModal,
}) => {
  const dispatch = useDispatch()
  const { data: products, loading, success } = useProducts()
  const [selectedHandle, setSelectedHandle] = useState('')
  const [productsToDisplay, setProductsToDisplay] = useState()
  const [selectProtocoleValue, setSelectedProtocoleValue] = useState({})
  const [filters, setFilters] = useState([])
  const [viewMode, setViewMode] = useState(ViewMode.GRID2)

  useEffect(() => {
    setProductsToDisplay(
      products.filter(p => p.isShown).sort(sortAscendingByProductName)
    )
  }, [products])

  useEffect(() => {
    if (filters.length > 0) {
      const filteredProducts = products.filter(p => {
        const loweredTags = p.tags.map(t => normalizeString(t))

        return (
          filters.find(f => loweredTags.indexOf(normalizeString(f)) !== -1) &&
          p.isShown
        )
      })
      setProductsToDisplay(filteredProducts.sort(sortAscendingByProductName))

      return
    }
    setProductsToDisplay(
      products.filter(p => p.isShown).sort(sortAscendingByProductName)
    )
  }, [products, filters])

  useEffect(() => {
    if (selectedProtocole)
      setSelectedProtocoleValue({
        value: selectedProtocole.id,
        label: selectedProtocole.name,
      })
  }, [selectedProtocole])

  const protocolesOptions = protocoles.map(p => ({
    value: p.id,
    label: p.name,
  }))

  protocolesOptions.push({ value: null, label: 'Aucun' })

  const onProductClicked = product => {
    if (
      !product.isStandAlone &&
      takes.filter(t => t.type !== TakeType.STAND_ALONE).length > 1
    ) {
      setSelectedHandle(product.handle)
    } else {
      addProduct(product)
    }
  }

  const addProduct = (product, takeIndex = undefined) => {
    dispatch(setDraftProductTake({ product, takeIndex }))
    setSelectedHandle('')
  }

  return (
    <Container>
      <TitleWrapper>
        <Title>Personnaliser la cure</Title>
        <Tooltip
          size='l'
          label={
            viewMode === ViewMode.LIST
              ? 'Passer en vue grille'
              : 'Passer en vue liste'
          }
        >
          <Button
            appearance='minimal'
            iconLeft={viewMode === ViewMode.LIST ? 'grid' : 'list'}
            onClick={() =>
              setViewMode(
                viewMode === ViewMode.LIST ? ViewMode.GRID2 : ViewMode.LIST
              )
            }
          />
        </Tooltip>
      </TitleWrapper>
      <Form>
        {canSelectProtocole && (
          <div style={{ zIndex: '2' }}>
            <Field
              label='Sélectionner un protocole existant'
              type='select'
              options={protocolesOptions}
              value={selectProtocoleValue}
              isLoading={protocolesLoading}
              onChange={data => {
                if (data.value === selectProtocoleValue.value || !data.value) {
                  dispatch(resetProtocoleToDraft())
                  setSelectedProtocoleValue({})
                  return
                }
                setSelectedProtocoleValue({ ...data })
                dispatch(resetProtocoleToDraft())
                dispatch(
                  setProtocoleToDraft(protocoles.find(p => p.id === data.value))
                )
              }}
              isSearchable
            />
          </div>
        )}
        {productSearchBarContent && (
          <ProductSearchBarHeader>
            {productSearchBarContent}
          </ProductSearchBarHeader>
        )}
        <StickyContainer>
          <Row>
            <SelectInputContainer>
              <ProductSearchBar
                setProductsToDisplay={setProductsToDisplay}
                products={products}
                onDetailsClicked={openDetailModal}
                hasDetailsBtn
                onAddClicked={product => onProductClicked(product)}
              />
            </SelectInputContainer>
            <Input
              type='select'
              options={Object.values(ProductCategory).map(value => ({
                label: value,
                value: normalizeString(value),
              }))}
              isMulti
              onChange={newFilters =>
                setFilters(newFilters.map(({ value }) => value))
              }
              hideSelectedOptions
              isSearchable
              closeMenuOnSelect={false}
              inputContainerStyling={{
                width: '300px',
              }}
              placeholder='Filter'
            />
          </Row>
        </StickyContainer>
        <SelectProductContainer $mode={viewMode}>
          {loading && <Spinner />}
          {success &&
            productsToDisplay &&
            productsToDisplay.map(product => (
              <ProductPreviewCard
                mode={viewMode}
                key={product.handle}
                product={product}
                onClick={product => onProductClicked(product)}
                recommendedDuration={recommendedDuration || '1-month'}
                products={products}
                hasDetailModalOpen={detailModalOpenHandle === product.handle}
                closeDetailModal={closeDetailModal}
              />
            ))}
        </SelectProductContainer>
      </Form>
      <Dialog isOpen={selectedHandle} onClose={() => setSelectedHandle('')}>
        <Title>
          Pour quelle prise voulez vous ajouter{' '}
          {selectedHandle
            ? products.find(p => p.handle === selectedHandle).name
            : ''}{' '}
          ?
        </Title>
        {takes
          .filter(take => take.type !== TakeType.STAND_ALONE)
          .map((take, index) => (
            <TakeChoice
              key={index}
              onClick={() =>
                addProduct(
                  products.find(p => p.handle === selectedHandle),
                  take.index
                )
              }
            >
              {take.type === TakeType.STAND_ALONE
                ? 'Produit hors cure'
                : take.label || `Prise #${index + 1}`}
            </TakeChoice>
          ))}
      </Dialog>
    </Container>
  )
}

PrescriptionFormStep2.propTypes = {
  setDraftProductTake: PropTypes.func,
  draft: PropTypes.object,
  canSelectProtocole: PropTypes.bool,
  setProtocoleToDraft: PropTypes.func,
  resetProtocoleToDraft: PropTypes.func,
  closeDetailModal: PropTypes.func,
  detailModalOpenHandle: PropTypes.string,
}

export default PrescriptionFormStep2
