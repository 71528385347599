import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuthPrescriber } from './authHooks'

/**
 * This hook is checking if the authUser have a firstName and lastName, if
 * one of them are missing, he will redirect the user to the onboarding
 * step where it's asked to fill these information
 */
export const useOnboardingProfileCheck = () => {
  const { authUser } = useAuthPrescriber()
  let history = useHistory()

  useEffect(() => {
    if (authUser && !authUser.firstName && !authUser.lastName) {
      history.push('/onboarding/profile')
    }
  }, [authUser, history])
}
