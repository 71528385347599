import { Link } from 'react-router-dom'

import { Container, Content } from './style'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import PatientsContainer from '../../components/patient/patients-container'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { Button } from '../../components/ui'

const PatientsPage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout>
      <PageTitle
        title='Mes patients'
        subtitle='Consultez et modifiez les informations de vos patients.'
        rightElements={
          <Button
            appearance='primary'
            iconLeft='plus'
            to='/patients/new'
            as={Link}
          >
            Nouveau patient
          </Button>
        }
      />
      <Container>
        <Content>
          <PatientsContainer />
        </Content>
      </Container>
    </Layout>
  )
}

export default PatientsPage
