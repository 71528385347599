import styled from 'styled-components/macro'

export const Container = styled.article`
  display: flex;
  width: 100%;
  border-radius: 15px;
  padding: 25px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 40px;
`

export const Label = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 2px;
  letter-spacing: 1px;
`

export const P = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`
