import { useEffect } from 'react'
import queryString from 'query-string'
import { Container, Content, StatsPreviewContainer, Left, Right } from './style'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import PrescriptionsStatsPreviewCard from '../../components/prescription/prescriptions-stats-preview-card'
import PatientsStatsPreviewCard from '../../components/patient/patients-stats-preview-card'
import ProtocolesStatsPreviewCard from '../../components/protocoles/protocole-stats-preview-card'
import {
  useAuthPrescriber,
  usePrescriptions,
  usePatients,
  useProtocoles,
  useOnboardingProfileCheck,
  useCreateCalendlyCode,
} from '../../utils/hooks'
import mixpanel from 'mixpanel-browser'
// import { Link } from 'react-router-dom'

const HomePage = ({ location }) => {
  useOnboardingProfileCheck()
  const { data: prescriptions, loading: prescriptionsLoading } =
    usePrescriptions()
  const { data: patients, loading: patientsLoading } = usePatients()
  const { data: protocoles, loading: protocolesLoading } = useProtocoles()
  const { addCalendlyCode } = useCreateCalendlyCode()
  const { authUser } = useAuthPrescriber()

  useEffect(() => {
    if (location) {
      const { code } = queryString.parse(location.search)
      if (code && code !== authUser.calendlyCode)
        addCalendlyCode({
          variables: {
            id: authUser.id,
            calendlyCode: code,
          },
        })
    }
  }, [authUser, location, addCalendlyCode])

  useEffect(() => {
    mixpanel.identify(authUser.id)
    mixpanel.people.set_once({
      $name: authUser.fullName,
      $email: authUser.email,
      $created: new Date(),
      invoiceAddress: authUser.invoiceAddress,
      phoneNumber: authUser.phoneNumber,
    })
  }, [authUser])

  const hour = new Date().getHours()

  return (
    <Layout>
      <PageTitle
        title={`${(hour < 18 && 'Bonjour') || (hour >= 18 && 'Bonsoir')} ${
          authUser?.firstName
        } 👋`}
      />
      <Container>
        <Content>
          <StatsPreviewContainer>
            <Left>
              <PatientsStatsPreviewCard
                data={patients}
                loading={patientsLoading}
              />
            </Left>
            <Right>
              <PrescriptionsStatsPreviewCard
                data={prescriptions}
                loading={prescriptionsLoading}
              />
            </Right>
          </StatsPreviewContainer>
          <StatsPreviewContainer style={{ width: '96%', margin: '0' }}>
            <ProtocolesStatsPreviewCard
              data={protocoles}
              loading={protocolesLoading}
            />
          </StatsPreviewContainer>
          {/* <Link to='/orders'>Mes commandes</Link> */}
        </Content>
      </Container>
    </Layout>
  )
}

export default HomePage
