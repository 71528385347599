import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType, ErrorCodes, TakeType } from '../../utils/constants'
import {
  pushItemInTake,
  pushTakeInDraft,
  getTakeByIndex,
  getTakeIndexByIndex,
} from '../../utils/helpers'

const fetchProtocolesFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.FAILED, error }

  return newState
}
const fetchProtocolesLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.LOADING }

  return newState
}
const fetchProtocolesSuccess = (state, { payload, error }) => {
  const newState = cloneDeep(state)
  newState.list = [...payload]
  newState.actions.fetchAll = { status: ActionType.SUCCESS, error }

  return newState
}

const fetchProtocoleFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.fetchOne = { status: ActionType.FAILED, error }

  return newState
}
const fetchProtocoleLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchOne = { status: ActionType.LOADING }

  return newState
}
const fetchProtocoleSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const protocoleIndex = newState.list.findIndex(({ id }) => id === payload.id)

  newState.list[protocoleIndex === -1 ? newState.list.length : protocoleIndex] =
    payload

  newState.actions.fetchOne = { status: ActionType.SUCCESS }

  return newState
}

const setNewProtocoleDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.newDraft = { ...newState.newDraft, ...payload }

  return newState
}
const resetNewProtocoleDraft = state => {
  const newState = cloneDeep(state)
  newState.newDraft = {
    message: '',
    name: '',
    description: '',
    takes: [],
    duration: 1,
    durationUnit: 'month',
    share: false,
  }

  return newState
}
const setProtocoleDraftProductTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { takeIndex, product } = payload
  const { STAND_ALONE, IN_CURE } = TakeType

  if (product.isStandAlone) {
    const take = newState.newDraft.takes.find(t => t.type === STAND_ALONE)
    if (take) {
      pushItemInTake(take, product.handle)
    } else {
      pushTakeInDraft(newState.newDraft, STAND_ALONE, product.handle)
    }
  } else {
    const takes = newState.newDraft.takes.filter(t => t.type === IN_CURE)
    if (!takes.length) {
      // Need to create a new take
      pushTakeInDraft(newState.newDraft, IN_CURE, product.handle)
    } else if (takeIndex !== undefined) {
      // Add to a specific take
      const concernedTake = takes.find(take => take.index === takeIndex)
      pushItemInTake(concernedTake, product.handle)
    } else {
      // Add to the first take since no take index is received
      pushItemInTake(takes[0], product.handle)
    }
  }
  return newState
}

const removeProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { product, takeIndex } = payload
  const {
    newDraft: { takes },
  } = newState

  const take = getTakeByIndex(takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === product.handle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items.splice(productIndex, 1)

  /**
   * When a product is removed, we want to make sure we're not staying with an
   * empty take, so if it's the case, we remove it as well.
   */
  if (!take.items.length) {
    const takeIndexToRemove = getTakeIndexByIndex(takes, takeIndex)
    takes.splice(takeIndexToRemove, 1)
  }

  return newState
}

const decreaseQttyProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  const currentQuantity = take.items[productIndex].quantity

  if (currentQuantity > 1) {
    take.items[productIndex].quantity -= 1
  }

  return newState
}
const increaseQttyProductFromDraftTake = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items[productIndex].quantity += 1

  return newState
}
const setProductNotesDraft = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { productHandle, takeIndex, notes } = payload
  const take = getTakeByIndex(newState.newDraft.takes, takeIndex)
  const productIndex = take.items.findIndex(
    item => item.handle === productHandle
  )

  if (productIndex === -1) throw Error(ErrorCodes.PRODUCT_NOT_FOUND)

  take.items[productIndex].notes = notes

  return newState
}
const setNewProtocoleId = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.lastCreatedId = payload

  return newState
}

const addProtocoleFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.FAILED, error: error }

  return newState
}
const addProtocoleLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.add = { status: ActionType.LOADING, error: null }

  return newState
}
const addProtocoleSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = [...newState.list, payload]
  newState.actions.add = { status: ActionType.SUCCESS, error: null }

  return newState
}

const resetDeleteProtocole = state => {
  const newState = cloneDeep(state)
  newState.actions.delete = {
    status: null,
    error: null,
  }

  return newState
}

const deleteProtocoleFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.delete = { status: ActionType.FAILED, error: error }

  return newState
}

const deleteProtocoleSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = newState.list.filter(p => p.id !== payload.id)
  newState.actions.delete = { status: ActionType.SUCCESS, error: null }

  return newState
}

const deleteProtocoleLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.delete = {
    status: ActionType.LOADING,
    error: null,
  }

  return newState
}

const addTake = state => {
  const newState = cloneDeep(state)
  newState.newDraft.takes.push({
    index: newState.newDraft.takes.length + 1,
    label: '',
    type: TakeType.IN_CURE,
    items: [],
  })

  return newState
}
const setTakeLabel = (state, { payload }) => {
  const newState = cloneDeep(state)
  const { label, takeIndex } = payload
  const {
    newDraft: { takes },
  } = newState
  const take = getTakeByIndex(takes, takeIndex)
  take.label = label

  return newState
}

const pushProtocoleStatusFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const pushProtocoletatusLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = { status: ActionType.LOADING }

  return newState
}
const pushProtocoleStatusSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const protocoleIndex = newState.list.findIndex(({ id }) => id === payload.id)

  if (protocoleIndex === -1) {
    // TODO: push sentry error
    console.warn(`Precription not in the list -> ID: ${payload.PROTOCOLE_id}`)
    return state
  }

  const newStatus = {
    created: payload.created,
    status: payload.status,
  }

  newState.list[protocoleIndex].PROTOCOLE_statuses.push(newStatus)
  newState.actions.pushStatus = { status: ActionType.SUCCESS }

  return newState
}

const pushProtocoleStatusReset = state => {
  const newState = cloneDeep(state)
  newState.actions.pushStatus = { status: ActionType.RESET }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_PROTOCOLES_FAILED:
      return fetchProtocolesFailed(state, action)
    case Constants.FETCH_PROTOCOLES_LOADING:
      return fetchProtocolesLoading(state)
    case Constants.FETCH_PROTOCOLES_SUCCESS:
      return fetchProtocolesSuccess(state, action)

    case Constants.FETCH_PROTOCOLE_FAILED:
      return fetchProtocoleFailed(state, action)
    case Constants.FETCH_PROTOCOLE_LOADING:
      return fetchProtocoleLoading(state)
    case Constants.FETCH_PROTOCOLE_SUCCESS:
      return fetchProtocoleSuccess(state, action)

    case Constants.SET_NEW_PROTOCOLE_DRAFT:
      return setNewProtocoleDraft(state, action)
    case Constants.RESET_NEW_PROTOCOLE_DRAFT:
      return resetNewProtocoleDraft(state)
    case Constants.SET_PROTOCOLE_DRAFT_PRODUCT_TAKE:
      return setProtocoleDraftProductTake(state, action)
    case Constants.REMOVE_PRODUCT_FROM_PROTOCOLE_DRAFT_TAKE:
      return removeProductFromDraftTake(state, action)
    case Constants.INCREASE_PRODUCT_QTTY_PROTOCOLE_DRAFT:
      return increaseQttyProductFromDraftTake(state, action)
    case Constants.DECREASE_PRODUCT_PROTOCOLE_QTTY_DRAFT:
      return decreaseQttyProductFromDraftTake(state, action)
    case Constants.SET_PRODUCT_NOTES_PROTOCOLE_DRAFT_TAKE:
      return setProductNotesDraft(state, action)
    case Constants.SET_NEW_PROTOCOLE_ID:
      return setNewProtocoleId(state, action)
    case Constants.ADD_PROTOCOLE_TAKE:
      return addTake(state)
    case Constants.SET_PROTOCOLE_TAKE_LABEL:
      return setTakeLabel(state, action)

    case Constants.ADD_PROTOCOLE_FAILED:
      return addProtocoleFailed(state, action)
    case Constants.ADD_PROTOCOLE_LOADING:
      return addProtocoleLoading(state)
    case Constants.ADD_PROTOCOLE_SUCCESS:
      return addProtocoleSuccess(state, action)
    case Constants.DELETE_PROTOCOLE_FAILED:
      return deleteProtocoleFailed(state, action)
    case Constants.DELETE_PROTOCOLE_SUCCESS:
      return deleteProtocoleSuccess(state, action)
    case Constants.DELETE_PROTOCOLE_LOADING:
      return deleteProtocoleLoading(state)
    case Constants.RESET_DELETE_PROTOCOLE:
      return resetDeleteProtocole(state)

    case Constants.PUSH_PROTOCOLE_STATUS_FAILED:
      return pushProtocoleStatusFailed(state, action)
    case Constants.PUSH_PROTOCOLE_STATUS_LOADING:
      return pushProtocoletatusLoading(state)
    case Constants.PUSH_PROTOCOLE_STATUS_SUCCESS:
      return pushProtocoleStatusSuccess(state, action)
    case Constants.PUSH_PROTOCOLE_STATUS_RESET:
      return pushProtocoleStatusReset(state, action)

    default:
      return state
  }
}
