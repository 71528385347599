export const ProductCategory = {
  BEAUTY: 'Beauté',
  DETOX: 'Detox',
  DIGESTION: 'Digestion',
  ENERGY: 'Energie',
  HAIR_NAILS: 'Cheveux & Ongles',
  IMUNITY: 'Immunité',
  PREGNANCY: 'Grossesse',
  SKIN: 'Peau',
  SLEEP: 'Sommeil',
  STRESS: 'Stress',
  WEIGHT: 'Poids',
  SPORT: 'Sport',
  ARTICULATION: 'Articulations',
  BRAIN: 'Cerveau',
  VIEW: 'Vision',
  CARDIOVASCULAR: 'Cardiovasculaire',
  VEGAN: 'Végane',
  BONES: 'Os',
}

export const ProductHandle = {
  ACEROLA: 'acerola-extra-bio',
  ASHWAGANDHA: 'ashwagandha-extra-bio',
  BOURRACHE: 'bourrache-onagre-bio',
  CHARDON_MARIE: 'chardon-marie-bio',
  COLLAGENE: 'collagene',
  CURCUMA: 'curcuma',
  DIET: 'complete-diet',
  DRAINAGE: 'complexe-drainage',
  ENERGY: 'complexe-energy',
  GLUTAMINE: 'glutamine',
  HAIR_NAIL: 'complexe-cheveux-ongles',
  IRON: 'pidolate-de-fer',
  MAGNESIUM: 'complexe-de-magnesium',
  MELATONINE: 'spray-de-melatonine',
  OMEGA_3_VEGGIE: 'omega-3-veggie',
  OMEGA_3: 'omega-3-epa-dha',
  OSTEOSIN: 'osteosin',
  PAMPLEMOUSSE: 'pepin-de-pamplemousse',
  PRENATAL: 'complexe-prenatal',
  PROBIO: 'probiotique',
  RELAX: 'complexe-relax',
  SUN: 'complete-sun',
  TYROSINE: 'tyrosine',
  V_B12: 'vitamine-b12',
  V_C: 'vitamine-c-liposomale',
  V_D: 'vitamine-d',
  VEGAN_WHEY: 'proteines-vegetales',
  ZINC: 'pidolate-de-zinc',
  COMPLETE_THYROIDE: 'complete-thyroide',
  ENZYMES_DIGESTIVES: 'enzymes-digestives',
  PHYCO: 'phyco',
  LYCO: 'lyco',
  METABO: 'metabo',
  IMMUNO: "immuno"
}
