import { AuthUser } from '../../utils/classes'

export const getAuthUser = ({ auth }) =>
  auth.current ? new AuthUser(auth.current) : null
export const getUserUid = ({ auth }) => auth.userUid
export const getUserToken = ({ auth }) => auth.token
export const getUserIsEmailVerified = ({ auth }) => auth.isEmailVerified

export const getFetchAuthPrescriberStatus = ({ auth }) =>
  auth.actions.fetchAuthUser.status
export const getFetchAuthPrescriberError = ({ auth }) =>
  auth.actions.fetchAuthUser.error
export const getLoginStatus = ({ auth }) => auth.actions.login.status
export const getRegisterStatus = ({ auth }) => auth.actions.register.status
export const getResetPasswordStatus = ({ auth }) =>
  auth.actions.resetPassword.status
export const getResetPasswordError = ({ auth }) =>
  auth.actions.resetPassword.error
export const getSendResetPasswordError = ({ auth }) =>
  auth.actions.sendResetPassword.error
export const getSendResetPasswordStatus = ({ auth }) =>
  auth.actions.sendResetPassword.status
export const getRegisterError = ({ auth }) => auth.actions.register.error
export const getEmailConfirmationStatus = ({ auth }) =>
  auth.actions.verifyEmail.status
export const getEmailConfirmationError = ({ auth }) =>
  auth.actions.verifyEmail.error
export const getGiftCard = ({ auth }) => auth.current.gift_card
