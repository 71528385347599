import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Container, Content } from './style'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import PrescriptionsContainer from '../../components/prescription/prescriptions-container'
import { Button } from '../../components/ui'
import {
  usePrescriptions,
  usePatients,
  useOnboardingProfileCheck,
} from '../../utils/hooks'
// import StartPrescriptionDialog from '../../components/prescription/start-prescription-dialog'

const PrescriptionsPage = ({ location }) => {
  const history = useHistory()
  const [showFilterBar, setShowFilterBar] = useState(false)
  // const [isDialogOpen, setIsDialogOpen] = useState(false)

  useOnboardingProfileCheck()
  usePrescriptions()
  usePatients()

  return (
    <Layout>
      <PageTitle
        title='Mes recommandations'
        subtitle={`Créez et envoyez des recommandations à vos patients. Une recommandation terminée peut être dupliquée via le bouton 'Dupliquer”.`}
        rightElements={
          <Button
            onClick={() => {
              history.push({
                pathname: '/prescriptions/new',
                search: '?step=1',
              })
            }}
            iconLeft='plus'
            appearance='primary'
          >
            Nouvelle recommandation
          </Button>
        }
      />
      <Container>
        <Content>
          <PrescriptionsContainer
            headerCta={
              <>
                <Button
                  style={{ marginRight: '5px' }}
                  onClick={() => setShowFilterBar(!showFilterBar)}
                >
                  Filtres
                </Button>
              </>
            }
            location={location}
            setShowFilterBar={setShowFilterBar}
            showFilterBar={showFilterBar}
            showDashboard={true}
          />
        </Content>
      </Container>
      {/* <StartPrescriptionDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        onGoToNextStep={protocolId => {
          setIsDialogOpen(false)
          let search = `?step=1`
          if (protocolId) {
            search += `&protocoleId=${protocolId}`
          }
          history.push({
            pathname: '/prescriptions/new',
            search,
          })
        }}
      /> */}
    </Layout>
  )
}

export default PrescriptionsPage
