const Constants = {
  ADD_PROTOCOLE_TAKE: 'ADD_TAKE',
  ADD_PROTOCOLE_LOADING: 'ADD_PROTOCOLE_LOADING',
  ADD_PROTOCOLE_SUCCESS: 'ADD_PROTOCOLE_SUCCESS',
  ADD_PROTOCOLE_FAILED: 'ADD_PROTOCOLE_FAILED',
  DELETE_PROTOCOLE_SUCCESS: 'DELETE_PROTOCOLE_SUCCESS',
  DELETE_PROTOCOLE_FAILED: 'DELETE_PROTOCOLE_FAILED',
  DELETE_PROTOCOLE_LOADING: 'DELETE_PROTOCOLE_LOADING',
  RESET_DELETE_PROTOCOLE: 'RESET_DELETE_PROTOCOLE',
  DECREASE_PRODUCT_PROTOCOLE_QTTY_DRAFT:
    'DECREASE_PRODUCT_PROTOCOLE_QTTY_DRAFT',
  FETCH_PROTOCOLE_FAILED: `FETCH_PROTOCOLE_FAILED`,
  FETCH_PROTOCOLE_LOADING: `FETCH_PROTOCOLE_LOADING`,
  FETCH_PROTOCOLE_SUCCESS: `FETCH_PROTOCOLE_SUCCESS`,
  FETCH_PROTOCOLES_FAILED: `FETCH_PROTOCOLES_FAILED`,
  FETCH_PROTOCOLES_LOADING: `FETCH_PROTOCOLES_LOADING`,
  FETCH_PROTOCOLES_SUCCESS: `FETCH_PROTOCOLES_SUCCESS`,
  INCREASE_PRODUCT_QTTY_PROTOCOLE_DRAFT:
    'INCREASE_PRODUCT_QTTY_PROTOCOLE_DRAFT',
  REMOVE_PRODUCT_FROM_PROTOCOLE_DRAFT_TAKE:
    'REMOVE_PRODUCT_FROM_PROTOCOLE_DRAFT_TAKE',
  RESET_NEW_PROTOCOLE_DRAFT: `RESET_NEW_PROTOCOLE_DRAFT`,
  SET_PROTOCOLE_DRAFT_PRODUCT_TAKE: 'SET_PROTOCOLE_DRAFT_PRODUCT_TAKE',
  SET_NEW_PROTOCOLE_DRAFT: `SET_NEW_PROTOCOLE_DRAFT`,
  SET_NEW_PROTOCOLE_ID: 'SET_NEW_PROTOCOLE_ID',
  SET_PRODUCT_NOTES_PROTOCOLE_DRAFT_TAKE:
    'SET_PRODUCT_NOTES_PROTOCOLE_DRAFT_TAKE',
  SET_PROTOCOLE_TAKE_LABEL: 'SET_TAKE_LABEL',
}

export default Constants
