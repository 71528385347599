import styled from 'styled-components/macro'

import { Color } from '../../utils/constants'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  padding: 25px;
  border-radius: 3px;
`

export const Title = styled.h1`
  font-size: 22px;
  text-align: center;
`

export const FooterText = styled.p`
  font-size: 12px;
  max-width: 280px;

  > a {
    color: ${Color.DEEP_SEA};
    text-decoration: none;
  }
`
