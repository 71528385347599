import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import { getAuthUser } from '../store/auth-user'

const AuthenticatedRoute = ({ component: Component, authUser, ...rest }) => {
  const renderRoute = props => <Component {...props} />

  const renderToLogin = props => {
    const redirectUri = `?redirectUri=${props.location.pathname}`

    return (
      <Redirect
        to={{
          pathname: '/login',
          search: redirectUri,
          state: { from: props.location },
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => (authUser ? renderRoute(props) : renderToLogin(props))}
    />
  )
}

const mapStateToProps = state => ({
  authUser: getAuthUser(state),
})

export default connect(mapStateToProps)(AuthenticatedRoute)
