const Constants = {
  ADD_PATIENT_FAILED: `ADD_PATIENT_FAILED`,
  ADD_PATIENT_LOADING: `ADD_PATIENT_LOADING`,
  ADD_PATIENT_SUCCESS: `ADD_PATIENT_SUCCESS`,
  ADD_PATIENT_RESET: `ADD_PATIENT_RESET`,
  DELETE_PATIENT_SUCCESS: `DELETE_PATIENT_SUCCESS`,
  DELETE_PATIENT_FAILED: `DELETE_PATIENT_FAILED`,
  DELETE_PATIENT_LOADING: `DELETE_PATIENT_LOADING`,
  EDIT_PATIENT_FAILED: `EDIT_PATIENT_FAILED`,
  EDIT_PATIENT_LOADING: `EDIT_PATIENT_LOADING`,
  EDIT_PATIENT_RESET: `EDIT_PATIENT_RESET`,
  EDIT_PATIENT_SUCCESS: `EDIT_PATIENT_SUCCESS`,
  FETCH_PATIENTS_FAILED: `FETCH_PATIENTS_FAILED`,
  FETCH_PATIENTS_LOADING: `FETCH_PATIENTS_LOADING`,
  FETCH_PATIENTS_SUCCESS: `FETCH_PATIENTS_SUCCESS`,
}

export default Constants
