import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Form } from './style'
import { Button, Field } from '../../ui'
import {
  resetPassword,
  getResetPasswordStatus,
  getResetPasswordError,
} from '../../../store/auth-user'
import { ActionType } from '../../../utils/constants'

const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Minimum 8 charactères requis')
    .required('Champs requis'),
  passwordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Les mots de passes doivent correspondre'
    )
    .required('Champs requis'),
})

const ResetPasswordForm = ({ history, oobCode }) => {
  const dispatch = useDispatch()
  const resetPasswordStatus = useSelector(getResetPasswordStatus)
  const resetPasswordError = useSelector(getResetPasswordError)
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        password: '',
        passwordConfirm: '',
      },
      validationSchema: ResetPasswordSchema,
      onSubmit: values => {
        dispatch(resetPassword(values.password, oobCode))
      },
    })

  useEffect(() => {
    let errorMsg = ''
    switch (resetPasswordError) {
      case 'auth/weak-password':
        errorMsg =
          "Le niveau de sécurité du mot de passe entré est trop faible, merci d'en fournir un autre"
        break
      default:
        errorMsg =
          'Il y a eu une erreur dans la réinitialisation de votre mot de passe, merci de réessayer'
    }
    if (
      resetPasswordStatus !== ActionType.LOADING &&
      resetPasswordStatus === ActionType.FAILED
    ) {
      toast.error(errorMsg)
      return
    }
    if (resetPasswordStatus === ActionType.SUCCESS) {
      toast.success('Le mot de passe a été réinitialisé avec succès!')
      history.push('/')
    }
  }, [resetPasswordStatus, resetPasswordError, history])

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Nouveau mot de passe'
        name='password'
        id='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={resetPasswordStatus === ActionType.LOADING}
        isRequired
      />
      <Field
        label='Confirmez votre mot de passe'
        name='passwordConfirm'
        id='passwordConfirm'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.passwordConfirm}
        error={errors.passwordConfirm}
        touched={touched.passwordConfirm}
        isDisabled={resetPasswordStatus === ActionType.LOADING}
        isRequired
      />
      <Button
        type='submit'
        isLoading={resetPasswordStatus === ActionType.LOADING}
      >
        Réinitialiser son mot de passe
      </Button>
    </Form>
  )
}

export default ResetPasswordForm
