import store from '../index'
import { getProducts } from '../product'
import { getBaseAppLink } from '../../utils/helpers'
import { Prescription } from '../../utils/classes'

export const getPrescriptions = ({ prescription }) =>
  prescription.list.map(p => new Prescription(p))

export const getPrescriptionById = ({ prescription }, id) => {
  const p = prescription.list.find(p => p.id === id)

  if (!p) return null

  return new Prescription(p)
}

export const getPrescriptionNewDraft = ({ prescription }) =>
  prescription.newDraft

// Statuses
export const getFetchPrescriptionsStatus = ({ prescription }) =>
  prescription.actions.fetchAll.status
export const getFetchPrescriptionStatus = ({ prescription }) =>
  prescription.actions.fetchOne.status
export const getAddPrescriptionStatus = ({ prescription }) =>
  prescription.actions.add.status
export const getDeletePrescriptionStatus = ({ prescription }) =>
  prescription.actions.delete.status
export const getUpdatePrescriptionStatus = ({ prescription }) =>
  prescription.actions.update.prescription.status
export const getAddPrescriptionTakeStatus = ({ prescription }) =>
  prescription.actions.update.take.add.status
export const getDeletePrescriptionTakeStatus = ({ prescription }) =>
  prescription.actions.update.take.delete.status
export const getSendByEmailStatus = ({ prescription }) =>
  prescription.actions.sendByEmail.status
export const getPushPrescriptionStatusStatus = ({ prescription }) =>
  prescription.actions.pushStatus.status
export const getNewlyCreatedPrescription = ({ prescription }) => {
  if (!prescription.lastCreatedId) return null

  const p = prescription.list.find(p => p.id === prescription.lastCreatedId)

  if (!p) return null

  return new Prescription(p)
}

export const getPrescriptionPrice = (state, id) => {
  const prescription = getPrescriptionById(state, id)
  const products = getProducts(store.getState())

  if (
    !products.length ||
    !prescription ||
    !prescription.lastStatus.takes.length
  )
    return 0

  const totalPrice = prescription.lastStatus.takes.reduce(
    (takeAccPrice, currentTake) => {
      return (
        takeAccPrice +
        currentTake.items.reduce((itemAccPrice, currentItem) => {
          const product = products.find(
            p => p.handle === currentItem.productHandle
          )

          return (
            itemAccPrice +
            Number(product.getPrice(prescription.recommendedDuration)) *
              Number(currentItem.quantity)
          )
        }, 0)
      )
    },
    0
  )

  return totalPrice.toFixed(2)
}

export const getPrescriptionRecoLink = ({ prescription }, id) => {
  if (!prescription.list.length) return null

  const prescriptionData = prescription.list.find(p => p.id === id)

  if (!prescriptionData) return null

  return `${getBaseAppLink()}r/${id}`
}

export const getPrescriptionDraftPrice = ({ prescription }) => {
  if (!prescription.newDraft.takes.length) return 0.0

  const products = getProducts(store.getState())

  if (!products.length) return 0

  const totalPrice = prescription.newDraft.takes.reduce(
    (takeAccPrice, currentTake) => {
      return (
        takeAccPrice +
        currentTake.items.reduce((itemAccPrice, currentItem) => {
          const product = products.find(p => p.handle === currentItem.handle)

          return (
            itemAccPrice +
            Number(
              product.getPrice(`${prescription.newDraft.duration}-month`)
            ) *
              Number(currentItem.quantity)
          )
        }, 0)
      )
    },
    0
  )

  return totalPrice.toFixed(2)
}

export const getIsStatusSendedManually = ({ prescription }) =>
  prescription.isStatusSendedManually
