import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const PillImageContainer = styled.div`
  width: ${({ $isSmall }) => ($isSmall ? '30px' : '45px')};
  height: ${({ $isSmall }) => ($isSmall ? '30px' : '45px')};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ $bg }) => `url("${$bg}")`};
`

export const PillImage = styled.img`
  width: ${({ $isSmall }) => ($isSmall ? '15px' : '30px')};
  height: ${({ $isSmall }) => ($isSmall ? '15px' : '30px')};
  object-fit: contain;
`
export const PillText = styled.p`
  font-size: 22px;
  color: white;
  margin-top: -2px;
`
