import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, Spinner, Icon } from '../../../components/ui'
import {
  ActionSection,
  BodySection,
  Container,
  Content,
  HeaderSection,
  MainTitle,
  Note,
  P,
  SecondaryTitle,
  ProtocolePageHeader,
  ButtonWrapper,
  SubTitle,
} from './style'
import { BreadCrumbsContainer } from '../../patients/patient/style'
import Layout from '../../../components/layout/default'
import { getProtocolePrice, getProtocoleById } from '../../../store/protocole'
import {
  useProtocoles,
  useDeleteProtocole,
  useAuthPrescriber,
} from '../../../utils/hooks'
import { getRecommendationDurationDisplay } from '../../../utils/helpers'
import PrescriptionProductsList from '../../../components/prescription/prescription-products-list'

const ProtocolePage = ({ history, match }) => {
  const { loading: protocolesLoading, success: protocolesSuccess } =
    useProtocoles()
  const protocole = useSelector(state =>
    getProtocoleById(state, Number(match.params.id))
  )
  const prescriptionPrice = useSelector(state =>
    getProtocolePrice(state, protocole?.id)
  )
  const { authUser } = useAuthPrescriber()

  const {
    success: deleteProtocoleSuccess,
    loading: deleteProtocoleLoading,
    failed: deleteProtocoleFailed,
    deleteProtocole,
  } = useDeleteProtocole()

  useEffect(() => {
    if (deleteProtocoleSuccess) {
      history.push('/protocoles/')
    }
    if (deleteProtocoleFailed) {
      toast.error(
        `Il y a eu une erreur dans la supression du protocole, merci de réessayer.`
      )
    }
  }, [deleteProtocoleFailed, history, deleteProtocoleSuccess])

  const onDeleteProtocole = protocole => {
    deleteProtocole({ variables: { id: protocole.id } })
  }

  return (
    <Layout>
      <Container>
        <Content>
          {(protocolesLoading || !protocole) && <Spinner />}
          {protocolesSuccess && protocole && (
            <>
              <ProtocolePageHeader>
                <BreadCrumbsContainer>
                  <Link to='/protocoles'>Protocoles</Link>
                  <Icon name='angle-right' />
                  <span>Protocole {protocole.name}</span>
                </BreadCrumbsContainer>
                {protocole.prescriberId === authUser.id && (
                  <ButtonWrapper>
                    <Button
                      onClick={() => onDeleteProtocole(protocole)}
                      style={{
                        fontFamily: 'Basis Grotesque Pro',
                      }}
                      isDisabled={!protocole}
                      appearance={'red'}
                      isLoading={deleteProtocoleLoading}
                    >
                      Supprimer
                    </Button>
                  </ButtonWrapper>
                )}
              </ProtocolePageHeader>
              <HeaderSection>
                <MainTitle>
                  Détails de la recommandation de votre protocole{' '}
                  {protocole.name}
                </MainTitle>
                {protocole.description && (
                  <SubTitle>{protocole.description}</SubTitle>
                )}
              </HeaderSection>
              <BodySection>
                <PrescriptionProductsList
                  takes={protocole.takes}
                  prescriptionRecommendedDuration={
                    protocole.recommendedDuration
                  }
                />
                <SecondaryTitle>La durée recommandée</SecondaryTitle>
                {getRecommendationDurationDisplay(
                  protocole.recommendedDuration
                )}
                <SecondaryTitle>Message personnel</SecondaryTitle>
                <Note>{protocole.customMessage || '-'}</Note>
                <SecondaryTitle>Le prix</SecondaryTitle>
                <P>
                  €{(Number(prescriptionPrice) / protocole.duration).toFixed(2)}{' '}
                  par mois
                </P>
                <P>
                  Soit €{prescriptionPrice} pour{' '}
                  {getRecommendationDurationDisplay(
                    protocole.recommendedDuration
                  )}
                </P>
              </BodySection>
              <ActionSection>
                <Button
                  appearance='minimal'
                  onClick={() => history.push('/protocoles')}
                >
                  Retour
                </Button>
                <Button
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: '?step=1&protocoleId=' + protocole.id,
                  }}
                  appearance='primary'
                >
                  Utiliser
                </Button>
              </ActionSection>
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default ProtocolePage
