import { Link } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import {
  Container,
  Nav,
  InfoContainer,
  PopupContainer,
  Name,
  Email,
} from './style'
import { Avatar, Dropdown, NavLink } from '../../ui'
import { logout } from '../../../store/auth-user'
import { useAuthPrescriber, useDispatch } from '../../../utils/hooks'
// import { isElectron, getOs } from '../../../utils/helpers'

const AuthState = () => {
  const { authUser } = useAuthPrescriber()
  const dispatch = useDispatch()

  return (
    <Container>
      <Dropdown
        content={
          <PopupContainer>
            <InfoContainer>
              <Avatar
                firstName={authUser?.firstName}
                lastName={authUser?.lastName}
              />
              <Name>{`${authUser?.firstName} ${authUser?.lastName}`}</Name>
              <Email>{authUser?.email}</Email>
            </InfoContainer>
            <Nav>
              <NavLink as={Link} to='/settings/profile'>
                Paramètres
              </NavLink>
              <NavLink
                href='https://complimentme.notion.site/FAQ-7187b78977f740df8b72e66dbb9fd914'
                target='_blank'
              >
                FAQ
              </NavLink>
              <NavLink
                onClick={() => {
                  dispatch(logout())
                  mixpanel.track('Logout')
                }}
                variant='danger'
              >
                Se déconnecter
              </NavLink>
            </Nav>
          </PopupContainer>
        }
      >
        <Avatar firstName={authUser?.firstName} lastName={authUser?.lastName} />
      </Dropdown>
    </Container>
  )
}

export default AuthState
