import { Container, Content, Aside } from './style'
import Layout from '../../../components/layout/default'
import ProfileView from '../../../components/prescriber/profile-view'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import SettingAsideNav from '../../../components/settings/aside-nav'

const ProfilePage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout>
      <Container>
        <Aside>
          <SettingAsideNav />
        </Aside>
        <Content>
          <ProfileView />
        </Content>
      </Container>
    </Layout>
  )
}

export default ProfilePage
