import HomePage from '../pages/home'
import LoginPage from '../pages/login'
import UserActions from '../pages/user-actions'
import ResetPassword from '../pages/reset-password'
import EmailConfirmation from '../pages/email-confirmation'
import NewPatientPage from '../pages/patients/new-patient'
import NewPrescriptionConfirmPage from '../pages/prescriptions/new-prescription-confirm'
import NewPrescriptionPage from '../pages/prescriptions/new-prescription'
import PatientPage from '../pages/patients/patient'
import PatientsPage from '../pages/patients'
import PrescriptionPage from '../pages/prescriptions/prescription'
import PrescriptionsPage from '../pages/prescriptions'
import ProfilePage from '../pages/settings/profile'
import PaymentPage from '../pages/settings/payment'
import RegisterPage from '../pages/register'
import RecommendationPage from '../pages/recommendation'
import ProductsPage from '../pages/products'
import OnboardingProfileInfo from '../pages/onboarding/profile-info'
import ProtocolesPage from '../pages/protocoles'
import ProtocolePage from '../pages/protocoles/protocole'
import NewProtocolePage from '../pages/protocoles/NewProtocole'
import IntegrationsPage from '../pages/integrations/'
import OrdersPage from '../pages/orders'

const routes = [
  { c: HomePage, path: '/', auth: true },
  { c: LoginPage, path: '/login' },
  { c: RegisterPage, path: '/register' },
  { c: UserActions, path: '/user-actions/:mode?/:oobcode?' },
  { c: ResetPassword, path: '/reset-password/:mode?/:oobcode?' },
  { c: EmailConfirmation, path: '/email-confirm/:mode?/:oobcode?' },
  { c: PatientsPage, path: '/patients', auth: true },
  { c: NewPatientPage, path: '/patients/new', auth: true },
  { c: PatientPage, path: '/patients/:id', auth: true },
  { c: PrescriptionsPage, path: '/prescriptions', auth: true },
  { c: NewPrescriptionPage, path: '/prescriptions/new', auth: true },
  { c: NewPrescriptionConfirmPage, path: '/prescriptions/confirm', auth: true },
  { c: PrescriptionPage, path: '/prescriptions/:id', auth: true },
  { c: ProfilePage, path: '/settings/profile', auth: true },
  { c: PaymentPage, path: '/settings/payment', auth: true },
  { c: RecommendationPage, path: '/r/:prescriptionId', isPublic: true },
  { c: ProductsPage, path: '/catalog', isPublic: true },
  { c: OnboardingProfileInfo, path: '/onboarding/profile', auth: true },
  { c: ProtocolesPage, path: '/protocoles', auth: true },
  { c: NewProtocolePage, path: '/protocoles/new', auth: true },
  { c: ProtocolePage, path: '/protocoles/:id', auth: true },
  { c: IntegrationsPage, path: '/integrations', auth: true },
  { c: OrdersPage, path: '/orders', auth: true },
]

export default routes
