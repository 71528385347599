import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'

import { ActionType } from '../constants'
import {
  addProtocoleFailed,
  addProtocoleLoading,
  addProtocoleSuccess,
  deleteProtocoleFailed,
  deleteProtocoleLoading,
  deleteProtocoleSuccess,
  fetchProtocoleFailed,
  fetchProtocoleLoading,
  fetchProtocolesFailed,
  fetchProtocolesLoading,
  fetchProtocolesSuccess,
  fetchProtocoleSuccess,
  getAddProtocoleStatus,
  getDeleteProtocoleStatus,
  getFetchProtocolesStatus,
  getFetchProtocoleStatus,
  getProtocoleById,
  getProtocoles,
} from '../../store/protocole'
import {
  ADD_PROTOCOLE,
  PROTOCOLES,
  DELETE_PROTOCOLE,
} from '../../services/queries'
import { getUserToken } from '../../store/auth-user'

export const useAddProtocole = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const addProtocoleStatus = useSelector(getAddProtocoleStatus)
  const [
    addProtocole,
    { data: protocoleData, loading: protocoleLoading, error: protocoleError },
  ] = useMutation(ADD_PROTOCOLE, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync addProtocole with store
   */
  useEffect(() => {
    protocoleLoading && dispatch(addProtocoleLoading())
  }, [protocoleLoading, dispatch])
  useEffect(() => {
    protocoleData && dispatch(addProtocoleSuccess(protocoleData))
  }, [protocoleData, dispatch])
  useEffect(() => {
    protocoleError && dispatch(addProtocoleFailed(protocoleError))
  }, [protocoleError, dispatch])

  return {
    addProtocole,
    data: protocoleData,
    failed: addProtocoleStatus === ActionType.FAILED,
    loading: addProtocoleStatus === ActionType.LOADING,
    success: addProtocoleStatus === ActionType.SUCCESS,
  }
}

export const useDeleteProtocole = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const deleteProtocoleStatus = useSelector(getDeleteProtocoleStatus)
  const [
    deleteProtocole,
    { data: protocoleData, loading: protocoleLoading, error: protocoleError },
  ] = useMutation(DELETE_PROTOCOLE, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })
  /**
   * Sync deleteProtocole with store
   */
  useEffect(() => {
    protocoleLoading && dispatch(deleteProtocoleLoading())
  }, [protocoleLoading, dispatch])
  useEffect(() => {
    protocoleData && dispatch(deleteProtocoleSuccess(protocoleData))
  }, [protocoleData, dispatch])
  useEffect(() => {
    protocoleError && dispatch(deleteProtocoleFailed(protocoleError))
  }, [protocoleError, dispatch])
  return {
    deleteProtocole,
    data: protocoleData,
    loading: deleteProtocoleStatus === ActionType.LOADING,
    failed: deleteProtocoleStatus === ActionType.FAILED,
    success: deleteProtocoleStatus === ActionType.SUCCESS,
  }
}

export const useProtocoles = () => {
  const dispatch = useDispatch()
  const fetchProtocolesStatus = useSelector(getFetchProtocolesStatus)
  const protocoles = useSelector(getProtocoles)
  const authToken = useSelector(getUserToken)
  const [
    fetchProtocoles,
    {
      data: protocolesData,
      loading: protocolesLoading,
      error: protocolesError,
    },
  ] = useLazyQuery(PROTOCOLES, {
    context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  useEffect(() => {
    fetchProtocolesStatus !== ActionType.SUCCESS &&
      fetchProtocolesStatus !== ActionType.FAILED &&
      fetchProtocoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync useProtocoles with store
   */
  useEffect(() => {
    protocolesLoading &&
      fetchProtocolesStatus !== ActionType.LOADING &&
      dispatch(fetchProtocolesLoading())
  }, [protocolesLoading, dispatch, fetchProtocolesStatus])
  useEffect(() => {
    protocolesData &&
      fetchProtocolesStatus !== ActionType.SUCCESS &&
      dispatch(fetchProtocolesSuccess(protocolesData))
  }, [protocolesData, dispatch, fetchProtocolesStatus])
  useEffect(() => {
    protocolesError &&
      fetchProtocolesStatus !== ActionType.FAILED &&
      dispatch(fetchProtocolesFailed(protocolesError))
  }, [protocolesError, dispatch, fetchProtocolesStatus])

  return {
    data: protocoles,
    failed: fetchProtocolesStatus === ActionType.FAILED,
    loading: fetchProtocolesStatus === ActionType.LOADING,
    success: fetchProtocolesStatus === ActionType.SUCCESS,
  }
}

/**
 * @param {number} id Protocole id
 */
export const useProtocole = id => {
  const dispatch = useDispatch()
  const fetchProtocoleStatus = useSelector(getFetchProtocoleStatus)
  const Protocole = useSelector(state => getProtocoleById(state, id))
  const [
    fetchProtocole,
    { data: ProtocoleData, loading: ProtocoleLoading, error: ProtocoleError },
  ] = useLazyQuery(Protocole, {
    variables: { id },
  })

  useEffect(() => {
    !Protocole &&
      fetchProtocoleStatus !== ActionType.LOADING &&
      fetchProtocole()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    ProtocoleLoading && dispatch(fetchProtocoleLoading())
  }, [ProtocoleLoading, dispatch])
  useEffect(() => {
    ProtocoleData && dispatch(fetchProtocoleSuccess(ProtocoleData))
  }, [ProtocoleData, dispatch])
  useEffect(() => {
    ProtocoleError && dispatch(fetchProtocoleFailed(ProtocoleError))
  }, [ProtocoleError, dispatch])

  return {
    data: Protocole,
    failed: fetchProtocoleStatus === ActionType.FAILED,
    loading: fetchProtocoleStatus === ActionType.LOADING,
    success: fetchProtocoleStatus === ActionType.SUCCESS,
  }
}
