import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  Container,
  Img,
  Name,
  InfoContainer,
  Info,
  Price,
  P,
  NotesContainer,
  Left,
  Right,
  Quantity,
  Minus,
  Number,
  Plus,
  MoreInfo,
  Remvove,
} from './style'
import { Icon } from '../../ui'
import ProductDialog from '../product-dialog'
import { getProductByHandle } from '../../../store/product'
import { getProductPicture, removePlural } from '../../../utils/helpers'

const ProductTile = ({
  handle,
  quantity,
  notes,
  recommendedDuration,
  isStandAlone,
  AbilityToManageQuantity,
  cart,
  updateVariantToCart,
  removeVariantsToCart,
  from,
  updateCartLoading,
  editMode,
}) => {
  const product = useSelector(state => getProductByHandle(state, handle))

  const nbMonth = recommendedDuration.split('-')[0]
  const nbPerDay = (quantity * product.formatQuantity) / 30
  const formatText =
    nbPerDay < 2 ? removePlural(product.formatType) : product.formatType
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  let cartItem = null

  if (cart?.lines.edges) {
    cartItem = cart?.lines.edges.find(
      i => i.node.merchandise.product.handle === handle
    )
  }

  return (
    <Container
      style={
        from === 'recommendationPage'
          ? { display: cartItem ? 'flex' : 'none' }
          : { display: 'flex' }
      }
    >
      <Img
        onClick={() => setIsDialogOpen(true)}
        alt={product.name}
        src={getProductPicture(product.handle)}
      />
      <InfoContainer>
        <Info>
          <Left>
            <Name onClick={() => setIsDialogOpen(true)}>
              {product.name}
              <MoreInfo>Plus d'infos</MoreInfo>
            </Name>

            {editMode && AbilityToManageQuantity && (
              <Quantity>
                {/* Quantité:&nbsp;&nbsp;&nbsp; */}
                <Minus
                  onClick={() => {
                    if (cartItem?.node.quantity === 1)
                      removeVariantsToCart(cart?.id, [cartItem.node.id])
                    else
                      updateVariantToCart(
                        cart?.id,
                        cartItem.node.id,
                        cartItem.node.quantity - 1
                      )
                  }}
                />

                <Number>
                  {cartItem?.node.quantity
                    ? cartItem?.node.quantity * product.formatQuantity
                    : product.formatQuantity}
                </Number>

                <Plus
                  onClick={() =>
                    updateVariantToCart(
                      cart?.id,
                      cartItem.node.id,
                      cartItem.node.quantity + 1
                    )
                  }
                />
              </Quantity>
            )}
          </Left>
          <Right>
            <Price>
              €{product.getPrice() * quantity}
              {!isStandAlone && <small>/mois</small>}
            </Price>
            <P>
              {isStandAlone
                ? `${quantity} x ${product.format}`
                : `${nbPerDay} ${formatText}/jours pendant ${nbMonth} mois`}
            </P>
          </Right>
          {editMode && AbilityToManageQuantity && (
            <Remvove
              onClick={() => {
                removeVariantsToCart(cart?.id, [cartItem.node.id])
              }}
            >
              <Icon width='12px' name='cancel' />
            </Remvove>
          )}
        </Info>
        {notes && (
          <NotesContainer>
            <P>{notes}</P>
          </NotesContainer>
        )}
      </InfoContainer>
      <ProductDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        name={product.name}
        headline={product.headline}
        pillPicture={product.pillPicture}
        strongPoints={product.strongPoints}
        ingredients={product.ingredients}
        whyRecommendIt={product.whyRecommendIt}
        forWho={product.forWho}
        benefits={product.benefits}
        contraindication={product.contraindication}
        deficiencies={product.deficiencies}
        handle={product.handle}
      />
    </Container>
  )
}

ProductTile.propTypes = {
  handle: PropTypes.string.isRequired,
  notes: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  isStandAlone: PropTypes.bool,
}

ProductTile.defaultProps = {
  isStandAlone: false,
}

export default ProductTile
