import React from 'react'
import PropTypes from 'prop-types'

import { Container, InfoContainer, P, Label } from './style'
import { Avatar } from '../../ui'

const PatientTile = ({ patient }) => (
  <Container>
    <Avatar size='l' firstName={patient?.firstname} lastName={patient?.name} />
    <InfoContainer>
      <Label>Nom</Label>
      <P>{`${patient?.firstname} ${patient?.name}`}</P>
      <Label>Email</Label>
      <P>{patient?.email}</P>
      <Label>Téléphone</Label>
      <P>{patient?.phone_number || '-'}</P>
    </InfoContainer>
  </Container>
)

PatientTile.propTypes = {
  patient: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
}

export default PatientTile
