import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Container, Content, Title } from './style'
import Layout from '../../../components/layout/action'
import { Button } from '../../../components/ui'
import CreatePatientForm from '../../../components/patient/create-patient-form'
import {
  useAddPatient,
  useOnboardingProfileCheck,
  useAuthPrescriber,
} from '../../../utils/hooks'
import { addPatientReset } from '../../../store/patient'
import { toast } from 'react-toastify'
import mixpanel from 'mixpanel-browser'

const NewPatientPage = ({ history }, props) => {
  useOnboardingProfileCheck()
  const dispatch = useDispatch()
  const { authUser } = useAuthPrescriber()
  const {
    addPatient,
    data: patientData,
    loading: patientLoading,
    success: patientSuccess,
  } = useAddPatient()

  useEffect(() => {
    patientSuccess && dispatch(addPatientReset()) && history.push('/patients')
  }, [history, patientData, dispatch, patientSuccess])

  const onFormSubmit = formData => {
    if (formData.email === authUser.email) {
      toast.error(
        "L'email entrée est la même que la vôtre, merci de réessayer avec une autre adresse e-mail."
      )
      return
    }
    if (formData.email && formData.first && formData.last) {
      addPatient({
        variables: {
          email: formData.email,
          fn: formData.first,
          ln: formData.last,
          phone: formData.phone,
          prescriberId: authUser.id,
        },
      })
      mixpanel.track('New patient', {
        email: formData.email,
        prescriberFullName: authUser.fullName,
        prescriberId: authUser.id,
      })
    }
  }

  return (
    <Layout
      top={
        <Button
          as={Link}
          to='/patients'
          appearance='minimal'
          iconLeft='arrow-left'
        >
          Retour
        </Button>
      }
    >
      <Container>
        <Content>
          <Title>Créer un nouveau patient</Title>
          <CreatePatientForm
            onSubmit={onFormSubmit}
            isLoading={patientLoading}
          />
        </Content>
      </Container>
    </Layout>
  )
}

export default NewPatientPage
