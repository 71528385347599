import axios from 'axios'

import Constants from './constants'
import { auth } from '../../services/firebase'

export const fetchAuthUserLoading = () => ({
  type: Constants.FETCH_AUTH_USER_LOADING,
})
export const fetchAuthUserSuccess = payload => ({
  type: Constants.FETCH_AUTH_USER_SUCCESS,
  payload: payload.prescriber[0],
})
export const fetchAuthUserFailed = error => ({
  type: Constants.FETCH_AUTH_USER_FAILED,
  error,
})
export const fetchAuthUserReset = () => ({
  type: Constants.FETCH_AUTH_USER_RESET,
})

const loginLoading = () => ({ type: Constants.LOGIN_LOADING })
const loginSuccess = payload => ({ type: Constants.LOGIN_SUCCESS, payload })
const loginFailed = error => ({ type: Constants.LOGIN_FAILED, error })

/**
 * Login user
 * @return {void}
 */
export const login = data => dispatch => {
  dispatch(loginLoading())
  auth
    .signInWithEmailAndPassword(data.email, data.password)
    .then(() => {
      // No need to handle the success here
      dispatch(loginSuccess())
    })
    .catch(error => {
      dispatch(loginFailed(error.code))
    })
}

const registerLoading = () => ({ type: Constants.REGISTER_LOADING })
const registerSuccess = payload => ({
  type: Constants.REGISTER_SUCCESS,
  payload,
})
const registerFailed = error => ({ type: Constants.REGISTER_FAILED, error })

const resetPasswordLoading = () => ({ type: Constants.RESET_PASSWORD_LOADING })
const resetPasswordSuccess = payload => ({
  type: Constants.RESET_PASSWORD_SUCCESS,
  payload,
})

const confirmEmailSuccess = payload => ({
  type: Constants.CONFIRM_EMAIL_SUCCESS,
  payload,
})
const confirmEmailFailed = error => ({
  type: Constants.CONFIRM_EMAIL_FAILED,
  error,
})

const resetPasswordFailed = error => ({
  type: Constants.RESET_PASSWORD_FAILED,
  error,
})
const sendResetPasswordSuccess = payload => ({
  type: Constants.SEND_RESET_PASSWORD_SUCCESS,
  payload,
})
const sendResetPasswordFailed = error => ({
  type: Constants.SEND_RESET_PASSWORD_FAILED,
  error,
})
/**
 * Register user
 * @return {void}
 */
export const register = data => dispatch => {
  dispatch(registerLoading())
  axios
    .post(process.env.REACT_APP_SIGNUP_ENDPOINT, data)
    .then(({ data }) => {
      dispatch(registerSuccess())
    })
    .catch(error => {
      let message = "Un erreur s'est produite, nous travaillons dessus."
      if (
        error?.response?.data?.error &&
        error?.response?.data?.error.includes('already')
      ) {
        message = 'Cet email est déjà utilisé'
      }
      dispatch(registerFailed(message))
    })
}

export const sendResetPasswordEmail = email => dispatch => {
  auth
    .sendPasswordResetEmail(email)
    .then(() => dispatch(sendResetPasswordSuccess()))
    .catch(error => dispatch(sendResetPasswordFailed(error.code)))
}

export const resetPassword = (newPassword, code) => dispatch => {
  dispatch(resetPasswordLoading())
  auth
    .verifyPasswordResetCode(code)
    .then(email => {
      auth
        .confirmPasswordReset(code, newPassword)
        .then(() => {
          dispatch(resetPasswordSuccess())
          auth.signInWithEmailAndPassword(email, newPassword)
        })
        .catch(error => dispatch(resetPasswordFailed(error.code)))
    })
    .catch(error => dispatch(resetPasswordFailed(error.code)))
}

export const verifyEmail = code => dispatch => {
  auth
    .applyActionCode(code)
    .then(data => {
      dispatch(confirmEmailSuccess())
    })
    .catch(e => dispatch(confirmEmailFailed(e.Code)))
}

const logoutLoading = () => ({ type: Constants.LOGOUT_LOADING })
const logoutSuccess = payload => ({
  type: Constants.LOGOUT_SUCCESS,
  payload,
})
const logoutFailed = error => ({ type: Constants.LOGOUT_FAILED, error })

export const setUid = payload => ({ type: Constants.SET_UID, payload })
export const setToken = payload => ({ type: Constants.SET_TOKEN, payload })
export const setIsEmailVerified = payload => ({
  type: Constants.SET_IS_EMAIL_VERIFIED,
  payload,
})
export const setAuthPrescriber = payload => ({
  type: Constants.SET_AUTH_PRESCRIBER,
  payload,
})

/**
 * Logout user
 * @return {void}
 */
export const logout = () => dispatch => {
  dispatch(logoutLoading())
  auth
    .signOut()
    .then(() => {
      dispatch(logoutSuccess())
    })
    .catch(error => {
      dispatch(logoutFailed(error.code))
    })
}
