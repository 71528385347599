import axios from 'axios'

import Constants from './constants'

export const fetchPrescriptionsLoading = () => ({
  type: Constants.FETCH_PRESCRIPTIONS_LOADING,
})
export const fetchPrescriptionsSuccess = payload => ({
  type: Constants.FETCH_PRESCRIPTIONS_SUCCESS,
  payload: payload.prescription,
})
export const fetchPrescriptionsFailed = error => ({
  type: Constants.FETCH_PRESCRIPTIONS_FAILED,
  error,
})

export const fetchPrescriptionLoading = () => ({
  type: Constants.FETCH_PRESCRIPTION_LOADING,
})
export const fetchPrescriptionSuccess = payload => ({
  type: Constants.FETCH_PRESCRIPTION_SUCCESS,
  payload: payload.prescription[0],
})
export const fetchPrescriptionFailed = error => ({
  type: Constants.FETCH_PRESCRIPTION_FAILED,
  error,
})

export const setNewPrescriptionDraft = payload => ({
  type: Constants.SET_NEW_PRESCRIPTION_DRAFT,
  payload,
})
export const setPrescriptionDraftProductTake = payload => ({
  type: Constants.SET_PRESCRIPTION_DRAFT_PRODUCT_TAKE,
  payload,
})
export const setProtocoleToDraft = payload => ({
  type: Constants.SET_PROTOCOLE_TO_DRAFT,
  payload,
})
export const setPrescriptionToDraft = payload => ({
  type: Constants.SET_PRESCRIPTION_TO_DRAFT,
  payload,
})
export const resetProtocoleToDraft = () => ({
  type: Constants.RESET_PROTOCOLE_DRAFT,
})
export const resetDeletePrescription = () => ({
  type: Constants.RESET_DELETE_PRESCRIPTION,
})
export const removeProductFromDraftTake = payload => ({
  type: Constants.REMOVE_PRODUCT_FROM_PRESCRIPTION_DRAFT_TAKE,
  payload,
})
export const resetNewPrescriptionDraft = payload => ({
  type: Constants.RESET_NEW_PRESCRIPTION_DRAFT,
  payload,
})
export const decreaseQttyProductFromDraftTake = payload => ({
  type: Constants.DECREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT,
  payload,
})
export const increaseQttyProductFromDraftTake = payload => ({
  type: Constants.INCREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT,
  payload,
})
export const setProductNotesDraft = payload => ({
  type: Constants.SET_PRODUCT_NOTES_PRESCRIPTION_DRAFT_TAKE,
  payload,
})
export const setNewPrescriptionId = payload => ({
  type: Constants.SET_NEW_PRESCRIPTION_ID,
  payload,
})
export const addPrescriptionTake = payload => ({
  type: Constants.ADD_PRESCRIPTION_TAKE,
  payload,
})
export const removePrescriptionTake = payload => ({
  type: Constants.REMOVE_PRESCRIPTION_TAKE,
  payload,
})
export const setTakeLabel = payload => ({
  type: Constants.SET_PRESCRIPTION_TAKE_LABEL,
  payload,
})

export const addPrescriptionLoading = () => ({
  type: Constants.ADD_PRESCRIPTION_LOADING,
})
export const addPrescriptionSuccess = payload => ({
  type: Constants.ADD_PRESCRIPTION_SUCCESS,
  payload: payload.insert_prescription.returning[0],
})
export const addPrescriptionFailed = error => ({
  type: Constants.ADD_PRESCRIPTION_FAILED,
  error,
})
export const updatePrescriptionLoading = () => ({
  type: Constants.UPDATE_PRESCRIPTION_LOADING,
})
export const updatePrescriptionSuccess = payload => ({
  type: Constants.UPDATE_PRESCRIPTION_SUCCESS,
  payload: payload.update_prescription_by_pk,
})
export const updatePrescriptionFailed = error => ({
  type: Constants.UPDATE_PRESCRIPTION_FAILED,
  error,
})
export const addPrescriptionTakeLoading = () => ({
  type: Constants.ADD_PRESCRIPTION_TAKE_LOADING,
})
export const deletePrescriptionTakeLoading = () => ({
  type: Constants.DELETE_PRESCRIPTION_TAKE_LOADING,
})
export const deletePrescriptionTakeSuccess = payload => ({
  type: Constants.DELETE_PRESCRIPTION_TAKE_SUCCESS,
  payload: payload.delete_prescription_take.returning[0],
})
export const deletePrescriptionTakeFailed = error => ({
  type: Constants.DELETE_PRESCRIPTION_TAKE_FAILED,
  error,
})
export const addPrescriptionTakeSuccess = payload => ({
  type: Constants.ADD_PRESCRIPTION_TAKE_SUCCESS,
  payload: payload.insert_prescription_take.returning,
})
export const addPrescriptionTakeFailed = error => ({
  type: Constants.ADD_PRESCRIPTION_TAKE_FAILED,
  error,
})
export const deletePrescriptionLoading = () => ({
  type: Constants.DELETE_PRESCRIPTION_LOADING,
})
export const deletePrescriptionSuccess = payload => ({
  type: Constants.DELETE_PRESCRIPTION_SUCCESS,
  payload: payload.delete_prescription_by_pk,
})
export const deletePrescriptionFailed = error => ({
  type: Constants.DELETE_PRESCRIPTION_FAILED,
  error,
})

const sendByEmailLoading = () => ({ type: Constants.SEND_BY_EMAIL_LOADING })
const sendByEmailSuccess = payload => ({
  type: Constants.SEND_BY_EMAIL_SUCCESS,
  payload,
})
const sendByEmailFailed = error => ({
  type: Constants.SEND_BY_EMAIL_FAILED,
  error,
})
export const sendByEmailReset = () => ({ type: Constants.SEND_BY_EMAIL_RESET })

export const sendByEmail = data => dispatch => {
  dispatch(sendByEmailLoading())
  axios
    .post('https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4', data)
    .then(({ data }) => {
      dispatch(sendByEmailSuccess(data))
    })
    .catch(error => {
      dispatch(sendByEmailFailed(error.code))
    })
}

export const pushPrescriptionStatusLoading = () => ({
  type: Constants.PUSH_PRESCRIPTION_STATUS_LOADING,
})
export const pushPrescriptionStatusSuccess = payload => ({
  type: Constants.PUSH_PRESCRIPTION_STATUS_SUCCESS,
  payload: payload.insert_prescription_status.returning[0],
})
export const pushPrescriptionStatusFailed = error => ({
  type: Constants.PUSH_PRESCRIPTION_STATUS_FAILED,
  error,
})

export const pushPrescriptionStatusReset = () => ({
  type: Constants.PUSH_PRESCRIPTION_STATUS_RESET,
})

export const setIsStatusSendedManually = () => ({
  type: Constants.SET_IS_STATUS_SENDED_MANUALLY,
})

export const setIsStatusSendedManuallyReset = () => ({
  type: Constants.SET_IS_STATUS_SENDED_MANUALLY_RESET,
})
