class Item {
  data = null

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get productHandle() {
    return this.data.product_handle
  }

  get quantity() {
    return this.data.quantity
  }

  get notes() {
    return this.data.notes
  }
}

export default Item
