import { Container, PillImageContainer, PillImage, PillText } from './style'
import { useProducts } from '../../../utils/hooks'
import { Tooltip } from '../../ui'

const getLabels = products =>
  products?.reduce((acc, curr) => (acc ? `${acc}, ${curr?.name}` : curr?.name), '')

const ProductsPreviewContainer = ({ productHandles, size = 'm' }) => {
  const { data, loading } = useProducts()

  if (loading) return null

  let productToDisplay =
    data?.filter(({ handle }) => productHandles?.includes(handle)) || []
  const productOverFlow =
    productToDisplay?.length > 4 ? productToDisplay?.slice(4) : []
  let nonOverflow = []
  if (productOverFlow?.length) {
    nonOverflow = productToDisplay?.splice(0, 4)
  }
  const finalProductsToDisplay = nonOverflow?.length
    ? nonOverflow
    : productToDisplay

  return (
    <Container>
      {finalProductsToDisplay?.map((p, index) => (
        <Tooltip
          key={p?.handle}
          label={p?.name}
          style={{ marginLeft: !!index ? '-20px' : '0' }}
        >
          <PillImageContainer
            $isSmall={size === 's'}
            $bg={
              process.env.REACT_APP_AWS_BUCKET +
              'assets/images/product-background.jpg'
            }
          >
            <PillImage src={p.pillPicture} $isSmall={size === 's'} />
          </PillImageContainer>
        </Tooltip>
      ))}
      {!!productOverFlow?.length && (
        <Tooltip
          label={getLabels(productOverFlow)}
          style={{ marginLeft: '-20px' }}
          size='l'
        >
          <PillImageContainer
            $isSmall={size === 's'}
            $bg={
              process.env.REACT_APP_AWS_BUCKET +
              'assets/images/product-background.jpg'
            }
          >
            <PillText>{`+${productOverFlow?.length}`}</PillText>
          </PillImageContainer>
        </Tooltip>
      )}
    </Container>
  )
}

export default ProductsPreviewContainer
