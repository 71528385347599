import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Section, Header, Container, InputContainer } from './style'
import PatientCard from './patient-card'
import { Input, Spinner } from '../../ui'
import {
  usePatients,
  useDeletePatient,
  useDeletePrescription,
} from '../../../utils/hooks'

const PatientsContainer = () => {
  const { data: patients, loading, success } = usePatients()
  const [search, setSearch] = useState('')

  const { deletePrescription } = useDeletePrescription()
  const {
    data: patientData,
    success: deletePatientSuccess,
    failed: deletePatientFailed,
    deletePatient,
  } = useDeletePatient()

  const onDeletePatient = patient => {
    // use promises to make sure that the prescriptions of the patient
    // will be deleted before trying to delete the patient itself
    const promises = []
    if (patient.prescriptions && patient.prescriptions.length > 0) {
      promises.push(
        new Promise((resolve, reject) => {
          patient.prescriptions.forEach(pres => {
            deletePrescription({ variables: { id: pres.id } })
          })
        })
      )
    }
    promises.push(
      new Promise((resolve, reject) => {
        deletePatient({ variables: { id: patient.id } })
      })
    )
    Promise.all(promises)
  }

  useEffect(() => {
    if (patientData || deletePatientSuccess) {
      toast.success(`Le patient a été supprimé avec succès !`)
    }
    if (deletePatientFailed) {
      toast.error(
        `Il y a eu une erreur dans la supression du patient, merci de réessayer.`
      )
    }
  }, [patientData, deletePatientFailed, deletePatientSuccess])

  let dataToDisplay = patients
  if (search) {
    dataToDisplay = patients.filter(patient => {
      const searchValue = search.trim().toLowerCase()

      return (
        patient.email?.trim().toLowerCase().includes(searchValue) ||
        patient.name?.trim().toLowerCase().includes(searchValue) ||
        patient.firstname?.trim().toLowerCase().includes(searchValue)
      )
    })
  }
  dataToDisplay.sort((a, b) => a.id - b.id)

  return (
    <Section>
      <Header>
        <InputContainer>
          <Input
            iconLeft='search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Recherche de patient...'
          />
        </InputContainer>
      </Header>
      <Container>
        {loading && <Spinner />}
        {success &&
          dataToDisplay.map(
            patient =>
              patient && (
                <PatientCard
                  key={patient?.id}
                  patient={patient}
                  onDeletePatient={onDeletePatient}
                />
              )
          )}
      </Container>
    </Section>
  )
}

export default PatientsContainer
