import { useDispatch } from 'react-redux'

import { Field } from '../../../ui'
import { Form } from './style'
import { Container, Title } from '../style'
import { setNewProtocoleDraft } from '../../../../store/protocole'

const CreateProtocoleStep1 = ({ value, setValue, draft: { description } }) => {
  const dispatch = useDispatch()

  return (
    <Container>
      <Title>Informations du protocole</Title>
      <Form>
        <Field
          label='Nom du protocole'
          placeholder='Protocole stress'
          type='text'
          defaultValue={value}
          onChange={event => {
            dispatch(setNewProtocoleDraft({ name: event.target.value }))
            setValue(event.target.value)
          }}
        />
        <Field
          label={`Ajouter une description pour votre protocole`}
          name='message'
          id='message'
          type='textarea'
          onChange={e =>
            dispatch(setNewProtocoleDraft({ description: e.target.value }))
          }
          value={description}
        />
      </Form>
    </Container>
  )
}

export default CreateProtocoleStep1
