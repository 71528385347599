import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import mixpanel from 'mixpanel-browser'

import { Button, ButtonGroup, Step, Dialog } from '../../../components/ui'
import { Container, Content, ActionFooter, DialogContent, Title } from './style'
import CreatePatientForm from '../../../components/patient/create-patient-form'
import CreatePrescriptionStep1 from '../../../components/prescription/create-prescription-form/step-1'
import CreatePrescriptionStep2 from '../../../components/prescription/create-prescription-form/step-2'
import CreatePrescriptionStep3 from '../../../components/prescription/create-prescription-form/step-3'
import Layout from '../../../components/layout/action'
import {
  getPrescriptionNewDraft,
  resetNewPrescriptionDraft,
  setNewPrescriptionDraft,
  setPrescriptionDraftProductTake,
  setNewPrescriptionId,
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  getPrescriptionDraftPrice,
  setTakeLabel,
  setProtocoleToDraft,
  resetProtocoleToDraft,
  addPrescriptionTake,
  removePrescriptionTake,
} from '../../../store/prescription'
import NewPrescriptionDraftPreview from '../../../components/prescription/new-prescription-draft-preview'
import {
  useAddPatient,
  usePrescriptions,
  useAddPrescription,
  useUpdatePrescription,
  useAddPrescriptionTake,
  useOnboardingProfileCheck,
  useAuthPrescriber,
  useProtocoles,
  useProducts,
  useDeletePrescriptionTake,
} from '../../../utils/hooks'

const NewPrescriptionPage = ({ location, history }) => {
  useOnboardingProfileCheck()
  const [step, setStep] = useState(1)
  const [selectedProtocole, setSelectedProtocole] = useState()
  const [modifiedPrescription, setModifiedPrescription] = useState()
  const dispatch = useDispatch()
  const { data: products } = useProducts()
  const { authUser } = useAuthPrescriber()
  const draft = useSelector(getPrescriptionNewDraft)
  const { data: protocoles, loading: protocolesLoading } = useProtocoles()

  const {
    addPatient,
    data: patientData,
    loading: patientLoading,
  } = useAddPatient()
  const { data: prescriptions } = usePrescriptions()
  const {
    addPrescription,
    data: addPrescriptionData,
    loading: addPrescriptionLoading,
  } = useAddPrescription()
  const {
    updatePrescription,
    data: updatePrescriptionData,
    loading: updatePrescriptionLoading,
  } = useUpdatePrescription()
  const { addPrescriptionTakes, loading: addPrescriptionTakeLoading } =
    useAddPrescriptionTake()
  const { deletePrescriptionTake, loading: deletePrescriptionTakeLoading } =
    useDeletePrescriptionTake()
  const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] =
    useState(false)
  const [isBeforeLeaveModalOpen, setIsBeforeLeaveModalOpen] = useState(false)
  const [detailsProductDialog, setDetailsProductDialog] = useState('')

  useEffect(() => {
    const {
      step: urlStep,
      protocoleId,
      prescriptionId,
    } = queryString.parse(location.search)
    if (Number(step) !== Number(urlStep)) {
      history.push({ search: `?step=${step}` })
    }
    if (![1, 2, 3].includes(Number(step))) {
      setStep(1)
    }
    if (protocoleId) {
      const protocole = protocoles.find(p => p.id === Number(protocoleId))
      if (
        protocole &&
        (!selectedProtocole || protocole.id !== selectedProtocole.id)
      ) {
        setSelectedProtocole(protocole)
        dispatch(resetProtocoleToDraft)
        dispatch(setProtocoleToDraft(protocole))
      }
    }
    if (prescriptionId) {
      setStep(2)
      const prescription = prescriptions.find(
        p => p.id === Number(prescriptionId)
      )
      setModifiedPrescription(prescription)
    }
  }, [
    history,
    location.search,
    step,
    protocoles,
    dispatch,
    prescriptions,
    selectedProtocole,
  ])

  useEffect(() => {
    if (!patientData) return
    setIsCreatePatientModalOpen(false)
    const { id, firstname } = patientData.insert_patient.returning[0]
    dispatch(setNewPrescriptionDraft({ patientId: id }))
    toast.success(`${firstname} a été ajouté à la cure avec succès !`)
  }, [patientData, dispatch])

  useEffect(() => {
    if (!addPrescriptionData) return
    setIsCreatePatientModalOpen(false)
    dispatch(resetNewPrescriptionDraft())
    dispatch(
      setNewPrescriptionId(
        addPrescriptionData.insert_prescription.returning[0].id
      )
    )
    history.push('/prescriptions/confirm')
  }, [addPrescriptionData, history, dispatch])

  useEffect(() => {
    if (!updatePrescriptionData) return
    setIsCreatePatientModalOpen(false)
    dispatch(resetNewPrescriptionDraft())
    dispatch(
      setNewPrescriptionId(updatePrescriptionData.update_prescription_by_pk.id)
    )
    history.push('/prescriptions/confirm')
  }, [updatePrescriptionData, addPrescriptionLoading, history, dispatch])

  const updateStep = newStep => {
    const nbNewStep = Number(newStep)
    if (nbNewStep < 1 || nbNewStep > 3) return
    setStep(nbNewStep)
  }

  const onNewPatientSubmitted = newPatientData => {
    addPatient({
      variables: {
        email: newPatientData.email,
        fn: newPatientData.first,
        ln: newPatientData.last,
        phone: newPatientData.phone,
        prescriberId: authUser.id,
      },
    })
  }
  const onPrescriptionSubmitted = async () => {
    if (!modifiedPrescription) {
      const takes = draft.takes
        .filter(t => !!t.items.length)
        .map(take => ({
          index: take.index,
          label: take.label,
          type: take.type,
          prescription_items: {
            data: take.items.map(item => ({
              product_handle: item.handle,
              quantity: item.quantity,
              notes: item.notes,
            })),
          },
        }))

      addPrescription({
        variables: {
          discountCode: authUser.discountCode,
          prescriberId: authUser.id,
          patientId: draft.patientId,
          recommendedDuration: `${draft.duration}-${draft.durationUnit}`,
          customMessage: draft.message,
          purchaseOption: draft?.purchaseOption,
          prescriptionTakes: {
            data: takes,
          },
        },
      })
      mixpanel.track('Create prescription', {
        prescriberId: authUser.id,
        patientId: draft.patientId,
        prescriberFullName: authUser.fullName,
        prescriptionTakes: takes,
      })
    } else {
      console.log(draft.takes, 'draft.takes')
      const takes = draft.takes
        .filter(t => !!t.items.length)
        .map(take => ({
          index: take.index,
          label: take.label,
          prescription_status_id: modifiedPrescription.lastStatus.id,
          type: take.type,
          prescription_items: {
            data: take.items.map(item => ({
              product_handle: item.handle,
              quantity: item.quantity,
              notes: item.notes,
            })),
          },
        }))
      // Because upsert doesn't allow us to update array relationships we delete
      // every take(s) from the prescription and re-insert the ones from the current draft
      await deletePrescriptionTake({
        variables: { prescriptionId: modifiedPrescription.id },
      })
      await addPrescriptionTakes({
        variables: {
          prescriptionTakes: takes,
        },
      })

      await updatePrescription({
        variables: {
          prescriptionId: modifiedPrescription.id,
          discountCode: authUser.discountCode,
          prescriberId: authUser.id,
          patientId: draft.patientId,
          recommendedDuration: `${draft.duration}-${draft.durationUnit}`,
          customMessage: draft.message,
          purchaseOption: draft?.purchaseOption,
        },
      })
      mixpanel.track('Modify prescription', {
        prescriptionId: modifiedPrescription.id,
        prescriberId: authUser.id,
        patientId: draft.patientId,
        prescriberFullName: authUser.fullName,
        prescriptionTakes: takes,
      })
    }
  }
  let isNextStepBlocked = step === 1 || (step === 2 && draft.takes.length)
  return (
    <Layout
      aside={
        <NewPrescriptionDraftPreview
          draft={draft}
          setProductNotesDraft={setProductNotesDraft}
          decreaseQttyProductFromDraftTake={decreaseQttyProductFromDraftTake}
          increaseQttyProductFromDraftTake={increaseQttyProductFromDraftTake}
          removeProductFromDraftTake={removeProductFromDraftTake}
          getDraftPrice={getPrescriptionDraftPrice}
          addTake={addPrescriptionTake}
          removeTake={removePrescriptionTake}
          setTakeLabel={setTakeLabel}
          products={products}
          openDetailModal={handle => setDetailsProductDialog(handle)}
        />
      }
      top={
        <Button
          appearance='minimal'
          iconLeft='arrow-left'
          onClick={() => {
            if (draft.patientId || (draft.takes && draft.takes.length > 0)) {
              setIsBeforeLeaveModalOpen(true)
            } else {
              history.push('/prescriptions')
            }
          }}
        >
          Annuler
        </Button>
      }
    >
      <Container>
        <Content>
          <Step
            currentStep={step}
            texts={['Patient', 'Cure', 'Finalisation']}
          />
          {step === 1 && (
            <CreatePrescriptionStep1
              onCreatePatient={() => setIsCreatePatientModalOpen(true)}
            />
          )}
          {step === 2 && (
            <CreatePrescriptionStep2
              draft={draft}
              setDraftProductTake={setPrescriptionDraftProductTake}
              canSelectProtocole={true}
              setProtocoleToDraft={setProtocoleToDraft}
              resetProtocoleToDraft={resetProtocoleToDraft}
              selectedProtocole={selectedProtocole}
              protocoles={protocoles}
              protocolesLoading={protocolesLoading}
              productSearchBarContent={'ou ajouter produit par produit'}
              detailModalOpenHandle={detailsProductDialog}
              closeDetailModal={() => setDetailsProductDialog('')}
              openDetailModal={handle => setDetailsProductDialog(handle)}
            />
          )}
          {step === 3 && (
            <CreatePrescriptionStep3
              draft={draft}
              setNewDraft={setNewPrescriptionDraft}
              shareProtocole={false}
            />
          )}
        </Content>
        <ActionFooter>
          <div>
            {step !== 1 && (
              <Button
                onClick={() => updateStep(step - 1)}
                isDisabled={step <= 1}
              >
                Précédent
              </Button>
            )}
          </div>
          <div>
            {step !== 3 && (
              <Button
                onClick={() => {
                  if (isNextStepBlocked) {
                    updateStep(step + 1)
                  }
                }}
                isDisabled={!isNextStepBlocked}
                appearance='primary'
              >
                Continuer
              </Button>
            )}
            {step === 3 && (
              <Button
                onClick={onPrescriptionSubmitted}
                isLoading={
                  addPrescriptionLoading ||
                  updatePrescriptionLoading ||
                  deletePrescriptionTakeLoading ||
                  addPrescriptionTakeLoading
                }
                appearance='primary'
                isDisabled={!draft.patientId}
              >
                Créer
              </Button>
            )}
          </div>
        </ActionFooter>
      </Container>
      <Dialog
        type='aside'
        isOpen={isCreatePatientModalOpen}
        onClose={() => setIsCreatePatientModalOpen(false)}
      >
        <DialogContent>
          <Title>Céez un nouveau patient pour votre prescription</Title>
          <CreatePatientForm
            onSubmit={onNewPatientSubmitted}
            isLoading={patientLoading}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        type='default'
        isOpen={isBeforeLeaveModalOpen}
        onClose={() => setIsBeforeLeaveModalOpen(false)}
      >
        <DialogContent>
          <Title isCenter>
            Attention, si vous annulez toutes vos données encodées seront
            perdues.
            <br />
            <br />
            Êtes-vous certain de vouloir quitter la création de votre
            recommandation ?
          </Title>
          <ButtonGroup spaced>
            <Button
              appearance='minimal'
              onClick={() => setIsBeforeLeaveModalOpen(false)}
            >
              Non, je continue ma recommandation
            </Button>
            <Button
              onClick={() => {
                dispatch(resetNewPrescriptionDraft())
                history.push('/prescriptions')
              }}
            >
              Oui, je suis sûr
            </Button>
          </ButtonGroup>
        </DialogContent>
      </Dialog>
    </Layout>
  )
}

export default NewPrescriptionPage
