import { gql } from '@apollo/client'

const cartObject = `
cart {
  id
  checkoutUrl
  createdAt
  updatedAt
  lines(first: 25) {
    edges {
      node {
        id
        quantity
        attributes {
          key
          value
        }
        merchandise {
          ... on ProductVariant {
            id
            title
            sellingPlanAllocations(first: 10) {
              edges {
                node {
                  sellingPlan {
                    id
                    name
                    recurringDeliveries
                  }
                }
              }
            }
            product {
              handle
            }
          }
        }
      }
    }
  }
  attributes {
    key
    value
  }
  estimatedCost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
  }
}
`

const fetchCartObject = `
cart (id: $cartId) {
  id
  checkoutUrl
  createdAt
  updatedAt
  lines(first: 25) {
    edges {
      node {
        id
        quantity
        attributes {
          key
          value
        }
        merchandise {
          ... on ProductVariant {
            id
            title
            sellingPlanAllocations(first: 10) {
              edges {
                node {
                  sellingPlan {
                    id
                    name
                    recurringDeliveries
                  }
                }
              }
            }
            product {
              handle
            }
          }
        }
      }
    }
  }
  attributes {
    key
    value
  }
  estimatedCost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
  }
}
`

export const CREATE_CART = gql`
  mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      ${cartObject}
    }
  }
`

export const UPDATE_CART = gql`
mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    ${cartObject}
  }
}
`

export const ADD_CART = gql`
  mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      ${cartObject}
    }
  }
`

export const FETCH_CART = gql`
  query getCart($cartId: ID!) {
    ${fetchCartObject}
  }
`

export const CART_LINES_REMOVE = gql`
mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    ${cartObject}
  }
}
`

export const CART_ATTRIBUTES_UPDATE = gql`
mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
  cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
    ${cartObject}
  }
}
`
