import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  ProductPreview,
  Img,
  Block1,
  Block2,
  ProductName,
  Quantity,
  Minus,
  Plus,
  Number,
  SmallPrice,
  Title,
  Form,
  IconsContainer,
  MainContainer,
  NotesContainer,
} from './style'
import { Button, Dialog, Field, Icon, Tooltip, Spinner } from '../../../ui'
import { getProductPicture } from '../../../../utils/helpers'

const ProductPreviewEl = ({
  productHandle,
  quantity,
  notes,
  takeIndex,
  recommendedDuration,
  isLast,
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  products,
  onDetailOpen,
}) => {
  const dispatch = useDispatch()
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)

  const [note, setNote] = useState('' || notes)
  const [noteHasChanged, setNoteHasChanged] = useState(false)
  const product = products.find(p => p.handle === productHandle)
  const nbMonth = recommendedDuration ? recommendedDuration.split('-')[0] : 1
  useEffect(() => {
    if (notes !== note && !noteHasChanged) {
      setNote(notes)
    }
  }, [notes, note, noteHasChanged])

  const handleNoteModalClose = () => {
    dispatch(
      setProductNotesDraft({
        productHandle,
        notes: note,
        takeIndex,
      })
    )
    setNoteHasChanged(false)
    setIsNotesModalOpen(false)
  }

  return (
    <>
      {!product ? (
        <Spinner />
      ) : (
        <ProductPreview isLast={isLast}>
          <MainContainer>
            <Img src={getProductPicture(productHandle)} alt={productHandle} />
            <Block1>
              <ProductName>{product.name}</ProductName>
              <Quantity>
                {quantity * product.formatQuantity} {product.formatType}
                {!product.isStandAlone && ` par mois pendant ${nbMonth} mois`}
              </Quantity>
              <Quantity>
                Quantité:&nbsp;&nbsp;&nbsp;
                <Minus
                  onClick={() =>
                    dispatch(
                      decreaseQttyProductFromDraftTake({
                        productHandle,
                        takeIndex,
                      })
                    )
                  }
                />
                <Number>{quantity}</Number>
                <Plus
                  onClick={() =>
                    dispatch(
                      increaseQttyProductFromDraftTake({
                        productHandle,
                        takeIndex,
                      })
                    )
                  }
                />
              </Quantity>
            </Block1>
            <Block2>
              <SmallPrice>
                €{product.getPrice(recommendedDuration || '1-month') * quantity}
              </SmallPrice>
              <IconsContainer>
                <Tooltip label='Voir les détails du produit'>
                  <Icon name='info' onClick={onDetailOpen} />
                </Tooltip>
                <Tooltip
                  label={
                    notes && notes.length
                      ? 'Gérer la note'
                      : "Ajouter un conseil d'utilisation"
                  }
                >
                  <Icon
                    name={
                      notes && notes.length ? 'comment-reversed' : 'comment'
                    }
                    onClick={() => setIsNotesModalOpen(true)}
                  />
                </Tooltip>
                <Tooltip label='Retirer de la cure'>
                  <Icon
                    name='trash'
                    onClick={() =>
                      dispatch(
                        removeProductFromDraftTake({ product, takeIndex })
                      )
                    }
                  />
                </Tooltip>
              </IconsContainer>
            </Block2>
          </MainContainer>
          {!!notes.length && (
            <NotesContainer>
              <p>{note}</p>
            </NotesContainer>
          )}
          <Dialog isOpen={isNotesModalOpen} onClose={handleNoteModalClose}>
            <Title>
              Ajouter un conseil d'utilisation pour "{product.name}"
            </Title>
            <Form
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <Field
                label='Ces conseils seront ajoutés au livret que votre patient recevra avec sa cure.'
                id={`note-${productHandle}`}
                type='textarea'
                placeholder={`Exemple: "Prenez ce complément dans un grand verre d'eau"`}
                value={note}
                onChange={e => {
                  setNoteHasChanged(true)
                  setNote(e.target.value)
                }}
              />
              <Button onClick={handleNoteModalClose}>Continuer</Button>
            </Form>
          </Dialog>
        </ProductPreview>
      )}
    </>
  )
}

ProductPreviewEl.propTypes = {
  productHandle: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  onDetailOpen: PropTypes.func,
}

export default ProductPreviewEl
