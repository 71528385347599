import styled from 'styled-components/macro'

import { Cell } from '../style'

export const PatientCell = styled(Cell)`
  display: flex;
  padding: 10px 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const P = styled.p`
  font-size: 14px;

  &:first-child {
    margin-bottom: 2px;
  }
`
