import { Container, Content } from './style'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import IntegrationsContainer from '../../components/integrations/integrations-container'
import {
  usePrescriptions,
  usePatients,
  useOnboardingProfileCheck,
} from '../../utils/hooks'

const IntegrationsPage = ({ location }) => {
  useOnboardingProfileCheck()
  usePrescriptions()
  usePatients()

  return (
    <Layout>
      <PageTitle
        title='Mes intégrations'
        subtitle={`Intégrez des applications tierces pour faciliter votre utilisation de ComplimentPRO.`}
      />
      <Container>
        <Content>
          <IntegrationsContainer location={location} />
        </Content>
      </Container>
    </Layout>
  )
}

export default IntegrationsPage
