import { useState, useEffect } from 'react'

import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import {
  ButtonWrapper,
  Container,
  Content,
  ProductsContainer,
  InputContainer,
  HeaderContainer,
  Row,
} from './style'
import ProductPreviewCard from '../../components/product/product-preview-card'
import ProductSearchBar from '../../components/product-search'
import { Button, Tooltip } from '../../components/ui'
// import FilterBar from '../../components/filter-bar'
import { useProducts, useOnboardingProfileCheck } from '../../utils/hooks'
import { Spinner, Input } from '../../components/ui'
import {
  sortAscendingByProductName,
  normalizeString,
} from '../../utils/helpers'
import { ViewMode, ProductCategory } from '../../utils/constants'

const ProductsPage = () => {
  useOnboardingProfileCheck()
  const [productsToDisplay, setProductsToDisplay] = useState()
  const [filters, setFilters] = useState([])
  const { data: products, loading, success } = useProducts()
  const [viewMode, setViewMode] = useState(ViewMode.GRID)
  const [productDetailsModal, setProductDetailsModal] = useState('')

  useEffect(() => {
    setProductsToDisplay(
      products.filter(p => p.isShown).sort(sortAscendingByProductName)
    )
  }, [products])

  useEffect(() => {
    if (filters.length > 0) {
      const filteredProducts = products.filter(p => {
        const loweredTags = p.tags.map(t => normalizeString(t))

        return (
          filters.find(f => loweredTags.indexOf(normalizeString(f)) !== -1) &&
          p.isShown
        )
      })
      setProductsToDisplay(filteredProducts.sort(sortAscendingByProductName))

      return
    }
    setProductsToDisplay(
      products.filter(p => p.isShown).sort(sortAscendingByProductName)
    )
  }, [products, filters])

  return (
    <Layout>
      <PageTitle
        title='Découvrez la gamme de produits'
        rightElements={
          <>
            <Tooltip size='l' label='Télécharger la gamme au format PDF'>
              <Button
                appearance='minimal'
                iconLeft='download'
                as='a'
                href={
                  process.env.REACT_APP_AWS_BUCKET +
                  'assets/pdfs/compliment_pro_brochure.pdf'
                }
                target='_blank'
              />
            </Tooltip>
            <Tooltip
              size='l'
              label={
                viewMode === ViewMode.LIST
                  ? 'Passer en vue grille'
                  : 'Passer en vue liste'
              }
            >
              <Button
                appearance='minimal'
                iconLeft={viewMode === ViewMode.LIST ? 'grid' : 'list'}
                onClick={() =>
                  setViewMode(
                    viewMode === ViewMode.LIST ? ViewMode.GRID : ViewMode.LIST
                  )
                }
              />
            </Tooltip>
          </>
        }
      />
      <Container>
        <Content>
          <HeaderContainer>
            <Row>
              <InputContainer>
                <ProductSearchBar
                  setProductsToDisplay={setProductsToDisplay}
                  products={products}
                  onDetailsClicked={handle => {
                    console.info('should open details of:', handle)
                    setProductDetailsModal(handle)
                  }}
                  hasDetailsBtn
                />
              </InputContainer>
              <ButtonWrapper>
                <Input
                  type='select'
                  options={Object.values(ProductCategory).map(value => ({
                    label: value,
                    value: normalizeString(value),
                  }))}
                  isMulti
                  onChange={newFilters =>
                    setFilters(newFilters.map(({ value }) => value))
                  }
                  hideSelectedOptions
                  isSearchable
                  closeMenuOnSelect={false}
                  inputContainerStyling={{
                    width: '500px',
                  }}
                  placeholder='Filter'
                />
              </ButtonWrapper>
            </Row>
          </HeaderContainer>
          {loading && <Spinner />}
          {success && (
            <ProductsContainer $mode={viewMode}>
              {productsToDisplay ? (
                productsToDisplay.map(product => (
                  <ProductPreviewCard
                    key={product.handle}
                    product={product}
                    recommendedDuration='1-month'
                    mode={viewMode}
                    hasDetailModalOpen={productDetailsModal === product.handle}
                    closeDetailModal={() => setProductDetailsModal('')}
                  />
                ))
              ) : (
                <Spinner />
              )}
            </ProductsContainer>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default ProductsPage
